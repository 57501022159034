import type { GridColDef } from '@mui/x-data-grid-pro';
import { numberFormatter } from '../../../../../libs/common/unitConverters';
import TextFieldForDataGrid from '../../../../../components/common/TextFieldForDataGrid';

const gridColDef = [
  {
    field: 'itemId',
    headerName: '상품ID',
    minWidth: 60,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'barcode',
    headerName: '상품바코드',
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'itemName',
    headerName: '상품명',
    minWidth: 180,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'qty',
    headerName: '입고수량',
    minWidth: 60,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ field, id, row, value }) =>
      row.rowIndex === 'total' ? (
        numberFormatter(value)
      ) : (
        <TextFieldForDataGrid field={field} id={id} value={value} />
      ),
  },
  {
    field: 'cost',
    headerName: '입고단가(원)',
    minWidth: 60,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ field, id, row, value }) =>
      row.rowIndex === 'total' ? (
        numberFormatter(value)
      ) : (
        <TextFieldForDataGrid field={field} id={id} value={value} />
      ),
  },
  {
    field: 'total',
    headerName: '입고소계(원)',
    minWidth: 60,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => numberFormatter(value),
  },
] as GridColDef[];

export default gridColDef;
