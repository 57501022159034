import React, { type ChangeEvent, useState } from 'react';
import { type AxiosResponse } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import usePopup from 'hooks/usePopup';
import { COLORS } from 'consts/common/colors';
import { INSPECTION } from 'consts/outbound/messages';
import ListTable from '../../../components/ListTable';
import { TableCell, TableRow } from '../../../../components/Table';
import { ErrorDetails, errorTitle } from '../ItemListLine';
import { createInspectionHistory, type HistoryParams } from '../../services';
import {
  columns,
  getTotalRemainingQuantity,
  makeColumns,
  makeHistoryDescription,
  makeTextByType,
} from './utils';

const modalWidth = 800;

type SelectedDefect = 'DEFECT' | 'EXPIRATION';

type Props = {
  inspectionId: number;
  errorDetails: ErrorDetails;
  onClose: () => void;
  resetStep: () => void;
};

const ErrorModal = (props: Props) => {
  const { inspectionId, errorDetails, onClose, resetStep } = props;
  const { showAlert, showSnackbar } = usePopup();

  const [selectedDefect, setSelectedDefect] = useState<SelectedDefect>('DEFECT');
  const [remark, setRemark] = useState('');

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDefect((event.target as HTMLInputElement).value as SelectedDefect);
  };

  const handleRemarkChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRemark((event.target as HTMLInputElement).value);
  };

  const textByType = makeTextByType(errorDetails.type);
  const newColumns = makeColumns(columns, errorDetails.type);

  const handleStopInspection = async () => {
    const historyDescription = makeHistoryDescription(errorDetails, remark);

    if (checkDefectReasonIsNotEmpty(historyDescription)) {
      requestDefectReason();
      return;
    }

    const errorRequestBody = makeErrorRequestBody(historyDescription);

    const response = await createInspectionHistory(errorRequestBody);

    if (response?.status === 200) {
      showSuccessOfStopInspection(errorRequestBody);
    } else {
      showFailOfStopInspection(errorRequestBody, response);
    }
  };

  const checkDefectReasonIsNotEmpty = (historyDescription: string) => {
    return (
      errorDetails.type !== 'MISSING' &&
      historyDescription === '' &&
      errorDetails.type !== 'WRONG' &&
      historyDescription === ''
    );
  };

  const requestDefectReason = () => {
    showSnackbar({
      message:
        selectedDefect === 'DEFECT'
          ? INSPECTION.inspectionDefectRemarkEmpty
          : INSPECTION.inspectionExpirationRemarkEmpty,
      severity: 'error',
    });
  };

  const makeErrorRequestBody = (historyDescription: string) => ({
    inspectionId,
    inspectionItems: errorDetails.items.map(item => ({
      barcode: item.barcode,
      itemId: item.itemId,
      inspectionQuantity: errorDetails.type === 'MISSING' ? item.remainingQuantity : 1,
    })),
    historyType: errorDetails.type === 'DEFECT' ? selectedDefect : errorDetails.type,
    historyDescription,
  });

  const showSuccessOfStopInspection = (errorRequestBody: HistoryParams) => {
    if (!errorRequestBody.historyType) {
      alertHistoryTypeEmpty(errorRequestBody);
      return;
    }

    datadogRum.addAction(
      `${errorTitle[errorRequestBody.historyType]}(${
        errorRequestBody.historyType
      }) 검수중지 성공: ${errorRequestBody.historyType}`
    );
    showSnackbar({
      message: '검수중지가 성공하였습니다.',
      severity: 'success',
    });
    onClose();
    resetStep();
  };

  const showFailOfStopInspection = (
    errorRequestBody: HistoryParams,
    response: AxiosResponse<any, any> | undefined
  ) => {
    if (!errorRequestBody.historyType) {
      alertHistoryTypeEmpty(errorRequestBody);
      return;
    }

    datadogRum.addError(
      `${errorTitle[errorRequestBody.historyType]}(${
        errorRequestBody.historyType
      }) 검수중지 실패: ${errorRequestBody.inspectionId}`,
      { errorMessage: response?.data.errorMessage }
    );
    showAlert({
      message: INSPECTION.inspectionCreateHistoryFailure,
      onClick: () => onClose(),
    });
  };

  const alertHistoryTypeEmpty = (errorRequestBody: HistoryParams) => {
    datadogRum.addAction(`검수오류타입에 대한 정보가 없음`, {
      errorRequestBody,
    });
    showAlert({
      message: INSPECTION.inspectionHistoryTypeNotExisted,
      onClick: () => onClose(),
    });
  };

  const isFaultyType = errorDetails.type === 'EXPIRATION' || errorDetails.type === 'DEFECT';

  return (
    <Box width={modalWidth}>
      <Box>
        {isFaultyType && (
          <Box sx={styles.faultyInfoContainer}>
            <TableRow>
              <TableCell
                label={'불량구분'}
                valueSx={styles.faultyRadioContainer}
                value={
                  <FormControl>
                    <RadioGroup
                      value={selectedDefect}
                      onChange={handleTypeChange}
                      sx={styles.faultyRadio}
                    >
                      <FormControlLabel value="DEFECT" control={<Radio />} label="물품불량" />
                      <FormControlLabel
                        value="EXPIRATION"
                        control={<Radio />}
                        label="유통기한 판매불가"
                      />
                    </RadioGroup>
                  </FormControl>
                }
              />
            </TableRow>
            <TableRow height={'92px'}>
              <TableCell
                label={selectedDefect === 'DEFECT' ? '불량사유' : '유통기한'}
                value={
                  <TextField
                    multiline
                    rows={2}
                    sx={styles.faultyRemark}
                    value={remark}
                    onChange={handleRemarkChange}
                  />
                }
                valueSx={styles.faultyRemarkContainer}
              />
            </TableRow>
          </Box>
        )}
        <ListTable
          rows={errorDetails.items.map(item => ({ ...item, id: item.itemId }))}
          columns={newColumns}
          hideFooter={errorDetails.type !== 'MISSING'}
          sx={styles.listTable}
          total={getTotalRemainingQuantity(errorDetails)}
        />
        <Box sx={styles.information}>
          <Typography sx={styles.informationText}>
            해당 아이템은&nbsp;<span style={{ fontWeight: 'bold' }}>{textByType.firstLine}</span>
            &nbsp;입니다.
          </Typography>
          <Typography sx={styles.informationText}>{textByType.secondLine}</Typography>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <Button sx={styles.button} onClick={handleStopInspection} variant="contained">
            검수중지
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorModal;

const styles = {
  faultyInfoContainer: { border: `1px solid ${COLORS.lightLine}`, my: 2 },
  faultyRadioContainer: { width: '640px' },
  faultyRadio: { display: 'flex', flexDirection: 'row' },
  faultyRemarkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '640px',
  },
  faultyRemark: { my: 1, width: '620px' },
  listTable: { maxHeight: '400px', mb: 3 },
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
};
