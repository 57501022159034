import _ from 'lodash';
import { RequestBodyForUpdate } from '../services/devices';
import { DeviceResponse as Data, Devices } from '../../../outbound/devices-works/index.page';
import { DeviceForm } from '../components/DevicesForm';
import { ValueByCode } from '../../../../../types/refCodes';

export const getSelectedDevice = (devices: Devices[], selectedDeviceId: number) => {
  return devices.find(row => row.id === selectedDeviceId);
};

const deviceFormKeys = [
  'id',
  'deviceNumber',
  'deviceType',
  'workType',
  'isWorkAvailable',
  'isWorkAssigned',
  'warehouseId',
] as const;

export const generateDeviceForm = (device: Devices | undefined) => {
  return _.pick(device, deviceFormKeys);
};

const RequestBodyForUpdateKeys = ['workType', 'isWorkAvailable'] as const;
export const makeRequestBodyForUpdate = (device: RequestBodyForUpdate) => {
  return _.pick(device, RequestBodyForUpdateKeys);
};

export const adjustRows = (data: Data) => {
  const { content, totalElements, size, number } = data;

  const makeRowIndex = (index: number) => {
    return totalElements - size * number - index;
  };

  return content.map((item, index) => ({
    ...item,
    rowIndex: makeRowIndex(index),
  }));
};
