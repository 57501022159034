import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useSWR from 'swr';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import fetcher from 'libs/common/fetcher';
import { COLORS } from 'consts/common/colors';
import type { Packing } from 'types/outbound';
import PopupTemplate from '../../../components/PopupTemplate';
import ListTable from '../../../components/ListTable';

type History = {
  errorCode: string;
  errorMessage: string;
  createdAt: string;
};

const PackingErrors = () => {
  const { id } = useParams();
  const { data, error } = useSWR<History[]>(`/packings/${id}/histories`, fetcher);
  const { data: packing } = useSWR<Packing>(`/packings/${id}`, fetcher);
  const histories = data
    ?.map((history, i) => ({
      ...history,
      order: i + 1,
      id: history.createdAt,
      createdAt: new Date(history.createdAt).toLocaleString('sv-SE'),
    }))
    .sort((a, b) => b.order - a.order);

  return (
    <>
      {error ? (
        '오류 내역을 불러오지 못했습니다'
      ) : (
        <>
          <HelmetProvider>
            <Helmet>
              <title>케타포 GMS - 포장 오류내역 조회</title>
            </Helmet>
          </HelmetProvider>
          <PopupTemplate
            title="포장 오류내역 조회"
            info={
              <>
                <Typography>배송ID</Typography>
                <Typography sx={{ fontWeight: 700, color: COLORS.ktown4u }}>
                  {packing?.deliverySeq}
                </Typography>
              </>
            }
            subTitle="포장 오류내역 목록"
            main={<ListTable hideFooter={true} columns={columns} rows={histories ?? []} />}
            footer={
              <Button variant="outlined" sx={{ width: '75px' }} onClick={() => window.close()}>
                닫기
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

export default PackingErrors;

const columns = [
  {
    headerName: 'NO',
    field: 'order',
    width: 20,
  },
  {
    headerName: '오류 케이스',
    field: 'errorCode',
    width: 100,
  },
  {
    headerName: '오류내역 기록',
    field: 'errorMessage',
    width: 120,
  },
  {
    headerName: '오류 발생 일시',
    field: 'createdAt',
    width: 80,
  },
];
