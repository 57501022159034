import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { inputAutoFocusAtom } from '../../../store';

const AutoFocusOffDialog = (props: DialogProps) => {
  const { open } = props;
  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);

  useEffect(() => {
    if (open) {
      setInputAutoFocus(false);
    }
  }, [open]);

  return <Dialog {...props} />;
};

export default AutoFocusOffDialog;
