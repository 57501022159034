import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';

const gridColumns2 = () => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'areaCode',
      headerName: '구역',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'stocktakingLocationCount',
      headerName: '조사대상 로케이션',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'statusByOpenCount',
      headerName: '조사대기 로케이션',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'statusByProcessingCount',
      headerName: '조사진행중 로케이션',
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'statusByCompletedCount',
      headerName: '조사완료 로케이션',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'statusByCompletedPercent',
      headerName: '조사완료율(%)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return Math.floor(value * 10) / 10;
      },
    },
  ];
  return columns;
};

export default gridColumns2;
