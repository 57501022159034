import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

type Props = {
  onSaveClick: () => void;
  onCloseClick: () => void;
};

const SaveButtonGroup = (props: Props) => {
  const { onSaveClick, onCloseClick } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        marginTop: 2,
      }}
    >
      <Button variant="outlined" onClick={onCloseClick}>
        닫기
      </Button>
      <Button variant="contained" onClick={onSaveClick}>
        재고 추가
      </Button>
    </Box>
  );
};

export default SaveButtonGroup;
