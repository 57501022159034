import React from 'react';

const ConnectIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_81_16349)">
        <path
          d="M10.351 11.0344L13.1047 13.7979"
          stroke="#222832"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.87091 24.8519L6.71833 25.5257"
          stroke="#222832"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.4809 3.24194L25.1547 7.08937"
          stroke="#222832"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.3698 53.0632L49.6063 50.2997"
          stroke="#222832"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.5621 60.5432L37.8883 56.6958"
          stroke="#222832"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.1721 38.9332L56.3247 38.2496"
          stroke="#222832"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.8731 21.7662C30.3497 23.2895 29.6662 25.3207 29.8029 27.3127C31.3164 27.7814 32.7421 28.6115 33.9432 29.8028L34.1874 30.0469C38.1227 33.9822 38.1227 40.3686 34.1874 44.3039L24.2759 54.2154C20.3406 58.1507 13.9542 58.1507 10.0189 54.2154L9.77479 53.9713C5.83949 50.0359 5.83949 43.6496 9.77479 39.7143L17.7333 31.7558C16.9814 28.8263 16.9033 25.7601 17.5087 22.8013C16.5713 23.4165 15.6826 24.1391 14.8526 24.9691L4.9411 34.8806C-1.65029 41.472 -1.65029 52.2038 4.9411 58.805L5.18523 59.0491C11.7766 65.6405 22.5084 65.6405 29.1096 59.0491L39.0211 49.1376C45.6124 42.5462 45.6124 31.8144 39.0211 25.2132L38.7769 24.9691C36.9704 23.1626 34.8416 21.8638 32.5859 21.0533L31.8731 21.7662Z"
          fill="#222832"
        />
        <path
          d="M34.8807 4.95087L24.9692 14.8624C18.3778 21.4538 18.3778 32.1855 24.9692 38.7867L25.2133 39.0308C27.0198 40.8374 29.1486 42.1361 31.4043 42.9466L32.1172 42.2337C33.6405 40.7104 34.3241 38.6793 34.1874 36.6872C32.6738 36.2185 31.2481 35.3885 30.047 34.1971L29.8029 33.953C25.8676 30.0177 25.8676 23.6314 29.8029 19.6961L39.7144 9.78456C43.6497 5.84925 50.036 5.84925 53.9713 9.78456L54.2154 10.0287C58.1507 13.964 58.1507 20.3503 54.2154 24.2856L46.2569 32.2441C47.0089 35.1736 47.087 38.2399 46.4815 41.1987C47.419 40.5835 48.3076 39.8609 49.1376 39.0308L59.0491 29.1193C65.6405 22.5279 65.6405 11.7962 59.0491 5.195L58.805 4.95087C52.2136 -1.64052 41.4818 -1.64052 34.8807 4.95087Z"
          fill="#222832"
        />
      </g>
      <defs>
        <clipPath id="clip0_81_16349">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConnectIcon;
