import type { GridColDef } from '@mui/x-data-grid-pro';
import Link from '@mui/material/Link';
import { Warehouse } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import usePopupWindow from '../../../../hooks/usePopupWindow';

const gridColumns = (warehouseOption: Warehouse[]) => {
  const { openPopupWindow } = usePopupWindow();

  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'sameShippingCount',
      headerName: '동일포장건수',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'sku',
      headerName: '품목수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: params => (
        <Link
          onClick={() =>
            openPopupWindow({
              url: `/gms/outbound/shippings/${params.row.keyId}/items`,
              features: {
                width: 1100,
                height: 600,
                left: 100,
                top: 100,
              },
              body: {
                sku: params.row.sku,
                quantity: params.row.quantity,
                tubeQuantity: params.row.tubeQuantity,
                expectedWeight: params.row.expectedWeight,
              },
            })
          }
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'quantity',
      headerName: '아이템수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: params => (
        <Link
          onClick={() =>
            openPopupWindow({
              url: `/gms/outbound/shippings/${params.row.keyId}/items`,
              features: {
                width: 1100,
                height: 600,
                left: 100,
                top: 100,
              },
              body: {
                sku: params.row.sku,
                quantity: params.row.quantity,
                tubeQuantity: params.row.tubeQuantity,
                expectedWeight: params.row.expectedWeight,
              },
            })
          }
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'tubeQuantity',
      headerName: '지관통수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'expectedWeight',
      headerName: '실제중량무게(g)',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
