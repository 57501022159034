import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';
import { isFetchingPackingAtom, packingAtom, stepAtom } from 'store/outbound/packing.recoil';
import { PACKING } from 'consts/outbound/messages';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import useBarcode from '../../hooks/useBarcode';
import BarcodeInput from '../../components/BarcodeInput';
import TimelineItem from '../../components/TimelineItem';
import Table, { TableCell, TableRow } from '../../../components/Table';
import { changeToteRequest, getPackingToChangeTote } from '../services/packing';
import { afterProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';

const firstStep = 0;

const ToteCodeLine = () => {
  const { barcode, setBarcode, handleFetchError } = useBarcode();
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const [isLoading, setIsLoading] = useRecoilState(isFetchingPackingAtom);
  const [packing, setPacking] = useRecoilState(packingAtom);

  useEffect(() => {
    if (selectedStep === firstStep) {
      setBarcode('');
    }
  }, [selectedStep, setBarcode]);
  const carriers = useRecoilValue(carriersAtom);
  const handleDeliveryIdSubmit = async () => {
    setIsLoading(true);

    const request: changeToteRequest = {
      barcode: barcode,
    };

    try {
      const packing = await getPackingToChangeTote(request);
      setPacking(packing);
      setBarcode('');
      setSelectedStep(1);
    } catch (error) {
      handleFetchError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TimelineItem
        step={0}
        inProgressComponent={
          <>
            <Typography sx={inProgressTitle}>{PACKING.changeTote}</Typography>
            <BarcodeInput
              value={barcode}
              onSubmit={handleDeliveryIdSubmit}
              onChange={e => setBarcode(e.target.value)}
              label="바코드"
              isLoading={isLoading}
            />
          </>
        }
        afterProgressComponent={
          <>
            <Box sx={styles.titleContainer}>
              <Typography sx={afterProgressTitle}>{PACKING.changeTote}</Typography>
            </Box>
            <Table>
              <TableRow>
                <TableCell sx={styles.tableCell} label="토트" value={packing?.toteBarcode} />
                <TableCell sx={styles.tableCell} label="출고ID" value={packing?.serialNumber} />
              </TableRow>
              <TableRow>
                <TableCell
                  sx={styles.tableCell}
                  label="배송방식"
                  value={convertCarrierId(packing?.carrierId as number, carriers)}
                />
                <TableCell
                  sx={styles.tableCell}
                  label="최대무게제한(g)"
                  value={packing?.maxWeight?.toLocaleString()}
                />
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCell} label="아이템수량" value={packing?.totalQty} />
              </TableRow>
            </Table>
          </>
        }
      />
    </>
  );
};

export default ToteCodeLine;

const styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  barcodeButtonContainer: { display: 'flex', alignItems: 'center', gap: '8px' },
  button: { height: '40px', fontSize: '17px' },
  tableCell: { fontSize: '16px' },
};
