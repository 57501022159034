import React, { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import { GridRowParams } from '@mui/x-data-grid-pro';
import fetcher from 'libs/common/fetcher';
import Page from 'components/common/Layout/Page';
import type { Form } from 'components/common/Filter';
import Filter from 'components/common/Filter';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { carrierOptionsAtom, carriersAtom } from '../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import useWaybillsPrint from './hook/useWaybillsPrint';
import LoadingModal from '../components/LoadingModal';
import TightDataGridPro from 'components/common/TightDataGridPro';
import type { SummarizedPacking } from 'types/outbound';
import type { Options } from 'types/form';
import { isPrintingAtom } from 'store/common/printer.recoil';
import { packingBoxOptionsAtom, packingIdsToPrintAtom } from 'store/outbound/packings.recoil';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import gridColumns from './gridColDef';
import convertUser from '../../../../libs/common/convertUser';
import { convertCarrierId } from '../../../../libs/common/convertCarrier';
import usePopup from '../../../../hooks/usePopup';
import FormModal from '../../../../components/common/FormModal';
import useConditionalSWR from '../../components/useConditionalSwr';
import { generateErrorExcelDownload, generateExcelDownload } from './services/excelDownload';
import JsonModal, { SelectedPackingForJson } from './components/JsonModal';

export type SearchQuery = {
  deliveryId?: string;
  deliverySeq?: string;
  page?: number;
  size?: number;
  status?: string;
  warehouseId?: number;
};

export type Packings = {
  content: SummarizedPacking[];
  totalElements: number;
  size: number;
  number: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };
const searchKeywordOptions: Options = [
  {
    displayName: '배송번호',
    field: 'deliveryId',
  },
  {
    displayName: '배송ID',
    field: 'deliverySeq',
  },
  {
    displayName: '송장번호',
    field: 'trackingNumber',
  },
  {
    displayName: '출고ID',
    field: 'shippingSerialNumber',
  },
];
const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시생성일',
    field: 'shippingOrderCreatedAt',
  },
  {
    displayName: '집품그룹생성일',
    field: 'pickingGroupCreatedAt',
  },
  {
    displayName: '집품완료일',
    field: 'pickingCompletedAt',
  },
  {
    displayName: '검수완료일',
    field: 'inspectCompletedAt',
  },
  {
    displayName: '포장완료일',
    field: 'packingCompletedAt',
  },
];

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '140px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1.4fr)',
  '50px',
  'minmax(55px, 0.8fr)',
  'minmax(55px, 0.8fr)',
  '80px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Packings = () => {
  const { showDialog, showErrorDialog, showSnackbar } = usePopup();
  const isPrinting = useRecoilValue(isPrintingAtom);
  const [hasSearched, setHasSearched] = useState(false);
  const [packingIdsToPrint, setPackingIdsToPrint] = useRecoilState(packingIdsToPrintAtom);
  const [isDownload, setIsDownload] = useState(false);
  const [selectedPackingForJson, setSelectedPackingForJson] = useState<SelectedPackingForJson>();
  const [isErrorDownload, setIsErrorDownload] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const packingBoxOptions = useRecoilValue(packingBoxOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);

  const multipleCarrierSelectRef = useRef<{ initialValues: () => void }>(null);

  const { data, mutate, isValidating } = useConditionalSWR<Packings>(
    [`/packings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );
  console.log(data);
  const packingStatusOptions = refCodeOptions?.packingStatus || [];
  const makeRowIndex = (data: Packings) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: any, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser:
        item.status != 'READY' ? convertUser(item.updatedUserName, item.updatedUserLoginId) : '',
      updatedAt: item.status != 'READY' ? item.updatedAt : '',
      boxWidth: item.packingDimensions?.widthInMillimeters ?? '',
      boxLength: item.packingDimensions?.lengthInMillimeters ?? '',
      boxHeight: item.packingDimensions?.heightInMillimeters ?? '',
    }));
  };

  const handleInitClick = async () => {
    multipleCarrierSelectRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const packingsToPrint = data
    ? data.content.filter(packing => packingIdsToPrint.includes(packing.id))
    : [];
  const handleWaybillsPrint = useWaybillsPrint(packingsToPrint, mutate);

  const requestSearchExcelDownload = async () => {
    const response = await generateExcelDownload(queryParams, '');
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsDownload(false);
    } else {
      setIsDownload(false);
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  const requestSelectExcelDownload = async (packingIds: number[]) => {
    const queryString = packingIds.join(',');
    const response = await generateExcelDownload({}, queryString);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsDownload(false);
    } else {
      setIsDownload(false);
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  const handleParcelPostExcelDownload = () => {
    setPackingIdsToPrint([]);
    setIsDownload(true);
    const packingIds = packingsToPrint.map(item => item.id);
    if (packingIds.length === 0) {
      requestSearchExcelDownload();
    } else {
      requestSelectExcelDownload(packingIds);
    }
  };

  const handleErrorExcelDownload = async () => {
    setPackingIdsToPrint([]);
    setIsErrorDownload(true);
    await generateErrorExcelDownload(queryParams);
    setIsErrorDownload(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        포장 현황 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          inputGridColumn="4/7"
        />
        <Filter.Select
          label="포장상태"
          gridRow={2}
          field="status"
          options={[defaultOption, ...packingStatusOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleCarrierSelectRef}
          label="배송방식"
          field="carrierIds"
          gridRow={2}
          options={[defaultOption, ...carrierOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={3}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
          typeDefaultValue={dateRangeTypeOptions[4].field}
        />
        <Filter.Select
          label="박스"
          field="boxName"
          options={[defaultOption, ...packingBoxOptions]}
          gridRow={3}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.NumberRangePicker
          label="실제중량무게"
          rangeStartField="minWeight"
          rangeEndField="maxWeight"
          unit="g"
          gridRow={3}
          labelGridColumn="10/11"
          rangeInputGridColumn="11/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Box>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              개별포장 목록
            </Typography>
          </Box>
          <Box>
            <LoadingButton
              variant="outlined"
              onClick={handleErrorExcelDownload}
              loading={isErrorDownload}
              style={{ marginRight: '10px', textTransform: 'none' }}
            >
              엑셀다운로드
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              onClick={handleParcelPostExcelDownload}
              loading={isDownload}
              style={{ marginRight: '10px', textTransform: 'none' }}
            >
              Parcel POST 엑셀다운로드
            </LoadingButton>
            <LoadingButton variant="outlined" onClick={handleWaybillsPrint} loading={isPrinting}>
              송장출력
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched ? (data?.content ? makeRowIndex(data) : []) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={packingIdsToPrint}
          onSelectionModelChange={ids => setPackingIdsToPrint(ids as number[])}
          isRowSelectable={(params: GridRowParams<SummarizedPacking>) =>
            (params.row.status === 'COMPLETED' ||
              params.row.status === 'REGISTERED' ||
              params.row.status === 'PRINTED') &&
            !!params.row.trackingNumber
          }
          onCellClick={params => {
            if (params.field === 'trackingNumber' && params.row.trackingNumber) {
              setSelectedPackingForJson({
                id: params.row.id,
              });
            }
          }}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={!!selectedPackingForJson}
        title={'운송사 API 연동 JSON 조회'}
        onClose={() => setSelectedPackingForJson(undefined)}
      >
        <JsonModal
          selected={selectedPackingForJson}
          onClose={() => setSelectedPackingForJson(undefined)}
        />
      </FormModal>
      <LoadingModal isLoading={isPrinting} message="송장 출력 중..." />
    </Page>
  );
};

export default Packings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
