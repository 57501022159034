import _ from 'lodash';
import { Box } from 'types/outbound';

type BoxSize = 'height' | 'length' | 'width';
type G = number;
const getWeightByVolume = (box: Pick<Box, BoxSize>, volumetricDivisor: number): G => {
  const volumeWeight = (box.height * box.length * box.width) / volumetricDivisor;
  return _.round(volumeWeight, 0);
};

export default getWeightByVolume;
