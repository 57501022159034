import { atom } from 'recoil';
import packingsAtoms from './outbound/packings.recoil';
import printerAtoms from './common/printer.recoil';
import packingAtoms from './outbound/packing.recoil';
import menuAtoms from './common/menu.recoil';
import barcodeAtoms from './common/barcode.recoil';
import diagnosisAtoms from './outbound/diagnosis.recoil';
import inspectionAtoms from './outbound/inspection.recoil';
import carrierChangeAtom from './outbound/carrier.recoil';

export const shouldMountAppBarAtom = atom({
  key: 'shouldMountAppBarAtom',
  default: true,
});

export const shouldMountDrawerAtom = atom({
  key: 'shouldMountDrawerAtom',
  default: true,
});

export const drawerOpenAtom = atom({
  key: 'drawerOpenAtom',
  default: true,
});

export const inputAutoFocusAtom = atom({
  key: 'inputAutoFocusAtom',
  default: true,
});

export const allAtoms = {
  shouldMountAppBar: shouldMountAppBarAtom,
  shouldMountDrawer: shouldMountDrawerAtom,
  drawerOpen: drawerOpenAtom,
  inputAutoFocus: inputAutoFocusAtom,
  carrierChangeAtom,
  ...diagnosisAtoms,
  ...printerAtoms,
  ...barcodeAtoms,
  ...packingsAtoms,
  ...packingAtoms,
  ...menuAtoms,
  ...inspectionAtoms,
};
