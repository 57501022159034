import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { COLORS } from 'consts/common/colors';

type GridSectionProps = BoxProps & {
  isLabel?: boolean;
};

const GridSection = (props: GridSectionProps) => {
  const { isLabel = false, sx, ...other } = props;
  const sxStyle = {
    color: (theme: Theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'text.primary'),
    borderRadius: 0,
    alignSelf: 'center',
    justifySelf: isLabel ? 'end' : 'stretch',
    '& .MuiTypography-root': {
      fontSize: isLabel ? 13 : 14,
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
    },
    '& .MuiButton-root': {
      fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.darkLine,
      },
    },
    ...sx,
  };

  return <Box sx={sxStyle} {...other} />;
};

export default GridSection;
