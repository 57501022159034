import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useCountdown from '../hooks/useCountdown';
import { COLORS } from 'consts/common/colors';
import CompletionCard from '../../components/CompletionCard';
import useInitialization from '../hooks/useInitialization';

const CompletionNotice = () => {
  const { remaining, complete } = useCountdown(3);
  const initializeStates = useInitialization();

  useEffect(() => {
    if (complete) {
      initializeStates();
    }
  }, [complete]);

  return (
    <CompletionCard
      content={
        <>
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 700,
              color: 'primary.main',
            }}
          >
            {remaining}
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              color: COLORS.veryDarkGrey,
            }}
          >
            송장 출력이 완료되었습니다.
            <br />
            다음 토트의 바코드를 스캔해 주세요.
          </Typography>
        </>
      }
    />
  );
};

export default CompletionNotice;
