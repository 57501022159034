import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';

export type removePdaRequest = {
  pickingGroupIds: number[] | undefined;
};

export const generateRemovePdas = async (requestBody: removePdaRequest) => {
  try {
    return await axiosV2.patch(`/picking-groups/devices/remove-assign`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`pda 배정 해제 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const removePickingGroup = async (pickingGroupId: number) => {
  try {
    return await axiosV2.post(`/picking-groups/${pickingGroupId}/remove`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`집품그룹 해제 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
