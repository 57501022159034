import React, { useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import gridColumns from './gridColDef';
import { Options } from 'types/form';
import usePopup from 'hooks/usePopup';
import TopBoard from '../../components/TopBoard';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertUser from '../../../../libs/common/convertUser';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import useConditionalSWR from '../../components/useConditionalSwr';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateExcelDownload } from './services/excelDownload';

const dateRangeTypeOptions: Options = [
  {
    displayName: '집품시작일',
    field: 'pickingStartAt',
  },
  {
    displayName: '집품완료일',
    field: 'pickingCompletedAt',
  },
];

const pickingOptions: Options = [
  {
    displayName: '배송ID',
    field: 'deliverySeq',
  },
  {
    displayName: '배송번호',
    field: 'deliveryId',
  },
  {
    displayName: '출고ID',
    field: 'shippingSerialNumber',
  },
  {
    displayName: '출고지시ID',
    field: 'shippingSerialNumber',
  },
  {
    displayName: '작업자',
    field: 'trackingNumber',
  },
  {
    displayName: '토트',
    field: 'toteBarcode',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

const managerOptions = [
  { displayName: '전체', value: 'all' },
  { displayName: '작업자없음', value: 'Y' },
  { displayName: '작업자있음', value: 'N' },
];

export type Totes = {
  shippingId: number;
  userName: string;
  loginId: string;
  toteBarcode: string;
  createdAt: string;
};

export type Devices = {
  shippingId: number;
  deviceNumber: string;
  createdAt: string;
};

type Shippings = {
  id: number;
  status: 'OPEN' | 'PREPARING_PICKING' | 'PICKING' | 'COMPLETED' | 'ERROR';
  type: 'SINGLE' | 'BULK';
  serialNumber: string;
  deliveryId: string;
  deliverySeq: string;
  pickingGroupSerialNumber: string;
  carrierName: string;
  countryCode: string;
  pickingStartAt: string;
  pickingCompletedAt: string;
  skuCount: number;
  itemQuantity: number;
  totes: Totes[];
  devices: Devices[];
};

type ShippingResponse = {
  content: Shippings[];
  totalElements: number;
  size: number;
  number: number;
};

export type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

type PickingStatusCount = {
  status: string;
  count: number;
};

const gridTemplateColumns = [
  '60px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '70px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Pickings = () => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const multipleCarrierSelectRef = useRef<{ initialValues: () => void }>(null);
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const [hasSearched, setHasSearched] = useState(false);

  const { data: pickingStatusCounts } = useConditionalSWR<PickingStatusCount[]>(
    [`/picking-groups/status/counts`, { warehouseId: globalWarehouse }],
    fetcher,
    hasSearched
  );

  const { data, mutate, isValidating } = useConditionalSWR<ShippingResponse>(
    [`/picking-groups/pickings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const pickingStatusOptions = refCodeOptions?.pickingStatus || [];

  const topBoard = [
    {
      title: '토트매칭대기',
      value: 0,
    },
    {
      title: '집품중',
      value: 0,
    },
    {
      title: '집품오류',
      value: 0,
    },
  ];
  pickingStatusCounts?.forEach((item: PickingStatusCount) => {
    if (item.status === 'OPEN') {
      topBoard[0].value = item.count;
    } else if (item.status === 'PICKING') {
      topBoard[1].value = item.count;
    } else if (item.status === 'ERROR') {
      topBoard[2].value = item.count;
    }
  });

  const makeRowIndex = (data: ShippingResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: any, index: number) => {
      function getDevice(item: any) {
        if (item.devices.length <= 1) return item.devices;
        if (item.devices.length > 1) {
          return item.devices.sort((a: Devices, b: Devices) => {
            // @ts-ignore
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
      }

      function getTote(item: any) {
        if (item.totes.length <= 1) return item.totes;
        if (item.totes.length > 1) {
          return item.totes.sort((a: Totes, b: Totes) => {
            // @ts-ignore
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
      }

      return {
        ...item,
        rowIndex: totalElements - size * number - index,
        pda: getDevice(item)
          .map((d: Devices) => d.deviceNumber)
          .join(', '),
        // 최종 PDA 등록 시간
        pdaAssignedAt: getDevice(item)[0]?.createdAt,
        tote: getTote(item)
          .map((t: Totes) => t.toteBarcode)
          .join(', '),
        updatedUser:
          item.status != 'OPEN' ? convertUser(item.updatedUsername, item.updatedLoginId) : '',
      };
    });
  };

  const handleInitClick = async () => {
    multipleCarrierSelectRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleExcelDownload = async () => {
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        집품 현황 조회
      </Typography>
      <TopBoard data={topBoard} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="집품상태"
          field="status"
          options={[defaultOption, ...pickingStatusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/10"
        />
        <Filter.Select
          label="집품구분"
          field="type"
          options={[defaultOption, ...pickingGroupTypeOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.Select
          label="작업자여부"
          field="manager"
          gridRow={2}
          options={managerOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleCarrierSelectRef}
          label="배송방식"
          field="carrierIds"
          gridRow={2}
          options={[defaultOption, ...carrierOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Search
          label="집품그룹ID"
          field="pickingGroupSerialNumber"
          gridRow={2}
          labelGridColumn="7/8"
          inputGridColumn="8/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={3}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={3}
          searchOptions={pickingOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            출고 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="contained"
              onClick={handleExcelDownload}
              loading={isExcelDownload}
            >
              엑셀다운로드
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default Pickings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
