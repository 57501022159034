import { useRecoilValue } from 'recoil';
import Link from '@mui/material/Link';
import type { GridColDef } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import { COLORS } from '../../../../consts/common/colors';
import { convertCarrierId } from '../../../../libs/common/convertCarrier';
import { carriersAtom } from '../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import usePopupWindow from '../../../../hooks/usePopupWindow';

const gridColumns = () => {
  const carriers = useRecoilValue(carriersAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const packingStatusOptions = refCodeOptions?.packingStatus || [];
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const { openPopupWindow } = usePopupWindow();

  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '포장상태',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        packingStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'errorCode',
      headerName: '포장오류내역',
      minWidth: 160,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: params => (
        <Link
          onClick={() => openPopupWindow({ url: `gms/outbound/packings/${params.row.id}/errors` })}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'pickingGroupType',
      headerName: '집품구분',
      minWidth: 100,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 250,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 140,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'trackingNumber',
      headerName: '송장번호',
      minWidth: 140,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ value }) => (
        <Typography
          sx={{
            fontSize: 13,
            textDecoration: 'underline',
            color: COLORS.ktown4u,
            cursor: 'pointer',
          }}
        >
          {value}
        </Typography>
      ),
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => convertCarrierId(value, carriers),
    },
    {
      field: 'boxName',
      headerName: '박스명',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boxWidth',
      headerName: '가로(mm)',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boxLength',
      headerName: '세로(mm)',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boxHeight',
      headerName: '높이(mm)',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'weight',
      headerName: '실제중량무게(g)',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'completedAt',
      headerName: '포장완료일시',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '작업일시',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
