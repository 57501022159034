import React, { ChangeEvent, useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import type { SelectChangeEvent } from '@mui/material';
import { Warehouse } from '../../../../../store/outbound/warehouse.recoil';
import { RefCodeOptions } from '../../../../../store/outbound/refCode.recoil';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import usePopup from '../../../../../hooks/usePopup';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import SelectBase from '../../devices/components/SelectBase';
import { Bin, BinResponse } from '../index.page';
import {
  createBin,
  CreateBinRequest,
  getBins,
  SearchBayRequest,
  updateBin,
  UpdateBinRequest,
} from '../services';

const modalWidth = 480;
const modalFontSize = FONT_SIZE.medium;
const binTypeDefaultOption = { displayName: '선택', value: 'ALL' };

const BayCodeBinTypeMapping: { [key: string]: string } = {
  RCV: 'RCV',
  XX: 'LOST_OR_FAULTY',
  XY: 'LOST',
  XZ: 'FAULTY',
  TRI: 'INVENTORY_TRANSFER',
};

export type BinForm = Pick<
  Bin,
  | 'warehouseId'
  | 'binType'
  | 'locationCode'
  | 'pickingOrder'
  | 'isStairRequired'
  | 'coordX'
  | 'coordY'
  | 'isActive'
> & {
  bayId?: number;
  bayCode: string;
  binCode: string;
};

type Options = { warehouseOption: Warehouse[]; binTypeOptions: RefCodeOptions[] };
type Props = {
  selectedRow?: Bin;
  warehouseId: number;
  options: Options;
  onClose: () => void;
  onMutate: () => Promise<BinResponse | undefined>;
};

type AutocompleteOption = { id: number; label: string };

const initialState: BinForm = {
  warehouseId: -1,
  binType: 'ALL',
  locationCode: '',
  pickingOrder: 9999,
  isStairRequired: false,
  coordX: 0,
  coordY: 0,
  isActive: 'ACTIVE',
  bayCode: '',
  binCode: '',
};

const BinForm = (props: Props) => {
  const { onClose, onMutate, selectedRow, warehouseId, options } = props;
  const [binForm, setBinForm] = useState<BinForm>({ ...initialState, warehouseId });
  const [bayCodeOptions, setBayCodeOptions] = useState<AutocompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar, showAlert } = usePopup();

  useEffect(() => {
    if (!!selectedRow) {
      setBinForm(makeBinForm(selectedRow));
      return;
    }

    getBayCodeOptions(binForm.warehouseId);
    setBinForm(binForm => ({ ...initialState, warehouseId: binForm.warehouseId }));
  }, [binForm.warehouseId, selectedRow]);

  const getBayCodeOptions = async (warehouseId: number) => {
    const params: SearchBayRequest = { warehouseId };
    const response = await getBins(params);

    if (response?.status === 200) {
      const res = response?.data.map(
        (item: { bayId: number; code: string; warehouseId: number }) => ({
          id: item.bayId,
          label: item.code,
        })
      );
      setBayCodeOptions(res);
    }
  };

  const handleChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = event.target;
    setBinForm({ ...binForm, [name]: makeValue(event.target) });
  };

  const updateBinFormFromAutocomplete = (value: string | number, displayName: string) => {
    const bay = displayName.split('-')[0];

    setBinForm(form => ({
      ...form,
      bayId: value ? Number(value) : undefined,
      bayCode: displayName ?? '',
      binType: BayCodeBinTypeMapping[bay] ?? binTypeDefaultOption.value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (binForm.binType === 'ALL' || !binForm.binType || !binForm.pickingOrder) {
      showAlert({ message: '필수항목을 모두 입력해 주세요.' });
      setIsLoading(false);
      return;
    }

    if (selectedRow) {
      const requestBody: UpdateBinRequest = {
        isActive: binForm.isActive,
        binType: binForm.binType,
        pickingOrder: binForm.pickingOrder,
        isStairRequired: binForm.isStairRequired,
        coordX: binForm.coordX || undefined,
        coordY: binForm.coordY || undefined,
      };

      const res = await updateBin(selectedRow.id, requestBody);
      if (res?.status !== 200) {
        showAlert({ message: res?.data?.errorMessage });
        setIsLoading(false);
        return;
      }

      showSnackbar({ message: 'BIN 정보가 수정 완료되었습니다.', severity: 'success' });
      await onMutate();
      onClose();
      setIsLoading(false);
      return;
    }

    if (!binForm.bayId || !binForm.binCode) {
      showAlert({ message: '필수항목을 모두 입력해 주세요.' });
      setIsLoading(false);
      return;
    }

    const requestBody: CreateBinRequest = {
      bayId: binForm.bayId,
      code: binForm.binCode,
      isActive: binForm.isActive,
      binType: binForm.binType,
      pickingOrder: binForm.pickingOrder,
      isStairRequired: binForm.isStairRequired,
      coordX: binForm.coordX || undefined,
      coordY: binForm.coordY || undefined,
    };

    const res = await createBin(requestBody);
    if (res?.status !== 200) {
      showAlert({ message: res?.data?.errorMessage });
      setIsLoading(false);
      return;
    }

    showSnackbar({ message: 'BIN 정보가 생성 완료되었습니다.', severity: 'success' });
    await onMutate();
    onClose();
    setIsLoading(false);
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>기본정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'소속창고'} labelSx={styles.label}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {convertWarehouseFullCode(binForm.warehouseId, options.warehouseOption)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'소속구획'} labelSx={styles.label} required={!selectedRow}>
            {selectedRow ? (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {binForm.bayCode}
              </Typography>
            ) : (
              <Autocomplete
                disablePortal
                options={bayCodeOptions ?? []}
                onBlur={() => {
                  const currentBayCode = bayCodeOptions.find(option => option.id === binForm.bayId);
                  setBinForm({ ...binForm, bayCode: currentBayCode?.label ?? '' });
                }}
                onChange={(event, value) => {
                  !!value && updateBinFormFromAutocomplete(value.id, value.label);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={styles.autocomplete}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{ ...params.InputProps, sx: styles.inputProps }}
                    inputProps={{ ...params.inputProps, value: binForm.bayCode }}
                    placeholder={'선택'}
                    onChange={e => {
                      setBinForm({ ...binForm, bayCode: e.target.value });
                    }}
                  />
                )}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'BIN코드'} labelSx={styles.label} required={!selectedRow}>
            {selectedRow ? (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {binForm.binCode}
              </Typography>
            ) : (
              <FormControl fullWidth>
                <OutlinedInput
                  name={'binCode'}
                  value={binForm.binCode}
                  onChange={handleChange}
                  sx={styles.input}
                  inputProps={{ maxLength: 4 }}
                  type={'text'}
                  placeholder={'숫자 4자리만 입력가능'}
                />
              </FormControl>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'로케이션코드'} labelSx={styles.label}>
            {selectedRow ? (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {binForm.locationCode}
              </Typography>
            ) : (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {getFullCode(options.warehouseOption, binForm.warehouseId)}-{binForm.bayCode}-
                {binForm.binCode}
              </Typography>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'사용여부'} labelSx={styles.label}>
            <Box sx={styles.rowCenter}>
              <Checkbox
                name={'isActive'}
                checked={binForm.isActive === 'ACTIVE'}
                size="small"
                sx={styles.checkbox}
                onChange={handleChange}
              />
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {'사용'}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </Table>
      <Box>
        <Typography sx={styles.modalSubTitle}>집품정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'로케이션구분'} labelSx={styles.label} required>
            <SelectBase
              name={'binType'}
              options={[binTypeDefaultOption, ...options.binTypeOptions]}
              selected={binForm.binType || binTypeDefaultOption.value.toString()}
              sx={styles.select}
              onChange={handleChange}
              disabled={!!BayCodeBinTypeMapping[binForm.bayCode.split('-')[0]]}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'집품우선순위'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'pickingOrder'}
                value={binForm.pickingOrder}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'계단여부'} labelSx={styles.label}>
            <Box sx={styles.rowCenter}>
              <Checkbox
                name={'isStairRequired'}
                checked={binForm.isStairRequired}
                size="small"
                sx={styles.checkbox}
                onChange={handleChange}
              />
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {'필요'}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'X / Y 좌표'} labelSx={styles.label}>
            <Box sx={styles.coordinateBox}>
              <OutlinedInput
                name={'coordX'}
                value={binForm.coordX}
                onChange={handleChange}
                sx={styles.smallInput}
                placeholder={'X 좌표'}
                type={'text'}
              />
              <Typography fontSize={modalFontSize} fontWeight={'bold'} sx={styles.coordinateBlank}>
                {'/'}
              </Typography>
              <OutlinedInput
                name={'coordY'}
                value={binForm.coordY}
                onChange={handleChange}
                sx={styles.smallInput}
                placeholder={'Y 좌표'}
                type={'text'}
              />
            </Box>
          </TableCell>
        </TableRow>
      </Table>
      <SaveButtonGroup onSaveClick={handleSave} onCloseClick={onClose} isLoading={isLoading} />
    </Box>
  );
};

export default BinForm;

const makeBinForm = (selectedRow: Bin) => {
  const locationCodeArray = selectedRow.locationCode.split('-');
  return {
    ...selectedRow,
    bayCode: locationCodeArray.slice(1, 4).join('-'),
    binCode: locationCodeArray[4],
    coordX: selectedRow.coordX || 0,
    coordY: selectedRow.coordY || 0,
  };
};

type Target =
  | (EventTarget & (HTMLInputElement | HTMLTextAreaElement))
  | (EventTarget & { value: string; name: string });

const makeValue = (target: Target) => {
  if ('checked' in target) {
    if (target.name === 'isActive') {
      return target.checked ? 'ACTIVE' : 'INACTIVE';
    } else if (target.name === 'isStairRequired') {
      return target.checked;
    }
  }

  if (target.name === 'binCode') {
    return target.value.replace(/[^0-9]/g, '');
  }

  if (target.name === 'pickingOrder') {
    return Number(target.value.replace(/[^0-9]/g, ''));
  }

  if (target.name === 'warehouseId' || target.name === 'coordX' || target.name === 'coordY') {
    return Number(target.value);
  }

  return target.value;
};

const getFullCode = (warehouseOption: Warehouse[], warehouseId: number) => {
  return warehouseOption.find(warehouse => warehouse.id === warehouseId)?.fullCode;
};

const styles = {
  select: { width: 344, ml: -1.5 },
  input: { height: 28, width: 344, ml: -1.5, fontSize: modalFontSize },
  checkbox: { pl: 0, pr: 0.6 },
  smallInput: { height: 28, width: 120, fontSize: modalFontSize },
  modalSubTitle: { fontSize: 20, mb: 1.5 },
  rowHeight: { height: 36 },
  label: { width: 128, fontSize: modalFontSize },
  coordinateBox: {
    width: 344,
    ml: -1.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  coordinateBlank: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  autocomplete: { width: 344, height: 26, ml: -1.5 },
  inputProps: {
    height: 28,
    fontSize: modalFontSize,
    '& .MuiAutocomplete-input': { mt: -1.5 },
  },
};
