import _, { round } from 'lodash';
import { Box } from 'types/outbound';

type G = number;
const calWeightByVolume = (box: Box | any): G => {
  const volumeWeight = (box.height * box.length * box.width) / bulkPackingDim;
  return _.round(volumeWeight, 0);
};

export const bulkPackingDim = 5000;

export const calVolumetricWeight = (
  width: string | number,
  length: string | number,
  height: string | number
) => round((Number(width) * Number(length) * Number(height)) / Number(bulkPackingDim));

export default calWeightByVolume;
