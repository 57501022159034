import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { warehouseAtom, warehouseOptionsAtom } from 'store/outbound/warehouse.recoil';
import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { listItemTextTheme } from '../../../../../styles/customedMuiTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { TableCell, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import usePopup from '../../../../../hooks/usePopup';
import { createInventoryItem } from '../services/InventoryLocation';
import { datadogRum } from '@datadog/browser-rum';

const modalWidth = 900;

type Props = {
  onClose: () => void;
  mutate: () => void;
};
const CreateInventoryItemForm = (props: Props) => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const { onClose, mutate } = props;
  const [adjustDetailReason, setAdjustDetailReason] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [itemCode, setItemCode] = useState<string>('');
  const [qty, setQty] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOptions = useRecoilValue(warehouseOptionsAtom);
  const warehouse = warehouseOptions.find(warehouse => warehouse.id === Number(globalWarehouse));
  const isReflectInventory = warehouse?.isReflectInventory ?? false;
  const [isApplyBO, setIsApplyBO] = useState<boolean>(isReflectInventory);

  useEffect(() => {
    if (!Number(globalWarehouse)) return;
  }, []);

  const handleCreateInventoryItem = async () => {
    setIsLoading(true);
    if (!itemCode || !location || !qty) {
      showErrorDialog({
        title: '아이템재고 추가 실패',
        errorMessage: '로케이션, 아이템코드, 가용수량은 필수로 입력해주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      setIsLoading(false);
      return;
    }

    const requestBody = {
      warehouseId: Number(globalWarehouse),
      locationCode: location,
      itemCode,
      quantity: Number(qty),
      isApplyBO,
      adjustedReason: adjustDetailReason,
    };

    const response = await createInventoryItem(requestBody);
    if (response?.status === 200) {
      mutate();
      showSnackbar({ message: '아이템재고 추가가 완료되었습니다.', severity: 'success' });
      onClose();
      datadogRum.addAction(`아이템재고 추가 성공`);
    } else {
      showErrorDialog({
        title: '아이템재고 추가 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      datadogRum.addError(`아이템재고 추가 실패: ${response?.data?.errorMessage}`);
    }
    setIsLoading(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f3f3fa',
      color: theme.palette.common.black,
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isApplyBOValue = value === 'true';
    console.log(isReflectInventory);
    setIsApplyBO(isApplyBOValue);
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={{ color: 'text.primary', fontWeight: '800' }}>
          GMS 재고수량이 0인 아이템의 재고를 로케이션에 추가 등록하는 기능입니다.
        </Typography>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText}>{'재고수량 조정'}</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align={'center'} style={{ width: '180px' }}>
                  로케이션
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '150px' }}>
                  아이템코드
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '140px' }}>
                  로케이션 가용수량
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '140px' }}>
                  BO재고반영여부
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  <TextField
                    defaultValue={location}
                    onBlur={e => setLocation(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  <TextField
                    defaultValue={itemCode}
                    onBlur={e => setItemCode(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  <TextField
                    defaultValue={qty}
                    onBlur={e => setQty(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  <TextField
                    select
                    fullWidth
                    value={isApplyBO}
                    defaultValue={isReflectInventory ? 'true' : 'false'}
                    onChange={handleChange}
                    sx={{ margin: 0 }}
                    inputProps={{
                      sx: {
                        paddingY: 1,
                      },
                    }}
                  >
                    {isReflectInventory && (
                      <MenuItem key={'bo-inventory-reflection'} value={'true'}>
                        <ListItemText
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: 0,
                            ...listItemTextTheme.medium,
                          }}
                          primary={'BO재고반영'}
                        />
                      </MenuItem>
                    )}

                    <MenuItem key={'no-bo-inventory-reflection'} value={'false'}>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: 0,
                          ...listItemTextTheme.medium,
                        }}
                        primary={'BO재고반영안함'}
                      />
                    </MenuItem>
                  </TextField>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText} mt={5}>
            {'조정사유'}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align={'center'} style={{ width: '20%' }}>
                  조정사유
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '80%' }}>
                  조정 상세사유
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell align={'center'} style={{ width: '20%' }}>
                  유형상품입고반영
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '80%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    defaultValue={adjustDetailReason}
                    onBlur={e => setAdjustDetailReason(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <LoadingButton
            sx={styles.button}
            onClick={handleCreateInventoryItem}
            variant="contained"
            loading={isLoading}
          >
            재고수량 조정
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
export default CreateInventoryItemForm;

const styles = {
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
  select: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
};
