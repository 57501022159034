import React from 'react';
import Box from '@mui/system/Box';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AutoContainImage from 'components/common/Image/AutoContainImage';
import { getFinalUrl } from 'consts/outbound/imageUrl';

const gridColDef = [
  {
    field: 'rowIndex',
    headerName: 'NO',
    minWidth: 60,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'barcode',
    headerName: '바코드',
    minWidth: 120,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'offlineBarcode',
    headerName: '오프라인 바코드',
    minWidth: 120,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'itemId',
    headerName: '아이템코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'image',
    headerName: '이미지',
    minWidth: 50,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
        <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
      </Box>
    ),
  },
  {
    field: 'itemName',
    headerName: '아이템명',
    minWidth: 180,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'qty',
    headerName: '출고지시수량',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'receivedAt',
    headerName: '입고일',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'weight',
    headerName: '실제중량무게(g)',
    minWidth: 110,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'price',
    headerName: '공급단가(원)',
    minWidth: 90,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'totalPrice',
    headerName: '공급가합계(원)',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
] as GridColDef[];

export default gridColDef;
