import { axiosV2 } from '../common/fetcher';

const getWaybillImage = async (packingId: number) => {
  const {
    data: { base64Image },
  } = await axiosV2.get(`/packings/${packingId}/waybills`);

  return base64Image;
};

export default getWaybillImage;
