import { REACT_APP_BASE_PATH } from 'consts/common/environment';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { printerAtom, printerErrorAtom } from 'store/common/printer.recoil';

const PrinterScript = () => {
  const setPrinter = useSetRecoilState(printerAtom);
  const setPrinterError = useSetRecoilState(printerErrorAtom);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${REACT_APP_BASE_PATH}/BrowserPrint-3.0.216.min.js`;
    document.head.appendChild(script);
    script.async = true;
    script.onload = () => {
      //@ts-ignore
      BrowserPrint.getDefaultDevice('printer', setPrinter, setPrinterError);
    };
    script.onerror = e => {
      console.error('PrinterScript error: ', e);
    };
  }, []);

  return <></>;
};

export default PrinterScript;
