type Button = 'b';
export const BUTTON: Button = 'b';

export const BARCODE_SCAN_ACTION: Record<string, `${Button}${string}`> = {
  stopPacking: `${BUTTON}_stop_packing`,
  changeBox: `${BUTTON}_change_box`,
  finishGiftInspection: `${BUTTON}_finish_gift_isp`,
  finishInspection: `${BUTTON}_finish_isp`,
  stopInspection: `${BUTTON}_stop_isp`,
  startInspection: `${BUTTON}_start_isp`,
  finishChangeTote: `${BUTTON}_finish_change_tote`,
};
