import _ from 'lodash';
import { Carrier } from '../index.page';
import { RequestBodyForUpdate } from '../services/carriers';

export const getSelectedCarrier = (carriers: Carrier[], selectedCarrierId: number) => {
  return carriers.find(row => row.id === selectedCarrierId);
};

const deviceFormKeys = [
  'id',
  'name',
  'displayOrder',
  'chuteNumber',
  'minWeight',
  'maxWeight',
  'volumetricDivisor',
] as const;
export const generateCarrierForm = (carrier: Carrier | undefined) => {
  return _.pick(carrier, deviceFormKeys);
};

const RequestBodyForUpdateKeys = [
  'name',
  'displayOrder',
  'chuteNumber',
  'minWeight',
  'maxWeight',
  'volumetricDivisor',
] as const;
export const makeRequestBodyForUpdate = (carrier: RequestBodyForUpdate) => {
  return _.pick(carrier, RequestBodyForUpdateKeys);
};
