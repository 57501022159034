import { useEffect, useState } from 'react';
import { SearchedItem } from 'types/common';

export type Item = SearchedItem & {
  rowNo: number;
  id: number;
  requestedQuantity: number;
  totalPrice: number;
};

const useReceiveItemsFromSearch = () => {
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    window.addEventListener('message', event => receiveDataFromSearchPopup(event, items));
    return () => {
      window.removeEventListener('message', event => receiveDataFromSearchPopup(event, items));
    };
  }, [items]);

  const receiveDataFromSearchPopup = (event: MessageEvent, items: Item[]) => {
    if (event.origin !== window.location.origin) return;
    const receivedData: Item[] = event.data;
    if (!receivedData[0]) return;

    const newItems = addReceivedDataToItems(receivedData, items);
    const newItemsWithRowNo = makeItemsWithRowNo(newItems);
    updateItems(newItemsWithRowNo);
  };

  const updateItems = (items: Item[]) => {
    setItems(items);
  };

  return { items, updateItems };
};

const addReceivedDataToItems = (receivedData: Item[], items: Item[]) => {
  const newItems = [...items];

  receivedData.forEach((data: Item, index: number) => {
    if (hasDuplicateItemCode(newItems, data.itemCode as string)) {
      return;
    }
    const newReceivedData = makeNewReceivedData(data, index);
    newItems.push(newReceivedData);
  });

  return newItems;
};

const hasDuplicateItemCode = (items: Item[], itemCode: string) => {
  return items.some(item => item.itemCode === itemCode);
};

const makeNewReceivedData = (data: Item, index: number) => {
  return {
    ...data,
    id: data.goodsId,
  };
};

const makeItemsWithRowNo = (newItems: Item[]) =>
  newItems.map((item, index) => ({ ...item, rowNo: index + 1 }));

export default useReceiveItemsFromSearch;
