import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BULK_PACKING, PACKING } from 'consts/outbound/messages';
import { afterProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import bulkPackingAtom, {
  BulkPacking,
  bulkPackingIsLoadingAtom,
  bulkPackingStepAtom,
} from '../../../../../store/outbound/bulkPacking.recoil';
import { errorType } from '../../../../../types/common';
import BarcodeInput from '../../components/BarcodeInput';
import useBarcode from '../../hooks/useBarcode';
import BulkPackingTable from '../components/BulkPackingTable';
import usePopup from 'hooks/usePopup';
import TimelineItem from '../../components/TimelineItem';
import { BULK_PACKING_ID, PICKING_GROUP_ID } from '../index.page';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getBulkPacking, updateBulkPackingStatus } from '../services';
import { AxiosResponse } from 'axios';
import usePopupWindow from '../../../../../hooks/usePopupWindow';

const CURRENT_STEP = 0;

const ToteScanLine = () => {
  const [isLoading, setIsLoading] = useRecoilState(bulkPackingIsLoadingAtom);
  const { barcode, setBarcode } = useBarcode();
  const [bulkPacking, setBulkPacking] = useRecoilState(bulkPackingAtom);
  const { showDialog, showSnackbar, showErrorDialog } = usePopup();
  const { openPopupWindow } = usePopupWindow();
  const setSelectedStep = useSetRecoilState(bulkPackingStepAtom);
  const handleCarrierChangeClick = () => {
    if (!bulkPacking) {
      return;
    }

    localStorage.setItem(PICKING_GROUP_ID, bulkPacking?.serialNumber);
    localStorage.setItem(BULK_PACKING_ID, String(bulkPacking.bulkPackingId));
    openPopupWindow({ url: `gms/outbound/bulk-packing/carrier-change` });
  };

  const handlePickingGroupIdSubmit = async () => {
    setIsLoading(true);

    const response = await getBulkPacking(barcode);

    function getBulkPackingError(
      response: AxiosResponse | AxiosResponse<errorType, string> | undefined
    ) {
      const error = response?.data as errorType;
      showErrorDialog({
        title: 'API 연동 실패',
        errorMessage: error?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    if (response?.status == 200) {
      const bulkPacking = response?.data as BulkPacking;
      const updateResponse = await updateBulkPackingStatus(bulkPacking?.bulkPackingId);
      if (updateResponse?.status == 200) {
        setBulkPacking(bulkPacking);
        setSelectedStep(CURRENT_STEP + 1);
      } else {
        getBulkPackingError(updateResponse);
      }
    } else {
      getBulkPackingError(response);
    }
    setBarcode('');
    setIsLoading(false);
  };

  const handlePackingStop = () => {
    showDialog({
      message: BULK_PACKING.stopConfirm,
      buttons: [
        {
          text: '확인',
          marked: true,
          onClick: () => {
            setSelectedStep(CURRENT_STEP);
            showSnackbar({ message: PACKING.changeToErrorStatus, severity: 'success' });
          },
        },
        { text: '취소' },
      ],
    });
  };

  return (
    <TimelineItem
      step={0}
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle} suppressHydrationWarning>
            {BULK_PACKING.batchNumberSubmit}
          </Typography>
          <BarcodeInput
            value={barcode}
            onChange={e => setBarcode(e.target.value)}
            label="바코드"
            onSubmit={() => handlePickingGroupIdSubmit()}
            isLoading={isLoading}
          />
        </>
      }
      afterProgressComponent={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={afterProgressTitle} suppressHydrationWarning>
              {BULK_PACKING.batchNumberSubmit}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Button
                variant="contained"
                sx={{ height: '28px', padding: '0px 10px', fontWeight: 700, visibility: 'hidden' }}
                onClick={handleCarrierChangeClick}
              >
                운송사 변경
              </Button>
              <Button
                variant="contained"
                sx={{ height: '28px', padding: '0px 10px', fontWeight: 700 }}
                onClick={handlePackingStop}
              >
                포장중지
              </Button>
            </Box>
          </Box>
          {bulkPacking && (
            <BulkPackingTable
              pickingGroupId={bulkPacking.serialNumber}
              shippingQuantity={bulkPacking.sameShippingCount}
              toteList={bulkPacking.totes}
              itemList={bulkPacking.shippingItems}
              estimatedWeight={bulkPacking.estimatedWeight}
            />
          )}
        </>
      }
    />
  );
};

export default ToteScanLine;
