import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from '../../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../../types/common';

export const getReceiptsInspectDetails = async (receivingId: number | undefined) => {
  try {
    return await axiosV2.get(`/receivings/${receivingId}/inspect`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고검수 상세 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
export const returnInspect = async (receivingId: number | undefined, requestBody: string[]) => {
  try {
    return await axiosV2.post(`/receivings/return/${receivingId}/inspections`, {
      locationCodes: requestBody,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고반품검수 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
