import { useContext } from 'react';
import { DateRangeContext } from '../index';

const useDateRange = () => {
  const context = useContext(DateRangeContext);

  if (context === null) {
    throw new Error('useDateRange must be used within a <DateRangePickerWithSelect />');
  }

  return context;
};

export default useDateRange;
