import { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { carriersAtom } from '../../../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../../../store/outbound/refCode.recoil';
import { convertCarrierId } from '../../../../../../libs/common/convertCarrier';

const gridColumns = () => {
  const carriers = useRecoilValue(carriersAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatus = refCodeOptions?.shippingStatus || [];
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'NO',
      width: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'status',
      headerName: '출고상태',
      width: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => shippingStatus?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'shippingBasedAt',
      headerName: '출고기준일시',
      width: 160,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'serialNumber',
      headerName: '출고ID',
      width: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      width: 240,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      width: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'skuQty',
      headerName: '품목수량',
      width: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemQty',
      headerName: '아이템수량',
      width: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      width: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'countryCode',
      headerName: '배송국가',
      width: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
