export const PACKING = {
  boxCodeScan: '박스종류 바코드를 스캔해 주세요',
  weight: '전자저울에서 실제중량무게를 측정해 주세요',
  stopConfirm: '진행중인 개별포장 작업을 중지하시겠습니까?',
  toteCodeScan: '토트의 바코드 또는 출고ID의 바코드를 스캔해 주세요.',
  changeToErrorStatus: '포장오류 건으로 등록 완료되었습니다',
  wrongBoxCode: '등록되지 않은 박스종류입니다. 다른 박스종류를 스캔해 주세요.',
  scaleRetry: '무게를 다시 재주세요',
  checkWeightDiff: '예상/측정무게 간의 오차가 기준치 초과입니다.\n책임자 호출 후 대기해 주세요.',
  overMaxWeight: '운송사의 배송가능 무게를 초과하였습니다. 책임자에게 문의해 주세요.',
  lessThanMinWeight: '25g ~ 49g 무게로 입력해 주세요.',
  inputWeightRangeBulkPacking: '25g ~ 20,000g 무게로 입력해 주세요.',
  carrierApiError: "송장출력 연동오류가 발생하였습니다.\n'송장출력 재연동'을 진행해주세요.",
  printNotPrepared:
    '프린터가 준비되지 않았습니다. 잠시 후 새로고침하여 재시도해주세요.\n문제가 반복되면 책임자에게 문의해주세요.',
  continuousPrintFailure: '송장출력 오류가 지속될 경우 책임자를 호출해주세요',
  unavailableBarcode: '개별포장 작업이 가능한 바코드 번호가 아닙니다. 책임자에게 문의해주세요.',
  unrecognizableBarcode:
    '유효한 바코드 번호가 아닙니다.\n키보드가 영문모드인지 확인해주시고, 계속 발생하면 책임자에게 문의해주세요.',
  notPickedShipping: '집품이 되지 않은 출고 건입니다',
  alreadyCompletedPacking: '이미 포장이 완료되었습니다',
  alreadyProcessingPacking: '포장이 진행 중인 건입니다',
  notReadyOrErrorPacking: '포장 상태가 준비/오류인 건만 개별포장이 가능합니다',
  notInspectedPacking: '검수가 완료된 출고건만 개별포장이 가능합니다',
  printError: '프린터에서 오류가 발생하여 송장출력이 진행되지 않았습니다',
  orderFetchFailure: '주문 정보를 가져오는 데 실패했습니다. 책임자에게 문의해주세요',
  packingFetchFailure: '포장 정보를 가져오는 데 실패했습니다. 책임자에게 문의해주세요',
  statusChangeFailure: '포장 상태를 변경하는 데 실패했습니다. 책임자에게 문의해주세요.',
  updatePackingFailure: '포장 정보를 업데이트하는 데 실패했습니다. 책임자에게 문의해주세요.',
  createFailure: '새 포장 정보를 생성하는 데 실패하였습니다. 책임자에게 문의해주세요.',
  waybillUploadSuccess: '운송장을 저장하였습니다',
  waybillUploadFailure: '운송장을 저장하는데 실패하였습니다',
  invalidWeight: '유효한 무게가 아닙니다',
  shouldUseScale: '50g 이상은 저울로 측정해주세요',
  changeTote: '이관전 토트의 바코드 또는 출고ID의 바코드를 스캔해 주세요.',
} as const;

export const BULK_PACKING = {
  batchNumberSubmit: '토트 바코드 스캔 또는 집품그룹ID를 입력해 주세요',
  stopConfirm: '진행중인 대량포장 작업을 중지하시겠습니까?',
};

export const INSPECTION = {
  finishInspection: '수기 전체검수완료',
  finishGiftInspection: '특전/포스터 등 수량자동입력',
  stopInspection: '미집품 검수중지',
  startInspection: '다음 검수시작',
  combineTotes: '토트 1개로 합치기',
  inspectItemsInfo: '검수대상 아이템정보',
  scanTote: '토트의 바코드를 스캔해 주세요.',
  scanItems: '토트 내의 아이템 바코드를 스캔해 주세요.',
  inspectionCompleteSuccess: '주문이 검수완료 처리되었습니다.',
  inspectionCompleteFailure: '검수완료 처리하는 데 실패했습니다. 책임자에게 문의해주세요.',
  inspectionCompleteFetchFailure: '검수완료 처리하는 데 실패했습니다. 책임자에게 문의해주세요.',
  inspectionCreateHistoryFailure: '검수오류를 기록하는데 실패했습니다. 책임자에게 문의해주세요.',
  inspectionDefectRemarkEmpty: '불량사유를 기재해 주세요.',
  inspectionExpirationRemarkEmpty: '유통기한을 기재해 주세요.',
  inspectionHistoryTypeNotExisted: '검수오류 종류에 대한 정보가 없습니다. 책임자에게 문의해주세요.',
};

export const ERROR_CODE: Record<string, string> = {
  // 샘플. 기획 설계 후 수정 필요.
  DCT_0001: '과세대상 신고금액 오류',
} as const;
