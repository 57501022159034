import type { GridColDef } from '@mui/x-data-grid-pro';
import { Warehouse } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';

const gridColumns = (warehouseOption: Warehouse[]) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const adjustedInventoryHistoryTypeOptions = refCodeOptions?.adjustedInventoryHistoryType || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'adjustedType',
      headerName: '조정사유',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        adjustedInventoryHistoryTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'adjustedReason',
      headerName: '상세조정사유',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'baseQuantity',
      headerName: '변경전 로케이션 가용수량',
      minWidth: 170,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'adjustedQuantity',
      headerName: '변경후 로케이션 가용수량',
      minWidth: 170,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'changedQuantity',
      headerName: '변경수량',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isReflectInventory',
      headerName: 'BO재고반영여부',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => (value === true ? 'Y' : value === false ? 'N' : ''),
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'locationCode',
      headerName: '로케이션',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'lpnCode',
      headerName: 'LPN',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdUser',
      headerName: '작업자',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: '작업일시',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
