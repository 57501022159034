import { atom } from 'recoil';

export interface Supplier {
  id: number;
  value: string | number | boolean;
  displayName: string;
}

export const suppliersOptionsAtom = atom<Supplier[]>({
  key: 'suppliersOptionsAtom',
  default: [],
});

export default suppliersOptionsAtom;
