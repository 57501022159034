import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import _ from 'lodash';
import Page from '../../components/common/Layout/Page';
import TightDataGridPro from '../../components/common/TightDataGridPro';
import fetcher from '../../libs/common/fetcher';
import convertWarehouseFullCode from '../../libs/warehouse/convertWarehouseFullCode';
import { warehouseAtom, warehouseOptionsAtom } from '../../store/outbound/warehouse.recoil';
import { listBoardTheme } from '../../styles/customedMuiTheme';
import TopBoard from './components/TopBoard';
import makeGridColDef from './gridColDef';

type ShippingByShippingType = {
  shippingOrderCreatedAt: string;
  shippingCount: number;
  openCount: number;
  pickingGroupCount: number;
  pickingCount: number;
  inspectionCount: number;
  packingCount: number;
  shippedCount: number;
  completionRate: number;
};

const GMSHome = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const { data, mutate } = useSWR<ShippingByShippingType[]>(
    [`/shipping-orders/count-by-shipping-type`, { warehouseId: globalWarehouse, sort: 'id,desc' }],
    fetcher
  );

  useEffect(() => {
    const interval = setInterval(() => {
      mutate();
    }, 300000); // 5분마다 데이터 갱신
    return () => clearInterval(interval);
  }, []);

  const totalShippingCount = data?.reduce((acc, cur) => acc + cur.shippingCount, 0) ?? 0;
  const totalShippedCount = data?.reduce((acc, cur) => acc + cur.shippedCount, 0) ?? 0;
  const totalCompletionRate =
    totalShippingCount !== 0 ? _.round((totalShippedCount / totalShippingCount) * 100, 2) : 0;
  const topBoard = [
    {
      title: '출고잔여건수',
      value: totalShippingCount - totalShippedCount,
    },
    {
      title: '출고완료율',
      value: totalCompletionRate + '%',
    },
  ];

  const makeRowIndex = (data: ShippingByShippingType[]) => {
    const map = data.map((item: ShippingByShippingType, index: number) => ({
      ...item,
      id: item.shippingOrderCreatedAt,
      rowIndex: index + 1,
    }));
    const summery = {
      id: 'total',
      rowIndex: map.length + 1,
      shippingOrderCreatedAt: '합계',
      shippingCount: totalShippingCount,
      openCount: data.reduce((acc, cur) => acc + cur.openCount, 0),
      pickingGroupCount: data.reduce((acc, cur) => acc + cur.pickingGroupCount, 0),
      pickingCount: data.reduce((acc, cur) => acc + cur.pickingCount, 0),
      inspectionCount: data.reduce((acc, cur) => acc + cur.inspectionCount, 0),
      packingCount: data.reduce((acc, cur) => acc + cur.packingCount, 0),
      shippedCount: totalShippedCount,
      completionRate: totalCompletionRate,
    };
    return [...map, summery];
  };

  return (
    <Page>
      <TopBoard data={topBoard} />
      <Box sx={listBoardTheme.header}>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          [{convertWarehouseFullCode(Number(globalWarehouse), warehouseOption)}] 출고지시서
          생성일자별 출고현황
        </Typography>
        <Box>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            <Link
              onClick={() =>
                window.open(`https://zeppelin.ktown4u.io/#/notebook/2K2DX12XW`, '_blank')
              }
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
                fontSize: '0.8125rem',
                fontWeight: '400',
              }}
            >
              출고지시별 집품그룹 출고상태 건수 확인
            </Link>
          </Typography>
        </Box>
      </Box>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <TightDataGridPro
          autoHeight
          rows={data ? makeRowIndex(data) : []}
          columns={makeGridColDef()}
          rowCount={data?.length ?? 0}
          hideFooter
          hasSearched
          getRowClassName={params => (params.id === 'total' ? 'total-row' : '')}
        />
      </Stack>
    </Page>
  );
};

export default GMSHome;
