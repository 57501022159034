import React from 'react';
import MUISnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export type Severity = 'info' | 'error' | 'warning' | 'success';

type SnackbarProps = {
  open: boolean;
  message: string;
  severity?: Severity;
  onClose: () => void;
};

const Snackbar = (props: SnackbarProps) => {
  const { open, message, onClose, severity } = props;

  return (
    <MUISnackbar
      open={open}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert severity={severity} sx={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
