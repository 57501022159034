import { useRecoilValue } from 'recoil';
import { GridColDef } from '@mui/x-data-grid-pro';

import { carriersAtom } from 'store/outbound/carrier.recoil';
import { convertCarrierId } from 'libs/common/convertCarrier';
import PACKING_STATUS from 'consts/outbound/packingStatus';
import Link from '@mui/material/Link';
import usePopupWindow from '../../../../../../hooks/usePopupWindow';

const gridColumns = () => {
  const carriers = useRecoilValue(carriersAtom);
  const { openPopupWindow } = usePopupWindow();

  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'status',
      headerName: '포장상태',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => PACKING_STATUS[value as keyof typeof PACKING_STATUS],
    },
    {
      field: 'errorCode',
      headerName: '포장오류내역',
      minWidth: 180,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: params => (
        <Link
          onClick={() => openPopupWindow({ url: `gms/outbound/packings/${params.row.id}/errors` })}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'isImageUploaded',
      headerName: '송장이미지',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => (value ? 'Y' : 'N'),
    },
    {
      field: 'shippingBaseAt',
      headerName: '출고기준일시',
      minWidth: 190,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 240,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'trackingNumber',
      headerName: '송장번호',
      minWidth: 150,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'totalSku',
      headerName: '품목수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'totalQuantity',
      headerName: '아이템수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'countryCode',
      headerName: '배송국가',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];

  return columns;
};

export default gridColumns;
