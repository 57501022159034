import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import { packingAtom, stepAtom, weightByScaleAtom } from 'store/outbound/packing.recoil';
import mapPackingErrorToMessage from 'libs/outbound/mapErrorToMessage';
import usePopup from 'hooks/usePopup';
import Dialog from 'components/common/Popup/Dialog';
import { MAX_WEIGHTS, MIN_WEIGHT } from 'consts/outbound/weights';
import { PACKING } from 'consts/outbound/messages';
import WeightMismatch from '../../components/WeightMismatch';
import useScale from '../hooks/useScale';
import { WEIGHT_STEP } from './WeightLine';
import { updatePacking } from '../services/packing';
import useApiErrorDialog from '../../hooks/useApiErrorDialog';
import useInitialization from '../hooks/useInitialization';
import {
  isDiagnosisOnAtom,
  isScaleConnectedAtom,
  shouldOpenScaleListAtom,
  weightForDiagnosisAtom,
} from 'store/outbound/diagnosis.recoil';

type Gram = number;
const weightErrorTolerance: Gram = 1000; // TODO: 출시 전 확인 필요

const Scale = () => {
  const [isWeightMismatch, setIsWeightMismatch] = useState(false);
  const [mismatchedWeight, setMismatchedWeight] = useState('');
  const selectedStep = useRecoilValue(stepAtom);
  const packing = useRecoilValue(packingAtom);
  const [weightByScale, setWeightByScale] = useRecoilState(weightByScaleAtom);
  const { showSnackbar, showDialog, showAlert } = usePopup();
  const showApiErrorDialog = useApiErrorDialog();
  const initializeStates = useInitialization();

  const [shouldOpenScaleList, setShouldOpenScaleListAtom] = useRecoilState(shouldOpenScaleListAtom);
  const setIsScaleConnected = useSetRecoilState(isScaleConnectedAtom);
  const isDiagnosisOn = useRecoilValue(isDiagnosisOnAtom);
  const setWeightForDiagnosis = useSetRecoilState(weightForDiagnosisAtom);

  const handleWeight = (weight: string) => {
    if (isDiagnosisOn) {
      setWeightForDiagnosis(weight);
      return;
    }

    if (selectedStep < WEIGHT_STEP) {
      return;
    }

    if (!packing) {
      showAlert({ message: 'packing 정보를 받아오지 않은 채 무게를 잴 수 없습니다.' });
      return;
    }

    if (weightByScale !== '') {
      return;
    }

    if (Number(weight) < MIN_WEIGHT) {
      datadogRum.addAction(`최소 허용 무게 미만: ${weight}`, { weight });
      showDialog({
        message: PACKING.lessThanMinWeight,
        buttons: [
          {
            text: '다시 측정',
            onClick: () => setWeightByScale(''),
            marked: true,
          },
          {
            text: '포장 중지',
            onClick: () =>
              changePackingStatusToError(
                '최소 무게 미만',
                `최소 무게 ${MIN_WEIGHT}g / 측정 무게 ${weight}g`
              ),
          },
        ],
      });
    }

    if (Number(weight) > MAX_WEIGHTS[packing.carrierName]) {
      datadogRum.addAction(`최대 허용 무게 이상: ${weight}/${MAX_WEIGHTS[packing.carrierName]}`);
      showDialog({
        message: PACKING.overMaxWeight,
        buttons: [
          {
            text: '확인',
            onClick: () =>
              changePackingStatusToError(
                '운송사 배송 가능 무게 초과',
                `배송 가능 무게 ${MAX_WEIGHTS[packing.carrierName]}g / 측정 무게 ${weight}g`
              ),
          },
        ],
      });

      return;
    }

    const diff = Math.abs(packing.estimatedWeight - Number(weight));
    if (diff > weightErrorTolerance) {
      datadogRum.addAction(`예상/측정 무게 오차 발생: ${packing.estimatedWeight}/${weight}`, {
        estimatedWeight: packing.estimatedWeight,
        weight,
      });
      setIsWeightMismatch(true);
      setMismatchedWeight(weight);

      return;
    }

    datadogRum.addAction(`무게 측정 완료: ${weight}g`, { weight });
    setWeightByScale(weight);
  };

  const changePackingStatusToError = async (errorCode: string, errorMessage: string) => {
    if (!packing) {
      showAlert({ message: '포장 정보 없음' });
      return;
    }

    try {
      await updatePacking({ ...packing, status: 'ERROR', errorCode, errorMessage });
      initializeStates();
      showSnackbar({ message: PACKING.changeToErrorStatus, severity: 'error' });
    } catch (error) {
      const errorMessage = mapPackingErrorToMessage(error);
      showApiErrorDialog(errorMessage, PACKING.statusChangeFailure);
    }
  };

  const { requestScalePort, isConnected } = useScale(handleWeight, (error: Error) => {
    showSnackbar({ message: error.message, severity: 'error' });
    setIsScaleConnected(false);
  });

  useEffect(() => {
    if (isConnected) {
      setIsScaleConnected(true);
    }
  }, [isConnected]);

  useEffect(() => {
    if (shouldOpenScaleList) {
      requestScalePort();
      setShouldOpenScaleListAtom(false);
    }
  }, [shouldOpenScaleList]);

  return (
    <>
      <Dialog
        onClose={() => setIsWeightMismatch(false)}
        open={isWeightMismatch}
        buttons={[
          {
            text: '포장중지',
            onClick: () =>
              changePackingStatusToError(
                '예상/측정 무게 오차 발생',
                `예상 무게 ${packing?.estimatedWeight}g / 측정 무게 ${mismatchedWeight}g`
              ),
            marked: true,
          },
          {
            text: '계속 포장진행',
            onClick: () => setWeightByScale(mismatchedWeight),
          },
        ]}
      >
        <WeightMismatch
          estimated={packing?.estimatedWeight ?? 0}
          measured={Number(mismatchedWeight)}
        />
      </Dialog>
    </>
  );
};

export default Scale;
