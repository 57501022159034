import { atom } from 'recoil';

export interface Warehouse {
  id: number;
  fullCode?: string;
  displayName: string;
  value: string | number | boolean;
  isReflectInventory: boolean;
}

export const warehouseAtom = atom({
  key: 'warehouseAtom',
  default: '43',
});

export const warehouseOptionsAtom = atom<Warehouse[]>({
  key: 'warehousesAtom',
  default: [],
});

export const allWarehouseOptionsAtom = atom<Warehouse[]>({
  key: 'allWarehousesAtom',
  default: [],
});

export default warehouseAtom;
