import axios, { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { REACT_APP_API_ENDPOINT_V2 } from '../../../../../consts/common/environment';
import FileDownload from 'js-file-download';

export const generateExcelDownload = async (stocktakingId: number) => {
  try {
    const url =
      REACT_APP_API_ENDPOINT_V2 +
      `/inventories/stocktakings/${stocktakingId}/locations/excel-download`;
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    FileDownload(response.data, 'locations.xls');
    return response;
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    const promise = await blobToJson(error1.response?.data);
    datadogRum.addAction(`엑셀 다운로드 실패 : ${promise}`);
    return promise;
  }
};

const blobToJson = (blob: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsText(blob);
  });
};
