import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Link from '@mui/material/Link';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { numberFormatter } from '../../../../libs/common/unitConverters';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { REACT_APP_BASE_PATH } from '../../../../consts/common/environment';
import { SearchQuery } from './index.page';

const makeGridColDef = (queryParams: SearchQuery) => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingTypeOptions = refCodeOptions?.receivingType || [];
  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 50,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'receivingType',
      headerName: '입고구분',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return receivingTypeOptions.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'supplierName',
      headerName: '공급업체',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return warehouseOption.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'totalQty',
      headerName: '입고수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return row.receivingType === 'RETURN' || row.receivingType === 'DEFECT_RETURN'
          ? '-' + numberFormatter(row.totalQty)
          : numberFormatter(row.totalQty);
      },
    },
    {
      field: 'totalPrice',
      headerName: '입고매입소계(원)',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
      renderCell: ({ row }) => {
        const handleClick = () => {
          if (
            !queryParams.dateType ||
            !queryParams.receivingCompletedAtFrom ||
            !queryParams.receivingCompletedAtTo
          ) {
            return;
          }

          const data = {
            warehouseId: row.warehouseId,
            dateType: queryParams.dateType,
            receivingCompletedAtFrom: queryParams.receivingCompletedAtFrom,
            receivingCompletedAtTo: queryParams.receivingCompletedAtTo,
            supplierName: row.supplierName,
            receivingType: row.receivingType,
          };

          const queryString = new URLSearchParams(data).toString();

          window.open(
            REACT_APP_BASE_PATH + '/gms/inbound/specification-completed-receiving?' + queryString,
            '_blank'
          );
        };

        return (
          <Link
            onClick={handleClick}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }}
          >
            {row.receivingType === 'RETURN' || row.receivingType === 'DEFECT_RETURN'
              ? '-' + numberFormatter(row.totalPrice)
              : numberFormatter(row.totalPrice)}
          </Link>
        );
      },
    },
  ] as GridColDef[];
};

export default makeGridColDef;
