import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import type { SelectChangeEvent } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { SxProps } from '@mui/material/styles';

export type OptionValue = string | boolean | number;

export type Option = {
  displayName: string;
  value: OptionValue;
};

type SelectBaseProps = {
  name: string;
  options: Option[];
  selected: string;
  sx?: SxProps;
  onChange: (e: SelectChangeEvent<string>) => void;
  disabled?: boolean;
};

const SelectBase = (props: SelectBaseProps) => {
  const { name, options, selected, sx, onChange, disabled = false } = props;

  return (
    <FormControl fullWidth>
      <Select
        name={name}
        value={selected}
        onChange={onChange}
        displayEmpty
        disabled={disabled}
        sx={{
          ...sx,
          '& .MuiSelect-select': {
            padding: 0.4,
            pl: 1.5,
          },
        }}
      >
        {options.map((option: Option) => (
          <MenuItem key={option.displayName} value={option.value.toString()}>
            <ListItemText
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0,
                '& .MuiListItemText-primary': {
                  fontSize: '13px',
                },
              }}
              primary={option.displayName}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBase;
