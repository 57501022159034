import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from 'libs/common/fetcher';
import { errorType } from '../../../../../types/common';

type MovingInventory = {
  fromLocationCode: string;
  toLocationCode: string;
  inventoryLpnId: number;
  quantity: number;
};

export type MovementRequestBody = {
  warehouseId: number;
  movementType: string;
  note?: string | null;
  movingItems: MovingInventory[];
};

export const moveInventory = async (movingItems: MovementRequestBody) => {
  try {
    return await axiosV2.post(`/inventories/inventory-lpns/movements`, movingItems);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고 이동 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
