import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import { axiosV2 } from 'libs/common/fetcher';
import { errorType } from '../../../../../types/common';

export type createPickingGroupRequest = {
  warehouseId: number | string;
};

export const fetchPickingGroupOfInventoryTransfer = async (
  requestBody: createPickingGroupRequest
) => {
  try {
    return await axiosV2.post(`/picking-groups/inventory-transfer`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`집품그룹 생성 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
