import { useContext } from 'react';
import { PopupContext } from 'components/common/Popup/PopupProvider';

const usePopup = () => {
  const actions = useContext(PopupContext);

  if (actions == null) {
    throw new Error('usePopup는 PopupProvider의 자식 컴포넌트에서만 사용할 수 있습니다.');
  }

  return actions;
};

export default usePopup;
