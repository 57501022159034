import React from 'react';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/common/colors';

type Props = {
  width: number;
  height: number;
  length: number;
};

const BoxVolumeText = (props: Props) => {
  const { width, height, length } = props;

  return (
    <>
      <Typography display="inline" color={COLORS.lightGrey}>
        가로{' '}
      </Typography>
      <Typography display="inline" fontWeight={700}>
        {width}
      </Typography>
      <Typography display="inline" color={COLORS.lightGrey}>
        {' '}
        x 세로
      </Typography>
      <Typography display="inline" fontWeight={700}>
        {length}
      </Typography>
      <Typography display="inline" color={COLORS.lightGrey}>
        {' '}
        x 높이
      </Typography>
      <Typography display="inline" fontWeight={700}>
        {height}
      </Typography>
    </>
  );
};

export default BoxVolumeText;
