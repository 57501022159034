import React from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import type { Row, Value } from '../../components/ListTable';
import ListTable from '../../components/ListTable';
import AutoContainImage from 'components/common/Image/AutoContainImage';
import type { InspectionItems } from 'types/outbound';
import { separateArrow } from './ItemListLine/utils';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';

const modalWidth = 800;

type Props = {
  items: InspectionItems[];
  select: (item: Row | null) => void;
  isWrongItem: () => void;
};

const DuplicateBarcodeModal = (props: Props) => {
  const { items, select, isWrongItem } = props;

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>
          바코드 동일 아이템이 있습니다. 아이템 확인 후 선택해 주세요.
        </Typography>
        <ListTable
          rows={items.map((item: InspectionItems) => ({ ...item, id: item.shippingItemId }))}
          columns={makeColumns}
          hideFooter={true}
          onClickRow={select}
          sx={styles.listTable}
        />
        <Box sx={styles.buttonContainer}>
          <Button
            onClick={() => {
              isWrongItem();
              select(null);
            }}
            variant="outlined"
            sx={{ mr: 3 }}
          >
            오집품 등록
          </Button>
          <Button
            onClick={() => {
              select(null);
            }}
            variant="outlined"
          >
            닫기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DuplicateBarcodeModal;

const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  listTable: { maxHeight: '400px', mb: 3 },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
};

const makeColumns = [
  {
    headerName: '바코드',
    field: 'barcode',
    width: 80,
  },
  {
    headerName: '아이템코드',
    field: 'itemCode',
    width: 100,
  },
  {
    headerName: '이미지',
    field: 'imageUrl',
    width: 80,
    renderCell: (url: Value) => {
      const finalUrl = getFinalUrl(url as string);
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
          <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
        </Box>
      );
    },
  },
  {
    headerName: '아이템명',
    field: 'itemName',
    width: 380,
    renderCell: (itemName: Value) => {
      if (!itemName) {
        return;
      }

      const [item, remark] = (itemName as string).split(separateArrow);

      return (
        <p style={{ textAlign: 'start' }}>
          <span>{item}</span>
          <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
        </p>
      );
    },
  },
  {
    headerName: '선택',
    field: 'selectButton',
    width: 100,
    renderCell: () => <Button variant={'contained'}>선택</Button>,
  },
];
