import React, { useEffect } from 'react';
import MUISelect from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import type { SelectChangeEvent } from '@mui/material';
import useFilter from './hooks/useFilter';
import GridSection from '../GridSection';
import { selectTheme, listItemTextTheme } from 'styles/customedMuiTheme';

export type Option = {
  displayName: string;
  secondaryDisplayName?: string;
  value: string | number | boolean;
};

type Props = {
  label: string;
  field: string;
  options: Option[];
  labelGridColumn: `${number}/${number}`;
  selectGridColumn: `${number}/${number}`;
  gridRow?: number;
  defaultValue?: string;
};

const Select = (props: Props) => {
  const { label, field, labelGridColumn, selectGridColumn, gridRow, options, defaultValue } = props;
  const { form, setForm } = useFilter();

  useEffect(() => {
    if (defaultValue) {
      setForm(form => ({ ...form, [field]: defaultValue }));
    }
  }, [defaultValue, field, setForm]);

  const initialValue = defaultValue ?? options[0]?.value;
  const handleChange = (e: SelectChangeEvent) => {
    setForm(form => ({ ...form, [field]: e.target.value }));
  };

  return (
    <>
      <GridSection sx={{ gridRow, gridColumn: labelGridColumn }} isLabel>
        <Typography>{label}</Typography>
      </GridSection>
      <GridSection sx={{ gridRow, gridColumn: selectGridColumn }}>
        <FormControl fullWidth>
          <MUISelect
            name={field}
            value={(form[field] as string) ?? initialValue}
            onChange={handleChange}
            inputProps={{ 'aria-label': label }}
            sx={{
              '& .MuiSelect-select': {
                padding: 1,
              },
              ...selectTheme.medium,
            }}
          >
            {options.map(option => (
              <MenuItem key={option.displayName} value={option.value.toString()}>
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                    ...listItemTextTheme.medium,
                  }}
                  primary={option.displayName}
                  secondary={option.secondaryDisplayName}
                />
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
      </GridSection>
    </>
  );
};

export default Select;
