import { axiosV2 } from 'libs/common/fetcher';
import type { DeviceForm } from '../components/DevicesForm';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

export type RequestBodyForCreate = Pick<
  DeviceForm,
  'warehouseId' | 'deviceType' | 'jobType' | 'registrationNumber' | 'isDeviceAvailable'
>;

export const createDevice = async (device: RequestBodyForCreate) => {
  return await axiosV2.post(`/devices`, device);
};

export type RequestBodyForUpdate = Pick<
  DeviceForm,
  'jobType' | 'registrationNumber' | 'isDeviceAvailable'
>;

export const updateDevice = async (id: number, device: RequestBodyForUpdate) => {
  try {
    return await axiosV2.put(`/devices/${id}`, device);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`작업기기 수정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
