import Typography from '@mui/material/Typography';
import styles from 'styles/Home.module.css';

const Custom403 = () => {
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <Typography variant="h4">이 페이지에 접근할 수 없습니다</Typography>
      </main>
    </div>
  );
};

export default Custom403;
