import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import type { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import { warehouseAtom, warehouseOptionsAtom } from 'store/outbound/warehouse.recoil';
import fetcher, { axiosV2 } from 'libs/common/fetcher';
import Page from 'components/common/Layout/Page';
import TightDataGridPro from 'components/common/TightDataGridPro';
import type { Form } from 'components/common/Filter';
import Filter from 'components/common/Filter';
import FormModal from 'components/common/FormModal';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import convertUser from '../../../../libs/common/convertUser';
import type { DeviceForm } from './components/DevicesForm';
import DevicesForm from './components/DevicesForm';
import type { Option } from './components/SelectBase';
import { generateDeviceForm, getSelectedDevice } from './utils';
import gridColumns from './gridColDef';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import useConditionalSWR from '../../components/useConditionalSwr';

export type Device = {
  id: number;
  warehouseId: number;
  deviceType: string;
  jobType: string;
  deviceNumber: string;
  isForInventoryInvestigation: boolean;
  registrationNumber: string;
  isDeviceAvailable: boolean;
  updatedUsername: string;
  updatedLoginId: string;
  updatedAt: string;
};

export type Devices = {
  content: Device[];
  totalElements: number;
  size: number;
  number: number;
};

export const isDeviceAvailableOptions: Option[] = [
  { displayName: '사용', value: true },
  { displayName: '사용안함', value: false },
];

const defaultOptionValue = 'ALL';

export const defaultOption = {
  displayName: '전체',
  value: defaultOptionValue,
};

export const selectOption = {
  displayName: '선택',
  value: defaultOptionValue,
};

const defaultPage = 0;
const defaultSize = 100;

const gridInputColumn = 'repeat(2, minmax(10px, 1fr))';
const gridTemplateColumns = ['auto', 'auto', 'auto', 'auto'].reduce(
  (prevValue, labelWidth) => prevValue + labelWidth + ' ' + gridInputColumn + ' ',
  ''
);

const Devices = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeviceForm, setSelectedDeviceForm] = useState<DeviceForm>();
  const [hasSearched, setHasSearched] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<Record<string, string | number>>({
    page: defaultPage,
    size: defaultSize,
    warehouseId: Number(globalWarehouse),
  });
  const { data, mutate, isValidating } = useConditionalSWR<Devices>(
    [`/devices`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const deviceTypeOptions = refCodeOptions?.deviceType || [];
  const jobTypeOptions = refCodeOptions?.jobType || [];

  const handleChangeInventoryInvestigation = async (value: boolean) => {
    if (value) {
      await axiosV2.put(`/devices/active-inventory-investigation`);
    } else {
      await axiosV2.put(`/devices/inactive-inventory-investigation`);
    }
  };

  const handleRegistration = () => {
    setSelectedDeviceForm(undefined);
    setIsModalOpen(true);
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    if (!data?.content) {
      return;
    }

    const selectedDevice = getSelectedDevice(data.content, params.id as number);
    const deviceForm = generateDeviceForm(selectedDevice) as DeviceForm;
    setSelectedDeviceForm(deviceForm);
    setIsModalOpen(true);
  };

  const makeRowIndex = (data: Devices) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Device, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: defaultPage,
      size: defaultSize,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOptionValue);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: defaultPage }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        작업기기 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="기기구분"
          field="deviceType"
          options={[defaultOption, ...deviceTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Select
          label="업무파트"
          field="jobType"
          options={[defaultOption, ...jobTypeOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.Select
          label="사용여부"
          field="isDeviceAvailable"
          options={[defaultOption, ...isDeviceAvailableOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.Search
          label="기기고유번호"
          field="registrationNumber"
          labelGridColumn="1/2"
          inputGridColumn="2/7"
        />
        <Filter.Search
          label="작업기기번호"
          field="deviceNumber"
          labelGridColumn="7/8"
          inputGridColumn="8/13"
        />
        <Divider
          sx={{
            gridRow: 3,
            gridColumn: '1/-1',
            pb: 1,
            mb: 1,
          }}
        />
        <Filter.DefaultButtonGroup
          sx={styles.filterDefaultButtonGroup}
          gridColumn="1/13"
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={styles.gridBorderTopTitle}>
            작업기기 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={() => handleChangeInventoryInvestigation(true)}
            >
              {`전수재고조사 'Y' 일괄저장`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={() => handleChangeInventoryInvestigation(false)}
            >
              {`전수재고조사 'N' 일괄저장`}
            </Button>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="contained"
              onClick={handleRegistration}
            >
              등록
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColumns(warehouseOption)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => {
            setQueryParams(params => ({ ...params, page }));
          }}
          pageSize={Number(queryParams.size) ?? defaultSize}
          onPageSizeChange={(pageSize: number) => {
            setQueryParams(params => ({ ...params, size: pageSize }));
          }}
          getRowId={row => row.id}
          sx={styles.tightDataGrid}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={isModalOpen}
        title={selectedDeviceForm ? '작업기기 수정' : '작업기기 등록'}
        onClose={onCloseModal}
      >
        <DevicesForm
          selectedDevice={selectedDeviceForm}
          onClose={onCloseModal}
          mutate={mutate}
          options={{ deviceTypeOptions, jobTypeOptions }}
        />
      </FormModal>
    </Page>
  );
};

export default Devices;

const styles = {
  filterDefaultButtonGroup: {
    justifySelf: 'center',
  },
  gridBorderTopTitle: {
    color: 'text.primary',
  },
  tightDataGrid: {
    bgColor: 'white',
    elevation: 6,
  },
};
