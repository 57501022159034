import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

export const deleteShipping = async (deliveryId: number) => {
  try {
    return await axiosV2.delete(`/cs/shippings/${deliveryId}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`출고 삭제 실패: ${error1}`);
    return error1.response;
  }
};

export const completedShipping = async (deliveryId: number) => {
  try {
    return await axiosV2.post(`/cs/shippings/completed/${deliveryId}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`출고(포장) 완료 실패: ${error1}`);
    return error1.response;
  }
};
