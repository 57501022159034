import React, { ChangeEvent, useState } from 'react';
import Box from '@mui/system/Box';
import { COLORS } from 'consts/common/colors';
import SaveButtonGroup from './SaveButtonGroup';
import TextField from '@mui/material/TextField';
import { useRecoilValue } from 'recoil';
import { warehouseAtom } from '../../../../../store/outbound/warehouse.recoil';
import usePopup from '../../../../../hooks/usePopup';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

const modalWidth = 500;

type AddInventoryFormProps = {
  onClose: () => void;
};

const AddInventoryForm = (props: AddInventoryFormProps) => {
  const { onClose } = props;
  const { showSnackbar, showErrorDialog } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [locationCode, setLocationCode] = useState<string>('');
  const [itemCode, setItemCode] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('');

  const onChangeLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setLocationCode(event.target.value);
  };
  const onChangeItemCode = (event: ChangeEvent<HTMLInputElement>) => {
    setItemCode(event.target.value);
  };
  const onChangeCount = (event: ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value);
  };

  const fetchInventoryLpn = async () => {
    try {
      return await axiosV2.post(`/inventories/inventory-lpns`, {
        warehouseId: Number(globalWarehouse),
        locationCode: locationCode,
        itemCode: itemCode,
        quantity: quantity,
      });
    } catch (error) {
      const error1 = error as AxiosError<errorType, string>;
      datadogRum.addAction(`재고 추가 실패 : ${error1.response?.data}`);
      return error1.response;
    }
  };

  const handleCreate = async () => {
    if (quantity === '') {
      showErrorDialog({
        title: '재고 추가 실패',
        errorMessage: '재고수량을 입력해주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    const response = await fetchInventoryLpn();
    if (response?.status === 200) {
      showSnackbar({
        message: '재고 추가가 완료되었습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: '재고 추가 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    onClose();
  };

  return (
    <Box width={modalWidth}>
      <TextField
        sx={{ mb: 2 }}
        id="location"
        label="로케이션"
        variant="outlined"
        fullWidth
        value={locationCode}
        onChange={onChangeLocation}
        InputLabelProps={{ sx: styles.inputLabel }}
      />
      <TextField
        sx={{ mb: 2 }}
        id="itemCode"
        label="아이템코드"
        variant="outlined"
        fullWidth
        value={itemCode}
        onChange={onChangeItemCode}
        InputLabelProps={{ sx: styles.inputLabel }}
      />
      <TextField
        sx={{ mb: 2 }}
        id="count"
        label="재고수량"
        variant="outlined"
        fullWidth
        value={quantity}
        onChange={onChangeCount}
        InputLabelProps={{ sx: styles.inputLabel }}
      />
      <SaveButtonGroup onSaveClick={handleCreate} onCloseClick={onClose} />
    </Box>
  );
};

export default AddInventoryForm;

const styles = {
  inputLabel: { color: COLORS.darkGrey },
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  container: {
    border: `1px solid ${COLORS.darkLine}`,
  },
  select: {
    height: '32px',
  },
};
