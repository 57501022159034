import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import Table, { SmallTableCell, TableRow } from '../../../../components/Table';
import { COLORS } from '../../../../../../consts/common/colors';
import { buttonTheme } from '../../../../../../styles/customedMuiTheme';
import { TransferOutGenerateResult } from '../types';

const TransferOutResult = ({ result }: { result: TransferOutGenerateResult }) => {
  const { totalSku, totalItem, totalWeight, totalPrice, memo, serialNumber } = result;
  const navigate = useNavigate();

  const moveToInventoryTransferSearch = () => {
    navigate(`/gms/transfer/orders/searches?serialNumber=${serialNumber}`);
  };

  return (
    <>
      <Table>
        <TableRow height="36px">
          <SmallTableCell label={'품목수량'} value={totalSku} />
          <SmallTableCell label={'아이템수량'} value={totalItem} />
        </TableRow>
        <TableRow height="36px">
          <SmallTableCell label={'실제중량무게 총합계(g)'} value={totalWeight} />
          <SmallTableCell label={'공급가 총합계(원)'} value={totalPrice} />
        </TableRow>
        <TableRow height="36px">
          <SmallTableCell label={'비고'} value={memo} />
        </TableRow>
      </Table>
      <Stack sx={styles.container}>
        <DoubleArrowIcon sx={styles.arrow} />
        <Card variant="outlined" sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            <Stack>
              <Typography sx={styles.message}>{'대체출고지시가 생성완료되었습니다.'}</Typography>
              <Typography sx={styles.id}>{serialNumber}</Typography>
            </Stack>
          </CardContent>
          <CardActions sx={{ padding: 0 }}>
            <Button
              variant="outlined"
              onClick={moveToInventoryTransferSearch}
              sx={buttonTheme.medium}
            >
              대체출고지시서 조회
            </Button>
            <Button variant="contained" onClick={reload} sx={buttonTheme.medium}>
              다음 대체출고 생성 시작
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default TransferOutResult;

const reload = () => {
  window.location.reload();
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
  },
  arrow: {
    transform: 'rotate(90deg) translateY(16px)',
    fontSize: '28px',
    color: 'primary.main',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    padding: '12px',
    borderColor: 'primary.main',
    backgroundColor: COLORS.bgLight,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  message: {
    fontSize: 20,
    color: COLORS.veryDarkGrey,
  },
  id: {
    fontSize: '28px',
    fontWeight: 700,
    color: COLORS.veryDarkGrey,
    alignSelf: 'center',
  },
};
