import { axiosV2 } from '../../../../../libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

export const fetchReceivingItemsForSpecification = async (receivingId: string | number) => {
  try {
    return await axiosV2.get(`/receivings/${receivingId}/specification`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`거래명세서 조회/입고목록 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

type RequestBodyForUpdateReceivingItem = {
  receiveCompletedDate: string;
  itemsToReceiving: { itemId: string | number; quantity: number; cost: number }[];
};

export const updateReceivingItemsForSpecification = async (
  receivingId: number,
  requestBody: RequestBodyForUpdateReceivingItem
) => {
  try {
    return await axiosV2.patch(`/receivings/${receivingId}/specification`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`거래명세서 조회/입고내역 수정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
