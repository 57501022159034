import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import warehouseAtom, { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import useConditionalSWR from '../../components/useConditionalSwr';
import TightDataGridPro from '../../../../components/common/TightDataGridPro';
import gridColDef from './gridColDef';

type conveyorPath = {
  id: number;
  warehouseId: number;
  basePath: string;
  conveyorPath: string;
  count: number;
};

type SearchQuery = {
  warehouseId?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const ConveyorPaths = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    warehouseId: Number(globalWarehouse),
  });

  const { data, isValidating, mutate } = useConditionalSWR<conveyorPath[]>(
    [`/conveyors/shippings/count`, { ...queryParams }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: conveyorPath[]) => {
    return data.map((item: conveyorPath, index: number) => ({
      ...item,
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(() => ({ ...updatedForm }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      warehouseId: Number(globalWarehouse),
    });
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        경로별 포장필요건 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={warehouseOption}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            컨베이어 포장경로별 포장전 출고건 목록
          </Typography>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data ? makeRowIndex(data) : []}
          columns={gridColDef()}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default ConveyorPaths;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
