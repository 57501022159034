import type { GridColDef } from '@mui/x-data-grid-pro';

const gridColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'locationCode',
      headerName: '로케이션',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '온라인바코드',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인바코드',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'code',
      headerName: '아이템 코드',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'name',
      headerName: '아이템명',
      minWidth: 440,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'sumOfQuantityA',
      headerName: 'A 수량합계',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'sumOfQuantityB',
      headerName: 'B 수량합계',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'difference',
      headerName: '수량차이',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
