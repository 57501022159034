import { useContext } from 'react';
import { FilterContext } from '../index';

const useFilter = () => {
  const context = useContext(FilterContext);

  if (context === null) {
    throw new Error('useFilter must be used within a <Filter />');
  }

  return context;
};

export default useFilter;
