import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { Option } from '../components/SelectBase';
import { DeviceForm } from '../components/DevicesForm';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';

const generateDeviceRows = (device: DeviceForm) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const deviceWorkTypeOptions = refCodeOptions?.workType || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const warehouse = warehouseOption.find(item => item.value === device.warehouseId);
  const warehouseName = warehouse?.displayName ?? '';
  const availableOptions: Option[] = [
    { displayName: '작업가능', value: true },
    { displayName: '작업제외', value: false },
  ];
  const pdaWorkType = ['PICKING', 'BULK_PICKING', 'SINGLE_BULK_PICKING', 'DONATION'];
  const pcWorkType = ['INSPECTION', 'PACKING'];
  const deviceTypeByWorkType =
    device.deviceType === 'PDA'
      ? deviceWorkTypeOptions.filter(item => pdaWorkType.includes(item.value as string))
      : deviceWorkTypeOptions.filter(item => pcWorkType.includes(item.value as string));
  const deviceTypeByAvailableOptions =
    device.deviceType === 'PDA' ? availableOptions : [{ displayName: '-', value: true }];
  return {
    basics: [
      {
        label: '창고',
        value: {
          type: 'typography',
          typography: warehouseName,
        },
      },
      {
        label: '기기구분',
        value: {
          type: 'typography',
          typography: device.deviceType,
        },
      },
      {
        label: '작업기기번호',
        value: {
          type: 'typography',
          typography: device.deviceNumber,
        },
      },
    ],
    details: [
      {
        label: '작업파트구분',
        value: {
          type: 'select',
          select: {
            name: 'workType',
            options: deviceTypeByWorkType,
            selected: device.workType,
          },
        },
      },
      {
        label: '작업가능여부',
        value: {
          type: 'select',
          select: {
            name: 'isWorkAvailable',
            options: deviceTypeByAvailableOptions,
            selected: device.isWorkAvailable.toString(),
          },
        },
      },
    ],
  };
};

export default generateDeviceRows;
