import React from 'react';
import Box from '@mui/system/Box';
import AutoContainImage from 'components/common/Image/AutoContainImage';
import { ErrorDetails } from '../../ItemListLine';
import { separateArrow } from '../../ItemListLine/utils';
import type { Column, Value } from '../../../../components/ListTable';
import { useSetRecoilState } from 'recoil';
import { imageModalAtom } from 'store/outbound/inspection.recoil';
import { getFinalUrl } from '../../../../../../../consts/outbound/imageUrl';

export const columns = [
  {
    headerName: '바코드',
    field: 'barcode',
    width: 80,
  },
  {
    headerName: '아이템코드',
    field: 'itemCode',
    width: 100,
  },
  {
    headerName: '이미지',
    field: 'imageUrl',
    width: 80,
    renderCell: (url: Value) => {
      const setImageModal = useSetRecoilState(imageModalAtom);

      if (!url) {
        return;
      }
      const finalUrl = getFinalUrl(url as string);

      return (
        <Box
          sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          onClick={() => {
            setImageModal({
              shouldShow: true,
              imageUrl: finalUrl.toString(),
            });
          }}
        >
          <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
        </Box>
      );
    },
  },
  {
    headerName: '아이템명',
    field: 'itemName',
    width: 380,
    renderCell: (itemName: Value) => {
      if (!itemName) {
        return;
      }

      const [item, remark] = (itemName as string).split(separateArrow);

      return (
        <p style={{ textAlign: 'start' }}>
          <span>{item}</span>
          <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
        </p>
      );
    },
  },
] as Column[];

export const makeTextByType = (type: string | null) => {
  const lines = {
    firstLine: '',
    secondLine: '[검수중지] 클릭 후 담당 매니저를 호출해 주세요.',
  };

  switch (type) {
    case 'WRONG':
      lines.firstLine = '출고건 미포함 아이템';
      break;
    case 'MISSING':
      lines.firstLine = '미집품 아이템';
      break;
    case 'DEFECT':
      lines.firstLine = '불량 아이템';
      break;
    case 'EXPIRATION':
      lines.firstLine = '유통기한 판매불가 아이템';
      break;
    default:
  }

  return lines;
};

export const makeColumns = (columns: Column[], type: string | null) => {
  switch (type) {
    case 'MISSING':
      return [
        ...columns,
        {
          headerName: '부족수량',
          field: 'remainingQuantity',
          width: 80,
        },
      ];
    case 'DEFECT':
      return [
        ...columns,
        {
          headerName: '유통기한',
          field: 'expired',
          width: 160,
        },
      ];
    case 'EXPIRATION':
      return [
        ...columns,
        {
          headerName: '유통기한',
          field: 'expired',
          width: 160,
        },
      ];
    case 'WRONG':
    default:
      return columns;
  }
};

export const makeHistoryDescription = (errorDetails: ErrorDetails, remark: string) => {
  switch (errorDetails.type) {
    case 'DEFECT':
    case 'EXPIRATION':
      return remark;
    default:
      return '';
  }
};

export const getTotalRemainingQuantity = (errorDetails: ErrorDetails) => {
  return errorDetails.items.reduce((total, preValue) => total + preValue.remainingQuantity, 0);
};
