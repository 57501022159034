import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { shouldMountAppBarAtom, shouldMountDrawerAtom } from '../store';

const useShouldMountAppBarAndDrawer = () => {
  const setShouldMountAppBar = useSetRecoilState(shouldMountAppBarAtom);
  const setShouldMountDrawer = useSetRecoilState(shouldMountDrawerAtom);

  useEffect(() => {
    setShouldMountAppBar(false);
    setShouldMountDrawer(false);

    return () => {
      setShouldMountAppBar(true);
      setShouldMountDrawer(true);
    };
  }, []);
};

export default useShouldMountAppBarAndDrawer;
