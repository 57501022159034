import { atom } from 'recoil';
import { GmsMenuItem } from '../../components/common/Layout/menus';

export const favoriteMenusAtom = atom<Pick<GmsMenuItem, 'title' | 'route'>[]>({
  key: 'favoriteMenusAtom',
  default: [],
});

const menuAtoms = {
  favoriteMenus: favoriteMenusAtom,
};

export default menuAtoms;
