import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from 'components/common/Layout/Page';
import type { Options } from 'types/form';
import pageTitle from 'styles/pageTitle';
import { warehouseAtom } from '../../../../store/outbound/warehouse.recoil';
import usePopup from '../../../../hooks/usePopup';
import { generateExcelDownload } from './services/excelDownload';
import Filter, { Form } from '../../../../components/common/Filter';
import _ from 'lodash';

const defaultOption = { displayName: '전체', value: 'ALL' };

export type SearchQuery = {
  warehouseId?: number;
};

const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시생성일',
    field: 'shippingOrderCreatedAt',
  },
  {
    displayName: '집품그룹생성일',
    field: 'pickingGroupCreatedAt',
  },
  {
    displayName: '집품완료일',
    field: 'pickingCompletedAt',
  },
  {
    displayName: '검수완료일',
    field: 'inspectCompletedAt',
  },
  {
    displayName: '포장완료일',
    field: 'packingCompletedAt',
  },
];

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '140px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1.4fr)',
  '50px',
  'minmax(55px, 0.8fr)',
  'minmax(55px, 0.8fr)',
  '80px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const ShippingTask = () => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const [isDownload, setIsDownload] = useState(false);
  const [isLookupClicked, setIsLookupClicked] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    warehouseId: Number(globalWarehouse),
  });

  const requestSearchExcelDownload = async () => {
    if (!isLookupClicked) {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: '조회를 먼저 해주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }
    setIsDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsDownload(false);
    } else {
      setIsDownload(false);
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  const handleSearchClick = (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];
    setQueryParams(() => ({ ...updatedForm, warehouseId: Number(globalWarehouse) }));
    setIsLookupClicked(true);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고작업현황 조회
      </Typography>
      <Box>
        <Filter gridTemplateColumns={gridTemplateColumns}>
          <Filter.DateRangePickerWithSelect
            label="기간"
            rangeTypeOptions={dateRangeTypeOptions}
            gridRow={3}
            labelGridColumn="1/2"
            selectGridColumn="2/4"
            dateRangePickerGridColumn="4/6"
            rangeAmountSelectGridColumn="6/7"
            typeDefaultValue={dateRangeTypeOptions[4].field}
          />
          <Filter.DefaultButtonGroup
            gridColumn="11/14"
            gridRow={3}
            onInitClick={() => {
              return;
            }}
            onLookupClick={handleSearchClick}
          />
        </Filter>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          variant="contained"
          onClick={requestSearchExcelDownload}
          loading={isDownload}
          style={{ width: '150px' }}
        >
          엑셀다운로드
        </LoadingButton>
      </Box>
    </Page>
  );
};

export default ShippingTask;
