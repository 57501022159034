import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import type { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import FormModal from '../../../../components/common/FormModal';
import { Option } from '../../../../components/common/Filter/Select';
import convertUser from '../../../../libs/common/convertUser';
import usePopup from '../../../../hooks/usePopup';
import useConditionalSWR from '../../components/useConditionalSwr';
import gridColDef from './gridColDef';
import BinForm from './components/BinForm';
import ExcelUpload from './components/ExcelUpload';
import { generateExcelDownload } from './services';

const defaultOption = { displayName: '전체', value: 'ALL' };

const stairsOptions: Option[] = [
  {
    displayName: '필요',
    value: true,
  },
  {
    displayName: '불필요',
    value: false,
  },
];

export type Bin = {
  rowIndex: number;
  id: number;
  centerId: number;
  warehouseId: number;
  binType: string;
  locationCode: string;
  pickingOrder: number;
  isStairRequired: boolean;
  coordX: number;
  coordY: number;
  isActive: string;
  username: string;
  loginId: string;
  updatedAt: string;
};

export type BinResponse = {
  content: Bin[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const gridTemplateColumns = [
  '80px',
  'minmax(45px, 1fr)',
  'minmax(45px, 1fr)',
  '80px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Bins = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const binTypeOptions = refCodeOptions?.binType || [];
  const activeTypeOptions = refCodeOptions?.activeType || [];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExcelUploadModalOpen, setIsExcelUploadModalOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isExelDownloading, setIsExelDownloading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Bin>();
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const { showErrorDialog, showSnackbar } = usePopup();

  const { data, mutate, isValidating } = useConditionalSWR<BinResponse>(
    [`/bins/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: BinResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Bin, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.username, item.loginId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    params.id && setIsModalOpen(true);

    const row = data?.content?.find(item => item.id === params.id);
    setSelectedRow(row);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(undefined);
  };

  const handleRegisterBin = () => {
    setIsModalOpen(true);
  };

  const handleExcelDownload = async () => {
    if (!hasSearched) {
      showSnackbar({ message: `'조회' 후 사용해 주세요`, severity: 'warning' });
      return;
    }

    setIsExelDownloading(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    setIsExelDownloading(false);
  };

  const handleExcelUpload = () => {
    setIsExcelUploadModalOpen(true);
  };

  const handleCloseExcelUploadModal = () => {
    setIsExcelUploadModalOpen(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        BIN 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="로케이션구분"
          field="binType"
          options={[defaultOption, ...binTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Select
          label="계단여부"
          field="isStairRequired"
          options={[defaultOption, ...stairsOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.Select
          label="사용여부"
          field="isActive"
          options={[defaultOption, ...activeTypeOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.Search
          label="로케이션코드"
          field="locationCode"
          labelGridColumn="1/2"
          inputGridColumn="2/7"
        />
        <Divider
          sx={{
            gridRow: 3,
            gridColumn: '1/-1',
            pb: 1,
            mb: 1,
          }}
        />
        <Filter.DefaultButtonGroup
          sx={styles.filterDefaultButtonGroup}
          gridColumn="1/13"
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            로케이션 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleRegisterBin}
            >
              BIN 등록
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleExcelUpload}
            >
              로케이션 엑셀업로드
            </Button>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              loading={isExelDownloading}
              onClick={handleExcelDownload}
            >
              엑셀다운로드
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={isModalOpen}
        title={selectedRow ? 'BIN 수정' : 'BIN 등록'}
        onClose={onCloseModal}
      >
        <BinForm
          selectedRow={selectedRow}
          warehouseId={Number(globalWarehouse)}
          onClose={onCloseModal}
          onMutate={mutate}
          options={{ warehouseOption, binTypeOptions }}
        />
      </FormModal>
      <FormModal
        open={isExcelUploadModalOpen}
        title={'BIN 생성'}
        onClose={handleCloseExcelUploadModal}
      >
        <ExcelUpload onCloseClick={handleCloseExcelUploadModal} onMutate={mutate} />
      </FormModal>
    </Page>
  );
};

export default Bins;

const styles = {
  filterDefaultButtonGroup: {
    justifySelf: 'center',
  },
  gridBorderTopTitle: {
    color: 'text.primary',
  },
  tightDataGrid: {
    bgColor: 'white',
    elevation: 6,
  },
};
