import _ from 'lodash';
import { DeviceForm } from '../components/DevicesForm';
import { RequestBodyForUpdate } from '../services/devices';
import type { Device, Devices as Data } from '../index.page';
import { ValueByCode } from 'types/refCodes';

export const validateDeviceForm = (device: DeviceForm) => {
  if (device.deviceType === '' || device.deviceType === 'ALL') {
    return '기기구분을 선택해 주세요.';
  }

  if (device.registrationNumber === '') {
    return '기기고유번호를 입력해 주세요.';
  }
};

export const getSelectedDevice = (devices: Device[], selectedDeviceId: number) => {
  return devices.find(row => row.id === selectedDeviceId);
};

const deviceFormKeys = [
  'id',
  'warehouseId',
  'deviceType',
  'jobType',
  'registrationNumber',
  'isDeviceAvailable',
  'isForInventoryInvestigation',
  'deviceNumber',
] as const;
export const generateDeviceForm = (device: Device | undefined) => {
  return _.pick(device, deviceFormKeys);
};

const RequestBodyForCreateKeys = [
  'warehouseId',
  'deviceType',
  'jobType',
  'registrationNumber',
  'isDeviceAvailable',
] as const;
export const makeRequestBodyForCreate = (device: DeviceForm) => {
  return _.pick(device, RequestBodyForCreateKeys);
};

const RequestBodyForUpdateKeys = ['jobType', 'registrationNumber', 'isDeviceAvailable'] as const;
export const makeRequestBodyForUpdate = (device: RequestBodyForUpdate) => {
  return _.pick(device, RequestBodyForUpdateKeys);
};

export const adjustRows = (data: Data) => {
  const { content, totalElements, size, number } = data;

  const makeRowIndex = (index: number) => {
    return totalElements - size * number - index;
  };

  return content.map((item, index) => ({
    ...item,
    rowIndex: makeRowIndex(index),
  }));
};

export type DeviceError = Error & { response: { status: number } };
export const filterDeviceIdExistedError = (error: DeviceError) => {
  if (error.response.status === 400) {
    return '동일한 기기고유번호가 있습니다. \n다른 기기고유번호를 입력해 주세요.';
  }

  return error.message;
};

type SortOrder = 'asc' | 'desc';
export const generateOptions = (data: ValueByCode, sortOrder: SortOrder = 'asc') => {
  const options = Object.entries(data).map(([key, displayName]) => ({
    displayName,
    value: key,
  }));

  return sortOrder === 'asc' ? options : _.orderBy(options, ['displayName'], ['desc']);
};
