import { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import type { KeyedMutator } from 'swr';
import { isPrintingAtom, printerAtom } from 'store/common/printer.recoil';
import { packingIdsToPrintAtom } from 'store/outbound/packings.recoil';
import usePopup from 'hooks/usePopup';
import type { SummarizedPacking } from 'types/outbound';
import getWaybillImage from 'libs/outbound/getWaybillImage';
import { WAYBILL_FORMAT } from 'consts/outbound/waybillFormat';
import { isZpl } from '../../../../../libs/common/isZpl';
import type { Packings } from '../index.page';

type PrintError = { deliveryId?: number; code: string };

const useWaybillsPrint = (packingsToPrint: SummarizedPacking[], mutate: KeyedMutator<Packings>) => {
  const printer = useRecoilValue(printerAtom);
  const [packingIdsToPrint, setPackingIdsToPrint] = useRecoilState(packingIdsToPrintAtom);
  const [isPrinting, setIsPrinting] = useRecoilState(isPrintingAtom);
  const [printErrors, setPrintErrors] = useState<PrintError[]>([]);
  const [printFinishCount, setPrintFinishCount] = useState(0);
  const { showErrorDialog, showDialog } = usePopup();

  useEffect(() => {
    const printDoneCount = printErrors.length + printFinishCount;
    const isPrintDone = printDoneCount > 0 && printDoneCount === packingsToPrint.length;
    if (!isPrintDone) {
      return;
    }

    setIsPrinting(false);

    const okButton = {
      text: '확인',
      onClick: () => {
        setPrintErrors([]);
        setPackingIdsToPrint([]);
        setPrintFinishCount(0);
        mutate();
      },
    };

    if (printErrors.length > 0) {
      showErrorDialog({
        title: '송장 출력 오류',
        top: `${printErrors.length}건의 출력 오류가 발생했습니다\n${printFinishCount}건의 출력이 완료되었습니다`,
        errorMessage: printErrors
          .map(({ deliveryId, code }) => `배송번호 ${deliveryId} ${code}`)
          .join('\n'),
        buttons: [okButton],
      });

      return;
    }

    showDialog({
      message: '송장이 성공적으로 출력되었습니다',
      buttons: [okButton],
    });
  }, [isPrinting, printErrors.length, printFinishCount, packingsToPrint.length]);

  const printWaybill = async (packing: SummarizedPacking) => {
    const { id, deliveryId } = packing;
    try {
      const image = await getWaybillImage(id);
      if (isZpl(image)) {
        printer.send(
          `${image}`,
          () => handlePrintFinish(),
          (error: string) => handlePrintError(error, deliveryId)
        );
      } else {
        printer.convertAndSendFile(
          `data:image/${WAYBILL_FORMAT};base64,${image}`,
          () => handlePrintFinish(),
          (error: string) => handlePrintError(error, deliveryId)
        );
      }
    } catch (error) {
      setPrintErrors(errors => [...errors, { deliveryId, code: 'API 오류' }]);
    }
  };

  const handlePrintFinish = () => {
    setPrintFinishCount(count => count + 1);
  };

  const handlePrintError = (error: string, deliveryId: number) => {
    setPrintErrors(errors => [...errors, { deliveryId, code: '프린터 오류' }]);
    console.error('handlePrintError: ', error);
  };

  return async () => {
    if (!packingIdsToPrint.length) {
      window.alert('송장을 출력할 포장이 선택되지 않았습니다');
      return;
    }

    if (!printer) {
      window.alert('프린터가 연결되어 있지 않습니다');
      return;
    }

    setIsPrinting(true);

    for (const packing of packingsToPrint) {
      await printWaybill(packing);
    }
  };
};

export default useWaybillsPrint;
