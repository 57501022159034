import React, { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {
  isScaleConnectedAtom,
  isPrinterConnectedAtom,
  isDiagnosisOnAtom,
  weightForDiagnosisAtom,
  shouldOpenScaleListAtom,
} from 'store/outbound/diagnosis.recoil';
import { inputAutoFocusAtom } from 'store';
import { printerAtom } from 'store/common/printer.recoil';
import { COLORS } from 'consts/common/colors';
import diagnosisGuides from 'pages/gms/outbound/packing/diagnosisGuides';
import checkPrinterConnection from 'libs/outbound/checkPrinterConnection';
import LoadingModal from '../../components/LoadingModal';
import DiagnosisDialogContent from './DiagnosisDialogContent';
import usePopup from 'hooks/usePopup';

const DiagnosisDialog = () => {
  const printer = useRecoilValue(printerAtom);
  const isScaleConnected = useRecoilValue(isScaleConnectedAtom);
  const [isPrinterConnected, setIsPrinterConnected] = useRecoilState(isPrinterConnectedAtom);
  const [weightForDiagnosis, setWeightForDiagnosis] = useRecoilState(weightForDiagnosisAtom);
  const [isDiagnosisOn, setIsDiagnosisOn] = useRecoilState(isDiagnosisOnAtom);
  const setShouldOpenScaleList = useSetRecoilState(shouldOpenScaleListAtom);

  const [isPrinting, setIsPrinting] = useState(false);
  const [isDiagnosisFailed, setIsDiagnosisFailed] = useState(false);
  const [hasPrinterTriedToConnect, setHasPrinterTriedToConnect] = useState(false);
  const [countDiagnosisTries, setCountDiagnosisTries] = useState(1);
  const [diagnosisStep, setDiagnosisStep] = useState(0);
  const { showSnackbar } = usePopup();

  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);
  useEffect(() => {
    setInputAutoFocus(!isDiagnosisOn);
  }, [isDiagnosisOn]);

  useEffect(() => {
    setCountDiagnosisTries(1);
    setWeightForDiagnosis('');
    setIsDiagnosisOn(true);

    return () => {
      setWeightForDiagnosis('');
      setIsDiagnosisOn(false);
    };
  }, []);

  useEffect(() => {
    if (!isScaleConnected || !isPrinterConnected) {
      return;
    }

    !!weightForDiagnosis && startDiagnosis();
  }, [isScaleConnected, isPrinterConnected, weightForDiagnosis]);

  useEffect(() => {
    changeDiagnosisStep(isScaleConnected, isPrinterConnected, isDiagnosisFailed);
  }, [isScaleConnected, isPrinterConnected, isDiagnosisFailed]);

  const changeDiagnosisStep = (
    isScaleConnected: boolean,
    isPrinterConnected: boolean,
    isDiagnosisFailed: boolean
  ) => {
    if (!isScaleConnected) {
      setDiagnosisStep(0);
    } else if (isScaleConnected && !isPrinterConnected) {
      setDiagnosisStep(1);
    } else if (isScaleConnected && isPrinterConnected && !isDiagnosisFailed) {
      setDiagnosisStep(2);
    } else if (isScaleConnected && isPrinterConnected && isDiagnosisFailed) {
      setDiagnosisStep(3);
    }
  };

  const startDiagnosis = async () => {
    if (!printer) {
      return;
    }

    setIsPrinting(true);

    const handlePrintCompleted = () => {
      setIsPrinting(false);

      if (countDiagnosisTries > 1) {
        showSnackbar({ message: '연결상태 진단이 완료되었습니다.', severity: 'info' });
        setWeightForDiagnosis('');
        setCountDiagnosisTries(1);
        setIsDiagnosisOn(false);
        return;
      }
      setCountDiagnosisTries(countDiagnosisTries => countDiagnosisTries + 1);
      setWeightForDiagnosis('');
    };

    const handlePrintError = (error: Error) => {
      setIsPrinting(false);
      setIsDiagnosisFailed(true);
      setWeightForDiagnosis('');
      datadogRum.addError(`운송장프린터 인쇄 실패: ${error}`);
    };

    const diagnosisSuccessMessage = `^XA^FO50,50^ADN,36,20^FD${'Printer works well!!'}^FS^XZ`;
    await printer.send(diagnosisSuccessMessage, handlePrintCompleted, handlePrintError);
  };

  const handleOpenScaleList = () => {
    setShouldOpenScaleList(true);
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const generateDiagnosisGuides = (step: number) => {
    switch (step) {
      case 0:
        return getScaleConnectionGuide();
      case 1:
        return getPrinterConnectionGuide();
      case 2:
        return getDiagnosisGuide();
      case 3:
        return getRetryDiagnosisGuide();
    }
  };

  const getScaleConnectionGuide = () => {
    return (
      <DiagnosisDialogContent guides={diagnosisGuides.scaleConnect} onClick={handleOpenScaleList} />
    );
  };

  const getPrinterConnectionGuide = () => {
    if (isPrinterConnected) {
      return null;
    }

    const hasConnectionTryFailed = hasPrinterTriedToConnect && !isPrinterConnected;
    if (hasConnectionTryFailed) {
      return (
        <DiagnosisDialogContent
          guides={diagnosisGuides.retryPrinterConnect}
          onClick={handleReloadPage}
        />
      );
    }

    return (
      <DiagnosisDialogContent
        guides={diagnosisGuides.printerConnect}
        onClick={handleCheckPrinterConnection}
      />
    );
  };

  const handleCheckPrinterConnection = () => {
    checkPrinterConnection()
      .then(isConnected => {
        isConnected ? setIsPrinterConnected(isConnected) : setHasPrinterTriedToConnect(true);
      })
      .catch(() => {
        setHasPrinterTriedToConnect(true);
        datadogRum.addError(`운송장 프린터 연결 에러`);
      });
  };

  const getDiagnosisGuide = () => {
    return (
      <DiagnosisDialogContent
        guides={
          countDiagnosisTries === 2
            ? diagnosisGuides.secondDiagnosis
            : diagnosisGuides.firstDiagnosis
        }
        onClick={handleReloadPage}
      >
        {generateScaleWeightContent(weightForDiagnosis)}
      </DiagnosisDialogContent>
    );
  };

  const generateScaleWeightContent = (weight: string) => {
    return (
      <Box sx={styles.scaledWeightWrapper}>
        <Typography sx={styles.scaledWeightTitle}>측정무게 :</Typography>
        {weight === '' ? (
          <Typography sx={styles.scaledWeightMessage}>측정대기</Typography>
        ) : (
          <Typography sx={{ ...styles.scaledWeightMessage, color: 'red' }}>
            {weight + 'g'}
          </Typography>
        )}
      </Box>
    );
  };

  const getRetryDiagnosisGuide = () => {
    return (
      <DiagnosisDialogContent guides={diagnosisGuides.retryDiagnosis} onClick={handleReloadPage} />
    );
  };

  const shouldDiagnosisDialogOpen = !isScaleConnected || !isPrinterConnected || isDiagnosisOn;

  return (
    <Dialog
      open={shouldDiagnosisDialogOpen}
      PaperProps={{ sx: styles.paperProps }}
      BackdropProps={{ sx: styles.backdropProps }}
    >
      <DialogTitle sx={styles.dialogTitle}>전자저울/프린터 연결상태 확인</DialogTitle>
      <Box sx={styles.dialogSteps}>
        <Typography sx={diagnosisStep === 0 ? styles.currentStep : styles.otherStep}>
          전자저울 확인
        </Typography>
        <TrendingFlatIcon fontSize={'large'} />
        <Typography sx={diagnosisStep === 1 ? styles.currentStep : styles.otherStep}>
          프린터 확인
        </Typography>
        <TrendingFlatIcon fontSize={'large'} />
        <Typography sx={diagnosisStep > 1 ? styles.currentStep : styles.otherStep}>
          연결상태 진단
          {diagnosisStep > 1 && `(${countDiagnosisTries}/2)`}
        </Typography>
      </Box>
      <DialogContent sx={styles.dialogContent}>
        {generateDiagnosisGuides(diagnosisStep)}
      </DialogContent>
      <LoadingModal
        isLoading={isPrinting}
        message={'송장 출력을 준비중입니다\n잠시만 기다려주세요'}
      />
    </Dialog>
  );
};

export default DiagnosisDialog;

const styles = {
  paperProps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '520px',
    height: '460px',
  },
  backdropProps: {
    backgroundColor: COLORS.faintBlack,
  },
  dialogTitle: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '-0.3px',
  },
  dialogSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '76%',
  },
  otherStep: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  currentStep: {
    color: COLORS.ktown4u,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-even',
    alignItems: 'center',
    width: '92%',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  scaledWeightWrapper: {
    width: '100%',
    height: '24%',
    border: `1px solid ${COLORS.darkLine}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 20,
    marginTop: 1,
  },
  scaledWeightTitle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: 20,
    pr: 2,
  },
  scaledWeightMessage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    fontWeight: 'bold',
  },
};
