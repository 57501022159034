import { useState } from 'react';
import ListTable, { Value } from '../../../components/ListTable';
import Button from '@mui/material/Button';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useSWR from 'swr';
import { useParams, useSearchParams } from 'react-router-dom';
import fetcher from '../../../../../../libs/common/fetcher';
import PopupTemplateItemList from '../../../components/PopupTemplateItemList';
import Box from '@mui/material/Box';
import AutoContainImage from '../../../../../../components/common/Image/AutoContainImage';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { separateArrow } from '../../../inspection/components/ItemListLine/utils';
import { getFinalUrl } from '../../../../../../consts/outbound/imageUrl';
import { COLORS } from '../../../../../../consts/common/colors';
import ImageZoomModal from '../../../../../../components/ImageZoomModal';

type Item = {
  itemId: number;
  onlineBarcode: string;
  itemCode: string;
  imageUrl: string;
  itemName: string;
  remark: string;
  itemQuantity: number;
  realWeight: number;
};

const PickingGroupItems = () => {
  const { id } = useParams();
  const { data } = useSWR<Item[]>(`/shippings/${id}/items`, fetcher);
  const [params] = useSearchParams();
  const sku = params.get('sku');
  const quantity = params.get('quantity');
  const tubeQuantity = params.get('tubeQuantity');
  const expectedWeight = params.get('expectedWeight');

  const [zoomedImageUrl, setZoomedImageUrl] = useState<string>('');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f3f3fa',
      color: theme.palette.common.black,
      fontSize: 14,
      width: '25%',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: '25%',
    },
  }));

  const items = data
    ?.map((item, i) => ({
      ...item,
      order: i + 1,
      id: item.itemId,
      itemName: item.remark ? item.itemName + separateArrow + item.remark : item.itemName,
      totalWeight: item.itemQuantity * item.realWeight,
    }))
    .sort((a, b) => b.order - a.order);

  const totalQuantity = data?.reduce((acc, cur) => acc + cur.itemQuantity, 0);

  return (
    <>
      {
        <>
          <HelmetProvider>
            <Helmet>
              <title>케타포 GMS - 아이템정보 조회</title>
            </Helmet>
          </HelmetProvider>
          <Stack sx={{ padding: '32px', minHeight: '100vh', backgroundColor: 'white' }}>
            <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 700 }}>
              아이템정보 조회
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table
                size="small"
                sx={{ border: `1px solid ${COLORS.lightLine}` }}
                aria-label="customized table"
              >
                <TableHead sx={{ height: 36 }}>
                  <TableRow>
                    <StyledTableCell align={'center'}>품목수량</StyledTableCell>
                    <StyledTableCell align={'center'}>아이템수량</StyledTableCell>
                    <StyledTableCell align={'center'}>지관통</StyledTableCell>
                    <StyledTableCell align={'center'}>실제중량무게(g)</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ height: 36 }}>
                  <TableRow>
                    <StyledTableCell align={'center'}>{sku}</StyledTableCell>
                    <StyledTableCell align={'center'}>{quantity}</StyledTableCell>
                    <StyledTableCell align={'center'}>{tubeQuantity}</StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {Number(expectedWeight).toLocaleString()}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <PopupTemplateItemList
              subTitle="아이템정보"
              main={
                <ListTable
                  columns={makeColumns(url => setZoomedImageUrl(url))}
                  total={totalQuantity}
                  rows={items ?? []}
                  isLastCellNumber={3}
                  isLabelCellNumber={4}
                />
              }
              footer={
                <Button variant="outlined" sx={{ width: '75px' }} onClick={() => window.close()}>
                  닫기
                </Button>
              }
            />
            <ImageZoomModal imageUrl={zoomedImageUrl} onClose={() => setZoomedImageUrl('')} />
          </Stack>
        </>
      }
    </>
  );
};

export default PickingGroupItems;

const makeColumns = (enlargeImage: (url: string) => void) => {
  return [
    {
      headerName: 'NO',
      field: 'order',
      width: 40,
    },
    {
      headerName: '바코드',
      field: 'onlineBarcode',
      width: 60,
    },
    {
      headerName: '아이템 코드',
      field: 'itemCode',
      width: 50,
    },
    {
      headerName: '이미지',
      field: 'imageUrl',
      width: 50,
      renderCell: (url: Value) => {
        const finalUrl = getFinalUrl(url as string);

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 0.5,
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => enlargeImage(finalUrl.toString())}
          >
            <AutoContainImage src={finalUrl.toString()} imageContainerSize={30} />
          </Box>
        );
      },
    },
    {
      headerName: '아이템명',
      field: 'itemName',
      width: 260,
      renderCell: (itemName: Value) => {
        const [item, remark] = (itemName as string).split(separateArrow);

        return (
          <p style={{ textAlign: 'start' }}>
            <span>{item}</span>
            <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
          </p>
        );
      },
    },
    {
      headerName: '아이템수량',
      field: 'itemQuantity',
      width: 70,
    },
    {
      headerName: '실제중량무게(g)',
      field: 'totalWeight',
      width: 90,
      renderCell: (value: Value) => {
        return <span>{value?.toLocaleString()}</span>;
      },
    },
    {
      headerName: '집품구역 가용재고',
      field: 'availableInventoryQty',
      width: 60,
      renderCell: (value: Value) => {
        return <span>{value?.toLocaleString()}</span>;
      },
    },
  ];
};
