import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { listItemTextTheme, selectTheme } from '../../../styles/customedMuiTheme';
import GridSection from '../GridSection';
import useFilter from './hooks/useFilter';

export type Option = {
  displayName: string;
  secondaryDisplayName?: string;
  value: string | number | boolean;
};

type Props = {
  label: string;
  field: string;
  options: Option[];
  labelGridColumn: `${number}/${number}`;
  selectGridColumn: `${number}/${number}`;
  defaults?: string[];
  gridRow?: number;
};

const getStyles = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    padding: 0,
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const MultipleSelectPlaceholder = forwardRef((props: Props, ref) => {
  const { label, field, labelGridColumn, selectGridColumn, gridRow, options, defaults } = props;
  const select = useRef<string>('');
  const isValuesInitiated = useRef<boolean>(false);
  const [values, setValues] = useState<string[]>(['ALL']);

  const theme = useTheme();
  const { form, setForm } = useFilter();

  useEffect(() => {
    if (isValuesInitiated.current) return;

    if (defaults) {
      setValues(defaults);
      setForm(form => ({ ...form, [field]: defaults.join(', ') }));
      isValuesInitiated.current = true;
      return;
    }

    if (options.length <= 1) return;

    setValues(options.map(option => option.value.toString()));
    isValuesInitiated.current = true;
  }, [options, defaults, isValuesInitiated.current, field, setValues, setForm]);

  useImperativeHandle(ref, () => ({ initialValues }));

  const initialValues = () => {
    select.current = '';
    isValuesInitiated.current = false;
    setValues(['ALL']);
  };

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;

    const selectedValues = typeof value === 'string' ? value.split(',') : value;

    const selectAllItem = select.current === 'ALL' && values.indexOf('ALL') === -1;
    const deselectAllItem = select.current === 'ALL' && values.indexOf('ALL') > -1;
    const selectOneItemWhenAllSelected = select.current !== 'ALL' && values.indexOf('ALL') > -1;
    const selectNothing =
      select.current !== 'ALL' && values.indexOf('ALL') === -1 && selectedValues.length === 0;
    const selectEverythingExceptAll =
      select.current !== 'ALL' &&
      values.indexOf('ALL') === -1 &&
      selectedValues.length === options.filter(option => option.value !== 'ALL').length;

    const makeValues = () => {
      if (deselectAllItem || selectNothing) return [''];
      if (selectAllItem || selectEverythingExceptAll) {
        return options.map(option => option.value.toString());
      }
      if (selectOneItemWhenAllSelected)
        return values.filter(value => value !== select.current && value !== 'ALL');

      return selectedValues.filter(value => value !== '');
    };

    const makeForm = () => {
      if (selectAllItem || deselectAllItem || selectNothing || selectEverythingExceptAll) {
        const newForm = { ...form };
        delete newForm[field];
        return newForm;
      }
      if (selectOneItemWhenAllSelected)
        return {
          ...form,
          [field]: selectedValues
            .filter(value => value !== select.current && value !== 'ALL')
            .join(', '),
        };

      return { ...form, [field]: selectedValues.filter(value => value !== '').join(', ') };
    };

    setValues(makeValues());
    setForm(makeForm());
  };

  const getDisplayName = (value: string) => {
    const foundOption = options.find(option => option.value.toString() === value);
    return foundOption ? foundOption.displayName : '';
  };

  return (
    <>
      <GridSection sx={{ gridRow, gridColumn: labelGridColumn }} isLabel>
        <Typography>{label}</Typography>
      </GridSection>
      <GridSection sx={{ gridRow, gridColumn: selectGridColumn }}>
        <FormControl fullWidth>
          <Select
            name={field}
            multiple
            value={values}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={selected => {
              if (selected.includes('ALL') || selected[0] === '') {
                return <div>전체</div>;
              }
              return selected
                .map(value => getDisplayName(value))
                .reverse()
                .join(', ');
            }}
            sx={{
              '& .MuiSelect-select': {
                padding: 1,
              },
              ...selectTheme.medium,
            }}
          >
            {options.map(option => (
              <MenuItem
                key={option.displayName}
                value={option.value.toString()}
                style={getStyles(option.value.toString(), values, theme)}
              >
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    select.current = option.value.toString();
                  }}
                >
                  <Checkbox
                    checked={values.indexOf(option.value.toString()) > -1}
                    sx={{ borderRadius: 0, height: 23 }}
                  />
                  <ListItemText
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: 0,
                      ...listItemTextTheme.medium,
                    }}
                    primary={option.displayName}
                  />
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridSection>
    </>
  );
});

export default MultipleSelectPlaceholder;
