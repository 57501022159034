import React, { KeyboardEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/common/colors';
import { INSPECTION } from 'consts/outbound/messages';
import { BARCODE_SCAN_ACTION } from 'consts/outbound/barcodeScanAction';
import Barcode from 'components/common/Barcode';
import ChevronDouble from '../../components/ChevronDouble';
import InvisibleInput from '../../components/InvisibleInput';
import usePopup from '../../../../../hooks/usePopup';
import FormModal from '../../../../../components/common/FormModal';
import TotesModal from './TotesModal';

type Props = {
  totalQuantity: number;
  totalCheckedQuantity: number;
  onStartInspection: () => void;
  totalToteBarcodes: string[];
};

const CompletedBottom = (props: Props) => {
  const { totalQuantity, totalCheckedQuantity, onStartInspection, totalToteBarcodes } = props;
  const [scannedBarcode, setScannedBarcode] = useState('');
  const { showAlert } = usePopup();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCombineTotes = () => {
    if (totalToteBarcodes.length === 1) {
      showAlert({ message: '토트 2개부터 사용이 가능합니다' });
      return;
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleScannedBarcode = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    if (koreaFilter()) {
      setScannedBarcode('');
      return;
    }
    if (scannedBarcode.toLowerCase() === BARCODE_SCAN_ACTION.startInspection) {
      onStartInspection();
    }
    setScannedBarcode('');
  };

  const koreaFilter = () => {
    const koreanRegex =
      /^.*[가-힣ㄱ-ㅎㅏ-ㅣ\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F].*$/;

    if (koreanRegex.test(scannedBarcode)) {
      showAlert({ message: '한글 문자열이 포함되어 있습니다.' });
      return true;
    }
    return false;
  };

  return (
    <Box sx={styles.cardContainer}>
      <ChevronDouble />
      <Box sx={styles.card}>
        <Typography sx={styles.cardTitleText}>검수가 완료되었습니다.</Typography>
        <Box sx={styles.cardBody}>
          <Box sx={styles.bodyLeftContainer}>
            <Typography sx={styles.resultTitle}>아이템수량 {totalQuantity}</Typography>
            <Button
              onClick={handleCombineTotes}
              size="large"
              variant="outlined"
              sx={styles.button}
              // disabled
            >
              {INSPECTION.combineTotes}
            </Button>
          </Box>
          <Typography sx={styles.bodyDivider}>|</Typography>
          <Box sx={styles.bodyRightContainer}>
            <Typography sx={styles.resultTitle}>검수정상수량 {totalCheckedQuantity}</Typography>
            <Box sx={styles.displayFlex}>
              <Barcode value={BARCODE_SCAN_ACTION.startInspection} height={40} />
              <InvisibleInput
                value={scannedBarcode}
                onChange={e => setScannedBarcode(e.target.value)}
                onKeyDown={handleScannedBarcode}
              />
              <Button
                onClick={onStartInspection}
                size="large"
                variant="contained"
                sx={styles.nextBtn}
              >
                {INSPECTION.startInspection}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <FormModal open={isModalOpen} title={'토트 1개로 합치기'} onClose={handleCloseModal}>
        <TotesModal onClose={handleCloseModal} toteBarcode={totalToteBarcodes[0]} />
      </FormModal>
    </Box>
  );
};

export default CompletedBottom;

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    mt: 2,
    border: `1px solid ${COLORS.ktown4u}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: COLORS.bgLight,
    p: 2,
    borderRadius: 1,
  },
  cardTitleText: {
    fontSize: '24px',
    color: COLORS.veryDarkGrey,
    mb: 1,
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    mb: 2,
  },
  bodyLeftContainer: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  resultTitle: {
    fontSize: '32px',
    fontWeight: 700,
    color: COLORS.veryDarkGrey,
    mb: 2,
  },
  bodyDivider: {
    fontSize: '32px',
    fontWeight: 700,
  },
  bodyRightContainer: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  button: {
    height: '40px',
    fontSize: '17px',
  },
  nextBtn: {
    height: '40px',
    fontSize: '17px',
    marginLeft: '10px',
  },
  displayFlex: { display: 'flex' },
};
