import React, { useEffect, useRef } from 'react';
import type { ChangeEvent, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import PackingButton from './PackingButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRecoilValue } from 'recoil';
import { inputAutoFocusAtom } from 'store';

type Props = {
  value: string;
  label: string;
  disabled?: boolean;
  onSubmit: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
};

const BarcodeInput = (props: Props) => {
  const autoFocus = useRecoilValue(inputAutoFocusAtom);
  const { value, label, disabled, onSubmit, onChange, isLoading = false } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isLoading) {
      return;
    }

    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isLoading) {
      return;
    }

    onChange(e);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={{
          marginRight: '16px',
          fontSize: '20px',
          fontWeight: 700,
          color: 'text.secondary',
        }}
      >
        {label}
      </Typography>
      <TextField
        id="barcode"
        onBlur={e => {
          autoFocus && e.target.focus();
        }}
        inputRef={inputRef}
        focused={autoFocus}
        autoFocus={autoFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        value={value}
        sx={{
          width: '320px',
          marginRight: '8px',
        }}
        inputProps={{
          sx: { padding: '16px 12px' },
        }}
        data-testid={label}
      />
      <PackingButton
        onClick={onSubmit}
        sx={{ height: '52px', fontSize: '20px' }}
        isLoading={isLoading}
      >
        확인
      </PackingButton>
    </Box>
  );
};

export default BarcodeInput;
