import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';

export const getReceiptsInspectDetails = async (receivingId: number | undefined) => {
  try {
    return await axiosV2.get(`/receivings/${receivingId}/inspect`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고검수 상세 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export type receivingItemInspectionRequests = {
  receivingItemId: number;
  goodsId: number;
  width: number;
  height: number;
  length: number;
  weight: number;
  quantity: number;
  receivingLpnRequests: {
    quantity: number;
    type: string;
    expirationDate: string;
    status: string;
  }[];
};

export const inspectReceiving = async (
  receivingId: number | undefined,
  requestBody: receivingItemInspectionRequests[]
) => {
  try {
    return await axiosV2.post(`/receivings/${receivingId}/inspections`, {
      receivingItemInspectionRequests: requestBody,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고확정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
