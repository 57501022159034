import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';
export const getCenters = async () => {
  try {
    return await axiosV2.get('/centers/list');
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const getDetail = async (id: number | undefined) => {
  try {
    return await axiosV2.get('/warehouses/' + id);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateWarehouseRequest = {
  centerId: number;
  name: string;
  code: string;
  isActive: string;
  managerName?: string;
  departmentName?: string;
  managerContact?: string;
  city?: string;
  zipCode?: string;
  address1?: string;
  address2?: string;
};

export const create = async (requestBody: CreateWarehouseRequest) => {
  try {
    return await axiosV2.post('/warehouses', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const update = async (id: number, requestBody: CreateWarehouseRequest) => {
  try {
    return await axiosV2.put(`/warehouses/${id}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
