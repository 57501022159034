import React, { ChangeEvent } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GridSection from '../GridSection';
import TextField from '@mui/material/TextField';
import useFilter from './hooks/useFilter';
import { inputTheme } from 'styles/customedMuiTheme';

type Props = {
  label: string;
  rangeStartField: string;
  rangeEndField: string;
  unit?: string;
  labelGridColumn: `${number}/${number}`;
  rangeInputGridColumn: `${number}/${number}`;
  gridRow?: number;
};

const NumberRangePicker = (props: Props) => {
  const {
    label,
    rangeStartField,
    rangeEndField,
    unit,
    labelGridColumn,
    rangeInputGridColumn,
    gridRow,
  } = props;
  return (
    <>
      <GridSection sx={styles.labelWrapper({ gridRow, labelGridColumn })} isLabel>
        <Typography>{label}</Typography>
      </GridSection>
      <GridSection sx={styles.textFieldWithUnitContainer({ gridRow, rangeInputGridColumn })}>
        <TextFieldWithUnit field={rangeStartField} unit={unit} />
        <Box sx={styles.textFieldWithUnitDivider}>~</Box>
        <TextFieldWithUnit field={rangeEndField} unit={unit} />
      </GridSection>
    </>
  );
};

export default NumberRangePicker;

const TextFieldWithUnit = (props: { field: string; unit?: string }) => {
  const { field, unit } = props;
  const { form, setForm } = useFilter();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(form => ({ ...form, [field]: e.target.value }));
  };

  return (
    <>
      <TextField value={form[field] ?? ''} sx={inputTheme.medium} onChange={handleInputChange} />
      {unit && <Box sx={styles.unitWrapper}>{unit}</Box>}
    </>
  );
};

const styles = {
  labelWrapper: ({ gridRow, labelGridColumn }: Pick<Props, 'gridRow' | 'labelGridColumn'>) => ({
    gridColumn: labelGridColumn,
    gridRow,
  }),
  textFieldWithUnitContainer: ({
    gridRow,
    rangeInputGridColumn,
  }: Pick<Props, 'gridRow' | 'rangeInputGridColumn'>) => ({
    display: 'flex',
    alignItems: 'center',
    gridColumn: rangeInputGridColumn,
    gridRow,
  }),
  textFieldWithUnitDivider: {
    m: 0.5,
  },
  unitWrapper: {
    ml: 0.5,
  },
};
