import Radio from '@mui/material/Radio';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';
import { numberFormatter } from '../../../../../libs/common/unitConverters';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';

const gridColumns = (
  changeSelectedRowId: (event: React.ChangeEvent<HTMLInputElement>) => void,
  selectedRowId: number
) => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const inventoryTransferStatusOptions = refCodeOptions?.inventoryTransferStatus || [];
  const carriers = useRecoilValue(carriersAtom);
  const inventoryTransferTypeOptions = refCodeOptions?.inventoryTransferType || [];
  const columns: GridColDef[] = [
    {
      field: 'radio',
      headerName: '',
      minWidth: 40,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Radio
            disabled={row.inventoryTransferStatus != 'REQUEST'}
            size={'small'}
            checked={row.id === selectedRowId}
            value={row.id}
            onChange={changeSelectedRowId}
          />
        );
      },
    },
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'fromWarehouseId',
      headerName: '출고창고',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'toWarehouseId',
      headerName: '입고창고',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'inventoryTransferStatus',
      headerName: '지시상태',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return inventoryTransferStatusOptions.find(option => option.value === value)?.displayName;
      },
    },
    {
      field: 'shippingStatus',
      headerName: '출고상태',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return shippingStatusOptions.find(option => option.value === value)?.displayName;
      },
    },
    {
      field: 'inventoryTransferType',
      headerName: '생성구분',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return inventoryTransferTypeOptions.find(option => option.value === value)?.displayName;
      },
    },
    {
      field: 'inventoryTransferSerialNumber',
      headerName: '대체출고지시ID',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'desiredAt',
      headerName: '대체출고 요청일자',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value?.split(' ')[0];
      },
    },
    {
      field: 'shippingOrderCreatedAt',
      headerName: '출고지시 생성일시',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'completedAt',
      headerName: '출고완료일시',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'skuCount',
      headerName: '품목수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalQuantity',
      headerName: '아이템수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'weight',
      headerName: '실제중량무게 총합계(g)',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalSupplyPrice', // TODO: 데이터에 없는 항목임.
      headerName: '공급가 총합계(원)',
      minWidth: 130,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'note',
      headerName: '메모',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdUser',
      headerName: '등록자',
      minWidth: 140,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: '등록일시',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
