import { Warehouse } from '../../store/outbound/warehouse.recoil';

const convertWarehouseFullCode = (value: number, warehouses: Warehouse[]) => {
  const warehouseOption = warehouses;
  return warehouseOption
    .filter(v => {
      if (v.id === value) {
        return v;
      }
    })
    .map(v => {
      return v.displayName;
    });
};

export default convertWarehouseFullCode;
