import { atom } from 'recoil';

export interface RefCode {
  groupCode: string;
  code: string;
  value: string;
}

export interface RefCodeOptions {
  displayName: string;
  value: string;
}

export interface RefCodeOptionsByField {
  [key: string]: RefCodeOptions[];
}

export const refCodeOptionsAtom = atom<RefCodeOptionsByField | undefined>({
  key: 'refCodeOptionsAtom',
  default: {},
});

export default refCodeOptionsAtom;
