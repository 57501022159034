import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import fetcher from 'libs/common/fetcher';
import Page from 'components/common/Layout/Page';
import type { Form } from 'components/common/Filter';
import Filter from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import Button from '@mui/material/Button';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import TopBoard from '../../components/TopBoard';
import usePopup from 'hooks/usePopup';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import _ from 'lodash';
import { Options } from 'types/form';
import gridColumns from './gridColDef';
import useConditionalSWR from '../../components/useConditionalSwr';
import { fetchPickingGroupOfInventoryTransfer } from './services';

const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시일', // '출고생성일',
    field: 'shippingOrderCreatedAt',
  },
  {
    displayName: '출고기준일',
    field: 'shippingBasedAt',
  },
];

export type SearchQuery = {
  warehouseId: string | number;
  page?: number;
  size?: number;
  shippingOrderType: string;
};

export type SummarizedPickingGroup = {
  keyId: number;
  sameShippingCount: number;
  sku: number;
  quantity: number;
  tubeQuantity: number;
  expectedWeight: number;
  warehouseFullCode: string;
};

export type PickingGroup = {
  content: SummarizedPickingGroup[];
  totalElements: number;
  size: number;
  number: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };
const gridTemplateColumns = [
  '70px',
  'minmax(55px, 1.4fr)',
  'minmax(55px, 1.4fr)',
  '10px',
  'minmax(55px, 0.8fr)',
  'minmax(55px, 0.8fr)',
  '50px',
  'minmax(45px, 1.2fr)',
  'minmax(45px, 1.2fr)',
  '70px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const defaultSize = 100;
const defaultPage = 0;
const shippingOrderType = 'INVENTORY_TRANSFER';
const PickingGroupOfInventoryTransfer = () => {
  const [selectedShippings, setSelectedShippings] = useState<number[]>();
  const { showDialog } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    warehouseId: globalWarehouse,
    page: defaultPage,
    size: defaultSize,
    shippingOrderType,
  });
  const [hasSearched, setHasSearched] = useState(false);
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar, showErrorDialog } = usePopup();

  const customCarrierOptions = carrierOptions.filter(
    carrierOption => carrierOption.value == '14' || carrierOption.value == '10'
  );

  const { data: shippingCounts } = useConditionalSWR(
    [`/picking-groups/shippings/counts`, { warehouseId: globalWarehouse, shippingOrderType }],
    fetcher,
    hasSearched
  );

  const { data, mutate, isValidating } = useConditionalSWR<PickingGroup>(
    [`/picking-groups/same-shippings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const handleCreatePickingGroups = () => {
    if (!hasSearched) {
      showDialogForNotSearchedYet();
      return;
    }

    showDialog({
      message: '출고건 전체 대상으로 집품그룹을 생성하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            setSelectedShippings([]);
            handleCreate();
          },
        },
      ],
    });
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const requestBody = {
      ...queryParams,
      keyIds: selectedShippings,
    };
    const response = await fetchPickingGroupOfInventoryTransfer(requestBody);
    if (response?.status === 200) {
      showSnackbar({
        message: '집품그룹이 생성 완료되었습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: '집품그룹 생성 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
    await mutate();
  };

  const handleCreatePickingGroupsWithSelectedShipping = () => {
    if (!hasSearched) {
      showDialogForNotSearchedYet();
      return;
    }

    const length = selectedShippings?.length;
    if (length === 0 || length === undefined) {
      showDialog({
        message: '집품그룹 생성을 진행할 동일포장건수를 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else {
      showDialog({
        message: '출고건 선택 대상으로 집품그룹을 생성하시겠습니까?',
        buttons: [
          {
            text: '취소',
          },
          {
            text: '확인',
            marked: true,
            onClick: async () => {
              handleCreate();
            },
          },
        ],
      });
    }
  };

  const showDialogForNotSearchedYet = () => {
    showDialog({
      message: `조회 조건 입력 후 '조회' 버튼을 클릭하신 후, 집품그룹 생성을 진행해 주세요.`,
      buttons: [
        {
          text: '확인',
        },
      ],
    });
  };
  const handleInitClick = async () => {
    setQueryParams({
      page: defaultPage,
      warehouseId: globalWarehouse,
      size: defaultSize,
      shippingOrderType,
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];
    const warehouseId: string | number = updatedForm.warehouseId ?? globalWarehouse;

    setQueryParams(({ size }) => ({
      ...updatedForm,
      warehouseId,
      size,
      page: defaultPage,
      shippingOrderType,
    }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  function handleSelectedSameShippings(ids: number[]) {
    setSelectedShippings(ids);
  }

  const makeRowIndex = (data: PickingGroup) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: any, index: number) => {
      return {
        ...item,
        rowIndex: totalElements - size * number - index,
        id: item.keyId,
      };
    });
  };

  const topBoard = [
    {
      title: '출고대기건',
      value: shippingCounts,
    },
  ];

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대체출고 집품그룹 생성
      </Typography>
      <TopBoard data={topBoard} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/3"
          dateRangePickerGridColumn="3/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.Select
          label="배송방식"
          field="carrierId"
          options={[defaultOption, ...customCarrierOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/9"
        />
        <Divider
          sx={{
            gridRow: 3,
            gridColumn: '1/-1',
            pb: 1,
            mb: 1,
          }}
        />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={4}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            집품그룹 생성대상 목록
          </Typography>
          <Box>
            <LoadingButton
              loading={isLoading}
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCreatePickingGroupsWithSelectedShipping}
            >
              {`집품그룹 선택생성`}
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleCreatePickingGroups}
            >
              {`집품그룹 자동생성`}
            </LoadingButton>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={() => console.log(1)}
              disabled
            >
              {`엑셀다운로드`}
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched ? (data?.content ? makeRowIndex(data) : []) : []}
          columns={gridColumns(warehouseOption)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? defaultSize}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedShippings}
          onSelectionModelChange={ids => handleSelectedSameShippings(ids as number[])}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default PickingGroupOfInventoryTransfer;
