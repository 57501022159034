import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useSetRecoilState } from 'recoil';
import { drawerOpenAtom } from 'store';

const FullScreenButton = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const setDrawerOpen = useSetRecoilState(drawerOpenAtom);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleClick = async () => {
    if (isFullScreen) {
      await document.exitFullscreen();
      handleFullScreenChange();
    } else {
      await document.documentElement.requestFullscreen();
      handleFullScreenChange();
    }
  };

  const handleFullScreenChange = () => {
    if (document.fullscreenElement !== null) {
      setDrawerOpen(false);
      setIsFullScreen(true);
    } else {
      setDrawerOpen(true);
      setIsFullScreen(false);
    }
  };

  return (
    <Button variant="outlined" onClick={handleClick}>
      {isFullScreen ? '일반화면' : '전체화면'}
    </Button>
  );
};

export default FullScreenButton;
