import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import usePopup from '../../../../../hooks/usePopup';
import { datadogRum } from '@datadog/browser-rum';
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import type { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import { inputTheme } from 'styles/customedMuiTheme';
import { createStocktaking } from '../services/StopStocktaking';
import SelectBase from '../../devices/components/SelectBase';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { SelectChangeEvent } from '@mui/material';

const modalWidth = 480;
const modalFontSize = FONT_SIZE.medium;
export type StockTaking = {
  warehouseId: number;
  type: string;
  name: string;
  scheduledAtFrom: string;
  scheduledAtTo: string;
};
type Props = {
  warehouseId: number;
  onClose: () => void;
  onMutate: () => void;
};
export const initialState: StockTaking = {
  warehouseId: -1,
  type: '',
  name: '',
  scheduledAtFrom: '2023-08-14',
  scheduledAtTo: '2023-08-15',
};

const validateStocktakingForm = (stockTaking: StockTaking) => {
  if (stockTaking.warehouseId < 0) {
    return '창고를 선택해주세요.';
  }
  if (stockTaking.type === '' || stockTaking.type === undefined) {
    return '재고조사구분을 선택해주세요.';
  }
  if (stockTaking.name === '' || stockTaking.name === undefined) {
    return '재고조사명을 입력해주세요.';
  }

  if (stockTaking.scheduledAtFrom === '' || stockTaking.scheduledAtFrom === undefined) {
    return '시작일을 입력해주세요.';
  }

  if (stockTaking.scheduledAtTo === '' || stockTaking.scheduledAtTo === undefined) {
    return '종료일을 입력해주세요.';
  }
};

const locale = 'ko';

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> & React.RefAttributes<HTMLInputElement>
) => JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = React.forwardRef(
  (props: SingleInputDateRangeFieldProps<Dayjs>, ref: React.Ref<HTMLInputElement>) => {
    return <SingleInputDateRangeField size="small" {...props} ref={ref} format={'YYYY-MM-DD'} />;
  }
) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

const StocktakingForm = (props: Props) => {
  const { warehouseId, onClose, onMutate: mutate } = props;
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [stocktaking, setStockTaking] = useState<StockTaking>(initialState);
  const { showErrorDialog, showSnackbar } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const stocktakingTypeOptions = refCodeOptions?.stocktakingType ?? [];

  const handleDateChange = (newDateRange: DateRange<Dayjs>) => {
    setDateRange(newDateRange);
  };
  useEffect(() => {
    if (warehouseId < 0) {
      return;
    }
    stocktaking.warehouseId = warehouseId;
  }, []);

  const handleSave = async () => {
    stocktaking.scheduledAtFrom = dateRange[0]?.format('YYYY-MM-DD') ?? '';
    stocktaking.scheduledAtTo = dateRange[1]?.format('YYYY-MM-DD') ?? '';
    const validationMessage = validateStocktakingForm(stocktaking);
    if (validationMessage) {
      showErrorDialog({
        title: '재고조사 등록 실패',
        errorMessage: validationMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    setIsLoading(true);
    const response = await createStocktaking(stocktaking);
    if (response?.status === 200) {
      showSnackbar({ message: '재고조사 정보가 생성 완료되었습니다.', severity: 'success' });
      await mutate();
      onClose();
      datadogRum.addAction(`재고조사 등록 성공: ${stocktaking}`);
    } else {
      showErrorDialog({
        title: '재고조사 등록 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      datadogRum.addError(`재고조사 등록 실패: ${response?.data?.errorMessage}`);
    }
    setIsLoading(false);
  };

  function handleChange(event: SelectChangeEvent<string>) {
    const { name, value } = event.target;
    setStockTaking({ ...stocktaking, [name]: value });
  }

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>재고조사 정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'창고'} labelSx={styles.label}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {convertWarehouseFullCode(Number(warehouseId), warehouseOption)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'재고조사구분'} labelSx={styles.label}>
            <SelectBase
              name={'type'}
              options={[{ value: '', displayName: '선택' }, ...stocktakingTypeOptions]}
              selected={stocktaking.type}
              sx={styles.select}
              onChange={event => handleChange(event)}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'재고조사명'} labelSx={styles.label}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={styles.outlinedInput}
                defaultValue={stocktaking.name}
                onChange={e => {
                  setStockTaking({ ...stocktaking, name: e.target.value });
                }}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'재고조사기간'} labelSx={styles.label}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
              <DemoContainer components={['SingleInputDateRangeField']} sx={styles.demoContainer}>
                <DateRangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  slots={{ field: WrappedSingleInputDateRangeField }}
                  slotProps={{
                    textField: {
                      placeholder: '시작일 - 종료일 선택',
                      value: dateRange,
                      sx: styles.slotProps,
                    },
                    actionBar: {
                      actions: ['clear', 'cancel', 'accept'],
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </TableCell>
        </TableRow>
      </Table>
      <SaveButtonGroup onSaveClick={handleSave} onCloseClick={onClose} isLoading={isLoading} />
    </Box>
  );
};

export default StocktakingForm;
const styles = {
  select: { width: '344px', ml: -1.5 },
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  rowHeight: { height: '36px' },
  label: { width: '128px', fontSize: modalFontSize },
  outlinedInput: { height: '28px', width: '344px', ml: -1.5 },
  demoContainer: {
    ...inputTheme.medium,
    p: '0px',
    '& .MuiInputBase-input': {
      pt: '0px',
      pb: '0px',
    },
  },
  slotProps: {
    width: '100%',
  },
};
