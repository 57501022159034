import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

export type createPickingGroupRequest = {
  warehouseId: number | string;
};

export const fetchPickingGroups = async (requestBody: createPickingGroupRequest) => {
  try {
    return await axiosV2.post(`/picking-groups/devices/auto-assign`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`PDA 자동배정 등록 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
