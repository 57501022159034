import JsCookies from 'js-cookie';
import { redirect } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

export const signOut = async () => {
  JsCookies.remove('accessToken');
  JsCookies.remove('fingerprint');
  localStorage.removeItem('loginUser');
  localStorage.removeItem('openSubMenus');
  datadogRum.clearUser();
  redirect('/auth/signin');
};
