import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import { drawerOpenAtom, shouldMountAppBarAtom, shouldMountDrawerAtom } from 'store';
import authAtom, { Auth } from '../../../store/auth.recoil';
import warehouseAtom, {
  allWarehouseOptionsAtom,
  warehouseOptionsAtom,
} from '../../../store/outbound/warehouse.recoil';
import Drawer from './Drawer';
import AppBar from './AppBar';
import { Outlet, useLoaderData } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import devOnlyFlag from '../../../libs/common/devOnlyFlag';

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenAtom);
  const shouldMountAppBar = useRecoilValue(shouldMountAppBarAtom);
  const shouldMountDrawer = useRecoilValue(shouldMountDrawerAtom);
  const setAuth = useSetRecoilState(authAtom);
  const setWarehouse = useSetRecoilState(warehouseAtom);
  const setUserWarehouse = useSetRecoilState(warehouseOptionsAtom);
  const allWarehouseOptions = useRecoilValue(allWarehouseOptionsAtom);

  const authData = useLoaderData() as Auth;
  useEffect(() => {
    if (authData) {
      const {
        loginId,
        username,
        primaryWarehouseId,
        availableWarehouseIds,
        changePasswordRequired,
      } = authData;
      setAuth({
        loginId,
        username,
        primaryWarehouseId,
        availableWarehouseIds,
        changePasswordRequired,
      });
      //   setWarehouse(primaryWarehouseId.toString());
      //   const warehouses = allWarehouseOptions.filter(v => availableWarehouseIds.includes(v.id));
      //   setUserWarehouse(warehouses);
      // 위 사용자 창고필터는 나중에 적용할 예정
      setUserWarehouse(allWarehouseOptions);
    }
  }, [authData, allWarehouseOptions]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>케타포 GMS</title>
        </Helmet>
      </HelmetProvider>
      <Box sx={styles.container}>
        {shouldMountAppBar && <AppBar />}
        {shouldMountDrawer && (
          <Drawer
            open={drawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
          />
        )}
        <Box component="main" sx={styles.outlet}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#f3f3f7',
  },
  outlet: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#f3f3f7',
  },
};
