import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { numberFormatter } from '../../../../libs/common/unitConverters';

const gridColumns = () => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'name',
      headerName: '이름',
      minWidth: 20,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'displayOrder',
      headerName: '정렬순서',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'chuteNumber',
      headerName: '슈트번호',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'minWeight',
      headerName: '최소무게(g)',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'maxWeight',
      headerName: '최대무게(g)',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'volumetricDivisor',
      headerName: 'DIM',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
