import React, { useState } from 'react';
import type { KeyboardEvent } from 'react';
import Button from '@mui/material/Button';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecoilState } from 'recoil';
import { afterProgressTitle, beforeProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import {
  Box,
  bulkPackingBoxAtom,
  bulkPackingStepAtom,
} from '../../../../../store/outbound/bulkPacking.recoil';
import Table, { TableRow, TableCell } from '../../../components/Table';
import { PACKING } from 'consts/outbound/messages';
import BoxVolumeText from '../../components/BoxVolumeText';
import InvisibleInput from '../../components/InvisibleInput';
import usePopup from 'hooks/usePopup';
import TimelineItem from '../../components/TimelineItem';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import FormModal from '../../../../../components/common/FormModal';
import BoxSizeForm from './BoxSizeForm';
import InvisibleInput2 from '../../components/InvisibleInput2';

export const BOX_STEP = 1;

const BulkPackingBoxLine = () => {
  const [selectedStep, setSelectedStep] = useRecoilState(bulkPackingStepAtom);
  const [isAutoFocus, setIsAutoFocus] = useState<boolean>(true);
  const [isBoxSize, setIsBoxSize] = useState<boolean>(false);
  const { showAlert } = usePopup();
  const [boxCode, setBoxCode] = useState<string>('');
  const [bulkPackingBox, setBulkPackingBox] = useRecoilState(bulkPackingBoxAtom);
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      axiosV2
        .get(`/packings/boxes/${boxCode}`)
        .then(res => {
          const box: Box = res.data;
          setBulkPackingBox(box);
          setBoxCode('');
          if (box.barcode === 'Genesys') {
            setBulkPackingBox(undefined);
            setIsAutoFocus(false);
            showAlert({
              message: `${PACKING.wrongBoxCode}\n입력코드: Genesys`,
              onClick: () => setIsAutoFocus(true),
            });
            return;
          }
          setIsBoxSize(true);
        })
        .catch(err => {
          setBoxCode('');
          setIsAutoFocus(false);
          showAlert({
            message: `${PACKING.wrongBoxCode}\n입력코드: ${boxCode} `,
            onClick: () => setIsAutoFocus(true),
          });
        });
    }
  };

  const handleChangeBox = () => {
    setSelectedStep(selectedStep - 1);
    setBoxCode('');
    setBulkPackingBox(undefined);
  };

  const handleCloseModal = () => {
    setIsBoxSize(false);
  };

  return (
    <TimelineItem
      step={BOX_STEP}
      beforeProgressComponent={
        <Typography sx={beforeProgressTitle} suppressHydrationWarning>
          {PACKING.boxCodeScan}
        </Typography>
      }
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle} suppressHydrationWarning>
            {PACKING.boxCodeScan}
          </Typography>
          <InvisibleInput2
            onChange={e => setBoxCode(e.target.value)}
            onKeyDown={handleKeyDown}
            value={boxCode}
            data-testid="박스 코드"
            isAutoFocus={!isBoxSize && isAutoFocus}
          />
          {isBoxSize && (
            <FormModal open={isBoxSize} title={'박스 사이즈 입력'} onClose={handleCloseModal}>
              <BoxSizeForm onClose={handleCloseModal} boxBarcode={bulkPackingBox?.barcode} />
            </FormModal>
          )}
        </>
      }
      afterProgressComponent={
        <>
          <MUIBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={afterProgressTitle} suppressHydrationWarning>
              {PACKING.boxCodeScan}
            </Typography>
            <Button
              onClick={handleChangeBox}
              variant="contained"
              sx={{ height: '28px', padding: '0px 10px', fontWeight: 700 }}
            >
              박스변경
            </Button>
          </MUIBox>
          {bulkPackingBox && (
            <Table>
              <TableRow height="36px">
                <TableCell label="박스명" value={bulkPackingBox.name} />
                <TableCell
                  label="박스크키(mm)"
                  value={
                    <BoxVolumeText
                      width={bulkPackingBox.width}
                      height={bulkPackingBox.height}
                      length={bulkPackingBox.length}
                    />
                  }
                />
              </TableRow>
            </Table>
          )}
        </>
      }
    />
  );
};

export default BulkPackingBoxLine;
