import type { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { device } from './index.page';
import { Dispatch, SetStateAction } from 'react';

const gridColumns = (
  selectedPickingGroupId: number | null,
  setPdaModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const pickingGroupStatusOptions = refCodeOptions?.pickingGroupStatus || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '집품그룹상태',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'type',
      headerName: '집품구분',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'serialNumber',
      headerName: '집품그룹ID',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingCount',
      headerName: '집품그룹 출고건수',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'reason',
      headerName: '비고',
      minWidth: 400,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'devices',
      headerName: 'PDA배정',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortComparator: (v1, v2) => {
        const activeDeviceV1 = v1.filter((v: device) => v.status === 'ACTIVE');
        const activeDeviceNumber1: string =
          activeDeviceV1.length != 0 ? activeDeviceV1[0].deviceNumber : '';
        const activeDeviceV2 = v2.filter((v: device) => v.status === 'ACTIVE');
        const activeDeviceNumber2: string =
          activeDeviceV2.length != 0 ? activeDeviceV2[0].deviceNumber : '';
        return activeDeviceNumber1.localeCompare(activeDeviceNumber2);
      },
      renderCell: params => {
        return (
          <div
            style={{ color: '#0062CC', textDecoration: 'underline' }}
            onClick={() => {
              selectedPickingGroupId === null && setPdaModalOpen(true);
            }}
          >
            {params.row.devices
              .filter((v: device) => v.status === 'ACTIVE')
              .map((v: device) => v.deviceNumber)
              .join(',')}
          </div>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'PDA배정일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
