import React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { COLORS } from 'consts/common/colors';

type Props = {
  imageUrl: string | undefined;
  onClose: () => void;
};

const ImageZoomModal = ({ imageUrl, onClose }: Props) => {
  return (
    <Backdrop open={!!imageUrl} sx={styles.backdrop} onClick={onClose}>
      <Box sx={styles.modal}>
        {imageUrl && (
          <img
            src={imageUrl}
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
              height: '50vh',
              width: '50vh',
            }}
          />
        )}
        <Box sx={styles.closeIconWrapper}>
          <CloseIcon />
        </Box>
      </Box>
    </Backdrop>
  );
};

export default ImageZoomModal;

const CloseIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1534_36771)">
        <path d="M34 10L10 34" stroke="white" />
        <path d="M34 34L10 10" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_1534_36771">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const styles = {
  backdrop: {
    zIndex: 2000,
    backgroundColor: COLORS.faintBlack,
    top: -10,
  },
  modal: {
    position: 'relative',
    cursor: 'pointer',
  },
  closeIconWrapper: {
    position: 'absolute',
    top: -44,
    right: 0,
  },
};
