import React, { useState } from 'react';
import useSWR from 'swr';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoListSection from 'components/common/typography/InfoListSection';
import TightDataGridPro from 'components/common/TightDataGridPro';
import fetcher from 'libs/common/fetcher';
import type { Warehouse } from 'store/outbound/warehouse.recoil';
import { getFinalUrl } from 'consts/outbound/imageUrl';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from 'consts/common/pageAndSize';
import gridColumns from './gridColDef';
import type { SelectedTransferContent } from '../../index.page';
import ImageZoomModal from '../../../../../../../components/ImageZoomModal';

type TransferItemContent = {
  barcode: string;
  offlineBarcode: string;
  itemId: number;
  image: string;
  itemName: string;
  qty: number;
  receivedAt: string;
  weight: number;
  price: number;
  totalPrice: number;
};

type TransferItem = {
  content: TransferItemContent[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
};

type Props = {
  warehouseOption: Warehouse[];
  selectedContent: SelectedTransferContent;
};

const ItemDataGrid = ({ selectedContent, warehouseOption }: Props) => {
  const [image, setImage] = useState<string>('');
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });

  const { data, error } = useSWR<TransferItem>(
    [`/inventory-transfers/${selectedContent.id}/items`, { ...queryParams, sort: 'id,desc' }],
    fetcher
  );

  const fromWarehouse = warehouseOption.find(
    item => item.value === selectedContent?.fromWarehouseId
  );
  const toWarehouse = warehouseOption.find(item => item.value === selectedContent?.toWarehouseId);
  const infoList = [
    { name: '출고창고', value: fromWarehouse?.displayName },
    { name: '입고창고', value: toWarehouse?.displayName },
    { name: '대체출고지시ID', value: selectedContent.inventoryTransferSerialNumber },
    { name: '출고ID', value: selectedContent.shippingSerialNumber },
  ];

  const makeRows = (data: TransferItem) => {
    const { content, totalElements, size, number } = data;
    return content.map((item, index) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          아이템 목록
        </Typography>
      </Box>
      <InfoListSection infoList={infoList} hideValue={!data || error} />
      <Box sx={{ height: '460px' }}>
        <TightDataGridPro
          getRowId={row => row.itemId}
          columns={gridColumns}
          rows={data?.content ? makeRows(data) : []}
          paginationMode="server"
          rowCount={data?.totalElements ?? 0}
          loading={!data && !error}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          onCellClick={({ field, row }) => {
            if (field === 'image') setImage(getFinalUrl(row.image));
          }}
        />
      </Box>
      <ImageZoomModal imageUrl={image} onClose={() => setImage('')} />
    </>
  );
};

export default ItemDataGrid;
