import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';
export const getAreas = async (warehouseId: number) => {
  try {
    return await axiosV2.get('/areas/list', { params: { warehouseId } });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const getDetail = async (id: number | undefined) => {
  try {
    return await axiosV2.get('/aisles/' + id);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateRequest = {
  warehouseId: number;
  areaId: number;
  code: string;
  isActive: string;
};

export const create = async (requestBody: CreateRequest) => {
  try {
    return await axiosV2.post('/aisles', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const update = async (id: number, requestBody: CreateRequest) => {
  try {
    return await axiosV2.put(`/aisles/${id}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
