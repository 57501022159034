import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';

export const adjustInventoryLpn = async (
  inventoryLpnId: number | undefined,
  itemCode: string,
  quantity: number | string,
  reason?: string,
  isApplyBO?: boolean
) => {
  try {
    return await axiosV2.post(`/inventories/inventory-lpns/${inventoryLpnId}/adjust-between-item`, {
      toItemCode: itemCode,
      itemQtyToMove: Number(quantity),
      reason: reason,
      isApplyBO: isApplyBO,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고 보정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

type adjustQuantityType = {
  inventoryLpnId: number | undefined;
  adjustedReason: string;
  adjustedQuantity: string;
  adjustedInventoryHistoryType: string | undefined;
  isApplyBO: boolean;
};

export const adjustQuantityInventoryLpn = async (request: adjustQuantityType) => {
  try {
    return await axiosV2.post(
      `/inventories/inventory-lpns/${request.inventoryLpnId}/adjust-stock`,
      request
    );
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고수량 조정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

type createInventoryItemType = {
  warehouseId: number;
  itemCode: string;
  locationCode: string;
  quantity: number;
  isApplyBO: boolean;
  adjustedReason: string | undefined;
};

export const createInventoryItem = async (request: createInventoryItemType) => {
  try {
    return await axiosV2.post(`/inventories/inventory-lpns`, request);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`아이템재고 추가 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
