import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { ChangedAssignedDevice } from '../components/AssignPdaModal';

export const generateChangeAssign = async (
  pickingGroupId: number | null,
  reAssignedDevices: ChangedAssignedDevice[]
) => {
  try {
    return await axiosV2.patch(`/picking-groups/${pickingGroupId}/devices/change-assign`, {
      devices: reAssignedDevices,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`pda 배정 변경 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
