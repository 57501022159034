import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { COLORS } from 'consts/common/colors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import bulkPackingAtom from '../../../../../store/outbound/bulkPacking.recoil';
import CompletionCard from '../../components/CompletionCard';

type Props = {
  total: number;
  success: number;
  failure: number;
  onNextClick: () => void;
};

const InvoiceCompletion = (props: Props) => {
  const { total, success, failure, onNextClick } = props;
  const [bulkPacking, setBulkPacking] = useRecoilState(bulkPackingAtom);
  const serialNumber = bulkPacking?.serialNumber;
  const navigate = useNavigate();
  const move = () => {
    // 두번재 인자의 state 속성에 원하는 파라미터를 넣어준다. (id, job을 넣어봤다)
    navigate('/gms/outbound/bulk-packings', {
      state: {
        pickingGroupSerialNumber: serialNumber,
      },
    });
  };
  return (
    <CompletionCard
      content={
        <Stack textAlign="center">
          <Typography sx={{ fontSize: '20px', color: COLORS.veryDarkGrey }}>
            송장번호 발행이 완료되었습니다.
          </Typography>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Typography sx={boldFontStyle}>{`총건수 ${total.toLocaleString()}`}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Typography sx={boldFontStyle}>{`성공건수 ${success.toLocaleString()}`}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Typography sx={boldFontStyle}>{`연동오류건수 ${failure.toLocaleString()}`}</Typography>
          </Box>
        </Stack>
      }
      actions={
        <>
          <Button variant="contained" sx={{ fontWeight: 700, marginRight: '10px' }} onClick={move}>
            송장출력 화면이동
          </Button>
          <Button variant="outlined" sx={{ fontWeight: 700 }} onClick={onNextClick}>
            다음 대량포장 시작
          </Button>
        </>
      }
    />
  );
};

export const boldFontStyle = { fontSize: '28px', fontWeight: 700, color: COLORS.veryDarkGrey };

export default InvoiceCompletion;
