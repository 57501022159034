import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import CONVEYOR_PATH from '../../../../consts/info/conveyorPath';

const gridColumns = () => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'basePath',
      headerName: '포장경로',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return CONVEYOR_PATH[value as keyof typeof CONVEYOR_PATH];
      },
    },
    {
      field: 'conveyorPath',
      headerName: '작업공간',
      flex: 2,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'count',
      headerName: '포장전 출고건 합계',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
