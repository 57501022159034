type Props = { imageContainerSize: number; src: string };

const AutoContainImage = ({ imageContainerSize, src }: Props) => {
  return (
    <img
      src={src}
      width={imageContainerSize}
      height={imageContainerSize}
      style={{ objectFit: 'contain', objectPosition: 'center' }}
    />
  );
};

export default AutoContainImage;
