import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

export const createUsersFromExcel = async (formData: FormData) => {
  try {
    return await axiosV2.post('/users/excel-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`excelUpload 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getUser = async (userId: number) => {
  try {
    return await axiosV2.get(`/users/${userId}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type createUserRequest = {
  username: string;
  loginId: string;
  primaryWarehouseId: string;
  availableWarehouseIds: number[];
  password?: string;
  boLoginId?: string;
};

export type updateUserRequest = {
  primaryWarehouseId: string;
  availableWarehouseIds: number[];
  password?: string;
  boLoginId?: string;
};

export const createUser = async (request: createUserRequest) => {
  try {
    return await axiosV2.post(`/users`, request);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const updateUser = async (userId: number, request: updateUserRequest) => {
  try {
    return await axiosV2.put(`/users/${userId}`, request);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
