import React, { ReactNode, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useSetRecoilState } from 'recoil';
import { shouldMountAppBarAtom, shouldMountDrawerAtom } from 'store';

type Prop = {
  subTitle: string;
  main: ReactNode;
  footer: ReactNode;
};

const PopupTemplateItemList = (props: Prop) => {
  const { subTitle, main, footer } = props;
  const setShouldMountAppBar = useSetRecoilState(shouldMountAppBarAtom);
  const setShouldMountDrawer = useSetRecoilState(shouldMountDrawerAtom);

  useEffect(() => {
    setShouldMountAppBar(false);
    setShouldMountDrawer(false);
  }, []);

  return (
    <Stack spacing={3} sx={{ minHeight: '50vh', pt: '32px', backgroundColor: 'white' }}>
      <Stack spacing={1.5}>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 700 }}>
          {subTitle}
        </Typography>
        {main}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>{footer}</Box>
    </Stack>
  );
};

export default PopupTemplateItemList;
