import { atom } from 'recoil';

export interface Auth {
  loginId: string;
  username: string;
  primaryWarehouseId: number;
  availableWarehouseIds: number[];
  changePasswordRequired: boolean;
}

export const authAtom = atom<Auth>({
  key: 'authAtom',
  default: undefined,
});

export default authAtom;
