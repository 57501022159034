import axios, { AxiosError } from 'axios';
import FileDownload from 'js-file-download';
import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import { errorType } from '../../../../../types/common';
import { REACT_APP_API_ENDPOINT_V2 } from '../../../../../consts/common/environment';
import { SearchQuery } from '../../../outbound/packings/index.page';

export type SearchBayRequest = {
  warehouseId: number;
};

export const getBins = async (params: SearchBayRequest) => {
  try {
    return await axiosV2.get('/bins/bays/search', { params });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateBinRequest = {
  bayId: number;
  code: string;
  isActive: string;
  binType: string;
  pickingOrder: number;
  isStairRequired: boolean;
  coordX?: number;
  coordY?: number;
};

export const createBin = async (requestBody: CreateBinRequest) => {
  try {
    return await axiosV2.post('/bins', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type UpdateBinRequest = Omit<CreateBinRequest, 'bayId' | 'code'>;

export const updateBin = async (binId: number, requestBody: UpdateBinRequest) => {
  try {
    return await axiosV2.patch(`/bins/${binId}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const generateExcelDownload = async (queryParams: SearchQuery) => {
  try {
    const url = REACT_APP_API_ENDPOINT_V2 + `/bins/excel-download`;
    const response = await axios.get(url, {
      responseType: 'blob',
      params: {
        ...queryParams,
      },
    });
    const filename = `BIN 정보 조회_${getCurrentDateTimeString()}.xlsx`;
    FileDownload(response.data, filename);
    return response;
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    const promise = await blobToJson(error1.response?.data);
    datadogRum.addAction(`엑셀 다운로드 실패 : ${promise}`);
    return promise;
  }
};

const getCurrentDateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const blobToJson = (blob: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsText(blob);
  });
};

export const createBinFromExcel = async (formData: FormData) => {
  try {
    return await axiosV2.post('/bins/locations/excel-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`excelUpload 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
