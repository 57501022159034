import { atom } from 'recoil';

export interface Carrier {
  id: number;
  code: string;
  name: string;
  displayOrder: number;
}

export interface CarrierOption {
  displayName: string;
  value: string;
  order: number;
}

type Created = Record<string, string>;
const carrierChangeAtom = atom<Created>({
  key: 'carrierChangeAtom',
  default: {},
});

export const carriersAtom = atom<Carrier[]>({
  key: 'carriersAtom',
  default: [],
});

export const carrierOptionsAtom = atom<CarrierOption[]>({
  key: 'carrierOptionsAtom',
  default: [],
});

export default carrierChangeAtom;
