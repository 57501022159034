import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

import { stepAtom } from 'store/outbound/packing.recoil';
import { barcodeAtom } from 'store/common/barcode.recoil';
import { inspectionAtom, prevInspectionAtom } from 'store/outbound/inspection.recoil';
import Page from 'components/common/Layout/Page';
import { isZpl } from '../../../../libs/common/isZpl';
import Timeline from '../components/Timeline';
import ToteCodeLine from './components/ToteCodeLine';
import ItemListLine from './components/ItemListLine';
import pageTitle from 'styles/pageTitle';
import borderedBox from 'styles/borderedBox';
import FullScreenButton from '../components/FullScreenButton';
import Button from '@mui/material/Button';
import DiagnosisDialog from './components/DiagnosisDialog';
import { WAYBILL_FORMAT } from '../../../../consts/outbound/waybillFormat';
import { isPrintingAtom, printerAtom } from '../../../../store/common/printer.recoil';
import usePopup from '../../../../hooks/usePopup';
import getInspectionImage from './components/getInspectionImage';

const Inspection = () => {
  const step = useRecoilValue(stepAtom);
  const printer = useRecoilValue(printerAtom);
  const resetInspection = useResetRecoilState(inspectionAtom);
  const resetBarcode = useResetRecoilState(barcodeAtom);
  const resetStep = useResetRecoilState(stepAtom);
  const [isOpenPrinter, setIsOpenPrinter] = useState(false);
  const [isPrinting, setIsPrinting] = useRecoilState(isPrintingAtom);
  const prevInspection = useRecoilValue(prevInspectionAtom);
  const { showErrorDialog } = usePopup();
  useEffect(() => {
    return () => {
      resetInspection();
      resetBarcode();
      resetStep();
    };
  }, [resetBarcode, resetInspection, resetStep]);

  const handlePrinterSetting = () => {
    setIsOpenPrinter(true);
  };

  const printImage = async (image: string) => {
    if (isZpl(image)) {
      printer.send(
        `${image}`,
        () => setIsPrinting(false),
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
          setIsPrinting(false);
        }
      );
    } else {
      printer.convertAndSendFile(
        `data:image/${WAYBILL_FORMAT};base64,${image}`,
        () => setIsPrinting(false),
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
          setIsPrinting(false);
        }
      );
    }
  };

  const handleReprint = async () => {
    if (prevInspection?.inspectionId == undefined) {
      showErrorDialog({
        title: '검수건 출력 실패',
        errorMessage: '이전 검수건이 없습니다.',
        buttons: [{ text: '확인' }],
      });
    }
    showErrorDialog;
    const response = await getInspectionImage(prevInspection.inspectionId);
    if (response?.status === 200) {
      printImage(response.data?.base64Image);
    } else {
      showErrorDialog({
        title: '이미지 요청 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <>
      <Page>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h2" sx={pageTitle}>
              개별/1주문다/대체출고 집품검수 관리
            </Typography>
          </Box>
          <Box>
            <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleReprint}>
              이전 검수건 재출력
            </Button>
            <Button
              variant="outlined"
              style={{ marginRight: '10px' }}
              onClick={handlePrinterSetting}
            >
              프린터 진단
            </Button>
            <FullScreenButton />
          </Box>
        </Box>
        <Box
          sx={{
            ...borderedBox,
            flex: 1,
            height: '90vh',
            padding: '32px',
          }}
        >
          <Timeline currentStep={step} sx={{ padding: 0, margin: 0 }}>
            <ToteCodeLine />
            <ItemListLine />
          </Timeline>
        </Box>
        <DiagnosisDialog
          isOpenPrinterDialog={isOpenPrinter}
          setIsOpenPrinterDialog={setIsOpenPrinter}
        />
      </Page>
    </>
  );
};

export default Inspection;
