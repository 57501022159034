import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { useRecoilValue } from 'recoil';
import { carriersAtom } from '../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { convertCarrierId } from '../../../../libs/common/convertCarrier';

const gridColumns = () => {
  const carriers = useRecoilValue(carriersAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const pickingStatusOptions = refCodeOptions?.pickingStatus || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '집품상태',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'type',
      headerName: '집품구분',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'serialNumber',
      headerName: '출고ID',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 24,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 240,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pda',
      headerName: 'PDA',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'tote',
      headerName: '토트',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pdaAssignedAt',
      headerName: 'PDA배정일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingStartAt',
      headerName: '집품시작일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingCompletedAt',
      headerName: '집품완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingGroupSerialNumber',
      headerName: '집품그룹ID',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'skuCount',
      headerName: '품목수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'totalQuantity',
      headerName: '아이템수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'carrierId',
      headerName: '운송사',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'countryCode',
      headerName: '배송국가',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
