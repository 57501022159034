import React, {
  createContext,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import Typography from '@mui/material/Typography';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro/internal/models/range';
import DateRangePicker from './DateRangePicker';
import type { Dayjs } from 'dayjs';

import GridSection from '../../GridSection';
import TypeSelect from './TypeSelect';
import RangeSelect, { RangeType } from './RangeSelect';
import useFilter from '../hooks/useFilter';
import type { Options } from 'types/form';

type GridColumnType = `${number}/${number}`;

type Props = {
  label: string;
  gridRow?: number;
  rangeTypeOptions: Options;
  rangeAmountOptions?: Options;
  labelGridColumn: GridColumnType;
  selectGridColumn?: GridColumnType;
  dateRangePickerGridColumn: GridColumnType;
  rangeAmountSelectGridColumn: GridColumnType;
  fromEdge?: string;
  toEdge?: string;
  hasTypeSelect?: boolean;
  typeDefaultValue?: string;
  rangeDefaultValue?: RangeType;
};

type DateRangeContextValue = {
  dateRange: DateRange<Dayjs>;
  setDateRange: Dispatch<SetStateAction<DateRange<Dayjs>>>;
};

const dateRangeAmountOptions: Options = [
  {
    displayName: '오늘',
    field: 'today',
  },
  {
    displayName: '어제',
    field: 'yesterday',
  },
  {
    displayName: '최근 3일',
    field: 'lastThreeDays',
  },
  {
    displayName: '최근 7일',
    field: 'lastSevenDays',
  },
  {
    displayName: '최근 한달',
    field: 'lastMonth',
  },
  {
    displayName: '전체',
    field: 'all',
  },
];

export const DateRangeContext = createContext<DateRangeContextValue | null>(null);

const DateRangePickerWithSelect = forwardRef(
  (
    {
      label,
      gridRow,
      labelGridColumn,
      selectGridColumn,
      dateRangePickerGridColumn,
      rangeAmountSelectGridColumn,
      rangeTypeOptions,
      rangeAmountOptions = dateRangeAmountOptions,
      fromEdge = 'From',
      toEdge = 'To',
      hasTypeSelect = true,
      typeDefaultValue,
      rangeDefaultValue,
    }: Props,
    ref
  ) => {
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const { form, setForm } = useFilter();

    useEffect(() => {
      const [startDate, endDate] = dateRange;

      if (!form.dateType) {
        if (rangeTypeOptions && rangeTypeOptions[0]) {
          setForm(form => ({
            ...form,
            dateType: typeDefaultValue ?? rangeTypeOptions[0].field,
          }));
        }
        return;
      }

      setForm(form => ({
        ...form,
        [`${form.dateType + fromEdge}`]: startDate ? startDate.format('YYYY-MM-DD') : null,
        [`${form.dateType + toEdge}`]: endDate ? endDate.format('YYYY-MM-DD') : null,
      }));
    }, [dateRange, form.dateType, setForm]);

    const typeRef = useRef<{
      handleSelectChange: (event: { target: { value: string } }) => void;
    }>();
    const dateRangeRef = useRef<{
      handleDateChange: (newDateRange: MuiDateRange<Dayjs>) => void;
    }>();
    const rangeRef = useRef<{
      handleSelectChange: (event: { target: { value: string } }) => void;
    }>();

    useImperativeHandle(ref, () => ({
      selectType: (value: string) => {
        typeRef.current?.handleSelectChange({ target: { value } });
      },
      rangeDate: (value: MuiDateRange<Dayjs>) => {
        dateRangeRef.current?.handleDateChange(value);
      },
      selectRange: (value: RangeType) => {
        rangeRef.current?.handleSelectChange({ target: { value } });
      },
    }));

    return (
      <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
        <GridSection sx={{ gridRow, gridColumn: labelGridColumn }} isLabel>
          <Typography>{label}</Typography>
        </GridSection>
        {hasTypeSelect && (
          <GridSection sx={{ gridRow, gridColumn: selectGridColumn }}>
            <TypeSelect
              id="type-select"
              ref={typeRef}
              options={rangeTypeOptions}
              fromEdge={fromEdge}
              toEdge={toEdge}
            />
          </GridSection>
        )}
        <GridSection sx={{ gridRow, gridColumn: dateRangePickerGridColumn }}>
          <DateRangePicker ref={dateRangeRef} />
        </GridSection>
        <GridSection sx={{ gridRow, gridColumn: rangeAmountSelectGridColumn }}>
          <RangeSelect
            ref={rangeRef}
            id="range-select"
            options={rangeAmountOptions}
            defaultSelectedOption={rangeDefaultValue}
          />
        </GridSection>
      </DateRangeContext.Provider>
    );
  }
);

export default DateRangePickerWithSelect;
