import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import { errorType } from '../../../../../types/common';

export const getItemOption = async (itemOptionId: number) => {
  try {
    return await axiosV2.get(`/item-options/${itemOptionId}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateItemOptionRequest = {
  goodsCode: string;
  weight: number;
  widthInMillimeters: number;
  heightInMillimeters: number;
  lengthInMillimeters: number;
};

export const createItemOption = async (request: CreateItemOptionRequest) => {
  try {
    return await axiosV2.post(`/item-options`, request);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type UpdateItemOptionRequest = {
  itemOptionId: number;
  weight: number;
  widthInMillimeters: number;
  heightInMillimeters: number;
  lengthInMillimeters: number;
};

export const updateItemOption = async (updateItemOptionRequest: UpdateItemOptionRequest) => {
  try {
    console.log(updateItemOptionRequest.itemOptionId);
    return await axiosV2.put(
      `/item-options/${updateItemOptionRequest.itemOptionId}/dimensions`,
      updateItemOptionRequest
    );
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
