import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import { SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Warehouse, warehouseOptionsAtom } from '../../../../../../store/outbound/warehouse.recoil';
import { CarrierOption, carrierOptionsAtom } from '../../../../../../store/outbound/carrier.recoil';
import usePopup from '../../../../../../hooks/usePopup';
import { COLORS } from '../../../../../../consts/common/colors';
import { buttonTheme, inputTheme, selectTheme } from '../../../../../../styles/customedMuiTheme';

type Props = {
  fromWarehouseId: string;
  save: (warehouse: Warehouse, date: Dayjs, carrierId: string) => void;
};

const defaultOption = { displayName: '선택', value: 'ALL', id: 'ALL' };
export const deliveryType = {
  quickService: 14,
  localCourier: 10,
};

const SelectDelivery = (props: Props) => {
  const { fromWarehouseId, save } = props;

  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const warehouses = useRecoilValue(warehouseOptionsAtom);
  const otherWarehouses = getOtherWarehousesFrom(warehouses, fromWarehouseId);

  const [warehouse, setWarehouse] = useState<Warehouse | undefined>();
  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [carrier, setCarrier] = useState<CarrierOption>();
  const [hasSaved, setHasSaved] = useState<boolean>(false);
  const { showDialog } = usePopup();

  useEffect(() => {
    setCarrier(carrierOptions.find(item => Number(item.value) === deliveryType.quickService));
  }, [carrierOptions]);

  const handleWarehouseChange = (event: SelectChangeEvent) => {
    setWarehouse(getSelectedWarehouse(otherWarehouses, event.target.value));
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    setDate(newValue);
  };

  const handleCarrierChange = (event: SelectChangeEvent) => {
    setCarrier(carrierOptions.find(item => Number(item.value) === Number(event.target.value)));
  };

  const handleSave = () => {
    if (warehouse === undefined || warehouse.value === 'ALL') {
      showDialog({
        message: '창고를 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    if (date?.format('YYYY-MM-DD') === 'Invalid Date' || date === null) {
      showDialog({
        message: '대체입고 요청일자를 입력해 주세요.',
        buttons: [{ text: '확인' }],
      });

      return;
    }

    if (!carrier) return;

    save(warehouse, date, carrier.value);
    setHasSaved(true);
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={styles.title}
        color={hasSaved ? COLORS.grey : COLORS.ktown4u}
      >
        01. 대체출고 정보를 선택해 주세요.
      </Typography>
      <Stack sx={styles.container} direction={'row'}>
        <Stack flex={1}>
          <Box sx={styles.header}>
            <Typography sx={{ fontSize: 13 }}>
              대체입고 창고<span style={{ color: COLORS.red }}>*</span>
            </Typography>
          </Box>
          <Box sx={styles.searchBox}>
            <Typography sx={styles.fromWarehouse}>
              {getDisplayNameFrom(fromWarehouseId, warehouses)}
            </Typography>
            <ArrowRightAltIcon />
            {hasSaved ? (
              <Typography sx={styles.selectedValue}>{warehouse?.displayName}</Typography>
            ) : (
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  name="warehouse"
                  value={warehouse?.value.toString() ?? defaultOption.value}
                  onChange={handleWarehouseChange}
                  sx={styles.select}
                >
                  {[defaultOption, ...otherWarehouses].map(item => (
                    <MenuItem key={item.id} value={item.value.toString()}>
                      <Typography sx={{ fontSize: 13 }}>{item.displayName}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Stack flex={1}>
          <Box sx={styles.header}>
            <Typography sx={{ fontSize: 13 }}>
              대체출고 요청일자<span style={{ color: COLORS.red }}>*</span>
            </Typography>
          </Box>
          <Box sx={styles.searchBox}>
            {hasSaved ? (
              <Typography sx={styles.selectedValue}>{date?.format('YYYY-MM-DD')}</Typography>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                <DemoContainer components={['DatePicker']} sx={styles.dateContainer}>
                  <DemoItem>
                    <DatePicker
                      disablePast
                      value={date}
                      onChange={handleDateChange}
                      format={'YYYY-MM-DD'}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            )}
          </Box>
        </Stack>
        <Stack flex={1}>
          <Box sx={styles.header}>
            <Typography sx={{ fontSize: 13 }}>배송방식</Typography>
          </Box>
          <Box sx={styles.searchBox}>
            {hasSaved ? (
              <Typography sx={styles.selectedValue}>{carrier?.displayName}</Typography>
            ) : (
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  name="deliveryType"
                  value={carrier?.value.toString() || deliveryType.quickService.toString()}
                  onChange={handleCarrierChange}
                  sx={styles.select}
                >
                  {getLocalCarriersFrom(carrierOptions).map(item => (
                    <MenuItem key={item?.order} value={item?.value.toString()}>
                      <Typography sx={{ fontSize: 13 }}>{item?.displayName}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Stack>
      </Stack>
      {!hasSaved && (
        <Box sx={styles.buttonBox}>
          <Button variant="contained" onClick={handleSave} sx={buttonTheme.medium}>
            대체입고 창고 저장
          </Button>
        </Box>
      )}
    </>
  );
};

export default SelectDelivery;

const getDisplayNameFrom = (id: string, arrays: Warehouse[]) => {
  return arrays?.find(item => item.id === Number(id))?.displayName ?? '';
};

const getOtherWarehousesFrom = (warehouses: Warehouse[], fromWarehouseId: string) => {
  return warehouses?.filter(
    item => item.id !== Number(fromWarehouseId) && item.displayName !== null
  );
};

const getSelectedWarehouse = (warehouses: Warehouse[], target: string) => {
  if (warehouses === undefined) return undefined;
  return warehouses.find(item => item.value === Number(target));
};

const getLocalCarriersFrom = (carriers: CarrierOption[]) => {
  return carriers?.filter(
    carrier =>
      Number(carrier.value) === deliveryType.localCourier ||
      Number(carrier.value) === deliveryType.quickService
  );
};

const styles = {
  container: {
    margin: '10px 0',
    borderRadius: '4px',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E0E0E0',
  },
  header: {
    backgroundColor: COLORS.bgDeep,
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
  },
  title: {
    fontSize: 20,
    margin: 1,
    fontWeight: 'bold',
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    borderRight: '1px solid #E0E0E0',
  },
  dateContainer: {
    ...inputTheme.medium,
    overflow: 'hidden',
    p: '0px',
  },
  buttonBox: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
  fromWarehouse: { p: 1, fontSize: '13px', fontWeight: 'bold' },
  select: {
    '& .MuiSelect-select': {
      padding: 1,
    },
    ...selectTheme.medium,
  },
  selectedValue: { p: 1, fontSize: '13px', fontWeight: 'bold' },
};
