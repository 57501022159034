import React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSetRecoilState } from 'recoil';
import { inputAutoFocusAtom } from '../../../store';
import AutoFocusOffDialog from './AutoFocusOffDialog';

type Props = {
  message: string;
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
};

const Alert = (props: Props) => {
  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);
  const { message, open, onClose, onClick } = props;

  const handleClose = () => {
    onClose();
    setInputAutoFocus(true);
  };

  return (
    <AutoFocusOffDialog open={open} onClose={handleClose} PaperProps={{ sx: { padding: '16px' } }}>
      <DialogContent sx={{ padding: '16px', whiteSpace: 'pre-wrap' }}>{message}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
        <Button
          onClick={() => {
            onClick && onClick();
            handleClose();
          }}
          variant="contained"
        >
          확인
        </Button>
      </DialogActions>
    </AutoFocusOffDialog>
  );
};

export default Alert;
