import React, { ReactNode } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Stack from '@mui/material/Stack';
import { COLORS } from 'consts/common/colors';
import { CardActions } from '@mui/material';

type Props = {
  content: ReactNode;
  actions?: ReactNode;
};

const CompletionCard = (props: Props) => {
  const { content, actions } = props;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        width: '100%',
        gap: '16px',
        padding: '0 16px 0 108px',
      }}
    >
      <DoubleArrowIcon
        sx={{
          transform: 'rotate(90deg) translateY(16px)',
          fontSize: '28px',
          color: 'primary.main',
        }}
      />
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: actions ? '16px' : 0,
          width: '100%',
          padding: '24px',
          borderColor: 'primary.main',
          backgroundColor: COLORS.bgLight,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
            padding: 0,
          }}
        >
          {content}
        </CardContent>
        <CardActions sx={{ padding: 0 }}>{actions}</CardActions>
      </Card>
    </Stack>
  );
};

export default CompletionCard;
