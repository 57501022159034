import Ktown4uLogo from 'components/common/Ktown4uLogo';
import styles from 'styles/Home.module.css';

const Home = () => {
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <h1 className={styles.title}>TBD</h1>
      </main>

      <footer className={styles.footer}>
        Powered by{' '}
        <span className={styles.logo}>
          <Ktown4uLogo />
        </span>
      </footer>
    </div>
  );
};

export default Home;
