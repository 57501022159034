import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';
export const getCompanies = async () => {
  try {
    return await axiosV2.get('/companies/list');
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const getCenterDetail = async (centerId: number | undefined) => {
  try {
    return await axiosV2.get('/centers/' + centerId);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateCenterRequest = {
  companyId: number;
  name: string;
  code: string;
  isActive: string;
  managerName?: string;
  departmentName?: string;
  managerContact?: string;
};

export const createCenter = async (requestBody: CreateCenterRequest) => {
  try {
    return await axiosV2.post('/centers', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const updateCenter = async (centerId: number, requestBody: CreateCenterRequest) => {
  try {
    return await axiosV2.put(`/centers/${centerId}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
