import usePopup from 'hooks/usePopup';
import useInitialization from '../packing/hooks/useInitialization';

const useApiErrorDialog = () => {
  const { showErrorDialog } = usePopup();
  const initializeStates = useInitialization();

  const showApiErrorDialog = (message: string, info: string, onClick?: () => void) => {
    showErrorDialog({
      title: 'API 통신 에러',
      errorMessage: message,
      top: info,
      buttons: [
        {
          text: '확인',
          onClick: onClick ?? initializeStates,
        },
      ],
    });
  };

  return showApiErrorDialog;
};

export default useApiErrorDialog;
