import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ErrorBoundary } from 'react-error-boundary';
import DebugRecoil from 'store/DebugRecoil';
import { allAtoms } from 'store';
import ErrorFallback from 'components/common/ErrorFallback';
import { initializeRum } from 'libs/common/rum';
import PopupProvider from 'components/common/Popup/PopupProvider';
import muiTheme from 'styles/muiTheme';
import 'styles/globals.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { REACT_APP_MUI_LICENSE_KEY } from 'consts/common/environment';
import Router from 'pages/Router';
import PrinterScript from 'components/common/PrinterScript';
import InitGmsData from './components/common/InitGmsData';

// Refer https://dev.to/ofilipowicz/next-js-per-page-layouts-and-typescript-lh5, to find out why following types are added
type PropsWithRecoilState = {
  initialRecoilState?: Record<string, string>;
};

export const SELECTED_APP = 'selectedApp';

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY);

function MyApp({ initialRecoilState = {} }: PropsWithRecoilState) {
  useEffect(() => {
    initializeRum();
  }, []);

  useEffect(() => {
    const { selectedApp } = initialRecoilState;

    if (selectedApp) {
      sessionStorage.setItem(SELECTED_APP, selectedApp);
    }
  }, [initialRecoilState.selectedApp]);

  const initializeState = ({ set }: MutableSnapshot) => {
    Object.keys(initialRecoilState).map(key => {
      const value = initialRecoilState[key];
      const atom = allAtoms[key as keyof typeof allAtoms];
      set(atom, value);
    });
  };

  return (
    <SWRConfig value={{ revalidateOnFocus: false }}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={muiTheme}>
          <RecoilRoot initializeState={initializeState}>
            <PopupProvider>
              <DebugRecoil />
              <CssBaseline />
              <PrinterScript />
              <InitGmsData />
              <Router />
            </PopupProvider>
          </RecoilRoot>
        </ThemeProvider>
      </ErrorBoundary>
    </SWRConfig>
  );
}

export default MyApp;
