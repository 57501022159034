import { useEffect, useRef } from 'react';
import { REACT_APP_BASE_PATH } from '../consts/common/environment';

type Features = {
  height?: number;
  width?: number;
  left?: number;
  top?: number;
};

type Body = Record<string, string | number | boolean>;

type Props = {
  url: string;
  features?: Features;
  body?: Body;
};

const initFeatures = {
  height: 600,
  width: 800,
  left: 100,
  top: 100,
};

const usePopupWindow = () => {
  const popupWindow = useRef<Window | null>(null);

  useEffect(() => {
    return () => {
      popupWindow.current && popupWindow.current.close();
    };
  }, []);

  const openPopupWindow = ({ url, features = initFeatures, body }: Props) => {
    const { height, width, left, top } = features;

    popupWindow.current = window.open(
      `${REACT_APP_BASE_PATH}/${makeNewUrlBy(url, body)}`,
      'popup-window',
      `popup,height=${height},width=${width},left=${left},top=${top}`
    );
  };

  return { openPopupWindow };
};

export default usePopupWindow;

const makeNewUrlBy = (url: string, body?: Body) => {
  const path = url.startsWith('/') ? url.slice(1) : url;

  return body ? `${path}?${jsonToQueryString(body)}` : path;
};

const jsonToQueryString = (body: Body) => {
  return Object.keys(body)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
    })
    .join('&');
};
