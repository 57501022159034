import { defaultDeviceFormId, DeviceForm } from '../components/DevicesForm';
import { isDeviceAvailableOptions, selectOption } from '../index.page';
import { Option } from '../components/SelectBase';
import { useRecoilValue } from 'recoil';
import {
  warehouseAtom,
  warehouseOptionsAtom,
} from '../../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';

const generateDeviceRows = (device: DeviceForm, options: Record<string, Option[]>) => {
  const { deviceTypeOptions, jobTypeOptions } = options;
  const isCreateMode = device.id === defaultDeviceFormId;
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  return {
    basics: [
      {
        label: '창고',
        value: isCreateMode
          ? {
              type: 'typography',
              typography: convertWarehouseFullCode(Number(globalWarehouse), warehouseOption),
            }
          : {
              type: 'typography',
              typography: convertWarehouseFullCode(Number(device.warehouseId), warehouseOption),
            },
      },
      {
        label: '기기구분',
        required: true,
        isCreateMode: isCreateMode,
        value: isCreateMode
          ? {
              type: 'select',
              select: {
                name: 'deviceType',
                options: [selectOption, ...deviceTypeOptions],
                selected: device.deviceType,
              },
            }
          : {
              type: 'typography',
              typography: device.deviceType,
            },
      },
      {
        label: '작업기기번호',
        value: {
          type: 'typography',
          typography: device.deviceNumber,
        },
        shouldHide: isCreateMode,
      },
    ],
    details: [
      {
        label: '업무파트',
        value: {
          type: 'select',
          select: {
            name: 'jobType',
            options: jobTypeOptions.filter(
              option => option.value !== 'CS' && option.value !== 'STOCK'
            ),
            selected: device.jobType,
          },
        },
      },
      {
        label: '사용여부',
        value: {
          type: 'select',
          select: {
            name: 'isDeviceAvailable',
            options: isDeviceAvailableOptions,
            selected: device.isDeviceAvailable.toString(),
          },
        },
      },
      {
        label: '기기고유번호',
        labelSx: styles.label,
        required: true,
        value: {
          type: 'input',
          input: {
            name: 'registrationNumber',
            value: device.registrationNumber,
          },
        },
      },
    ],
  };
};

export default generateDeviceRows;

const styles = {
  label: { width: '128px', fontSize: '13px' },
};
