import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import usePopup from '../../../../../hooks/usePopup';
import InfoListSection from '../../../../../components/common/typography/InfoListSection';
import { getJsonById } from '../services/getJsonById';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';
import { useRecoilValue } from 'recoil';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';

const modalWidth = 1000;

export type SelectedPackingForJson = {
  id: number;
};

type Props = {
  selected: SelectedPackingForJson | undefined;
  onClose: () => void;
};

type WaybillResults = {
  requestBody: string;
  responseBody: string;
  serialNumber: string;
  waybillCreatedAt: string;
  trackingNumber: string;
  carrierId: number;
};

const JsonModal = (props: Props) => {
  const { selected, onClose } = props;
  const [jsons, setJsons] = useState<{ subTitle: string; text: string }[]>();
  const { showSnackbar } = usePopup();
  const [waybillResults, setWaybillResults] = useState<WaybillResults>();
  const carriers = useRecoilValue(carriersAtom);

  useEffect(() => {
    const fetchJson = async () => {
      if (!selected) return;

      const {
        requestBody,
        responseBody,
        serialNumber,
        waybillCreatedAt,
        trackingNumber,
        carrierId,
      } = await getJsonById(selected.id);
      setWaybillResults({
        requestBody,
        responseBody,
        serialNumber,
        waybillCreatedAt,
        trackingNumber,
        carrierId,
      });
      if (!requestBody || !responseBody) return;
      setJsons([
        { subTitle: 'Request(요청) JSON', text: JSON.stringify(JSON.parse(requestBody), null, 4) },
        {
          subTitle: 'Response(응답) JSON',
          text: isJsonTypeString(responseBody)
            ? JSON.stringify(JSON.parse(responseBody), null, 4)
            : responseBody.toString(),
        },
      ]);
    };

    fetchJson();
  }, []);

  const infoList = [
    { name: '출고ID: ', value: waybillResults?.serialNumber },
    { name: '송장발행일시: ', value: waybillResults?.waybillCreatedAt },
    { name: '배송방식: ', value: convertCarrierId(waybillResults?.carrierId, carriers)[0] },
    { name: '송장번호: ', value: waybillResults?.trackingNumber },
  ];

  return (
    <Stack width={modalWidth}>
      <InfoListSection infoList={infoList} hideValue={!jsons} />
      <Stack direction={'row'} justifyContent={'space-between'} gap={1} mt={2}>
        {jsons?.map(({ subTitle, text }) => (
          <Stack key={subTitle} width={'49%'} flex={1}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
              <Typography fontWeight={700}>{subTitle}</Typography>
              <Button
                variant={'outlined'}
                size={'small'}
                onClick={async () => {
                  await navigator.clipboard.writeText(text);
                  showSnackbar({ message: subTitle + '이 복사되었습니다.', severity: 'success' });
                }}
              >
                JSON 복사
              </Button>
            </Stack>
            <Box sx={styles.jsonText}>{text}</Box>
          </Stack>
        ))}
      </Stack>
      <Box sx={styles.buttonContainer}>
        <Button onClick={onClose} variant="outlined">
          닫기
        </Button>
      </Box>
    </Stack>
  );
};

export default JsonModal;

const isJsonTypeString = (responseBody: string) => {
  return responseBody.trim().startsWith('{') || responseBody.trim().startsWith('[');
};

const styles = {
  jsonText: {
    p: 2,
    height: '50vh',
    overflow: 'auto',
    whiteSpace: 'pre',
    border: '0.5px solid grey',
    fontSize: 13,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },
};
