import { HEIGHTS } from 'consts/common/heights';
import { FONT_SIZE, LINE_HEIGHT, LETTER_SPACING } from 'consts/common/typography';
import borderedBox from './borderedBox';

const typography = {
  small: {
    fontSize: `${FONT_SIZE.small} !important`,
    lineHeight: `${LINE_HEIGHT.small} !important`,
    letterSpacing: `${LETTER_SPACING.small} !important`,
  },
  medium: {
    fontSize: `${FONT_SIZE.medium} !important`,
    lineHeight: `${LINE_HEIGHT.medium} !important`,
    letterSpacing: `${LETTER_SPACING.medium} !important`,
  },
  large: {
    fontSize: `${FONT_SIZE.large} !important`,
    lineHeight: `${LINE_HEIGHT.large} !important`,
    letterSpacing: `${LETTER_SPACING.large} !important`,
  },
};

export const buttonTheme = {
  small: {
    height: HEIGHTS.small,
    '&.MuiButton-root': {
      ...typography.small,
    },
  },
  medium: {
    height: HEIGHTS.medium,
    '&.MuiButton-root': {
      ...typography.medium,
    },
  },
  large: {
    height: HEIGHTS.large,
    '&.MuiButton-root': {
      ...typography.large,
    },
  },
};

export const selectTheme = {
  small: {
    height: HEIGHTS.small,
    '& .MuiTypography-root': {
      ...typography.small,
    },
    '& .MuiInputBase-input': {
      ...typography.small,
    },
  },
  medium: {
    height: HEIGHTS.medium,
    '& .MuiTypography-root': {
      ...typography.medium,
    },
    '& .MuiInputBase-input': {
      ...typography.medium,
    },
  },
  large: {
    height: HEIGHTS.large,
    '& .MuiTypography-root': {
      ...typography.large,
    },
    '& .MuiInputBase-input': {
      ...typography.large,
    },
  },
};

export const listItemTextTheme = {
  small: {
    '& .MuiTypography-root': {
      ...typography.small,
    },
  },
  medium: {
    '& .MuiTypography-root': {
      ...typography.medium,
    },
  },
  large: {
    '& .MuiTypography-root': {
      ...typography.large,
    },
  },
};

export const inputTheme = {
  small: {
    '& .MuiInputBase-root': {
      height: HEIGHTS.small,
      boxSizing: 'border-box',
      ...typography.small,
    },
  },
  medium: {
    '& .MuiInputBase-root': {
      height: HEIGHTS.medium,
      boxSizing: 'border-box',
      ...typography.medium,
    },
  },
  large: {
    '& .MuiInputBase-root': {
      height: HEIGHTS.large,
      boxSizing: 'border-box',
      ...typography.large,
    },
  },
};

export const listBoardTheme = {
  container: {
    ...borderedBox,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    p: 3,
    mt: -1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    spacing: 1,
  },
  headerButton: {
    marginLeft: 1,
  },
};
