import React, { ReactNode, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSetRecoilState } from 'recoil';
import { inputAutoFocusAtom } from 'store';
import { COLORS } from 'consts/common/colors';
import ConnectIcon from '../packing/components/ConnectIcon';

type Props = {
  open: boolean;
  children: ReactNode;
};

const ScaleDialog = (props: Props) => {
  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);
  const { open, children } = props;

  useEffect(() => {
    setInputAutoFocus(!open);
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '520px',
          height: '320px',
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: COLORS.faintBlack,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '32px',
          fontSize: '24px',
          fontWeight: '700',
        }}
      >
        전자저울 연결상태 확인
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ConnectIcon />
        <DialogContentText
          sx={{
            margin: '16px 0 24px 0',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            color: 'text.primary',
          }}
        >
          전자저울이 연결되어 있지 않습니다.
          <br /> &apos;저울연결&apos; 클릭 후 포장을 진행해 주세요.
        </DialogContentText>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
          }}
        >
          {children}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ScaleDialog;
