import LoadingButton from '@mui/lab/LoadingButton';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { warehouseAtom, warehouseOptionsAtom } from 'store/outbound/warehouse.recoil';
import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { Inventory } from '../index.page';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { TableCell, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { adjustInventoryLpn } from '../services/InventoryLocation';
import { datadogRum } from '@datadog/browser-rum';
import usePopup from '../../../../../hooks/usePopup';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { listItemTextTheme } from '../../../../../styles/customedMuiTheme';

const modalWidth = 900;

type Props = {
  inventoryLpn: Inventory | null | undefined;
  onClose: () => void;
  mutate: () => void;
};

const MoveQuantityBetweenItemForm = (props: Props) => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const { inventoryLpn, onClose, mutate } = props;
  const [itemCode, setItemCode] = useState<string>('');
  const [adjustQty, setAdjustQty] = useState<string>('');
  const [adjustReason, setAdjustReason] = useState<string>('');
  const [isAdjust, setIsAdjust] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOptions = useRecoilValue(warehouseOptionsAtom);
  const warehouse = warehouseOptions.find(warehouse => warehouse.id === Number(globalWarehouse));
  const isReflectInventory = warehouse?.isReflectInventory ?? false;
  const [isApplyBO, setIsApplyBO] = useState<boolean>(isReflectInventory);

  useEffect(() => {
    if (!Number(globalWarehouse)) return;
  }, []);

  const handleAdjust = async () => {
    setIsAdjust(true);
    if (!itemCode) {
      showErrorDialog({
        title: '재고 보정 실패',
        errorMessage: '아이템 코드를 입력해주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      setIsAdjust(false);
      return;
    } else if (!adjustQty) {
      showErrorDialog({
        title: '재고 보정 실패',
        errorMessage: '보정 수량을 입력해주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      setIsAdjust(false);
      return;
    }

    const response = await adjustInventoryLpn(
      inventoryLpn?.id,
      itemCode,
      adjustQty,
      adjustReason,
      isApplyBO
    );
    if (response?.status === 200) {
      mutate();
      showSnackbar({ message: '재고 보정이 완료되었습니다.', severity: 'success' });
      onClose();
      datadogRum.addAction(`재고 보정 성공: ${inventoryLpn?.id}`);
    } else {
      showErrorDialog({
        title: '재고 보정 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      datadogRum.addError(`재고 보정 실패: ${response?.data?.errorMessage}`);
    }
    setIsAdjust(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f3f3fa',
      color: theme.palette.common.black,
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChange1 = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isApplyBOValue = value === 'true';
    setIsApplyBO(isApplyBOValue);
  };

  return (
    <Box width={modalWidth}>
      <Typography sx={{ color: 'text.primary', fontWeight: '800' }}>
        ‘재고 버전이동’ 기능은 동일버전 내에서의 아이템코드(GD코드)가 다른 아이템의 재고를 이동하는
        기능입니다.
      </Typography>
      <Box>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText}>{'01. 재고차감 아이템'}</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align={'center'} style={{ width: '180px' }}>
                  로케이션
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '150px' }}>
                  아이템코드
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '350px' }}>
                  아이템명
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '140px' }}>
                  로케이션 가용수량
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ width: '140px' }}>
                  BO재고반영여부
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '200px',
                  }}
                >
                  {inventoryLpn?.locationCode}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '150px',
                  }}
                >
                  {inventoryLpn?.itemCode}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '350px',
                  }}
                >
                  {inventoryLpn?.itemName}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  {inventoryLpn?.quantity}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  <TextField
                    select
                    fullWidth
                    value={isApplyBO}
                    defaultValue={isReflectInventory ? 'true' : 'false'}
                    onChange={handleChange1}
                    sx={{ margin: 0 }}
                    inputProps={{
                      sx: {
                        paddingY: 1,
                      },
                    }}
                  >
                    {isReflectInventory && (
                      <MenuItem key={'bo-inventory-reflection'} value={'true'}>
                        <ListItemText
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: 0,
                            ...listItemTextTheme.medium,
                          }}
                          primary={'BO재고반영'}
                        />
                      </MenuItem>
                    )}
                    <MenuItem key={'no-bo-inventory-reflection'} value={'false'}>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: 0,
                          ...listItemTextTheme.medium,
                        }}
                        primary={'BO재고반영안함'}
                      />
                    </MenuItem>
                  </TextField>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText} mt={5}>
            {'02. 재고증가 아이템'}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '180px',
                  }}
                >
                  로케이션
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '150px',
                  }}
                >
                  아이템코드
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  버전이동 수량
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '300px',
                  }}
                >
                  조정 상세사유
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '180px',
                  }}
                >
                  {inventoryLpn?.locationCode}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '150px',
                  }}
                >
                  <TextField
                    defaultValue={itemCode}
                    onBlur={e => setItemCode(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '100px',
                  }}
                >
                  <TextField
                    defaultValue={adjustQty.toString()}
                    onBlur={e => setAdjustQty(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    width: '300px',
                  }}
                >
                  <TextField
                    defaultValue={adjustReason.toString()}
                    onBlur={e => setAdjustReason(e.target.value)}
                    variant="outlined"
                    size="small"
                    style={{
                      width: '300px',
                    }}
                  />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <LoadingButton
            sx={styles.button}
            onClick={handleAdjust}
            variant="contained"
            loading={isAdjust}
          >
            재고 버전이동
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
export default MoveQuantityBetweenItemForm;

const styles = {
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
  select: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
};
