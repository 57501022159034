import { atom } from 'recoil';

export interface Center {
  id: number;
  code: string;
  name: string;
}

export const centerOptionsAtom = atom<Center[]>({
  key: 'centerOptionsAtom',
  default: [],
});
