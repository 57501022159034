import type { PackingStatus } from 'types/outbound';

const PACKING_STATUS: Record<PackingStatus, string> = {
  READY: '포장대기',
  PROCESSING: '포장중',
  ERROR: '포장오류',
  REGISTERED: '송장발행완료',
  PRINTING: '출력중',
  PRINTED: '송장출력완료',
  COMPLETED: '포장완료',
} as const;

export default PACKING_STATUS;
