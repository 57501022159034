import { atom } from 'recoil';

export interface PackingBox {
  pGoodsNo: number;
  pGoodsId: string;
  pGoodsName: string;
}

export interface PackingBoxOption {
  displayName: string;
  value: string;
  order: number;
}

export const packingIdsToPrintAtom = atom<number[]>({
  key: 'packingIdsToPrintAtom',
  default: [],
});

const packingsAtoms = {
  packingIdsToPrint: packingIdsToPrintAtom,
};

export const packingBoxOptionsAtom = atom<PackingBoxOption[]>({
  key: 'packingBoxOptionsAtom',
  default: [],
});

export default packingsAtoms;
