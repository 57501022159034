import { useRecoilValue } from 'recoil';
import type { GridColDef } from '@mui/x-data-grid-pro';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const isActiveOptions = refCodeOptions?.activeType;
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'name',
      headerName: '회사명',
      flex: 1,
      minWidth: 90,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'code',
      headerName: '회사코드',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isActive',
      headerName: '사용여부',
      flex: 1,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return isActiveOptions?.find(option => option.value === value)?.displayName;
      },
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
