import React, { ChangeEvent, KeyboardEvent, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import type { SxProps } from '@mui/material/styles';
import { bulkPackingIsLoadingAtom } from '../../../../store/outbound/bulkPacking.recoil';
import useScale from '../packing/hooks/useScale';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRecoilValue } from 'recoil';

type Props = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (weight: string) => void;
  onUnmount?: () => void;
  inputSxProps?: SxProps;
};

const WeightInput = (props: Props) => {
  const isLoading = useRecoilValue(bulkPackingIsLoadingAtom);
  const { value, onChange, onSubmit, onUnmount, inputSxProps } = props;

  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit(value);
    }
  };

  useScale(onSubmit, console.error);

  useEffect(() => {
    if (onUnmount) {
      return () => onUnmount();
    }
  }, []);

  return (
    <>
      <TextField
        id="weight-input"
        value={value}
        onChange={onChange}
        onKeyDown={handleEnterPress}
        fullWidth
        inputProps={{ sx: { padding: '4px', fontSize: 14, ...inputSxProps } }}
        focused
        autoFocus
        onBlur={e => e.target.focus()}
      />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        sx={{ height: '28px', padding: '0px', fontWeight: 700 }}
        onClick={() => onSubmit(value)}
      >
        확인
      </LoadingButton>
    </>
  );
};

export default WeightInput;
