import type { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { Warehouse } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';

const gridColumns = (warehouseOption: Warehouse[]) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingOrderStatusOptions = refCodeOptions?.shippingOrderStatus || [];
  const shippingOrderTypeOptions = refCodeOptions?.shippingOrderType || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '출고지시서상태',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        shippingOrderStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'type',
      headerName: '출고지시구분',
      minWidth: 140,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        shippingOrderTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'serialNumber',
      headerName: '출고지시ID',
      minWidth: 160,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: '출고지시 생성일시',
      minWidth: 160,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingCount',
      headerName: '출고건수',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdUser',
      headerName: '등록자',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
