import { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { convertCarrierId } from '../../../../../../libs/common/convertCarrier';
import { carriersAtom } from '../../../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../../../store/outbound/refCode.recoil';

const gridColumns = () => {
  const carriers = useRecoilValue(carriersAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'status',
      headerName: '출고상태',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        shippingStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'shippingBaseAt',
      headerName: '출고기준일시',
      minWidth: 190,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 100,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boxCount',
      headerName: '박스',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 240,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 120,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'skuCount',
      headerName: '품목수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'totalQuantity',
      headerName: '아이템수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'countryCode',
      headerName: '배송국가',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};
export default gridColumns;
