import React, { Fragment, useRef } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useReactToPrint } from 'react-to-print';
import useShouldMountAppBarAndDrawer from '../../../../hooks/useShouldMountAppBarAndDrawer';
import { COLORS } from '../../../../consts/common/colors';
import fetcher from '../../../../libs/common/fetcher';
import './index.css';

export type ReceivingItemsForSpecification = {
  itemId: number | string;
  barcode: string;
  cost: number;
  imageUrl: string;
  itemCode: string;
  itemName: string;
  offlineBarcode: string;
  qty: number;
  width: number;
  height: number;
  length: number;
  //TODO: 부가세는 api에서 받지 못하는 값이어서 임의로 넣음.
  tax: number;
};

export type Specification = {
  companyFullName: string;
  companyOwnerName: string;
  companyRegistrationNumber: string;
  note: string;
  receiveCompletedDate: string;
  receivedDate: string;
  receivingId: number;
  receivingItemsForSpecification: ReceivingItemsForSpecification[];
  receivingName: string;
  receivingStatus: string;
  supplierName: string;
  receivingType: string;
  //TODO: 현재 api에서 받지 못하는 값은 임의로 넣어놓음.
  receiver: string;
  supplierRegistrationNumber: string;
  supplierOwnerName: string;
  supplierAddress: string;
  supplierBusinessType: string;
  supplierBusinessItem: string;
  supplierContact: string;
  deliveryCost: number;
};

const ReceivingSpecification = () => {
  useShouldMountAppBarAndDrawer();
  const { id } = useParams();
  const { data: specification } = useSWR<Specification>(`/receivings/${id}/specification`, fetcher);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef, onAfterPrint: () => window.close() });

  const totals = specification?.receivingItemsForSpecification.reduce(
    (acc, cur) => ({
      totalQty: acc.totalQty + cur.qty,
      totalCost: acc.totalCost + cur.cost * cur.qty,
      totalTax: acc.totalTax + (cur.tax ?? 0),
    }),
    { totalQty: 0, totalCost: 0, totalTax: 0 }
  );

  const supplier = [
    {
      id: 'supplierRegistrationNumber',
      title: '등록번호',
      value: specification?.supplierRegistrationNumber,
    },
    { id: 'supplierName', title: '상호(법인명)', value: specification?.supplierName },
    { id: 'supplierOwnerName', title: '대표자', value: specification?.supplierOwnerName },
    { id: 'supplierAddress', title: '사업장주소', value: specification?.supplierAddress },
    { id: 'supplierBusinessType', title: '업태', value: specification?.supplierBusinessType },
    { id: 'supplierBusinessItem', title: '종목', value: specification?.supplierBusinessItem },
    { id: 'supplierContact', title: '연락처', value: specification?.supplierContact },
  ];

  const receiver = [
    { id: 'receiverRegistrationNumber', title: '등록번호', value: '120-87-71116' },
    { id: 'receiverName', title: '상호(법인명)', value: '(주)케이타운포유' },
    { id: 'receiverOwnerName', title: '대표자', value: '송효민' },
    {
      id: 'receiverAddress',
      title: '사업장주소',
      value: '서울특별시 강남구 영동대로 513, 3층(삼성동, 코엑스)',
    },
    { id: 'receiverBusinessType', title: '업태', value: '소매' },
    { id: 'receiverBusinessItem', title: '종목', value: '전자상거래' },
    { id: 'receiverContact', title: '인수자', value: specification?.receiver },
  ];

  const getSpecificationName = () => {
    if (
      (specification?.receivingStatus === 'COMPLETED' ||
        specification?.receivingStatus === 'COMPLETED_MOVEMENT') &&
      specification?.receivingType === 'RETURN'
    ) {
      return '반품 거래명세서 (반품)';
    }
    if (
      (specification?.receivingStatus === 'COMPLETED' ||
        specification?.receivingStatus === 'COMPLETED_MOVEMENT') &&
      specification?.receivingType === 'DEFECT_RETURN'
    ) {
      return '반품 거래명세서 (불량반품)';
    }
    if (specification?.receivingType === 'RETURN') {
      return '입고 반품명세서 (반품)';
    } else if (specification?.receivingType === 'DEFECT_RETURN') {
      return '입고 반품명세서 (불량 반품)';
    } else if (
      specification?.receivingStatus === 'COMPLETED' ||
      specification?.receivingStatus === 'COMPLETED_MOVEMENT'
    ) {
      return '거래 명세서';
    } else {
      return '입고 명세서';
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>명세서</title>
        </Helmet>
      </HelmetProvider>
      <Stack ref={contentRef} spacing={1} sx={{ padding: '32px', backgroundColor: 'white' }}>
        <Stack spacing={1.5}>
          <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 700 }}>
            {getSpecificationName()}
          </Typography>
          <Box sx={styles.infoContainer}>
            <Typography>거래일자</Typography>
            <Typography sx={{ fontWeight: 700, color: COLORS.ktown4u }}>
              {specification?.receivedDate}
            </Typography>
            |<Typography>입고ID</Typography>
            <Typography sx={{ fontWeight: 700, color: COLORS.ktown4u }}>
              {specification?.receivingName}
            </Typography>
          </Box>
        </Stack>
        <Stack spacing={1}>
          <StyledBoxContainer
            sx={{
              gridTemplateColumns: '1fr 4fr 15fr 1fr 4fr 15fr',
              gridTemplateRows: 'repeat(7, auto)',
            }}
          >
            <StyledBox sx={{ gridColumn: '1', gridRow: '1/8', p: 1 }}>공급자</StyledBox>
            {supplier.map((item: Record<string, string | undefined>, index) => (
              <Fragment key={item.id}>
                <StyledBox sx={{ gridColumn: '2', gridRow: `${index + 1}` }}>
                  {item.title}
                </StyledBox>
                <StyledBoxLeftAligned sx={{ gridColumn: '3', gridRow: `${index + 1}` }}>
                  {item.value}
                </StyledBoxLeftAligned>
              </Fragment>
            ))}

            <StyledBox sx={{ gridColumn: '4', gridRow: '1/8', p: 1 }}>공급받는자</StyledBox>
            {receiver.map((item, index) => (
              <Fragment key={item.id}>
                <StyledBox sx={{ gridColumn: '5', gridRow: `${index + 1}` }}>
                  {item.title}
                </StyledBox>
                <StyledBoxLeftAligned sx={{ gridColumn: '6', gridRow: `${index + 1}` }}>
                  {item.value}
                </StyledBoxLeftAligned>
              </Fragment>
            ))}
          </StyledBoxContainer>
          <StyledBoxContainer
            sx={{
              gridTemplateColumns: '1fr 5fr 5fr 21fr 3fr 3fr 5fr 5fr 5fr',
            }}
          >
            <StyledBox sx={{ gridColumn: '1' }}>NO</StyledBox>
            <StyledBox sx={{ gridColumn: '2' }}>바코드</StyledBox>
            <StyledBox sx={{ gridColumn: '3' }}>아이템코드</StyledBox>
            <StyledBox sx={{ gridColumn: '4' }}>품목/규격</StyledBox>
            <StyledBox sx={{ gridColumn: '5' }}>단위</StyledBox>
            <StyledBox sx={{ gridColumn: '6' }}>수량</StyledBox>
            <StyledBox sx={{ gridColumn: '7' }}>공급단가</StyledBox>
            <StyledBox sx={{ gridColumn: '8' }}>공급가합계</StyledBox>
            <StyledBox sx={{ gridColumn: '9' }}>부가세</StyledBox>

            {specification?.receivingItemsForSpecification.map((item, index) => (
              <Fragment key={item.itemCode}>
                <StyledBox sx={{ gridColumn: '1' }}>{index + 1}</StyledBox>
                <StyledBoxLeftAligned sx={{ gridColumn: '2' }}>{item.barcode}</StyledBoxLeftAligned>
                <StyledBoxLeftAligned sx={{ gridColumn: '3' }}>
                  {item.itemCode}
                </StyledBoxLeftAligned>
                <StyledBoxLeftAligned sx={{ gridColumn: '4' }}>
                  {item.itemName}
                </StyledBoxLeftAligned>
                <StyledBoxCenterAligned sx={{ gridColumn: '5' }}>EA</StyledBoxCenterAligned>
                <StyledBoxRightAligned sx={{ gridColumn: '6' }}>
                  {item.qty.toLocaleString()}
                </StyledBoxRightAligned>
                <StyledBoxRightAligned sx={{ gridColumn: '7' }}>
                  {item.cost.toLocaleString()}
                </StyledBoxRightAligned>
                <StyledBoxRightAligned sx={{ gridColumn: '8' }}>
                  {(item.cost * item.qty).toLocaleString()}
                </StyledBoxRightAligned>
                <StyledBoxRightAligned sx={{ gridColumn: '9' }}>{item.tax}</StyledBoxRightAligned>
              </Fragment>
            ))}
            <StyledBox sx={{ gridColumn: '1/6', p: 1 }}>합계</StyledBox>
            <StyledBoxRightAlignedWithBg sx={{ gridColumn: '6', backgroundColor: COLORS.bgDeep }}>
              {totals?.totalQty.toLocaleString()}
            </StyledBoxRightAlignedWithBg>
            <StyledBoxRightAlignedWithBg
              sx={{ gridColumn: '7', backgroundColor: COLORS.bgDeep }}
            ></StyledBoxRightAlignedWithBg>
            <StyledBoxRightAlignedWithBg sx={{ gridColumn: '8', backgroundColor: COLORS.bgDeep }}>
              {totals?.totalCost.toLocaleString()}
            </StyledBoxRightAlignedWithBg>
            <StyledBoxRightAlignedWithBg sx={{ gridColumn: '9', backgroundColor: COLORS.bgDeep }}>
              {totals?.totalTax.toLocaleString()}
            </StyledBoxRightAlignedWithBg>
          </StyledBoxContainer>
          <StyledBoxContainer
            sx={{
              gridTemplateColumns: '5fr 1fr 1fr 1fr 1fr ',
            }}
          >
            <StyledBox sx={{ gridColumn: '1' }}>비고</StyledBox>
            <StyledBox sx={{ gridColumn: '2' }}>배송비</StyledBox>
            <StyledBox sx={{ gridColumn: '3' }}>공급가액</StyledBox>
            <StyledBox sx={{ gridColumn: '4' }}>부가세</StyledBox>
            <StyledBox sx={{ gridColumn: '5' }}>합계</StyledBox>

            <StyledBoxLeftAligned sx={{ gridColumn: '1' }}>
              {specification?.note}
            </StyledBoxLeftAligned>
            <StyledBoxRightAligned sx={{ gridColumn: '2' }}>
              {specification?.deliveryCost}
            </StyledBoxRightAligned>
            <StyledBoxRightAligned sx={{ gridColumn: '3' }}>
              {totals?.totalCost.toLocaleString()}
            </StyledBoxRightAligned>
            <StyledBoxRightAligned sx={{ gridColumn: '4' }}>
              {totals?.totalTax.toLocaleString()}
            </StyledBoxRightAligned>
            <StyledBoxRightAligned sx={{ gridColumn: '5' }}>
              {(
                (totals?.totalCost ?? 0) +
                (totals?.totalTax ?? 0) +
                (specification?.deliveryCost ?? 0)
              ).toLocaleString()}
            </StyledBoxRightAligned>
          </StyledBoxContainer>
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white' }}>
        <Box sx={{ mb: 4 }}>
          <Button
            variant="outlined"
            sx={{ width: '75px', mr: '10px' }}
            onClick={() => window.close()}
          >
            닫기
          </Button>
          <Button variant="outlined" sx={{ width: '75px' }} onClick={() => reactToPrintFn()}>
            인쇄
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ReceivingSpecification;

const styles = {
  infoContainer: {
    display: 'flex',
    gap: '8px',
    padding: '12px 24px',
    backgroundColor: COLORS.bgDeep,
    flexDirection: 'row',
    border: `1px solid ${COLORS.lightLine}`,
  },
  stylesForStyledBox: {
    borderRight: `1px solid ${COLORS.lightLine}`,
    borderBottom: `1px solid ${COLORS.lightLine}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '11px',
    padding: '4px',
  },
};

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  borderLeft: `1px solid ${COLORS.lightLine}`,
  borderTop: `1px solid ${COLORS.lightLine}`,
  gap: 0,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  ...styles.stylesForStyledBox,
  backgroundColor: COLORS.bgDeep,
}));
const StyledBoxCenterAligned = styled(Box)(({ theme }) => ({
  ...styles.stylesForStyledBox,
  justifyContent: 'center',
}));

const StyledBoxLeftAligned = styled(Box)(({ theme }) => ({
  ...styles.stylesForStyledBox,
  justifyContent: 'flex-start',
}));
const StyledBoxRightAligned = styled(Box)(({ theme }) => ({
  ...styles.stylesForStyledBox,
  justifyContent: 'flex-end',
}));
const StyledBoxRightAlignedWithBg = styled(Box)(({ theme }) => ({
  ...styles.stylesForStyledBox,
  justifyContent: 'flex-end',
  backgroundColor: COLORS.bgDeep,
}));
