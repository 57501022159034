import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PACKING } from 'consts/outbound/messages';
import { COLORS } from 'consts/common/colors';

type Props = {
  estimated: number;
  measured: number;
};

const WeightMismatch = (props: Props) => {
  const { estimated, measured } = props;

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" sx={{ paddingBottom: '24px ', fontSize: '24px', fontWeight: 700 }}>
        예상/측정무게 오차 확인
      </Typography>
      <Typography
        sx={{
          paddingBottom: '16px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        }}
      >
        {PACKING.checkWeightDiff}
      </Typography>
      <Box sx={boxStyle}>
        <Typography fontSize="20px">예상 무게</Typography>
        <Typography sx={weightStyle}>{estimated.toLocaleString()}g</Typography>
      </Box>
      <Box sx={boxStyle}>
        <Typography fontSize="20px">측정 무게</Typography>
        <Typography sx={weightStyle}>{measured.toLocaleString()}g</Typography>
      </Box>
      <Typography sx={{ paddingTop: '8px' }}>
        <b>[책임자용 안내사항]</b> 집품상태 확인 후 다음 작업을 선택해 주세요.
      </Typography>
    </Stack>
  );
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '56px',
  width: '456px',
  height: '64px',
  marginBottom: '8px',
  border: `1px solid ${COLORS.darkLine}`,
  borderRadius: '4px',
};

const weightStyle = { fontSize: '20px', fontWeight: 700, color: COLORS.red };

export default WeightMismatch;
