import React from 'react';
import Box from '@mui/system/Box';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import { numberFormatter } from '../../../../../libs/common/unitConverters';

const gridColDef = [
  {
    field: 'rowIndex',
    headerName: 'NO',
    minWidth: 60,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'barcode',
    headerName: '바코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'offlineBarcode',
    headerName: '물류 바코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'itemCode',
    headerName: '아이템코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'imageUrl',
    headerName: '이미지',
    minWidth: 80,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
          <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
        </Box>
      ) : null,
  },
  {
    field: 'itemName',
    headerName: '아이템명',
    minWidth: 160,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    renderCell: ({ row }) => {
      return (
        <p style={{ textAlign: 'start' }}>
          <span>{row.itemName}</span>
          <span style={{ color: 'red' }}>{row.remark ? ` => ${row.remark}` : ''}</span>
        </p>
      );
    },
  },
  {
    field: 'quantity', //TODO: 필드명 확인요망
    headerName: '발주수량',
    minWidth: 80,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    valueFormatter: ({ value }) => numberFormatter(value),
  },
  {
    field: 'supplyPrice',
    headerName: '단가(원)',
    minWidth: 90,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    valueFormatter: ({ value }) => numberFormatter(value),
  },
  {
    field: 'totalCost',
    headerName: '소계(원)',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    valueFormatter: ({ value }) => numberFormatter(value),
  },
  {
    field: 'totalCostOfReceiving', //TODO: 필드명 확인요망
    headerName: '입고소계(원)',
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => numberFormatter(value),
  },
] as GridColDef[];

export default gridColDef;
