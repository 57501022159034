import { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import convertUser from '../../../../libs/common/convertUser';
import devOnlyFlag from '../../../../libs/common/devOnlyFlag';
import UserForm from './components/UserForm';
import gridColDef from './gridColDef';
import { Options } from 'types/form';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import FormModal from '../../../../components/common/FormModal';
import ExcelUpload from './components/ExcelUpload';
import useConditionalSWR from '../../components/useConditionalSwr';

const searchKeywordOptions: Options = [
  {
    displayName: '로그인ID',
    field: 'loginId',
  },
  {
    displayName: '사용자명',
    field: 'username',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

type User = {
  loginId: string;
  username: string;
  boLoginId?: string;
  updatedAt: string;
  primaryWarehouseId: number;
  availableWarehouseIds: number[];
  updatedUsername: string;
  updatedLoginId: string;
};

type UserResponse = {
  content: User[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Users = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });

  const { data, isValidating, mutate } = useConditionalSWR<UserResponse>(
    [`/users/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: UserResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: User, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
    });
    await mutate();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleExcelUpload = () => {
    setIsModalOpen(true);
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    params.id && setIsUserFormOpen(true);
    setSelectedId(params.id as number);
  };

  const createUserModalOpen = () => {
    setIsUserFormOpen(true);
  };

  const onCloseModal = () => {
    setIsUserFormOpen(false);
    setSelectedId(-1);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        사용자 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={3}
          searchOptions={searchKeywordOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          inputGridColumn="4/6"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            사용자 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={createUserModalOpen}
            >
              사용자 등록
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleExcelUpload}
            >
              엑셀업로드
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
          onRowClick={handleRowClick}
        />
      </Stack>
      <FormModal
        open={isUserFormOpen}
        title={selectedId > 0 ? '사용자 수정' : '사용자 등록'}
        onClose={onCloseModal}
      >
        <UserForm userId={selectedId} onClose={onCloseModal} onMutate={mutate} />
      </FormModal>
      <FormModal open={isModalOpen} title={'사용자 등록'} onClose={handleCloseModal}>
        <ExcelUpload onCloseClick={handleCloseModal} onMutate={mutate} />
      </FormModal>
    </Page>
  );
};

export default Users;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
