import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import Box from '@mui/system/Box';
import borderedBoxStyle from 'styles/borderedBox';
import { MAIN_WIDTH } from 'consts/common/layout';
import Select from './Select';
import Search from './Search';
import SearchWithSelect from './SearchWithSelect';
import DateRangePickerWithSelect from './DateRangePickerWithSelect';
import SubmitButton from './SubmitButton';
import InitButton from './InitButton';
import DefaultButtonGroup from './DefaultButtonGroup';
import NumberRangePicker from './NumberRangePicker';
import MultipleSelectPlaceholder from './MultipleSelectPlaceholder';
import VerticalSearchWithSelect from './VerticalSearchWithSelect';

export type Form = Record<string, string | number | null>;

type Props = {
  gridTemplateColumns: string;
  children: ReactNode;
};
type FilterContextValue = {
  form: Form;
  setForm: Dispatch<SetStateAction<Form>>;
};

export const FilterContext = createContext<FilterContextValue | null>(null);

const Filter = (props: Props) => {
  const { gridTemplateColumns, children } = props;
  const [form, setForm] = useState<Form>({});

  return (
    <FilterContext.Provider value={{ setForm, form }}>
      <Box
        sx={{
          ...borderedBoxStyle,
          display: 'grid',
          gridTemplateColumns,
          rowGap: 1,
          columnGap: 2,
          minWidth: MAIN_WIDTH,
          p: 3,
          pb: 2.5,
        }}
      >
        {children}
      </Box>
    </FilterContext.Provider>
  );
};

Filter.Select = Select;
Filter.MultipleSelectPlaceholder = MultipleSelectPlaceholder;
Filter.Search = Search;
Filter.SearchWithSelect = SearchWithSelect;
Filter.DateRangePickerWithSelect = DateRangePickerWithSelect;
Filter.NumberRangePicker = NumberRangePicker;
Filter.SubmitButton = SubmitButton;
Filter.InitButton = InitButton;
Filter.DefaultButtonGroup = DefaultButtonGroup;
Filter.VerticalSearchWithSelect = VerticalSearchWithSelect;

export default Filter;
