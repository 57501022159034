import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';
export const getWarehouses = async () => {
  try {
    return await axiosV2.get('/warehouses/list');
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const getDetail = async (id: number | undefined) => {
  try {
    return await axiosV2.get('/areas/' + id);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateRequest = {
  warehouseId: number;
  code: string;
  isActive: string;
};

export const create = async (requestBody: CreateRequest) => {
  try {
    return await axiosV2.post('/areas', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const update = async (id: number, requestBody: CreateRequest) => {
  try {
    return await axiosV2.put(`/areas/${id}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
