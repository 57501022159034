import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from '../../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../../types/common';

export const getReceiptsConfirmDetails = async (receivingId: number | undefined) => {
  try {
    return await axiosV2.get(`/receivings/${receivingId}/complete`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고확정 상세 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const receivingReInspect = async (receivingId: number, rejectionReason: string) => {
  try {
    return await axiosV2.post(`/receivings/${receivingId}/reinspect`, { rejectionReason });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재검수 요청 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const receivingCompleted = async (receivingId: number) => {
  try {
    return await axiosV2.post(`/receivings/${receivingId}/completed`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고확정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
