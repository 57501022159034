import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { PACKING } from 'consts/outbound/messages';
import { afterProgressTitle, beforeProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import Table, { TableCell, TableRow } from '../../../components/Table';
import ScaleDialog from '../../components/ScaleDialog';
import PackingButton from '../../components/PackingButton';
import WeightInput from '../../components/WeightInput';
import TimelineItem from '../../components/TimelineItem';
import { COLORS } from '../../../../../consts/common/colors';
import { useRecoilState, useRecoilValue } from 'recoil';
import calWeightByVolume from '../../../../../libs/outbound/calWeightByVolume';
import {
  bulkPackingBoxAtom,
  bulkPackingDisplayWeightAtom,
} from '../../../../../store/outbound/bulkPacking.recoil';
import usePopup from '../../../../../hooks/usePopup';
import {
  MIN_WEIGHT,
  SCALE_MIN_AVAILABLE_WEIGHT_IN_BULK_PACKING,
} from '../../../../../consts/outbound/weights';
import useScale from '../../packing/hooks/useScale';

type Props = {
  onSubmit: (weight: string) => void;
};

const BulkPackingWeightLine = (props: Props) => {
  const { onSubmit } = props;
  const [displayWeight, setDisplayWeight] = useRecoilState(bulkPackingDisplayWeightAtom);
  const bulkPackingBox = useRecoilValue(bulkPackingBoxAtom);
  const { showSnackbar } = usePopup();
  const [input, setInput] = useState('');
  const { requestScalePort, isConnected } = useScale(onSubmit, (error: Error) => {
    showSnackbar({ message: error.message, severity: 'error' });
  });
  const [modalOpen, setModalOpen] = useState(!isConnected);
  useEffect(() => {
    setModalOpen(!isConnected);
  }, [isConnected]);

  const gotoNextStep = () => {
    setDisplayWeight(input);
    onSubmit(input);
    setInput('');
  };

  const handleWeightRange = () => {
    showSnackbar({ message: PACKING.inputWeightRangeBulkPacking, severity: 'warning' });
  };

  const handleInvalidInput = () => {
    showSnackbar({ message: PACKING.invalidWeight, severity: 'warning' });
  };

  const handleWeightInput = () => {
    if (Number(input) < MIN_WEIGHT || Number(input) > SCALE_MIN_AVAILABLE_WEIGHT_IN_BULK_PACKING) {
      handleWeightRange();
    } else {
      gotoNextStep();
    }
  };

  const handleSubmit = () => {
    const isNumber = !isNaN(Number(input));

    if (isNumber) {
      handleWeightInput();
    } else {
      handleInvalidInput();
    }
  };

  const weightByVolume = calWeightByVolume(bulkPackingBox ?? {});
  return (
    <>
      <TimelineItem
        isLast
        step={2}
        beforeProgressComponent={
          <Typography sx={beforeProgressTitle} suppressHydrationWarning>
            {PACKING.weight}
          </Typography>
        }
        inProgressComponent={
          <>
            <Typography sx={inProgressTitle} suppressHydrationWarning>
              {PACKING.weight}
            </Typography>
            <Table>
              <TableRow height="36px">
                <TableCell
                  label={
                    <>
                      <Typography>포장실제중량무게(g)</Typography>
                      <Typography color={COLORS.red}>*</Typography>
                    </>
                  }
                  value={
                    <WeightInput
                      value={input}
                      onChange={e => setInput(e.target.value)}
                      onSubmit={handleSubmit}
                      onUnmount={() => setInput('')}
                    />
                  }
                  valueProps={valueCellStyle}
                />
                <TableCell label="포장실제부피무게(g)" value={weightByVolume.toLocaleString()} />
              </TableRow>
            </Table>
          </>
        }
        afterProgressComponent={
          <>
            <Typography sx={afterProgressTitle} suppressHydrationWarning>
              {PACKING.weight}
            </Typography>
            <Table>
              <TableRow height="36px">
                <TableCell
                  label="포장실제중량무게(g)"
                  value={Number(displayWeight).toLocaleString()}
                />
                <TableCell label="포장실제부피무게(g)" value={weightByVolume.toLocaleString()} />
              </TableRow>
            </Table>
          </>
        }
      />

      <ScaleDialog open={modalOpen}>
        <PackingButton onClick={() => setModalOpen(false)} variant="outlined">
          무게 직접입력
        </PackingButton>
        <PackingButton onClick={requestScalePort}>저울 연결</PackingButton>
      </ScaleDialog>
    </>
  );
};

const valueCellStyle = {
  sx: { display: 'flex', alignItems: 'center', flex: 1, gap: '4px', padding: '6px 4px' },
};

export default BulkPackingWeightLine;
