import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import { COLORS } from 'consts/common/colors';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useBarcode from '../../../hooks/useBarcode';
import { datadogRum } from '@datadog/browser-rum';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isFetchingPackingAtom } from '../../../../../../store/outbound/packing.recoil';
import { inputAutoFocusAtom } from '../../../../../../store';
import BarcodeInputByProps from '../BarcodeInputByProps';
import { koreaFilter } from '../ItemListLine/utils';
import usePopup from '../../../../../../hooks/usePopup';
import { handleFetchCombineTotes } from '../../services';
import BarcodeByProps from '../BarcodeByProps';
import getInspectionImage from '../getInspectionImage';
import { prevInspectionAtom } from '../../../../../../store/outbound/inspection.recoil';
import { isZpl } from '../../../../../../libs/common/isZpl';
import { WAYBILL_FORMAT } from '../../../../../../consts/outbound/waybillFormat';
import { isPrintingAtom, printerAtom } from '../../../../../../store/common/printer.recoil';

const modalWidth = 800;

type Props = {
  onClose: () => void;
  toteBarcode: string;
};

type errorType = {
  errorCode: string;
  errorMessage: string;
  status: number;
  timestamp: string;
};

const messageFirstLine =
  '토트 2개 이상으로 담아야 할 아이템 부피인 경우 [1주문다포장대]로 보내주세요.';

const messageBarcodeScanned =
  '다음 토트 1개에 아이템을 모두 옮겨 담은 후 개별포장대로 보내 주세요.';

const TotesModal = (props: Props) => {
  const { onClose, toteBarcode } = props;
  const { isBarcodeValid } = useBarcode();
  const [isLoading, setIsLoading] = useRecoilState(isFetchingPackingAtom);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [inputAutoFocus, setInputAutoFocus] = useRecoilState(inputAutoFocusAtom);
  const { showAlert, showErrorDialog } = usePopup();
  const [successScannedBarcode, setSuccessScannedBarcode] = useState(false);
  const printer = useRecoilValue(printerAtom);
  const [isPrinting, setIsPrinting] = useRecoilState(isPrintingAtom);
  const prevInspection = useRecoilValue(prevInspectionAtom);

  useEffect(() => {
    setInputAutoFocus(false);
  });

  const handleScanBarcode = async () => {
    if (!scannedBarcode) {
      showAlert({ message: '바코드번호를 입력해 주세요.' });
      return;
    }

    if (!isBarcodeValid()) {
      return;
    }

    if (koreaFilter(scannedBarcode, showAlert)) {
      setScannedBarcode('');
      return;
    }

    setScannedBarcode(scannedBarcode);
    datadogRum.addAction(`Scanned Tote Barcode: ${scannedBarcode}`, { scannedBarcode });
    const response = await handleFetchCombineTotes({ toteBarcode, newToteBarcode: scannedBarcode });
    if (response?.status !== 200) {
      showAlert({ message: response?.data?.errorMessage as string });
    } else {
      setSuccessScannedBarcode(true);
      const response = await getInspectionImage(prevInspection.inspectionId);
      if (response?.status === 200) {
        printImage(response.data?.base64Image);
      } else {
        showErrorDialog({
          title: '이미지 요청 실패',
          errorMessage: response?.data?.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    }
  };

  const printImage = async (image: string) => {
    if (isZpl(image)) {
      printer.send(
        `${image}`,
        () => setIsPrinting(false),
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
          setIsPrinting(false);
        }
      );
    } else {
      printer.convertAndSendFile(
        `data:image/${WAYBILL_FORMAT};base64,${image}`,
        () => setIsPrinting(false),
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
          setIsPrinting(false);
        }
      );
    }
  };

  return (
    <Box width={modalWidth}>
      <Box>
        {!successScannedBarcode ? (
          <Box sx={styles.information}>
            <Typography sx={styles.informationText}>{messageFirstLine}</Typography>
            <Typography sx={styles.informationText}>
              <span style={{ fontWeight: 'bold' }}>토트 1개로 합칠 수 있는 아이템 부피</span>인
              경우에만,&nbsp;<span style={{ fontWeight: 'bold' }}>옮겨 담을 토트 바코드</span>를
              스캔해 주세요.
            </Typography>
          </Box>
        ) : (
          <Box sx={styles.information}>
            <Typography sx={styles.informationText}>{messageBarcodeScanned}</Typography>
          </Box>
        )}
        <Box sx={styles.toteScanContainer}>
          {!successScannedBarcode ? (
            <BarcodeInputByProps
              value={scannedBarcode}
              onSubmit={handleScanBarcode}
              onChange={e => setScannedBarcode(e.target.value)}
              label="토트"
              isLoading={isLoading}
              isFocus={!inputAutoFocus}
            />
          ) : (
            <BarcodeByProps value={scannedBarcode} />
          )}
        </Box>
        {!successScannedBarcode && (
          <Box sx={styles.buttonContainer}>
            <Button sx={styles.button} onClick={onClose} variant="outlined">
              닫기
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TotesModal;

const styles = {
  toteScanContainer: {
    mt: 3,
    mb: 3,
  },
  faultyInfoContainer: { border: `1px solid ${COLORS.lightLine}`, my: 2 },
  faultyRadioContainer: { width: '640px' },
  faultyRadio: { display: 'flex', flexDirection: 'row' },
  faultyRemarkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '640px',
  },
  faultyRemark: { my: 1, width: '620px' },
  listTable: { maxHeight: '400px', mb: 3 },
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '17px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
};
