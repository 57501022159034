import TightDataGridPro, { TightDataGridProps } from './TightDataGridPro';

const TightDataGridSum = (props: TightDataGridProps) => {
  return (
    <TightDataGridPro
      pageSize={props.rows.length}
      isRowSelectable={params => params.row.rowIndex !== 'total'}
      isCellEditable={params => params.row.rowIndex !== 'total'}
      getRowClassName={params => (params.row.rowIndex === 'total' ? 'total-row' : '')}
      {...props}
    />
  );
};

export default TightDataGridSum;
