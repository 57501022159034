import { Helmet, HelmetProvider } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Filter = () => {
  return (
    <Box
      sx={{
        bgcolor: 'darkgrey',
        height: 300,
        width: '100%',
        p: 2,
      }}
    >
      Filter
    </Box>
  );
};

const List = () => {
  return <Box sx={{ bgcolor: 'lightcoral', height: 1000, width: '100%', p: 2 }}>List</Box>;
};

const AggregatedSales = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>매출 집계</title>
        </Helmet>
      </HelmetProvider>
      <Box
        component="main"
        sx={{
          minHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          mt: '64px',
          px: 2,
          py: 2,
        }}
      >
        <Typography variant="h5">매출집계 조회</Typography>
        <Filter />
        <List />
      </Box>
      {/* <footer className={styles.footer}>KTOWN4U</footer> */}
    </>
  );
};

export default AggregatedSales;
