import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { inputAutoFocusAtom } from 'store';
import Button from '@mui/material/Button';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  'data-testid'?: string;
};

const VisibleInput = (props: Props) => {
  const { onChange, onKeyUp, onKeyDown, value, 'data-testid': dataTestId } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const autoFocus = useRecoilValue(inputAutoFocusAtom);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginBottom: '10px' }}
    >
      <span style={{ marginRight: '10px' }}>아이템 바코드</span>
      <input
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        autoFocus
        onBlur={e => {
          autoFocus && e.target.focus();
        }}
        ref={inputRef}
        value={value}
        data-testid={dataTestId}
        style={{
          height: '30px',
          width: '200px',
          display: 'flex',
        }}
      />
      <Button
        variant={'outlined'}
        sx={{ backgroundColor: 'white', marginLeft: '10px', height: '32px' }}
        onClick={() => {
          if (onKeyUp) {
            onKeyUp({
              key: 'Enter',
              currentTarget: inputRef.current,
            } as any as React.KeyboardEvent<HTMLInputElement>);
          }
        }}
      >
        확인
      </Button>
    </div>
  );
};

export default VisibleInput;
