import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { COLORS } from 'consts/common/colors';
import { SxProps } from '@mui/material/styles';

type TableProps = {
  children: ReactNode;
};

const Table = (props: TableProps) => {
  const { children } = props;

  return (
    <Stack
      sx={{
        margin: '10px 0',
        borderRadius: '4px',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
      }}
    >
      {children}
    </Stack>
  );
};

export default Table;

type TableRowProps = {
  children: ReactNode;
  sx?: SxProps;
};

export const TableRow = (props: TableRowProps) => {
  const { children, sx } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
      }}
    >
      {children}
    </Box>
  );
};

type TableCellProps = {
  sx?: SxProps;
  label?: ReactNode;
  labelSx?: SxProps;
  valueSx?: SxProps;
  valueProps?: BoxProps;
  required?: boolean;
  children: ReactNode;
};

export const TableCell = (props: TableCellProps) => {
  const { sx, label, labelSx, valueSx, valueProps, required = false, children } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        color: 'common.black',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '160px',
          height: 'calc(100% - 1px)',
          padding: '0 16px',
          fontWeight: 400,
          backgroundColor: COLORS.bgDeep,
          borderBottom: '1px solid #E0E0E0',
          ...labelSx,
        }}
      >
        {label}
        {required && <span style={{ color: COLORS.red, marginLeft: '2px' }}>*</span>}
      </Box>
      <Box
        sx={{
          padding: '0 16px',
          fontWeight: 700,
          ...valueSx,
        }}
        {...valueProps}
      >
        {children}
      </Box>
    </Box>
  );
};
