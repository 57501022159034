import type { ReactNode } from 'react';
import React from 'react';
import { styled, SxProps } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { COLORS } from 'consts/common/colors';

const StyledTableCell = styled(TableCell)(props => {
  return {
    padding: '0 16px',
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 700,
      backgroundColor: COLORS.bgDeep,
      color: COLORS.veryDarkGrey,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    [`&.${tableCellClasses.footer}`]: {
      borderTop: `1px solid ${COLORS.ktown4u}`,
      fontSize: 14,
      fontWeight: 700,
      color: COLORS.ktown4u,
    },
    width: props.width,
    maxWidth: props.width,
    height: props.height,
    wordBreak: 'break-all',
  };
});

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
  },
}));

export type Value = string | number | boolean | undefined | null;
type BaseRow = Record<string, Value>;
export type Row = BaseRow & { id: string | number };
export type Column = {
  headerName: string;
  width?: string | number;
  field: keyof BaseRow;
  footerText?: string | number;
  renderCell?: (arg: Value) => ReactNode;
  valueFormatter?: (arg: Value) => ReactNode;
};

type Props = {
  rows: Row[];
  columns: Column[];
  rowHeight?: number | string;
  align?: 'center' | 'left' | 'right';
  total?: number;
  isQuantitiesFooter?: boolean;
  hideFooter?: boolean;
  onClickRow?: (rowItem: Row) => void;
  onClickCell?: (row: Row, cell: string) => void;
  isLastCellNumber?: number;
  isLabelCellNumber?: number;
  sx?: SxProps;
};

const ListTable = (props: Props) => {
  const {
    rows,
    columns,
    rowHeight = '36px',
    align = 'center',
    total,
    isQuantitiesFooter = false,
    hideFooter = false,
    onClickRow,
    onClickCell,
    isLastCellNumber,
    isLabelCellNumber,
    sx,
  } = props;

  const finalIsLastCellNumber = isLastCellNumber === undefined ? 1 : isLastCellNumber;
  const finalIsLabelCellNumber = isLabelCellNumber === undefined ? 2 : isLabelCellNumber;

  return (
    <TableContainer
      sx={{
        background: 'white',
        borderRadius: '4px',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        ...sx,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(({ headerName, width = '56px' }) => (
              <StyledTableCell key={headerName} align={align} width={width} height={rowHeight}>
                {headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map(row => (
            <StyledTableRow
              key={row.id}
              onClick={() => (onClickRow ? onClickRow(row) : null)}
              sx={{ cursor: onClickRow && 'pointer' }}
            >
              {columns.map(({ field, width }, i) => (
                <StyledTableCell
                  key={`${row.id}-${i}`}
                  align={align}
                  width={width}
                  height={rowHeight}
                  onClick={() => (onClickCell ? onClickCell(row, field) : null)}
                  sx={{
                    cursor: onClickCell && 'pointer',
                    backgroundColor:
                      !isQuantitiesFooter || row.inspectedQuantity === 0
                        ? 'rgba(255, 255, 255, 0.002)'
                        : row.inspectedQuantity === row.orderQuantity
                        ? '#F3F3F7'
                        : 'rgba(222, 226, 0, 0.15)',
                    color:
                      !isQuantitiesFooter || row.inspectedQuantity === 0
                        ? '#222832'
                        : row.inspectedQuantity === row.orderQuantity
                        ? '#C4CAD5'
                        : '#E91F27',
                  }}
                >
                  {columns[i].renderCell?.(row[field]) ??
                    columns[i].valueFormatter?.(row[field]) ??
                    row[field]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>

        {hideFooter ? null : (
          <TableFooter>
            <TableRow>
              {isQuantitiesFooter
                ? columns.map((column, i) => {
                    return (
                      <StyledTableCell
                        key={`footer-${i}`}
                        align={align}
                        width={column.width}
                        height={rowHeight}
                      >
                        {column.footerText}
                      </StyledTableCell>
                    );
                  })
                : columns.map(({ width }, i) => {
                    const isLastCell = i === columns.length - finalIsLastCellNumber;
                    const cellContent = isLastCell ? total : '';
                    const isLabelCell = i === columns.length - finalIsLabelCellNumber;
                    const cellText = isLabelCell ? '수량합계' : cellContent;

                    return (
                      <StyledTableCell
                        key={`footer-${i}`}
                        align={align}
                        width={width}
                        height={rowHeight}
                      >
                        {cellText}
                      </StyledTableCell>
                    );
                  })}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default ListTable;
