import { createContext, ReactNode } from 'react';
import MUITimeline from '@mui/lab/Timeline';
import { SxProps } from '@mui/material/styles';

type Props = {
  children: ReactNode;
  sx?: SxProps;
  currentStep: number;
};

export const TimelineStepContext = createContext<number | null>(null);

const Timeline = (props: Props) => {
  const { children, sx, currentStep } = props;

  return (
    <MUITimeline sx={sx} nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
      <TimelineStepContext.Provider value={currentStep}>{children}</TimelineStepContext.Provider>
    </MUITimeline>
  );
};

export default Timeline;
