import React, { ChangeEvent, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useFilter from './hooks/useFilter';
import GridSection from '../GridSection';
import { inputTheme } from 'styles/customedMuiTheme';
import type { Form } from './index';

type Props = {
  label: string;
  field: string;
  labelGridColumn: `${number}/${number}`;
  inputGridColumn: `${number}/${number}`;
  gridRow?: number;
  onSubmit?: (form: Form) => void;
  defaultValue?: string;
};

const Search = (props: Props) => {
  const { label, field, labelGridColumn, inputGridColumn, gridRow, onSubmit, defaultValue } = props;
  const { form, setForm } = useFilter();

  useEffect(() => {
    if (defaultValue) {
      setForm(form => ({ ...form, [field]: defaultValue }));
    }
  }, [defaultValue, field, setForm]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(form => ({ ...form, [field]: e.target.value }));
  };

  return (
    <>
      <GridSection sx={{ gridRow, gridColumn: labelGridColumn }} isLabel>
        <Typography>{label}</Typography>
      </GridSection>
      <GridSection sx={{ gridRow, gridColumn: inputGridColumn }}>
        <TextField
          id="search-by-keyword"
          variant="outlined"
          size="small"
          value={form[field] ?? ''}
          onChange={handleInputChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSubmit && onSubmit(form);
            }
          }}
          fullWidth
          sx={inputTheme.medium}
        />
      </GridSection>
    </>
  );
};

export default Search;
