import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import JsCookies from 'js-cookie';
import Cookies from 'js-cookie';
import { REACT_APP_API_ENDPOINT_V2 } from 'consts/common/environment';

const token = Cookies.get('accessToken') ?? '';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export const axiosV2 = axios.create({
  baseURL: REACT_APP_API_ENDPOINT_V2,
});

const getRequestConfig = (config: AxiosRequestConfig) => {
  const token = Cookies.get('accessToken');
  const fingerprint = Cookies.get('fingerprint');
  config.headers = config.headers ?? {};
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers.DeviceRegistrationNumber = fingerprint as string;
  return config;
};

const responseConfig = (response: AxiosResponse) => {
  return response;
};

axiosV2.interceptors.request.use(getRequestConfig);
axiosV2.interceptors.response.use(responseConfig, error => {
  if (error.response.status === 401) {
    JsCookies.remove('accessToken');
  }
  return Promise.reject(error);
});

const fetcher = async (path: string, params?: Record<string, string>) => {
  try {
    const res = await axiosV2.get(path, { params });

    if (res.status === 204) {
      return;
    }

    return res.data;
  } catch (error) {
    const err = error as AxiosError<Record<string, unknown>>;

    // 아래 if문은 배열 및 객체로 중첩된 v1 에러 메세지를 string으로 전달하기 위함임
    if (err.response?.data?.errors) {
      const message = Object.entries(err.response.data.errors as Record<string, string>[])?.reduce(
        (message, [field, error]) => message + `${field}: ${error}\n`,
        ''
      );

      throw new Error(message);
    }

    throw err;
  }
};

export default fetcher;
