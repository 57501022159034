import { ReactNode } from 'react';
import MUITimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CheckIcon from '@mui/icons-material/Check';
import { COLORS } from 'consts/common/colors';
import useTimelineCurrentStep from '../hooks/useTimelineCurrentStep';

type Props = {
  step: number;
  isLast?: boolean;
  beforeProgressComponent?: ReactNode;
  inProgressComponent: ReactNode;
  afterProgressComponent: ReactNode;
};

const TimelineItem = (props: Props) => {
  const currentStep = useTimelineCurrentStep();
  const { beforeProgressComponent, afterProgressComponent, inProgressComponent, step, isLast } =
    props;

  const isBeforeProgress = currentStep < step;
  const isInProgress = currentStep === step;
  const isAfterProgress = currentStep > step;
  const notInProgressStyle = isAfterProgress ? afterProgressStyle : beforeProgressStyle;
  const style = isInProgress ? inProgressStyle : notInProgressStyle;

  return (
    <MUITimelineItem
      sx={{
        minHeight: '80px',
        '&::before': {
          content: '""',
          flex: 0,
        },
      }}
    >
      <TimelineSeparator sx={{ marginRight: '16px' }}>
        <TimelineDot sx={style.dot}>
          {isAfterProgress ? <CheckIcon fontSize="large" /> : step + 1}
        </TimelineDot>
        {!isLast && <TimelineConnector sx={style.line} />}
      </TimelineSeparator>
      <TimelineContent>
        {isBeforeProgress && beforeProgressComponent}
        {isInProgress && inProgressComponent}
        {isAfterProgress && afterProgressComponent}
      </TimelineContent>
    </MUITimelineItem>
  );
};

const middleDotSize = '44px';
const bigDotSize = '60px';

const inProgressStyle = {
  dot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    margin: '11.5px 0',
    fontSize: '24px',
    fontWeight: 700,
    backgroundColor: COLORS.ktown4u,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: bigDotSize,
      height: bigDotSize,
      borderRadius: '50%',
      border: `3px solid ${COLORS.ktown4u}`,
    },
  },
  line: {
    width: '3px',
    backgroundColor: COLORS.lightLine,
  },
};

const afterProgressStyle = {
  dot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: middleDotSize,
    height: middleDotSize,
    margin: '6px 0',
    backgroundColor: COLORS.ktown4u,
  },
  line: {
    width: '3px',
    backgroundColor: COLORS.ktown4u,
  },
};

const beforeProgressStyle = {
  dot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: middleDotSize,
    height: middleDotSize,
    margin: '6px 0',
    border: `3px solid ${COLORS.lightLine}`,
    backgroundColor: 'white',
    color: COLORS.darkLine,
    fontSize: '20px',
    fontWeight: 700,
  },
  line: {
    width: '3px',
    backgroundColor: COLORS.lightLine,
  },
};

export default TimelineItem;
