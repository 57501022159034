import React from 'react';
import styles from 'styles/LoadingIcon.module.css';

const LoadingIcon = () => {
  return (
    <div className={styles.cube}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingIcon;
