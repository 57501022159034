import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type Props = {
  onCloseClick: () => void;
  onSaveClick: () => void;
  isLoading: boolean;
};

const ButtonGroup = (props: Props) => {
  const { onCloseClick, onSaveClick, isLoading } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '32px',
        marginTop: 2,
      }}
    >
      <Button
        variant="outlined"
        sx={{ height: '60px', width: '130px', fontSize: '20px' }}
        onClick={onCloseClick}
      >
        닫기
      </Button>
      <LoadingButton
        variant="contained"
        onClick={onSaveClick}
        loading={isLoading}
        sx={{ height: '60px', width: '130px', fontSize: '20px' }}
      >
        저장
      </LoadingButton>
    </Box>
  );
};

export default ButtonGroup;
