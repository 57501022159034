import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import useFilter from './hooks/useFilter';
import { buttonTheme } from 'styles/customedMuiTheme';
import type { Form } from './index';

type Props = {
  onClick: (form: Form) => void;
  children: ReactNode;
};

const SubmitButton = (props: Props) => {
  const { onClick, children } = props;
  const { form } = useFilter();

  return (
    <Button variant="contained" onClick={() => onClick(form)} sx={buttonTheme.medium}>
      {children}
    </Button>
  );
};

export default SubmitButton;
