import { atom } from 'recoil';

export interface Stocktaking {
  id: number;
  warehouseId: number;
  serialNumber: string;
  name: string;
  status: string;
}

export type StocktakingOption = { displayName: string; value: number; id: number };
export const stocktakingOptionsAtom = atom<StocktakingOption[]>({
  key: 'stocktakingsAtom',
  default: [],
});

export const stocktakingOptionsWithOutAppliedAtom = atom<StocktakingOption[]>({
  key: 'stocktakingOptionsWithOutAppliedAtom',
  default: [],
});
