import { atom } from 'recoil';

export const barcodeAtom = atom<string>({
  key: 'barcodeAtom',
  default: '',
});

const barcodeAtoms = {
  barcode: barcodeAtom,
};

export default barcodeAtoms;
