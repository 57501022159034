const diagnosisGuides = {
  scaleConnect: {
    label: '저울 연결확인',
    messages: [
      {
        icon: '①',
        primary: '[저울 연결확인]버튼을 클릭하여 저울을 선택해 주세요.',
        secondary: [],
      },
      {
        icon: '②',
        primary: '저울 목록에 연결할 저울이 나타나지 않나요?',
        secondary: [
          '- 저울의 USB 를 뺐다가 연결해 주세요.',
          '- [저울 연결확인] 버튼을 다시 클릭해 주세요.',
        ],
      },
    ],
  },
  printerConnect: {
    label: '프린터 연결확인',
    messages: [{ icon: '', primary: '[프린터 연결확인] 버튼을 클릭해 주세요.', secondary: [] }],
  },
  retryPrinterConnect: {
    label: '새로고침',
    messages: [
      { icon: '', primary: '프린터 연결이 되지 않나요?', secondary: [] },
      {
        icon: '①',
        primary:
          '송장 프린터의 드라이버 매니저를 열어서\n프린터를 다시 설정한 후 [새로고침] 버튼을 클릭해 주세요',
        secondary: [],
      },
      {
        icon: '②',
        primary: '위의 방법으로 해결이 되지 않나요?',
        secondary: [
          '- 프린터 전원을 껐다가 다시 켜고',
          '- 프린터의 USB를 뺐다가 다시 연결한 후',
          '- ①을 다시 진행해 주세요.',
        ],
      },
    ],
  },
  firstDiagnosis: {
    label: '새로고침',
    messages: [
      { icon: '①', primary: '저울의 영점이 조정되었는지 확인해 주세요.', secondary: [] },
      { icon: '②', primary: '저울에 물건을 올려 주세요.', secondary: [] },
      {
        icon: '③',
        primary: '무게측정이 되지 않으면 [새로고침] 버튼을 클릭해 주세요.',
        secondary: [],
      },
    ],
  },
  secondDiagnosis: {
    label: '새로고침',
    messages: [
      { icon: '①', primary: '저울에서 물건을 내려 주세요.', secondary: [] },
      { icon: '②', primary: '저울에 다시 물건을 올려 주세요.', secondary: [] },
      {
        icon: '③',
        primary: '무게측정이 되지 않으면 [새로고침] 버튼을 클릭해 주세요.',
        secondary: [],
      },
    ],
  },
  retryDiagnosis: {
    label: '새로고침',
    messages: [
      { icon: '', primary: '프린터 출력이 되지 않나요?', secondary: [] },
      {
        icon: '①',
        primary: `송장 프린터의 드라이버 매니저를 열어서\n 프린터를 다시 설정한 후 [새로고침] 버튼을 클릭해 주세요`,
        secondary: [],
      },
      {
        icon: '②',
        primary: '위의 방법으로 해결이 되지 않나요?',
        secondary: [
          '- 프린터 전원을 껐다가 다시 켜고',
          '- 프린터의 USB를 뺐다가 다시 연결한 후',
          '- ①을 다시 진행해 주세요.',
        ],
      },
    ],
  },
};

export default diagnosisGuides;
