import React, { forwardRef, useImperativeHandle } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import type { SelectChangeEvent } from '@mui/material';

import { listItemTextTheme, selectTheme } from 'styles/customedMuiTheme';
import useFilter from '../hooks/useFilter';
import type { Options } from 'types/form';

type InnerSelectProps = {
  id: string;
  options: Options;
  fromEdge: string;
  toEdge: string;
};

const TypeSelect = forwardRef((props: InnerSelectProps, ref) => {
  const { id, options, fromEdge, toEdge } = props;
  const { form, setForm } = useFilter();

  useImperativeHandle(ref, () => ({ handleSelectChange }));

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    delete form[`${form.dateType + fromEdge}`];
    delete form[`${form.dateType + toEdge}`];

    setForm(form => ({ ...form, dateType: e.target.value }));
  };

  return (
    <Select
      id={id}
      value={(form.dateType as string) || options[0].field}
      sx={selectTheme.medium}
      onChange={handleSelectChange}
      fullWidth
    >
      {options.map(({ field, displayName }) => (
        <MenuItem key={field} value={field}>
          <ListItemText sx={styles.listItemText} primary={displayName} />
        </MenuItem>
      ))}
    </Select>
  );
});

export default TypeSelect;

const styles = {
  listItemText: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    ...listItemTextTheme.medium,
  },
};
