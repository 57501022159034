import { useRecoilValue } from 'recoil';
import type { GridColDef } from '@mui/x-data-grid-pro';
import CONVEYOR_PATH from '../../../../consts/info/conveyorPath';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import { Warehouse, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { numberFormatter } from '../../../../libs/common/unitConverters';

const gridColumns = () => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const conveyorPathOptions = refCodeOptions?.conveyorPath || [];

  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: params => {
        const warehouse = warehouseOption.find(
          (warehouse: Warehouse) => warehouse.value === params.value
        );
        return warehouse?.displayName;
      },
    },
    {
      field: 'shippingStatus',
      headerName: '출고상태',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: params => {
        const shippingStatus = shippingStatusOptions.find(status => status.value === params.value);
        return shippingStatus?.displayName;
      },
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 230,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pathNumber',
      headerName: '포장경로',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => {
        return CONVEYOR_PATH[value as keyof typeof CONVEYOR_PATH];
      },
    },
    {
      field: 'conveyorName',
      headerName: '작업공간',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => {
        return conveyorPathOptions?.find(v => v.value === value)?.displayName;
      },
    },
    {
      field: 'sku',
      headerName: '품목수량',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalQty',
      headerName: '아이템수량',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalWeight',
      headerName: '실제중량무게(g)',
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'volumeWeight',
      headerName: '실제부피무게(g)',
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'width',
      headerName: '가로(mm)',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'length',
      headerName: '세로(mm)',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'height',
      headerName: '높이(mm)',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: params =>
        carrierOptions.find(carrier => carrier.value === params.value)?.displayName,
    },
    {
      field: 'countOfTube',
      headerName: '지관통',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: params => params.value,
    },
    {
      field: 'pickingGroupSerialNumber',
      headerName: '집품그룹ID',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'shippingOrderSerialNumber',
      headerName: '출고지시ID',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'updatedUser',
      headerName: '변경자',
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      valueGetter: params => {
        return `${params.row.updatedUsername} (${params.row.updatedUserLoginId})`;
      },
    },
    {
      field: 'updatedAt',
      headerName: '변경일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
    },
  ] as GridColDef[];
};
export default gridColumns;
