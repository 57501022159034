import React, { ChangeEvent, useState } from 'react';
import { KeyedMutator } from 'swr';
import Box from '@mui/system/Box';
import usePopup from 'hooks/usePopup';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import { TableCell, TableRow } from '../../../components/Table';
import { PickingGroup } from '../index.page';
import _ from 'lodash';
import { COLORS } from 'consts/common/colors';
import TextField from '@mui/material/TextField';
import { fetchPickingGroupOfDonation } from '../services';

const modalWidth = 750;
type RequestQuery = {
  warehouseId: number | string;
};

type PickingGroupFormProps = {
  request: RequestQuery;
  mutate: KeyedMutator<PickingGroup>;
  onClose: () => void;
  keyIds?: number[];
};

const RequestBodyForCreateKeys = [
  'warehouseId',
  'shippingBasedAtFrom',
  'shippingBasedAtTo',
  'shippingOrderCreatedAtFrom',
  'shippingOrderCreatedAtTo',
  'keyIds',
  'reason',
] as const;

export const makeRequestBodyForCreate = (request: RequestQuery) => {
  const requestBody = {
    ...request,
  };

  return _.pick(requestBody, RequestBodyForCreateKeys);
};

const PickingGroupForm = (props: PickingGroupFormProps) => {
  const { showSnackbar, showErrorDialog } = usePopup();
  const { onClose, mutate, request, keyIds } = props;
  const [countRequest, setCountRequest] = useState({
    ...request,
    reason: '',
    keyIds,
  });
  const requestBody = makeRequestBodyForCreate(countRequest);
  const [isLoading, setIsLoading] = useState(false);
  const handleCreate = async () => {
    setIsLoading(true);
    console.log(requestBody);
    const response = await fetchPickingGroupOfDonation(requestBody);
    if (response?.status === 200) {
      showSnackbar({
        message: '집품그룹이 생성 완료되었습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: '집품그룹 생성 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
    await mutate();
    onClose();
  };

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCountRequest({
      ...countRequest,
      'reason': event.target.value,
    });
  };

  return (
    <Box width={modalWidth}>
      <Box sx={styles.container}>
        <TableRow>
          <TableCell
            label={'비고'}
            value={
              <TextField
                variant="outlined"
                size="small"
                style={{ width: '550px' }}
                onChange={handleReasonChange}
              />
            }
          />
        </TableRow>
      </Box>
      <SaveButtonGroup
        onSaveClick={handleCreate}
        onCloseClick={onClose}
        isLoading={isLoading}
        saveButtonName={'집품그룹 생성'}
      />
    </Box>
  );
};

export default PickingGroupForm;

const styles = {
  container: {
    border: `1px solid ${COLORS.darkLine}`,
  },
  select: {
    height: '32px',
  },
};
