import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { Options } from 'types/form';
import { isZpl } from '../../../../libs/common/isZpl';
import TopBoard from '../../components/TopBoard';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import gridColumns from './gridColDef';
import convertUser from '../../../../libs/common/convertUser';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import LoadingButton from '@mui/lab/LoadingButton';
import { GridRowParams } from '@mui/x-data-grid-pro';
import usePopup from '../../../../hooks/usePopup';
import { printerAtom } from '../../../../store/common/printer.recoil';
import { WAYBILL_FORMAT } from '../../../../consts/outbound/waybillFormat';
import getInspectionImage from '../inspection/components/getInspectionImage';
import useConditionalSWR from '../../components/useConditionalSwr';
import { generateExcelDownload } from './service/excelDownload';

const dateRangeTypeOptions: Options = [
  {
    displayName: '검수완료일',
    field: 'inspectionCompletedAt',
  },
  {
    displayName: '집품완료일',
    field: 'pickingCompletedAt',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '배송ID',
    field: 'deliverySeq',
  },
  {
    displayName: '배송번호',
    field: 'deliveryId',
  },
  {
    displayName: '출고ID',
    field: 'shippingSerialNumber',
  },
  {
    displayName: '토트',
    field: 'toteBarcode',
  },
  {
    displayName: '작업대',
    field: 'workStand',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

type Inspections = {
  id: number;
  status: string;
  historyType: string;
  inspectionCompletedAt: string;
  pickingCompletedAt: string;
  toteBarcodes: string;
  shippingSerialNumber: string;
  deliveryId: string;
  deliverySeq: string;
  carrierId: number;
  totalQuantity: number;
  weight: number;
  volumeWeight: number;
  createdAt: string;
  updatedUserName: string;
  updatedUserLoginId: string;
  updatedAt: string;
};

type InspectionResponse = {
  content: Inspections[];
  totalElements: number;
  size: number;
  number: number;
};

type InspectionStatusCount = {
  status: string;
  count: number;
};

export type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

export type inspectionData = {
  inspectionId?: number;
  deliveryId?: number;
};

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 1.9fr)',
  'minmax(55px, 1.2fr)',
  '72px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Inspections = () => {
  const { showDialog, showErrorDialog, showSnackbar } = usePopup();
  const printer = useRecoilValue(printerAtom);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const [selectedInspectionPrint, setSelectedInspectionPrint] = useState<number[]>();
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const multipleCarrierSelectRef = useRef<{ initialValues: () => void }>(null);

  const { data: inspectionStatusCounts } = useConditionalSWR<InspectionStatusCount[]>(
    [`/inspections/status/counts`, { warehouseId: globalWarehouse }],
    fetcher,
    hasSearched
  );
  const { data, mutate, isValidating } = useConditionalSWR<InspectionResponse>(
    [`/inspections`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );
  const statusOptions = refCodeOptions?.inspectionStatus || [];
  const historyOptions = refCodeOptions?.inspectionHistoryType || [];

  const topBoard = [
    {
      title: '검수대기',
      value: 0,
    },
    {
      title: '검수중',
      value: 0,
    },
    {
      title: '검수오류',
      value: 0,
    },
  ];
  inspectionStatusCounts?.forEach((item: InspectionStatusCount) => {
    if (item.status === 'OPEN') {
      topBoard[0].value = item.count;
    } else if (item.status === 'PROCESSING') {
      topBoard[1].value = item.count;
    } else if (item.status === 'ERROR') {
      topBoard[2].value = item.count;
    }
  });

  const makeRowIndex = (data: InspectionResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Inspections, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser:
        item.status != 'OPEN' ? convertUser(item.updatedUserName, item.updatedUserLoginId) : '',
      updatedAt: item.status != 'OPEN' ? item.updatedAt : '',
    }));
  };

  const handleInitClick = async () => {
    multipleCarrierSelectRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const printImage = async (image: string) => {
    if (isZpl(image)) {
      printer.send(
        `${image}`,
        () => {
          showSnackbar({ message: '배송번호 출력이 완료되었습니다.', severity: 'success' });
        },
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
        }
      );
    } else {
      printer.convertAndSendFile(
        `data:image/${WAYBILL_FORMAT};base64,${image}`,
        () => {
          showSnackbar({ message: '배송번호 출력이 완료되었습니다.', severity: 'success' });
        },
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
        }
      );
    }
  };

  const requestPrint = async () => {
    for (const inspectionId of selectedInspectionPrint ?? []) {
      const response = await getInspectionImage(inspectionId);
      if (response?.status === 200) {
        printImage(response.data?.base64Image);
        setSelectedInspectionPrint([]);
        setIsPrintLoading(false);
      } else {
        showErrorDialog({
          title: '이미지 요청 실패',
          errorMessage: response?.data?.errorMessage,
          buttons: [{ text: '확인' }],
        });
        setIsPrintLoading(false);
      }
    }
  };

  const handleReprint = async () => {
    const length = selectedInspectionPrint?.length;
    if (length === 0 || length === undefined) {
      showDialog({
        message: '배송번호 출력할 출고건을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else {
      setIsPrintLoading(true);
      requestPrint();
    }
  };

  function handleSelectedInspections(ids: number[]) {
    setSelectedInspectionPrint(ids);
  }

  const handleExcelDownload = async () => {
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        개별/1주문다/대체출고 집품검수현황 조회
      </Typography>
      <TopBoard data={topBoard} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="검수상태"
          field="status"
          options={[defaultOption, ...statusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Select
          label="검수오류내역"
          field="historyType"
          options={[defaultOption, ...historyOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleCarrierSelectRef}
          label="배송방식"
          field="carrierIds"
          options={[defaultOption, ...carrierOptions]}
          labelGridColumn="10/12"
          selectGridColumn="12/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            검수 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="contained"
              style={{ marginRight: '10px' }}
              onClick={handleExcelDownload}
              loading={isExcelDownload}
            >
              엑셀다운로드
            </LoadingButton>
            <LoadingButton variant="contained" onClick={handleReprint} loading={isPrintLoading}>
              배송번호 출력
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched ? (data?.content ? makeRowIndex(data) : []) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedInspectionPrint}
          onSelectionModelChange={ids => handleSelectedInspections(ids as number[])}
          isRowSelectable={(params: GridRowParams<Inspections>) =>
            params.row.status === 'COMPLETED'
          }
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default Inspections;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
