import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import { packingAtom, stepAtom } from 'store/outbound/packing.recoil';
import { beforeProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import usePopup from 'hooks/usePopup';
import Table, { TableRow, TableCell } from '../../../components/Table';
import BarcodeInput from '../../components/BarcodeInput';
import TimelineItem from '../../components/TimelineItem';
import { datadogRum } from '@datadog/browser-rum';
import useBarcode from '../../hooks/useBarcode';
import { changeTote, changeToteRequest1 } from '../services/packing';

export const BOX_STEP = 1;

const ChangeToteLine = () => {
  const { barcode, setBarcode, isBarcodeValid } = useBarcode();
  const [packing] = useRecoilState(packingAtom);
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog } = usePopup();

  useEffect(() => {
    const firstStep = 0;
  }, [selectedStep]);

  const handleDeliveryIdSubmit = async () => {
    if (!isBarcodeValid()) {
      return;
    }
    const regex = /TOTE-07/;
    const isVirtualTote = regex.test(barcode);
    if (isVirtualTote) {
      showErrorDialog({
        title: '토트 이관 실패',
        errorMessage: '가상 토트로 이관할 수 없습니다.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    datadogRum.addAction(`Scanned Tote Barcode: ${barcode}`, { barcode });
    setIsLoading(true);

    const request: changeToteRequest1 = {
      fromToteBarcode: packing?.toteBarcode,
      toToteBarcode: barcode,
    };
    const response = await changeTote(request);
    if (response?.status === 200) {
      setSelectedStep(selectedStep + 1);
      setIsLoading(false);
    } else {
      showErrorDialog({
        title: '토트 이관 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      setIsLoading(false);
    }
  };
  return (
    <TimelineItem
      isLast={true}
      step={BOX_STEP}
      beforeProgressComponent={
        <Typography sx={beforeProgressTitle}>이관후 토트의 바코드를 스캔해 주세요.</Typography>
      }
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle}>이관후 토트의 바코드를 스캔해 주세요.</Typography>
          <BarcodeInput
            value={barcode}
            onSubmit={handleDeliveryIdSubmit}
            onChange={e => setBarcode(e.target.value)}
            label="토트"
            isLoading={isLoading}
          />
        </>
      }
      afterProgressComponent={
        <>
          <Typography sx={inProgressTitle}>이관후 토트의 바코드를 스캔해 주세요.</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles.tableCell} label="토트" value={barcode} />
            </TableRow>
          </Table>
        </>
      }
    />
  );
};

export default ChangeToteLine;

const styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  barcodeButtonContainer: { display: 'flex', alignItems: 'center', gap: '8px' },
  button: { height: '40px', fontSize: '17px' },
  tableCell: { fontSize: '16px' },
};
