import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import useSWR from 'swr';
import { CustomOrders } from '../../consts/outbound/optionOrder';
import fetcher from '../../libs/common/fetcher';
import {
  Carrier,
  CarrierOption,
  carrierOptionsAtom,
  carriersAtom,
} from '../../store/outbound/carrier.recoil';
import {
  RefCode,
  RefCodeOptions,
  refCodeOptionsAtom,
  RefCodeOptionsByField,
} from '../../store/outbound/refCode.recoil';
import {
  allWarehouseOptionsAtom,
  warehouseOptionsAtom,
} from '../../store/outbound/warehouse.recoil';
import { PackingBox, packingBoxOptionsAtom } from '../../store/outbound/packings.recoil';
import {
  Stocktaking,
  StocktakingOption,
  stocktakingOptionsAtom,
  stocktakingOptionsWithOutAppliedAtom,
} from '../../store/outbound/stocktaking.recoil';
import { centerOptionsAtom } from '../../store/outbound/center.recoil';
import { suppliersOptionsAtom } from '../../store/outbound/suppliers.recoil';

type Warehouse = {
  id: number;
  code: string;
  fullCode: string;
  name: string;
  isReflectInventory: boolean;
};

type Suppliers = {
  id: number;
  name: string;
};

const createWarehouseFullCodeOptions = (warehouses: Warehouse[] | undefined) => {
  const warehousesCodes = warehouses ?? [];
  return [
    ...warehousesCodes?.map((v: Warehouse) => ({
      displayName: v.name,
      id: v.id,
      value: v.id,
      fullCode: v.fullCode,
      isReflectInventory: v.isReflectInventory,
    })),
  ];
};

const createSuppliersFullCodeOptions = (suppliers: Suppliers[] | undefined) => {
  const suppliersCodes = suppliers ?? [];
  return [
    ...suppliersCodes?.map((v: Suppliers) => ({
      displayName: v.name,
      id: v.id,
      value: v.id,
    })),
  ];
};

const createStocktakingOptions = (stocktakings: Stocktaking[] | undefined): StocktakingOption[] => {
  const stocktakingOptions = stocktakings ?? [];
  return stocktakingOptions
    .map((v: Stocktaking) => ({
      displayName: v.name,
      value: v.id,
      id: v.id,
    }))
    .sort((a: StocktakingOption, b: StocktakingOption) => b.id - a.id);
};

const createStocktakingOptionsWithOutApplied = (
  stocktakings: Stocktaking[] | undefined
): StocktakingOption[] => {
  const stocktakingOptions = stocktakings ?? [];
  return stocktakingOptions
    .filter((v: Stocktaking) => v.status !== 'APPLIED')
    .map((v: Stocktaking) => ({
      displayName: v.name,
      value: v.id,
      id: v.id,
    }))
    .sort((a: StocktakingOption, b: StocktakingOption) => b.id - a.id);
};

const createCarrierOptions = (carriers: any) => {
  const carrierOptions = carriers
    ? carriers
        ?.map((carrier: Carrier) => ({
          displayName: carrier.name,
          value: carrier.id,
          order: carrier.displayOrder,
        }))
        .sort((a: CarrierOption, b: CarrierOption) => a.order - b.order)
    : [];
  return { carrierOptions };
};

const createPackingBoxOptions = (packingBoxes: any) => {
  const packingBoxOptions = packingBoxes
    ? packingBoxes
        ?.map((packingBox: PackingBox) => ({
          displayName: packingBox.pGoodsName,
          value: packingBox.pGoodsName,
        }))
        .sort((a: CarrierOption, b: CarrierOption) => a.order - b.order)
    : [];
  return { packingBoxOptions: packingBoxOptions };
};

const createRefCodes = (refCodesData: RefCode[] | undefined) => {
  refCodesData = refCodesData ?? [];
  const refCodeOptions = refCodesData.reduce((acc: RefCodeOptionsByField, curr: RefCode) => {
    const { groupCode } = curr;
    const key: string = groupCode.charAt(0).toLowerCase() + groupCode.slice(1);
    const value: RefCodeOptions = {
      displayName: curr.value,
      value: curr.code,
    };
    acc[key] = (acc[key] || []).concat(value);
    return acc;
  }, {});
  Object.entries(refCodeOptions).forEach(([key, value]) => {
    if (key in CustomOrders && Array.isArray(CustomOrders[key])) {
      refCodeOptions[key] = value.sort(
        (a: RefCodeOptions, b: RefCodeOptions) =>
          CustomOrders[key].indexOf(a.value) - CustomOrders[key].indexOf(b.value)
      );
    } else {
      const sort = CustomOrders[key] ? CustomOrders[key] : 'asc';
      refCodeOptions[key] = _.orderBy(value, ['displayName'], [sort]);
    }
  });
  return refCodeOptions;
};

const InitGmsData = () => {
  const setWarehouses = useSetRecoilState(allWarehouseOptionsAtom);
  const setSuppliers = useSetRecoilState(suppliersOptionsAtom);
  const setCenterOptions = useSetRecoilState(centerOptionsAtom);
  const setStocktakings = useSetRecoilState(stocktakingOptionsAtom);
  const setCarriers = useSetRecoilState(carriersAtom);
  const setCarrierOptions = useSetRecoilState(carrierOptionsAtom);
  const setPackingBoxOptions = useSetRecoilState(packingBoxOptionsAtom);
  const setRefCodeOptions = useSetRecoilState(refCodeOptionsAtom);
  const setStocktakingsWithOutApplied = useSetRecoilState(stocktakingOptionsWithOutAppliedAtom);
  const { data: warehouses } = useSWR<Warehouse[]>([`/warehouses`, { sort: 'id,desc' }], fetcher);
  const { data: suppliers } = useSWR<Suppliers[]>([`/suppliers`, { sort: 'id,desc' }], fetcher);
  const { data: centerOptions } = useSWR([`/companies/centers`, { sort: 'id,desc' }], fetcher);
  const warehouseFullCodeOptions = createWarehouseFullCodeOptions(warehouses);
  const suppliersFullCodeOptions = createSuppliersFullCodeOptions(suppliers);
  const { data: stocktakings } = useSWR<[]>(
    [`/inventories/stocktakings`, { sort: 'id,desc' }],
    fetcher
  );
  const stocktakingOptions = createStocktakingOptions(stocktakings);
  const stocktakingOptionsWithOutApplied = createStocktakingOptionsWithOutApplied(stocktakings);
  const { data: carriers } = useSWR([`/carriers`, { sort: 'id,desc' }], fetcher);
  const { data: packingBoxes } = useSWR([`/packings/boxes`], fetcher);
  const { carrierOptions } = createCarrierOptions(carriers);
  const { packingBoxOptions } = createPackingBoxOptions(packingBoxes);
  const { data: refCodes } = useSWR<RefCode[]>(['/commons/ref-codes'], fetcher);
  const refCodeOptions = createRefCodes(refCodes);

  useEffect(() => {
    setWarehouses(warehouseFullCodeOptions);
    setSuppliers(suppliersFullCodeOptions);
    setCenterOptions(centerOptions);
    setStocktakings(stocktakingOptions);
    setStocktakingsWithOutApplied(stocktakingOptionsWithOutApplied);
    setCarriers(carriers);
    setCarrierOptions(carrierOptions);
    setPackingBoxOptions(packingBoxOptions);
    setRefCodeOptions(refCodeOptions);
  }, [
    warehouseFullCodeOptions,
    centerOptions,
    stocktakingOptions,
    carriers,
    carrierOptions,
    packingBoxOptions,
    refCodeOptions,
    suppliersFullCodeOptions,
  ]);

  return <></>;
};

export default InitGmsData;
