import { useSetRecoilState } from 'recoil';
import {
  boxCodeAtom,
  isFetchingPackingAtom,
  packingAtom,
  stepAtom,
  weightByScaleAtom,
} from 'store/outbound/packing.recoil';

const useInitialization = () => {
  const setStep = useSetRecoilState(stepAtom);
  const setBoxCode = useSetRecoilState(boxCodeAtom);
  const setWeightByScale = useSetRecoilState(weightByScaleAtom);
  const setIsFetchingPacking = useSetRecoilState(isFetchingPackingAtom);
  const setPacking = useSetRecoilState(packingAtom);

  return () => {
    setStep(0);
    setBoxCode('');
    setWeightByScale('');
    setIsFetchingPacking(false);
    setPacking(undefined);
  };
};

export default useInitialization;
