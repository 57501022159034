import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { REACT_APP_API_ENDPOINT_V2 } from 'consts/common/environment';
import { errorType } from 'types/common';
import { axiosV2 } from '../../../../../../libs/common/fetcher';

export type RequestBody = {
  fromWarehouseId: number;
  toWarehouseId: number;
  desiredDate: string;
  carrierId: number;
  note: string;
  itemRequests: { itemId: number; qty: number; supplyPrice: number }[];
};

export const generateTransferOut = async (requestBody: RequestBody) => {
  try {
    return await axiosV2.post(`${REACT_APP_API_ENDPOINT_V2}/inventory-transfers`, requestBody);
  } catch (err) {
    const error = err as AxiosError<errorType, string>;
    datadogRum.addAction(`대체출고 생성 실패 : ${error.response?.data}`);
    return error.response;
  }
};
