import React from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/common/colors';
import SaveButtonGroup from './SaveButtonGroup';
import Shippings from '../../../outbound/shippings/index.page';
import { deleteShipping } from '../services';
import usePopup from '../../../../../hooks/usePopup';

const modalWidth = 420;

type Props = {
  selectedRow: Shippings;
  onClose: () => void;
};

const ShippingCompletedModal = (props: Props) => {
  const { selectedRow, onClose } = props;
  const { showErrorDialog } = usePopup();

  const handleCreate = async () => {
    const response = await deleteShipping(selectedRow?.deliveryId);
    if (response?.status !== 200) {
      showErrorDialog({
        title: '출고 삭제 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
      return;
    }
    onClose();
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>
          배송번호: {selectedRow.deliveryId} 삭제하시겠습니까?
        </Typography>
        <Typography sx={styles.modalSubTitle1}>삭제한 이후 되돌릴 수 없습니다.</Typography>
        <SaveButtonGroup onSaveClick={handleCreate} onCloseClick={onClose} />
      </Box>
    </Box>
  );
};

export default ShippingCompletedModal;

const styles = {
  inputLabel: { color: COLORS.darkGrey },
  modalSubTitle: { fontSize: '22px', mb: 1.5 },
  modalSubTitle1: { fontSize: '20px', mb: 1.5, color: COLORS.red },
  container: {
    border: `1px solid ${COLORS.darkLine}`,
  },
  select: {
    height: '32px',
  },
};
