import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

export const assignSelectedPDA = async (
  pickingGroupIds: number[] | string[] | undefined | null,
  deviceIdToAssign: number | undefined | null
) => {
  try {
    return await axiosV2.post(`/picking-groups/devices/select-assign`, {
      pickingGroupIds,
      deviceIdToAssign,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`PDA 선택배정 등록 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
