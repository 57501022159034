export enum COLORS {
  ktown4uLight = '#37D2E2',
  ktown4u = '#0062CC',
  red = '#E91F27',
  veryDarkGrey = '#465060',
  darkGrey = '#657386',
  grey = '#738092',
  lightGrey = '#768395',
  veryLightGrey = '#9AA4B2',
  lightLine = '#DDDDE8',
  darkLine = '#C4CAD5',
  black = '#222832',
  faintBlack = '#22283290',
  bgDeep = '#F3F3FA',
  bgLight = '#F7F7FA',
  white = '#FFFFFF',
  hoveredRow = '#F5FAFD',
}
