import React, { Ref } from 'react';
import type { SxProps } from '@mui/material';
import InitButton from './InitButton';
import SubmitButton from './SubmitButton';
import GridSection from '../GridSection';
import type { Form } from './index';

type Props = {
  initRef?: Ref<unknown>;
  sx?: SxProps;
  gridColumn: `${number}/${number}`;
  gridRow?: number;
  onLookupClick: (form: Form) => void;
  onInitClick: () => void;
};

const DefaultButtonGroup = (props: Props) => {
  const { initRef, sx, gridColumn, gridRow, onLookupClick, onInitClick } = props;
  return (
    <GridSection
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
        gridColumn,
        gridRow,
        ...sx,
      }}
    >
      <InitButton ref={initRef} onClick={onInitClick}>
        초기화
      </InitButton>
      <SubmitButton onClick={onLookupClick}>조회</SubmitButton>
    </GridSection>
  );
};

export default DefaultButtonGroup;
