type Printer = {
  deviceType: string;
  uid: string;
  provider: string;
  name: string;
  connection: string;
  version: number;
  manufacturer: string;
};

const checkPrinterConnection = async () => {
  const response = await fetch('http://127.0.0.1:9100/available');

  if (!response.ok) {
    return false;
  }

  const result = await response.json();

  return !!result.printer?.some((printer: Printer) => printer.name !== '');
};

export default checkPrinterConnection;
