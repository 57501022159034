import type { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { Devices } from './index.page';

const gridColumns = (content: Devices[] | undefined) => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const deviceWorkTypeOptions = refCodeOptions?.workType || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      flex: 2,
      minWidth: 40,
      field: 'warehouseId',
      headerName: '창고',
      headerAlign: 'center',
      editable: false,
      align: 'center',
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      flex: 2,
      field: 'deviceType',
      headerName: '기기구분',
      headerAlign: 'center',
      editable: false,
      align: 'center',
    },
    {
      flex: 2,
      field: 'deviceNumber',
      headerName: '작업기기번호',
      headerAlign: 'center',
      editable: false,
      align: 'center',
    },
    {
      flex: 3,
      field: 'workType',
      headerName: '작업파트구분',
      headerAlign: 'center',
      editable: false,
      align: 'center',
      valueFormatter: ({ value }) =>
        deviceWorkTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      flex: 3,
      field: 'isWorkAvailable',
      headerName: '작업가능여부',
      headerAlign: 'center',
      editable: false,
      align: 'center',
      valueFormatter: params => {
        const filter = content?.filter(item => item.id === params.id);
        return filter && filter[0].deviceType === 'PDA'
          ? filter[0].isWorkAvailable
            ? '작업가능'
            : '작업제외'
          : '-';
      },
    },
    {
      flex: 3,
      field: 'isWorkAssigned',
      headerName: '집품배정여부',
      headerAlign: 'center',
      editable: false,
      align: 'center',
      valueFormatter: params => {
        const filter = content?.filter(item => item.id === params.id);
        return filter && filter[0].deviceType === 'PDA'
          ? filter[0].isWorkAssigned
            ? '배정완료'
            : '미배정'
          : '-';
      },
    },
    {
      flex: 4,
      field: 'worker',
      headerName: '작업자',
      headerAlign: 'center',
      editable: false,
      align: 'center',
    },
    {
      flex: 4,
      field: 'loginAt',
      headerName: '로그인일시',
      headerAlign: 'center',
      editable: false,
      align: 'center',
    },
  ];
  return columns;
};

export default gridColumns;
