import { useState, useEffect } from 'react';

type OnSuccess<T> = (result: T) => void;
type OnError = (error: Error) => void;

const portFilters: { usbVendorId: number }[] = [];

const useScale = (
  onWeight: OnSuccess<string>,
  onError: OnError
): {
  requestScalePort: () => void;
  isConnected: boolean;
} => {
  const [scalePort, setScalePort] = useState<SerialPort>();
  const [weight, setWeight] = useState('');
  const [error, setError] = useState<Error>();
  const isConnected = !!scalePort;

  useEffect(() => {
    if (!weight) {
      return;
    }

    onWeight(weight);
    setWeight('');
  }, [weight, onWeight]);

  useEffect(() => {
    if (error) {
      setScalePort(undefined);
      onError(error);
    }
  }, [error]);

  useEffect(() => {
    const setPort = async () => {
      const port = await getSerialPort();
      setScalePort(port);
    };
    setPort();
  }, []);

  useEffect(() => {
    let reader: ReadableStreamDefaultReader | undefined;

    const listenScale = async () => {
      try {
        await scalePort?.open({ baudRate: 9600 });
        reader = getPortReader(scalePort);
        await readStream(convertChunksToWeight, setError, reader);
      } catch (error) {
        console.log('Could not open port');
      }
    };

    listenScale();

    const offScale = async () => {
      try {
        await reader?.cancel();
        await scalePort?.close();
      } catch (error) {
        console.log('Could not close port');
      }
    };

    return () => {
      offScale();
    };
  }, [scalePort]);

  const setConvertedWeight = (chunks: number[]) => {
    const weight = decodeOnlyNumber(chunks);
    const isKg = weight.includes('.');
    const weightAsGram = isKg ? Number(weight) * 1000 : Number(weight);
    setWeight(String(weightAsGram));
    console.log('weight:::' + weightAsGram); // 디버깅용 로그. 프로덕션 배포시 삭제해야 함.
  };

  const convertChunksToWeight = collectValidCharacters(setConvertedWeight);

  const requestScalePort = async () => {
    const port = await navigator.serial.requestPort({ filters: portFilters });
    setScalePort(port);
  };

  return { requestScalePort, isConnected };
};

export default useScale;

const getSerialPort = async (): Promise<SerialPort> => {
  const ports = await navigator.serial.getPorts({ filters: portFilters });

  return ports[0];
};

const getPortReader = (port?: SerialPort) => port?.readable?.getReader();

const readStream = async (
  onSuccess: OnSuccess<Uint8Array>,
  onError: OnError,
  reader?: ReadableStreamDefaultReader
) => {
  let shouldRead = true;

  while (reader && shouldRead) {
    console.log('Scale is ready');

    try {
      if (!(await reader.read())) {
        break;
      }

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          break;
        }

        onSuccess(value);
      }
    } catch (error) {
      onError(error as Error);
    } finally {
      reader.releaseLock();
      shouldRead = false;
    }
  }
};

const decodeOnlyNumber = (codes: number[]): string => {
  const decimalPoint = 46;
  const zero = 48;
  const nine = 57;

  return codes.reduce((acc: string, code: number) => {
    const isNumber = code >= zero && code <= nine;
    if (isNumber || code === decimalPoint) {
      return acc + String.fromCharCode(code);
    }

    return acc;
  }, '');
};

const collectValidCharacters = (
  func: (chunks: number[]) => void
): ((chunks: Uint8Array) => void) => {
  let results: number[] = [];

  return (chunks: Uint8Array) => {
    for (const chunk of chunks) {
      results.push(chunk);

      // 3은 개발용 저울의 end character, 10은 현업용 저울의 end character는 기기에 따라 다를 수 있음.
      if (chunk === 3 || chunk === 10) {
        func(results);
        results = [];
      }
    }
  };
};
