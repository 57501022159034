import LoadingButton from '@mui/lab/LoadingButton';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { COLORS } from 'consts/common/colors';
import ListTable, { Row, Value } from '../../components/ListTable';
import { Warehouse } from 'store/outbound/warehouse.recoil';
import { pickingGroupData } from '../index.page';
import usePopup from '../../../../../hooks/usePopup';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import fetcher from '../../../../../libs/common/fetcher';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { assignSelectedPDA } from '../services/selectedPdaAssign';

const modalWidth = 600;

type Props = {
  pickingGroupId: number[] | null | undefined;
  pickingGroups: pickingGroupData[] | undefined;
  warehouseId: number | undefined;
  warehouseOption: Warehouse[];
  onClose: () => void;
  onMutate: () => void;
};

type SelectedDevice = {
  id: number;
  selectPda: number | undefined;
};
type DeviceResponse = {
  deviceId: number;
  deviceNumber: string;
};
const AssignSelectedPdaModal = (props: Props) => {
  const { pickingGroupId, pickingGroups, warehouseId, warehouseOption, onClose, onMutate } = props;
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const [assignableDevices, setAssignableDevices] = useState<DeviceResponse[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<SelectedDevice[]>([]);
  const { showSnackbar, showErrorDialog } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];

  useEffect(() => {
    if (!warehouseId) return;

    fetcher(`/devices/available`, {
      warehouseId: warehouseId.toString(),
      pickingGroupType: pickingGroups?.[0].type ?? '',
    })
      .then(res => {
        const assignableDevices: SelectedDevice[] =
          res.map((device: DeviceResponse, index: number) => ({
            id: index + 1,
            deviceId: device.deviceId,
            selectPda: 0,
          })) ?? [];

        setAssignableDevices(res);
        setSelectedDevice(assignableDevices);

        const firstDevice =
          res.length > 0
            ? [
                {
                  id: 1,
                  deviceId: res[0].deviceId,
                  selectPda: 0,
                },
              ]
            : [];

        setSelectedDevice(firstDevice);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleClickCell = (row: Row, field: string) => {
    if (row.isAssigned && field !== 'isAssigned') {
      return;
    }

    setSelectedRow(row);
  };

  const handleSave = async () => {
    setIsLoading(true);

    const requestBody = selectedDevice
      .map((device: SelectedDevice) => {
        const { selectPda } = device;
        return {
          pickingGroupId: pickingGroupId,
          deviceIdToAssign: selectPda,
        };
      })
      .filter(device => device !== undefined);

    if (requestBody[0].deviceIdToAssign === 0) {
      showErrorDialog({
        title: 'PDA 선택 배정 실패',
        errorMessage: '배정할 PDA를 선택해주세요.',
        buttons: [{ text: '확인' }],
      });
      setIsLoading(false);
      return;
    }

    const response = await assignSelectedPDA(
      requestBody[0].pickingGroupId,
      requestBody[0].deviceIdToAssign
    );

    if (response?.status === 200) {
      showSnackbar({
        message: 'PDA 선택 배정이 완료되었습니다.',
        severity: 'success',
      });
      setIsLoading(false);
      onMutate();
    } else {
      showErrorDialog({
        title: 'PDA 선택 배정 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      setIsLoading(false);
    }

    onClose();
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '12px 24px',
            backgroundColor: COLORS.bgDeep,
          }}
        >
          <Box>{`창고 : ${
            warehouseOption.find((option: Warehouse) => option.id === Number(warehouseId))
              ?.displayName
          } | 집품구분 : ${
            pickingGroupTypeOptions?.find(v => v.value === pickingGroups?.[0].type)?.displayName
          } `}</Box>
          <Box>{`집품그룹ID : ${pickingGroups
            ?.map(pickingGroup => pickingGroup.serialNumber)
            .join(', ')}`}</Box>
        </Box>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText}>{'PDA 선택'}</Typography>
        </Box>
        <ListTable
          rows={selectedDevice ?? []}
          columns={makeColumns1(selectedDevice, setSelectedDevice, assignableDevices, selectedRow)}
          // columns={makeColumns(devices, setDevices, data?.assignableDevices, selectedRow)}
          onClickCell={handleClickCell}
          hideFooter={true}
          sx={styles.listTable}
        />
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <LoadingButton
            sx={styles.button}
            onClick={handleSave}
            variant="contained"
            loading={isLoading}
          >
            저장
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AssignSelectedPdaModal;

const makeColumns1 = (
  selectedDevice: SelectedDevice[] | undefined,
  setDevices: Dispatch<SetStateAction<SelectedDevice[]>>,
  rawAssignableDevices: DeviceResponse[] | undefined,
  selectedRow: Row | null
) => {
  const assignableDevices =
    rawAssignableDevices === undefined
      ? [{ deviceId: 0, deviceNumber: '선택' }]
      : [{ deviceId: 0, deviceNumber: '선택' }, ...rawAssignableDevices];

  const [selectPda, setSelectPda] = useState<number>();

  useEffect(() => {
    setDevices((selectedDevice: SelectedDevice[]) => {
      return selectedDevice.map(device => {
        if (device.id === selectedRow?.id) {
          return { ...device, selectPda };
        }

        setSelectPda(0);
        return device;
      });
    });
  }, [selectedRow]);

  const handleSelectPda = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectPda(Number(value));
  };

  return [
    {
      field: 'id',
      headerName: 'NO',
      width: '8%',
    },
    {
      field: 'id',
      headerName: 'PDA',
      width: '8%',
      renderCell: (id: Value) => {
        return (
          <Select
            sx={{ height: '80%' }}
            fullWidth={true}
            onChange={handleSelectPda}
            defaultValue={'0'}
            value={selectedDevice?.find(device => device.id === id)?.selectPda?.toString() ?? '0'}
          >
            {assignableDevices.map(device => (
              <MenuItem key={`pda-${device.deviceId}`} value={device.deviceId}>
                {device.deviceNumber}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];
};

const styles = {
  faultyInfoContainer: { border: `1px solid ${COLORS.lightLine}`, my: 2 },
  faultyRadioContainer: { width: '640px' },
  faultyRadio: { display: 'flex', flexDirection: 'row' },
  faultyRemarkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '640px',
  },
  faultyRemark: { my: 1, width: '620px' },
  listTable: { maxHeight: '400px', mb: 3 },
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
  select: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
};
