import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  LocalizationProvider,
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps,
  DateRange as MuiDateRange,
  DateRangePicker as MuiDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import type { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import { inputTheme } from 'styles/customedMuiTheme';
import useDateRange from './hooks/useDateRange';
import useFilter from '../hooks/useFilter';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const locale = 'ko';

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> & React.RefAttributes<HTMLInputElement>
) => JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = React.forwardRef(
  (props: SingleInputDateRangeFieldProps<Dayjs>, ref: React.Ref<HTMLInputElement>) => {
    return <SingleInputDateRangeField size="small" {...props} ref={ref} format={'YYYY-MM-DD'} />;
  }
) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

const DateRangePicker = forwardRef((props, ref) => {
  const { dateRange, setDateRange } = useDateRange();
  const { form } = useFilter();

  useEffect(() => {
    if (!form.dateType) {
      setDateRange([null, null]);
    }
  }, [form.dateType]);

  useImperativeHandle(ref, () => ({ handleDateChange }));

  const handleDateChange = (newDateRange: MuiDateRange<Dayjs>) => {
    setDateRange(newDateRange);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DemoContainer components={['SingleInputDateRangeField']} sx={styles.demoContainer}>
        <div>
          <MuiDateRangePicker
            value={dateRange}
            onChange={handleDateChange}
            slots={{ field: WrappedSingleInputDateRangeField }}
            slotProps={{
              textField: {
                placeholder: '시작일 - 종료일 선택',
                value: dateRange,
                sx: styles.slotProps,
                InputProps: {
                  endAdornment: <CalendarMonthOutlinedIcon />,
                },
              },
              actionBar: {
                actions: ['clear', 'cancel', 'accept'],
              },
            }}
          />
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
});

export default DateRangePicker;

const styles = {
  demoContainer: {
    ...inputTheme.medium,
    p: '0px',
    '& .MuiInputBase-input': {
      pt: '0px',
      pb: '0px',
    },
  },
  slotProps: {
    width: '100%',
  },
};
