import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const statusOptions = refCodeOptions?.stocktakingStatus || [];
  const typeOptions = refCodeOptions?.stocktakingType || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'stocktakingStatus',
      headerName: '재고조사상태',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => statusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'stocktakingType',
      headerName: '재고조사구분',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => typeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'serialNumber',
      headerName: '재고조사ID',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'name',
      headerName: '재고조사명',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'stocktakingRange',
      headerName: '재고조사기간',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
