import Link from '@mui/material/Link';
import React from 'react';
import { useRecoilValue } from 'recoil';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { numberFormatter } from '../../../../libs/common/unitConverters';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import usePopupWindow from '../../../../hooks/usePopupWindow';

const makeGridColDef = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingStatusOptions = refCodeOptions?.receivingStatus || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const receivingTypeOptions = refCodeOptions?.receivingType || [];
  const { openPopupWindow } = usePopupWindow();

  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 50,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return warehouseOption.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'receivedDate',
      headerName: '입고일자',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'receiveCompletedDate',
      headerName: '입고확정일자',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'receivingType',
      headerName: '입고구분',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return receivingTypeOptions.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'name',
      headerName: '거래명세서명',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'purchaseOrderName',
      headerName: '발주서명',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'supplierName',
      headerName: '공급업체',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'sku',
      headerName: '입고품목 수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'quantity',
      headerName: '입고수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return row.receivingType === 'RETURN' || row.receivingType === 'DEFECT_RETURN'
          ? '-' + numberFormatter(row.quantity)
          : numberFormatter(row.quantity);
      },
    },
    {
      field: 'totalCost',
      headerName: '입고금액',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return row.receivingType === 'RETURN' || row.receivingType === 'DEFECT_RETURN'
          ? '-' + numberFormatter(row.totalCost)
          : numberFormatter(row.totalCost);
      },
    },
    {
      field: 'status',
      headerName: '입고상태',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return receivingStatusOptions.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'specification',
      headerName: '명세서',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Link
            onClick={() =>
              openPopupWindow({
                url: `gms/inbound/registered-receiving/${row.id}/specification`,
                features: {
                  width: 800,
                  height: 580,
                  left: 100,
                  top: 100,
                },
              })
            }
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }}
          >
            {row.receivingType === 'RETURN' || row.receivingType === 'DEFECT_RETURN'
              ? '반품 거래명세서'
              : row.status === 'COMPLETED' || row.status === 'COMPLETED_MOVEMENT'
              ? '거래명세서'
              : '입고명세서'}
          </Link>
        );
      },
    },
  ] as GridColDef[];
};

export default makeGridColDef;
