import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COLORS } from '../../../consts/common/colors';

type TableProps = {
  children: ReactNode;
};

const TableHorizontal = (props: TableProps) => {
  const { children } = props;
  return <Stack sx={styles.container}>{children}</Stack>;
};

export default TableHorizontal;

type TableRowProps = {
  children: ReactNode;
  height?: string;
};

export const TableRow = (props: TableRowProps) => {
  const { children, height = 64 } = props;
  return (
    <Stack direction={'row'} height={height}>
      {children}
    </Stack>
  );
};

type TableCellProps = {
  sx?: SxProps;
  label: ReactNode;
  labelSx?: SxProps;
  value: ReactNode;
  valueSx?: SxProps;
  valueProps?: BoxProps;
  required?: boolean;
  isCreateMode?: boolean;
};

export const TableCell = (props: TableCellProps) => {
  const {
    label,
    labelSx,
    value,
    valueSx,
    valueProps,
    required = false,
    isCreateMode = true,
  } = props;

  return (
    <Stack flex={1}>
      <Box sx={{ ...styles.header, ...labelSx }}>
        <Typography sx={styles.label}>
          {label}
          {required && isCreateMode && (
            <span style={{ color: COLORS.red, marginLeft: '2px' }}>*</span>
          )}
        </Typography>
      </Box>
      <Box sx={{ ...styles.body, ...valueSx }} {...valueProps}>
        {value}
      </Box>
    </Stack>
  );
};

const styles = {
  container: {
    margin: '10px 0',
    borderRadius: '4px',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E0E0E0',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    backgroundColor: COLORS.bgDeep,
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    height: 32,
  },
  label: { p: 1, fontSize: '13px', fontWeight: 400 },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #E0E0E0',
    fontSize: '13px',
    fontWeight: 700,
    height: 32,
  },
};
