import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import { errorType } from '../../../../../types/common';

export const getJsonById = async (packingId: number) => {
  try {
    const res = await axiosV2.get(`/packings/${packingId}/waybill-result`);
    return res.data;
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
