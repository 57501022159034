import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AutoContainImage from 'components/common/Image/AutoContainImage';
import { Value } from '../../../../components/ListTable';
import { InspectionItems, InspectionResponse } from 'types/outbound';
import { COLORS } from 'consts/common/colors';
import { ShowAlert } from '../../../../../../../components/common/Popup/PopupProvider';
import { getFinalUrl } from '../../../../../../../consts/outbound/imageUrl';

export const separateArrow = ' => ';
const completedCode = '!@#';

//TODO: 윈도우 가로폭에 따라 항목 타이틀의 가로를 어떻게 할지 확인해야 함.
export const makeColumns = (
  totalOrder: number,
  totalChecked: number,
  totalRemaining: number,
  onChange: (quantity: string) => void,
  onKeyDown: (key: string) => void,
  onblur: (e: any) => void,
  currentItem?: InspectionItems | null | undefined
) => {
  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      width: '7%',
    },
    {
      field: 'barcode',
      headerName: '바코드',
      width: '9%',
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      width: '9%',
    },
    {
      field: 'imageUrl',
      headerName: '이미지',
      width: '6%',
      renderCell: (url: Value) => {
        const finalUrl = getFinalUrl(url as string);
        return (
          <Box
            sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          >
            <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
          </Box>
        );
      },
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      flex: 1,
      footerText: '수량합계',
      renderCell: (itemName: Value) => {
        const [item, remark] = (itemName as string).split(separateArrow);

        return (
          <p style={{ textAlign: 'start' }}>
            <span>{item}</span>
            <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
          </p>
        );
      },
    },
    {
      field: 'orderQuantity',
      headerName: '아이템수량',
      width: '9%',
      footerText: totalOrder,
    },
    {
      field: 'checkedQuantity',
      headerName: '정상수량',
      width: '8%',
      footerText: totalChecked,
      renderRowCell: (row: any) => {
        const { shippingItemId, checkedQuantity, orderQuantity } = row;
        const isInputDisabled =
          currentItem?.shippingItemId !== shippingItemId || orderQuantity == checkedQuantity;

        return isInputDisabled ? (
          <Typography>{checkedQuantity}</Typography>
        ) : (
          <input
            style={{
              width: 40,
              height: 30,
              borderRadius: '4px',
              border: `1px solid ${COLORS.lightLine}`,
            }}
            value={checkedQuantity as string}
            onChange={e => {
              onChange(e.target.value);
            }}
            onBlur={e => {
              onblur(e.target.value);
            }}
            onKeyDown={e => {
              onKeyDown(e.key);
            }}
          />
        );
      },
    },
    {
      field: 'remainingQuantity',
      headerName: '부족수량',
      width: '8%',
      footerText: totalRemaining,
    },
    {
      field: 'checkFaulty',
      headerName: '불량체크',
      width: '10%',
      renderCell: (isFaulty: Value) => (
        <Button
          variant={isFaulty ? 'outlined' : 'contained'}
          sx={{ backgroundColor: isFaulty ? 'lightgray' : '' }}
          disabled={isFaulty as boolean}
        >
          불량
        </Button>
      ),
    },
  ];
};

export const makeInspectionItems = (inspection: InspectionResponse) => {
  return inspection.inspectionItems.map(item => {
    const { remark, itemName, orderQuantity, offlineBarcode, onlineBarcode, shippingItemId } = item;

    const barcode = offlineBarcode || onlineBarcode;
    const finalItemName = remark ? itemName + separateArrow + remark : itemName;
    const inspectedQuantity = 0;
    const checkedQuantity =
      inspectedQuantity === orderQuantity ? completedCode + inspectedQuantity : inspectedQuantity;
    const remainingQuantity = orderQuantity - inspectedQuantity;

    return {
      ...item,
      barcode,
      itemName: finalItemName,
      inspectedQuantity,
      checkedQuantity,
      remainingQuantity,
      checkFaulty: remainingQuantity === 0,
    };
  });
};

export const makeGiftInspectionItems = (inspection: InspectionResponse) => {
  return inspection.inspectionItems
    .filter(item => {
      const { isGiftItem } = item;
      return isGiftItem;
    })
    .map(item => {
      const { remark, itemName, orderQuantity, offlineBarcode, onlineBarcode, inspectedQuantity } =
        item;
      const barcode = offlineBarcode || onlineBarcode;
      const finalItemName = remark ? itemName + separateArrow + remark : itemName;
      const checkedQuantity =
        inspectedQuantity === orderQuantity ? completedCode + inspectedQuantity : inspectedQuantity;
      const remainingQuantity = orderQuantity;

      return {
        ...item,
        barcode,
        itemName: finalItemName,
        inspectedQuantity,
        checkedQuantity,
        remainingQuantity,
        checkFaulty: remainingQuantity === 0,
      };
    });
};

export const koreaFilter = (scannedBarcode: string, showAlert: ShowAlert) => {
  const koreanRegex = /^.*[가-힣ㄱ-ㅎㅏ-ㅣ\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F].*$/;

  if (koreanRegex.test(scannedBarcode)) {
    showAlert({
      message:
        '키보드가 한글모드로 되어 있다면 영문모드로 변경해 주세요.\n' +
        '동일한 오류가 계속 발생하면 책임자에게 문의해 주세요.',
    });
    return true;
  }
  return false;
};
