import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import gridColumns from './gridColDef';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { Options } from '../../../../types/form';
import pageTitle from '../../../../styles/pageTitle';
import Button from '@mui/material/Button';
import FormModal from '../../../../components/common/FormModal';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import MoveQuantityBetweenItemForm from './components/MoveQuantityBetweenItemForm';
import usePopup from '../../../../hooks/usePopup';
import useConditionalSWR from '../../components/useConditionalSwr';

const defaultOption = { displayName: '전체', value: 'ALL' };

export type Inventory = {
  id: number;
  warehouseId: number;
  itemCode: string;
  itemName: string;
  barcode: string;
  quantity: number;
  pendingQuantity: number;
  status: string;
  locationCode: string;
  lpnSerialNumber: string;
};

type InventoriesResponse = {
  content: Inventory[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const searchKeywordOptions: Options = [
  {
    displayName: '아이템코드',
    field: 'itemCode',
  },
  {
    displayName: '아이템명',
    field: 'itemName',
  },
  {
    displayName: '바코드',
    field: 'barcode',
  },
];

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '80px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1.4fr)',
  'minmax(55px, 1.4fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const InventoryMovements = () => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const multipleBinTypeSelectRef = useRef<{ initialValues: () => void }>(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [isMoveQuantityBetweenItemModalOpens, setIsMoveQuantityBetweenItemModalOpens] =
    useState(false);
  const [selectedInventoryLpn, setSelectedInventoryLpn] = useState<number[]>();
  const [inventoryLpn, setInventoryLpn] = useState<Inventory | undefined>();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const statusOptions = refCodeOptions?.inventoryLpnStatus || [];
  const binTypes = refCodeOptions?.binType || [];
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<InventoriesResponse>(
    [`/inventories/locations`, { ...queryParams }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: InventoriesResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Inventory, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    multipleBinTypeSelectRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleMoveQuantityBetweenItems = async () => {
    if (!selectedInventoryLpn || selectedInventoryLpn.length === 0) {
      showErrorDialog({
        title: '재고이동 실패',
        errorMessage: '재고이동 할 로케이션을 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    if (selectedInventoryLpn.length > 1) {
      showErrorDialog({
        title: '재고이동 실패',
        errorMessage: '재고이동 할 로케이션을 하나만 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    const filter = data?.content.filter(item => item.id === selectedInventoryLpn?.[0]);
    setInventoryLpn(filter?.[0]);
    setIsMoveQuantityBetweenItemModalOpens(true);
  };

  const handleCloseMoveQuantityBetweenItemModal = () => {
    setIsMoveQuantityBetweenItemModalOpens(false);
    setSelectedInventoryLpn([]);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        개별재고이동 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={warehouseOption}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Search
          label="로케이션"
          field="locationCode"
          labelGridColumn="4/5"
          inputGridColumn="5/8"
        />
        <Filter.SearchWithSelect
          label="검색어"
          searchOptions={searchKeywordOptions}
          labelGridColumn="8/9"
          selectGridColumn="9/11"
          inputGridColumn="11/13"
        />
        <Filter.Select
          label="상태"
          field="status"
          gridRow={2}
          options={[defaultOption, ...statusOptions]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleBinTypeSelectRef}
          label="로케이션구분"
          field="binTypes"
          options={[defaultOption, ...binTypes]}
          labelGridColumn="4/5"
          selectGridColumn="5/8"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            재고 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleMoveQuantityBetweenItems}
            >
              개별재고이동
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColumns(warehouseOption)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          selectionModel={selectedInventoryLpn}
          onSelectionModelChange={ids => setSelectedInventoryLpn(ids as number[])}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={isMoveQuantityBetweenItemModalOpens}
        title={'개별재고이동'}
        onClose={handleCloseMoveQuantityBetweenItemModal}
      >
        <MoveQuantityBetweenItemForm
          inventoryLpn={inventoryLpn}
          onClose={handleCloseMoveQuantityBetweenItemModal}
          mutate={mutate}
        />
      </FormModal>
    </Page>
  );
};

export default InventoryMovements;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
