import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import Box from '@mui/material/Box';

type Props = {
  onSaveClick: () => void;
  isLoading: boolean;
};

const SaveButtonGroup = (props: Props) => {
  const { onSaveClick, isLoading } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        marginTop: 2,
      }}
    >
      <LoadingButton
        variant="contained"
        onClick={onSaveClick}
        loading={isLoading}
        sx={{ height: '60px', width: '130px', fontSize: '20px' }}
      >
        저장
      </LoadingButton>
    </Box>
  );
};

export default SaveButtonGroup;
