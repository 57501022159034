import { TableCell as MUITableCell } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { COLORS } from 'consts/common/colors';
import { round } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import InfoListSection from '../../../../../components/common/typography/InfoListSection';
import usePopup from '../../../../../hooks/usePopup';
import { numberFormatter } from '../../../../../libs/common/unitConverters';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import { boxAtom, packingAtom, stepAtom } from '../../../../../store/outbound/packing.recoil';
import { TableCell, TableRow } from '../../../components/Table';
import { getEstimatedDeliveryPriceOfSingle } from '../services/packing';
import SaveButtonGroup from './SaveButtonGroup';

const modalWidth = 600;

type Props = {
  onClose: () => void;
  boxBarcode?: string;
};

type BoxDimension = {
  width: number | string;
  length: number | string;
  height: number | string;
  volumetricWeight?: number;
  estimatedDeliveryPrice?: number | string;
};

const initialBoxDimension: BoxDimension = {
  width: 0,
  length: 0,
  height: 0,
  volumetricWeight: 0,
  estimatedDeliveryPrice: '-',
};

const convertNumberOrDefault = (value: string | number) => Number(value) || 0;

const BoxSizeForm = (props: Props) => {
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const { showErrorDialog } = usePopup();
  const { onClose, boxBarcode } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [box, setBox] = useRecoilState(boxAtom);
  const packing = useRecoilValue(packingAtom);
  const [baseBoxDimension, setBaseBoxDimension] = useState<BoxDimension>(initialBoxDimension);
  const [changingBoxDimension, setChangingBoxDimension] =
    useState<BoxDimension>(initialBoxDimension);
  const carrierOptions = useRecoilValue(carriersAtom);
  const carrierName = carrierOptions.find(carrier => carrier.id === packing?.carrierId)?.name;

  const handleSave = async () => {
    if (
      [changingBoxDimension.width, changingBoxDimension.length, changingBoxDimension.height].some(
        input => !input || Number(input) <= 0
      )
    ) {
      showErrorDialog({
        title: '박스정보 저장 실패',
        errorMessage: '박스의 가로, 세로, 높이 정보를 입력해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    setSelectedStep(selectedStep + 1);
    updatePackingBoxWidth();
    setIsLoading(false);
    onClose();
  };

  const getVolumetricWeight = (
    width: string | number,
    length: string | number,
    height: string | number
  ) =>
    round((Number(width) * Number(length) * Number(height)) / Number(packing?.volumetricDivisor));

  const getEstimatedDeliveryPrice = async (
    width: string | number,
    length: string | number,
    height: string | number
  ) => {
    const queryParams = {
      width: width,
      length: length,
      height: height,
      volumetricDivisor: packing?.volumetricDivisor,
    };
    const axiosResponse = await getEstimatedDeliveryPriceOfSingle(packing?.id, queryParams);
    if (axiosResponse?.status !== 200) {
      showErrorDialog({
        title: '예상 배송비 조회 실패',
        errorMessage: axiosResponse?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      return '-';
    }
    return axiosResponse?.data;
  };

  useEffect(() => {
    const initBaseBoxDimension = async (width: string, length: string, height: string) => {
      const volumetricWeight = getVolumetricWeight(width, length, height);
      const estimatedDeliveryPrice = await getEstimatedDeliveryPrice(width, length, height);
      const boxDimensions: BoxDimension = {
        width,
        length,
        height,
        volumetricWeight,
        estimatedDeliveryPrice,
      };
      setChangingBoxDimension(boxDimensions);
      setBaseBoxDimension(boxDimensions);
    };
    if (box && box.barcode !== 'BULK5080FAKE') {
      const width = box.width ? box.width.toString() : '';
      const length = box.length ? box.length.toString() : '';
      const height = box.height ? box.height.toString() : '';
      initBaseBoxDimension(width, length, height);
    }
  }, [box]);

  const updatePackingBoxWidth = () => {
    const currentPackingBox = { ...box };
    const updatedPackingBox: any = {
      ...currentPackingBox,
      width: changingBoxDimension.width,
      length: changingBoxDimension.length,
      height: changingBoxDimension.height,
    };
    setBox(updatedPackingBox);
  };

  const handleWidthChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setChangingBoxDimension({ ...changingBoxDimension, width: value });
    await updateChangingBoxDimension(
      value,
      changingBoxDimension.length,
      changingBoxDimension.height
    );
  };

  const handleLengthInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setChangingBoxDimension({ ...changingBoxDimension, length: value });
    await updateChangingBoxDimension(
      changingBoxDimension.width,
      value,
      changingBoxDimension.height
    );
  };

  const handleHeightInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setChangingBoxDimension({ ...changingBoxDimension, height: value });
    await updateChangingBoxDimension(
      changingBoxDimension.width,
      changingBoxDimension.length,
      value
    );
  };

  // 예상 배송비 조회
  const updateChangingBoxDimension = async (
    width2: number | string,
    length2: number | string,
    height2: number | string
  ) => {
    const width = convertNumberOrDefault(width2);
    const length = convertNumberOrDefault(length2);
    const height = convertNumberOrDefault(height2);
    const volumetricWeight = getVolumetricWeight(width, length, height);
    if (height <= 0 || width <= 0 || length <= 0 || volumetricWeight <= 0) {
      setChangingBoxDimension({
        width: width2,
        length: length2,
        height: height2,
        volumetricWeight: 0,
        estimatedDeliveryPrice: '-',
      });
      return;
    }
    const estimatedDeliveryPrice = await getEstimatedDeliveryPrice(width, length, height);
    setChangingBoxDimension({
      width,
      length,
      height,
      volumetricWeight,
      estimatedDeliveryPrice,
    });
  };

  const infoList = [
    { name: '배송방식', value: carrierName },
    { name: 'DIM', value: String(packing?.volumetricDivisor) },
    { name: '실제중량무게(g)', value: String(packing?.totalWeightOfShipping) },
  ];

  return (
    <Box width={modalWidth}>
      <Box sx={styles.container}>
        <TableRow>
          <TableCell
            label={'박스명'}
            value={boxBarcode === 'BULK5080FAKE' ? <Box>BULK BOX</Box> : <Box>{box?.name}</Box>}
          />
        </TableRow>
        <TableRow>
          <TableCell
            label={'가로(mm)'}
            value={
              boxBarcode === 'BULK5080FAKE' ? (
                <input
                  type="number"
                  value={changingBoxDimension.width}
                  onChange={handleWidthChange}
                  style={styles.select}
                />
              ) : (
                <Box>{changingBoxDimension.width}</Box>
              )
            }
          />
        </TableRow>
        <TableRow>
          <TableCell
            label={'세로(mm)'}
            value={
              boxBarcode === 'BULK5080FAKE' ? (
                <input
                  type="number"
                  value={changingBoxDimension.length}
                  onChange={handleLengthInput}
                  style={styles.select}
                />
              ) : (
                <Box>{changingBoxDimension.length}</Box>
              )
            }
          />
        </TableRow>
        <TableRow>
          <TableCell
            label={'높이(mm)'}
            value={
              <input
                type="number"
                value={changingBoxDimension.height}
                onChange={handleHeightInput}
                style={styles.select}
              />
            }
          />
        </TableRow>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={styles.informationText}>{'부피무게 예상배송비'}</Typography>
        <Box sx={{ marginY: 1 }}>
          <InfoListSection infoList={infoList} hideValue={false} />
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  구분
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  가로
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  세로
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  높이
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 3 }}>
                  포장실제부피무게(g)
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 2 }}>
                  예상배송비(원)
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  변경전
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {baseBoxDimension?.width}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {baseBoxDimension?.length}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {baseBoxDimension?.height}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 3 }}>
                  {numberFormatter(String(baseBoxDimension.volumetricWeight))}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 2 }}>
                  {numberFormatter(String(baseBoxDimension.estimatedDeliveryPrice))}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  변경후
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {changingBoxDimension.width}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {changingBoxDimension.length}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {changingBoxDimension.height}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 3,
                    color:
                      Number(baseBoxDimension.volumetricWeight || 0) >
                      Number(changingBoxDimension.volumetricWeight || 0)
                        ? COLORS.red
                        : '',
                  }}
                >
                  {numberFormatter(String(changingBoxDimension.volumetricWeight))}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 2,
                    color:
                      Number(baseBoxDimension.estimatedDeliveryPrice || 0) >
                      Number(changingBoxDimension.estimatedDeliveryPrice || 0)
                        ? COLORS.red
                        : '',
                  }}
                >
                  {numberFormatter(String(changingBoxDimension.estimatedDeliveryPrice))}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align={'center'} style={{ flex: 6.1 }}>
                  예상배송비 차이
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 3,
                    color:
                      Number(baseBoxDimension.volumetricWeight || 0) >
                      Number(changingBoxDimension.volumetricWeight || 0)
                        ? COLORS.red
                        : undefined,
                  }}
                >
                  {numberFormatter(
                    String(
                      (changingBoxDimension.volumetricWeight || 0) -
                        (baseBoxDimension.volumetricWeight || 0)
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 2,
                    color:
                      Number(baseBoxDimension.estimatedDeliveryPrice || 0) >
                      Number(changingBoxDimension.estimatedDeliveryPrice || 0)
                        ? COLORS.red
                        : undefined,
                  }}
                >
                  {numberFormatter(
                    String(
                      (Number(changingBoxDimension.estimatedDeliveryPrice) || 0) -
                        (Number(baseBoxDimension.estimatedDeliveryPrice) || 0)
                    )
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SaveButtonGroup onSaveClick={handleSave} isLoading={isLoading} />
    </Box>
  );
};

const StyledTableCell = styled(MUITableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f3f3fa',
    color: theme.palette.common.black,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 'none',
  },
}));

export default BoxSizeForm;

const styles = {
  container: {
    border: `1px solid ${COLORS.darkLine}`,
    fontSize: 16,
  },
  select: {
    height: 44,
    fontSize: 18,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
};
