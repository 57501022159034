import React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSetRecoilState } from 'recoil';
import { inputAutoFocusAtom } from '../../../store';
import AutoFocusOffDialog from './AutoFocusOffDialog';

export type Button = {
  text: string;
  onClick?: () => void;
  marked?: boolean;
};

type Props = {
  open: boolean;
  children: React.ReactNode;
  buttons: Button[];
  onClose: () => void;
};

const Dialog = (props: Props) => {
  const { open, children, buttons, onClose } = props;
  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);

  const handleClose = () => {
    onClose();
    setInputAutoFocus(true);
  };

  return (
    <AutoFocusOffDialog open={open} PaperProps={{ sx: { padding: '16px' } }}>
      <DialogContent sx={{ padding: '16px', whiteSpace: 'pre-wrap' }}>{children}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
        {buttons.map(({ text, onClick, marked }) => (
          <Button
            key={text}
            onClick={() => {
              onClick && onClick();
              handleClose();
            }}
            variant={marked ? 'contained' : 'outlined'}
            sx={{
              fontSize: '16px',
              fontWeight: 700,
            }}
          >
            {text}
          </Button>
        ))}
      </DialogActions>
    </AutoFocusOffDialog>
  );
};

export default Dialog;
