import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from 'libs/common/fetcher';
import type { Packing, PackingStatus } from 'types/outbound';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';

export type PatchPackingRequest =
  | (Partial<Packing> & { status: Exclude<PackingStatus, 'ERROR'>; id: number })
  | (Partial<Packing> & { status: 'ERROR'; errorCode: string; errorMessage: string; id: number });

export const updatePacking = (packing: PatchPackingRequest): Promise<void> =>
  axiosV2.patch(`/packings/${packing.id}`, packing);

export const scannedPacking = async (packing: PatchPackingRequest) => {
  try {
    return await axiosV2.patch(`/packings/${packing.id}`, packing);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`포장 상태 변경 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getPacking = async (toteBarcode: string) => {
  try {
    // toteBarcode를 대문자로 변환
    const upperCaseToteBarcode = toteBarcode.toUpperCase();
    return await axiosV2.get(`/packings/totes/${upperCaseToteBarcode}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`포장시 토트스캔 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getEstimatedDeliveryPriceOfSingle = async (
  packingId: number | undefined,
  params: any
) => {
  try {
    return await axiosV2.get(`/packings/${packingId}/estimated-delivery-price/single`, { params });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`예상배송비 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export type WaybillRequest = {
  weight: number;
  boxCode: string;
  boxWidth: number;
  boxLength: number;
  boxHeight: number;
};

export const fetchWaybill = async (
  id: number,
  request: WaybillRequest
): Promise<{ base64Image: string }> => {
  const response = await axiosV2.post(`/packings/${id}/waybills`, {
    ...request,
  });

  if (response.status === 200) {
    const { base64Image } = response.data;
    if (!base64Image) throw new Error('출력할 수 있는 송장 이미지가 없습니다');

    return { base64Image };
  }

  const { errorMessage } = response.data;
  throw new Error(errorMessage);
};
