import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/common/colors';
import Table, { TableCell, TableRow } from '../../../components/Table';
import ListTable, { Value } from '../../components/ListTable';
import Box from '@mui/system/Box';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { separateArrow } from '../../inspection/components/ItemListLine/utils';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';

type Tote = {
  toteBarcode: string;
  totalSkuCount: number;
  totalQuantity: number;
};

type Product = {
  itemId: number;
  barcode: string;
  code: string;
  itemName: string;
  imageUrl: string;
  option?: string;
  orderQuantity: number;
  remark?: string;
};

type Props = {
  pickingGroupId: string;
  shippingQuantity: number;
  toteList: Tote[];
  itemList: Product[];
  estimatedWeight: number;
};

const BulkPackingTable = (props: Props) => {
  const { pickingGroupId, shippingQuantity, toteList, itemList, estimatedWeight } = props;
  const mappedTotes = toteList.map((tote, i) => ({
    ...tote,
    id: tote.toteBarcode,
    order: i + 1,
  }));
  const mappedProducts = itemList.map((product, i) => ({
    ...product,
    id: product.code,
    order: i + 1,
    itemName: product.itemName + `${product.remark ? separateArrow + product.remark : ''}`,
  }));
  const totalProductQuantity = itemList.reduce(
    (total, { orderQuantity }) => total + orderQuantity,
    0
  );

  return (
    <Stack>
      <Table>
        <TableRow height="36px">
          <TableCell label="집품그룹ID" value={pickingGroupId} />
          <TableCell label="출고건수" value={shippingQuantity.toLocaleString()} />
        </TableRow>
        <TableRow height="36px">
          <TableCell label="1박스 아이템수량" value={totalProductQuantity} />
          <TableCell label="예상중량무게(g)" value={estimatedWeight.toLocaleString()} />
        </TableRow>
      </Table>

      <Typography
        variant="h6"
        sx={{
          margin: '14px 0 12px 0',
          ...subTitleStyle,
        }}
      >
        [집품그룹단위 토트정보]
      </Typography>
      <ListTable
        rows={mappedTotes}
        columns={toteColumns}
        sx={{ maxHeight: '200px' }}
        hideFooter={true}
      />

      <Typography
        variant="h6"
        sx={{
          margin: '24px 0 12px 0',
          ...subTitleStyle,
        }}
      >
        [1박스 포장단위 아이템정보]
      </Typography>
      <ListTable
        rows={mappedProducts}
        columns={productColumns}
        total={totalProductQuantity}
        sx={{ maxHeight: '400px' }} // 현장 모니터 해상도에 맞게 조절 필요
      />
    </Stack>
  );
};

const subTitleStyle = {
  fontWeight: 700,
  color: COLORS.grey,
};

const toteColumns = [
  { headerName: 'NO', field: 'order', width: '10%' },
  { headerName: '토트 바코드', field: 'toteBarcode', width: '56%' },
  { headerName: '품목수량', field: 'totalSkuCount', width: '17%' },
  { headerName: '아이템수량', field: 'totalQuantity', width: '17%' },
];

const productColumns = [
  { headerName: 'NO', field: 'order', width: '10%' },
  { headerName: '바코드', field: 'barcode', width: '10%' },
  { headerName: '아이템코드', field: 'code', width: '15%' },
  {
    headerName: '이미지',
    field: 'imageUrl',
    width: '5%',
    renderCell: (url: Value) => {
      const finalUrl = getFinalUrl(url as string);
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
          <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
        </Box>
      );
    },
  },
  {
    headerName: '아이템명',
    field: 'itemName',
    width: '45%',
    renderCell: (itemName: Value) => {
      const [item, remark] = (itemName as string).split(separateArrow);
      return (
        <p style={{ textAlign: 'start' }}>
          <span>{item}</span>
          <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
        </p>
      );
    },
  },
  { headerName: '아이템수량', field: 'orderQuantity', width: '15%' },
];

export default BulkPackingTable;
