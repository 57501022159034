import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import { errorType } from '../../../../../types/common';

export type UpdateLpnRequest = {
  lpnId: number;
  expirationDate: string;
};

export const updateLpn = async (request: UpdateLpnRequest) => {
  try {
    return await axiosV2.patch(`/inventories/lpns/${request.lpnId}`, request);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
