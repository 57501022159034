import { atom } from 'recoil';
import { InspectionResponse, PrevInspectionResponse } from 'types/outbound';

export const inspectionAtom = atom<InspectionResponse>({
  key: 'inspectionAtom',
  default: undefined,
});

export const prevInspectionAtom = atom<PrevInspectionResponse>({
  key: 'prevInspectionAtom',
  default: undefined,
});

export const imageModalAtom = atom({
  key: 'imageModalAtom',
  default: {
    shouldShow: false,
    imageUrl: '',
  },
});

const inspectionAtoms = {
  inspection: inspectionAtom,
  prevInspection: prevInspectionAtom,
  imageModal: imageModalAtom,
};

export default inspectionAtoms;
