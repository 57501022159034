import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useSWR from 'swr';
import { useRecoilValue } from 'recoil';

import InfoListSection from 'components/common/typography/InfoListSection';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import Button from '@mui/material/Button';
import gridColumns from './gridColDef';
import { warehouseOptionsAtom } from '../../../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../../../libs/warehouse/convertWarehouseFullCode';

type ShippingInPickingGroup = {
  id: number;
  status: 'OPEN' | 'PREPARING_PICKING' | 'PICKING' | 'INSPECTION' | 'PACKED';
  shippingBasedAt: string;
  serialNumber: string;
  deliverySeq: string;
  deliveryId: number;
  skuQty: number;
  itemQty: number;
};

type RequestQuery = {
  warehouseId?: number;
  type?: string;
  serialNumber?: string;
};

type Props = {
  pickingGroupId: number;
  request: RequestQuery;
};

const Index = ({ pickingGroupId, request }: Props) => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const { data, error } = useSWR<ShippingInPickingGroup[]>(
    `/picking-groups/${pickingGroupId}/shippings`,
    fetcher
  );
  const [pageSize, setPageSize] = useState(100);
  const shippings = data?.map(shipping => ({
    ...shipping,
  }));

  const infoList = [
    {
      name: '창고',
      value: !!request.warehouseId
        ? convertWarehouseFullCode(request.warehouseId, warehouseOption)[0]
        : '',
    },
    { name: '집품구분', value: request.type },
    { name: '집품그룹 ID', value: request.serialNumber },
  ];

  return (
    <>
      <Box sx={styles.gridBorderTop}>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          출고 목록
        </Typography>
        <Box>
          <Button
            sx={styles.gridBorderTopButton}
            variant="outlined"
            suppressHydrationWarning
            onClick={() => console.log(1)}
            disabled
          >
            {`엑셀다운로드`}
          </Button>
        </Box>
      </Box>
      <InfoListSection infoList={infoList} hideValue={!data || error} />
      <Box sx={{ height: '460px' }}>
        <TightDataGridPro
          rows={shippings ?? []}
          columns={gridColumns()}
          loading={!data && !error}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
      </Box>
    </>
  );
};

export default Index;

const styles = {
  gridBorderTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    spacing: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
