import LoadingButton from '@mui/lab/LoadingButton';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { COLORS } from 'consts/common/colors';
import fetcher from 'libs/common/fetcher';
import ListTable, { Row, Value } from '../../components/ListTable';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import { Warehouse } from 'store/outbound/warehouse.recoil';
import { pickingGroupData } from '../index.page';
import usePopup from '../../../../../hooks/usePopup';
import { generateChangeAssign } from '../services/changeAssign';

const modalWidth = 800;

type Props = {
  pickingGroupId: number | null;
  pickingGroup: pickingGroupData | undefined;
  warehouseId: number | undefined;
  warehouseOption: Warehouse[];
  onClose: () => void;
  onMutate: () => void;
};

type BaseDevice = {
  deviceId: number;
  deviceNumber: string;
};

type Device = BaseDevice & {
  id: number;
  isAssigned: boolean;
  selectPda: number | undefined;
};

export type ChangedAssignedDevice =
  | {
      assignDeviceId: number;
      unassignPda: boolean;
      deviceIdToChange?: number | undefined;
    }
  | undefined;

type Data = {
  devices: BaseDevice[];
  assignableDevices: BaseDevice[];
};

const AssignPdaModal = (props: Props) => {
  const { pickingGroupId, pickingGroup, warehouseId, warehouseOption, onClose, onMutate } = props;
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const [data, setData] = useState<Data>();
  const [devices, setDevices] = useState<Device[]>([]);
  const { showSnackbar, showErrorDialog } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!warehouseId) return;

    fetcher(`/picking-groups/${pickingGroupId}/devices`, {
      warehouseId: warehouseId.toString(),
    })
      .then(res => {
        const devicesWithId: Device[] =
          res.devices.map((device: BaseDevice, idx: number) => ({
            ...device,
            id: idx + 1,
            isAssigned: false,
            selectPda: -1, //TODO: 혹 DB에서 가져온 값을 넣어야 하는 것인지 확인 필요
          })) ?? [];

        setData(res);
        setDevices(devicesWithId);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleClickCell = (row: Row, field: string) => {
    if (row.isAssigned && field !== 'isAssigned') {
      return;
    }

    setSelectedRow(row);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const reAssignedDevices: ChangedAssignedDevice[] = devices
      .map((device: Device) => {
        const { deviceId, isAssigned, selectPda } = device;

        if (isAssigned === true) {
          return { assignDeviceId: deviceId, unassignPda: isAssigned };
        }

        if (deviceId === selectPda || selectPda === undefined || selectPda === -1) {
          return;
        }

        return {
          assignDeviceId: deviceId,
          unassignPda: isAssigned,
          deviceIdToChange: selectPda,
        };
      })
      .filter(device => device !== undefined);

    const response = await generateChangeAssign(pickingGroupId, reAssignedDevices);

    if (response?.status === 200) {
      showSnackbar({
        message: 'PDA 배정 변경이 완료되었습니다.',
        severity: 'success',
      });
      setIsLoading(false);
      onMutate();
    } else {
      showErrorDialog({
        title: 'PDA 배정 변경 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      setIsLoading(false);
    }

    onClose();
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Box
          sx={{ display: 'flex', gap: '8px', padding: '12px 24px', backgroundColor: COLORS.bgDeep }}
        >
          {`창고 : ${
            warehouseOption.find((option: Warehouse) => option.id === Number(warehouseId))
              ?.displayName
          } | 집품구분 : ${pickingGroup?.type} | 집품그룹ID : ${pickingGroup?.serialNumber}`}
        </Box>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText}>{'배정 PDA 목록'}</Typography>
        </Box>
        <ListTable
          rows={devices}
          columns={makeColumns(devices, setDevices, data?.assignableDevices, selectedRow)}
          onClickCell={handleClickCell}
          hideFooter={true}
          sx={styles.listTable}
        />
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <LoadingButton
            sx={styles.button}
            onClick={handleSave}
            variant="contained"
            loading={isLoading}
          >
            저장
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AssignPdaModal;

const makeColumns = (
  devices: Device[],
  setDevices: Dispatch<SetStateAction<Device[]>>,
  rawAssignableDevices: BaseDevice[] | undefined,
  selectedRow: Row | null
) => {
  const assignableDevices =
    rawAssignableDevices === undefined
      ? [{ deviceId: -1, deviceNumber: '선택' }]
      : [{ deviceId: -1, deviceNumber: '선택' }, ...rawAssignableDevices];

  const [isAssigned, setIsAssigned] = useState(false);
  const [selectPda, setSelectPda] = useState(-1);

  useEffect(() => {
    setDevices((devices: Device[]) => {
      return devices.map(device => {
        if (device.id === selectedRow?.id) {
          return { ...device, isAssigned, selectPda };
        }

        setIsAssigned(false);
        setSelectPda(-1);
        return device;
      });
    });
  }, [selectedRow]);

  const handleUnassignPda = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsAssigned(checked);
    checked && setSelectPda(-1);
  };

  const handleSelectPda = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectPda(Number(value));
  };

  return [
    {
      field: 'id',
      headerName: 'NO',
      width: '8%',
    },
    {
      field: 'deviceNumber',
      headerName: 'PDA',
      width: '8%',
    },
    {
      field: 'isAssigned',
      headerName: 'PDA배정해제',
      width: '8%',
      renderCell: () => {
        return <Checkbox sx={styles.select} onChange={checked => handleUnassignPda(checked)} />;
      },
    },
    {
      field: 'id',
      headerName: '변경PDA',
      width: '8%',
      renderCell: (id: Value) => {
        return (
          <Select
            sx={{ height: '80%' }}
            fullWidth={true}
            onChange={handleSelectPda}
            defaultValue={'-1'}
            disabled={devices.find(device => device.id === id)?.isAssigned}
            value={devices.find(device => device.id === id)?.selectPda?.toString() ?? '-1'}
          >
            {assignableDevices.map(device => (
              <MenuItem key={`pda-${device.deviceId}`} value={device.deviceId}>
                {device.deviceNumber}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];
};

const styles = {
  faultyInfoContainer: { border: `1px solid ${COLORS.lightLine}`, my: 2 },
  faultyRadioContainer: { width: '640px' },
  faultyRadio: { display: 'flex', flexDirection: 'row' },
  faultyRemarkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '640px',
  },
  faultyRemark: { my: 1, width: '620px' },
  listTable: { maxHeight: '400px', mb: 3 },
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
  select: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
};
