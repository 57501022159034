import React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { COLORS } from '../../../../../consts/common/colors';
import { DiffQtyItem, MatchingResult } from '../index.page';

type Props = {
  items: MatchingResult | undefined;
  onClose: () => void;
};

const MatchingResultModal = ({ items, onClose }: Props) => {
  return (
    <Stack width={600} gap={1}>
      <Typography>
        해당 집품그룹ID에 연결된 토트의 잔여재고를
        <br />
        재고이동 후, '대량포장 완료처리'를 진행해 주세요.
      </Typography>
      <Typography sx={styles.modalSubTitle}>토트 정보</Typography>
      <TableContainer component={Paper}>
        <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
          <TableHead sx={{ backgroundColor: COLORS.bgLight }}>
            <TableRow>
              <TableCell align={'center'}>{'토트'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.toteBarcode?.map((code: string) => (
              <TableRow key={code}>
                <TableCell align={'center'}>{code}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={styles.modalSubTitle}>토트 잔여재고 정보</Typography>
      <TableContainer component={Paper}>
        <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
          <TableHead sx={{ backgroundColor: COLORS.bgLight }}>
            <TableRow>
              <TableCell align={'center'}>{'아이템코드'}</TableCell>
              <TableCell align={'center'}>{'아이템명'}</TableCell>
              <TableCell align={'center'}>{'잔여수량'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.diffQtyItems?.map((diff: DiffQtyItem) => (
              <TableRow key={diff.itemName}>
                <TableCell align={'center'}>{diff.itemCode}</TableCell>
                <TableCell align={'center'}>{diff.itemName}</TableCell>
                <TableCell align={'center'}>{diff.diffQty}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack flexDirection={'row'} justifyContent={'center'} gap={1} m={1}>
        <Button variant={'outlined'} onClick={onClose}>
          닫기
        </Button>
      </Stack>
    </Stack>
  );
};

export default MatchingResultModal;

const styles = {
  modalSubTitle: { fontSize: '22px', mb: 1.5 },
};
