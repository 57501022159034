import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { TableCell } from '../../../components/Table';

type Props = {
  onClose: () => void;
  count: number | undefined;
  onCountChange: (count: number | undefined) => void;
  isPrinting: boolean;
  onConfirm: () => void;
};

const DonationBoxCountModal = ({ onClose, count, onCountChange, isPrinting, onConfirm }: Props) => {
  return (
    <Stack width={400} gap={1}>
      <Typography>박스 수량에 따라 송장 장수가 출력됩니다.</Typography>
      <TableCell
        sx={styles.boxContainer}
        label={'박스수량'}
        value={
          <TextField
            fullWidth
            value={count}
            onKeyDown={e => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={e => {
              if (e.target.value === '') {
                onCountChange(undefined);
                return;
              }

              const count = Number(e.target.value);
              if (isNaN(count) || count < 0) return;

              onCountChange(count);
            }}
          />
        }
        labelSx={styles.labelSx}
        valueSx={{ flex: 1 }}
        required={true}
      />
      <Stack flexDirection={'row'} justifyContent={'center'} gap={1} m={1}>
        <Button variant={'outlined'} onClick={onClose}>
          닫기
        </Button>
        <LoadingButton variant={'contained'} onClick={onConfirm} loading={isPrinting}>
          송장 출력
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default DonationBoxCountModal;

const styles = {
  boxContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
  },
  labelSx: {
    height: 60,
    width: '128px',
    fontSize: '13px',
  },
};
