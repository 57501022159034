import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { axiosV2 } from '../../../../../libs/common/fetcher';

export const excelUploadStocktakingLocations = async (
  stocktakingId: number,
  formData: FormData
) => {
  try {
    return await axiosV2.post(
      `/inventories/stocktakings/${stocktakingId}/locations/excel-upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`excelUpload 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
