import { atom } from 'recoil';

export const isScaleConnectedAtom = atom({
  key: 'isScaleConnectedAtom',
  default: false,
});

export const isPrinterConnectedAtom = atom({
  key: 'isPrinterConnectedAtom',
  default: false,
});

export const isDiagnosisOnAtom = atom({
  key: 'isDiagnosisOnAtom',
  default: true,
});

export const shouldOpenScaleListAtom = atom({
  key: 'shouldOpenScaleListAtom',
  default: false,
});

export const weightForDiagnosisAtom = atom({
  key: 'weightForDiagnosisAtom',
  default: '',
});

const diagnosisAtoms = {
  isScaleConnected: isScaleConnectedAtom,
  isPrinterConnected: isPrinterConnectedAtom,
  isDiagnosisOn: isDiagnosisOnAtom,
  shouldOpenScaleList: shouldOpenScaleListAtom,
  weightForDiagnosis: weightForDiagnosisAtom,
};

export default diagnosisAtoms;
