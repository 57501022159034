import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import Box from '@mui/system/Box';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import dayjs, { Dayjs } from 'dayjs';
import AutoContainImage from '../../../../../../components/common/Image/AutoContainImage';
import { COLORS } from '../../../../../../consts/common/colors';
import { getFinalUrl } from '../../../../../../consts/outbound/imageUrl';
import TextFieldForDataGrid from '../../../../../../components/common/TextFieldForDataGrid';
import { numberFormatter } from '../../../../../../libs/common/unitConverters';
import React from 'react';

const gridColDef = [
  {
    field: 'rowIndex',
    headerName: 'NO',
    minWidth: 60,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params: GridRenderCellParams) => {
      if (params.value === 'total') {
        return '합계';
      }

      return params.value;
    },
  },
  {
    field: 'barcode',
    headerName: '바코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'offlineBarcode',
    headerName: '물류 바코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'itemCode',
    headerName: '아이템코드',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'imageUrl',
    headerName: '이미지',
    minWidth: 80,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) =>
      params.value && (
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
          <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
        </Box>
      ),
  },
  {
    field: 'itemName',
    headerName: '아이템명',
    minWidth: 160,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      return (
        <p style={{ textAlign: 'start' }}>
          <span>{row.itemName}</span>
          <span style={{ color: 'red' }}>{row.remark ? ` => ${row.remark}` : ''}</span>
        </p>
      );
    },
  },
  {
    field: 'receivedQty',
    headerName: '입고반품등록수량',
    minWidth: 120,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ field, id, value, row }) => numberFormatter(value),
  },
  {
    field: 'cost',
    headerName: '입고공급단가(원)',
    minWidth: 110,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params: GridRenderCellParams) => {
      return params.value?.toLocaleString();
    },
  },
  {
    field: 'inspectedQty',
    renderHeader: () => (
      <span>
        {'검수수량'}
        <span style={{ color: COLORS.red, marginLeft: '2px' }}>*</span>
      </span>
    ),
    minWidth: 80,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ field, id, value, row }) => numberFormatter(value),
  },
  {
    field: 'totalInspectedCost',
    headerName: '소계(원)',
    minWidth: 110,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params: GridRenderCellParams) => {
      return params.value?.toLocaleString();
    },
  },
] as GridColDef[];

export default gridColDef;
