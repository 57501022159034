import { useRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import type { AxiosError } from 'axios';

import { barcodeAtom } from 'store/common/barcode.recoil';
import usePopup from 'hooks/usePopup';
import useApiErrorDialog from './useApiErrorDialog';
import mapPackingErrorToMessage from 'libs/outbound/mapErrorToMessage';
import { PACKING } from 'consts/outbound/messages';

const useBarcode = () => {
  const [barcode, setBarcode] = useRecoilState(barcodeAtom);
  const { showAlert } = usePopup();
  const showApiErrorDialog = useApiErrorDialog();

  const isBarcodeValid = () => {
    if (!barcode) {
      showAlert({ message: '바코드번호를 입력해주세요' });
      return false;
    }

    if (!barcode.toLowerCase().includes('tote')) {
      datadogRum.addAction(`유효한 바코드 아님: ${barcode}`, { barcode });
      showAlert({ message: `${PACKING.unrecognizableBarcode}\n바코드번호: ${barcode}` });
      setBarcode('');
      return false;
    }

    return true;
  };

  const isPackingBarcodeValid = () => {
    if (!barcode) {
      showAlert({ message: '바코드번호를 입력해주세요' });
      return false;
    }

    return true;
  };

  const handleAxiosError = () => {
    showAlert({ message: `${PACKING.unavailableBarcode}\n바코드번호: ${barcode}` });
    setBarcode('');
    datadogRum.addAction(`토트 바코드가 존재하지 않음: ${barcode}`, { barcode });
  };

  const handleApiError = (error: Error) => {
    const errorMessage = mapPackingErrorToMessage(error);
    setBarcode('');
    showApiErrorDialog(errorMessage, `${PACKING.orderFetchFailure}\n바코드번호: ${barcode}`);
    datadogRum.addAction(PACKING.orderFetchFailure, { barcode });
  };

  const handleFetchError = (error: AxiosError) => {
    if (error.response?.status === 404) {
      return handleAxiosError();
    }

    handleApiError(error);
  };

  const handleError = (error: AxiosError) => {
    handleApiError(error);
  };

  return {
    barcode,
    setBarcode,
    isBarcodeValid: isBarcodeValid,
    isPackingBarcodeValid: isPackingBarcodeValid,
    handleFetchError,
    handleError,
  };
};

export default useBarcode;
