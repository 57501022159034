import React, { useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import type { GridEventListener, GridEvents, GridRowId } from '@mui/x-data-grid-pro';
import Page from 'components/common/Layout/Page';
import FormModal from 'components/common/FormModal';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import fetcher, { axiosV2 } from 'libs/common/fetcher';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import gridColDef from './gridColDef';
import DevicesForm, { DeviceForm } from './components/DevicesForm';
import { adjustRows, generateDeviceForm, getSelectedDevice } from './utils';
import { Option } from '../../info/devices/components/SelectBase';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import usePopup from '../../../../hooks/usePopup';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import useConditionalSWR from '../../components/useConditionalSwr';

export type SearchQuery = {
  jobType: string;
  isDeviceAvailable: boolean;
  page?: number;
  size?: number;
  warehouseId?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };

const availableOptions: Option[] = [
  defaultOption,
  { displayName: '작업가능', value: true },
  { displayName: '작업제외', value: false },
];

const assignOptions = [
  defaultOption,
  { displayName: '미배정', value: false },
  { displayName: '배정완료', value: true },
];

const loginOptions = [
  defaultOption,
  { displayName: 'Y', value: 'loggedIn' },
  { displayName: 'N', value: 'loggedOut' },
];

const gridInputColumn = 'repeat(2, minmax(10px, 1fr))';
const gridTemplateColumns = ['auto', 'auto', 'auto', 'auto'].reduce(
  (prevValue, labelWidth) => prevValue + labelWidth + ' ' + gridInputColumn + ' ',
  ''
);

export type Devices = {
  id: number;
  deviceNumber: string;
  deviceType: string;
  workType: string;
  isWorkAvailable: boolean;
  isWorkAssigned: boolean;
  modifiedAt: string;
  warehouseId: string | number;
};

export type DeviceResponse = {
  content: Devices[];
  totalElements: number;
  size: number;
  number: number;
};

const DevicesWorks = () => {
  const jobType = 'OUTBOUND';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeviceForm, setSelectedDeviceForm] = useState<DeviceForm>();
  const [hasSearched, setHasSearched] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const { showErrorDialog } = usePopup();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>();
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    jobType: jobType,
    isDeviceAvailable: true,
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);

  const { data, mutate, isValidating } = useConditionalSWR<DeviceResponse>(
    [`/devices`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size, isDeviceAvailable, jobType }) => ({
      ...updatedForm,
      size,
      page: DEFAULT_PAGE,
      isDeviceAvailable,
      jobType,
    }));

    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitSearch = async () => {
    setQueryParams(() => ({
      jobType: jobType,
      isDeviceAvailable: true,
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    }));
    await mutate();
  };

  const deviceWorkTypeOptions = refCodeOptions?.workType || [];
  const deviceTypeOptions = refCodeOptions?.deviceType || [];

  const handleSelectionModelChange = (newSelectionModel: GridRowId[]) => {
    setSelectionModel(newSelectionModel);
  };

  const handleActiveWorkAvailable = async () => {
    await updateWorkAvailability(true);
    await mutate();
  };

  const handleInactiveWorkAvailable = async () => {
    await updateWorkAvailability(false);
    await mutate();
  };
  const updateWorkAvailability = async (isActive: boolean) => {
    const selectedDeviceIds = selectionModel?.map(id => Number(id));
    if (selectedDeviceIds === undefined || selectedDeviceIds?.length === 0) {
      showErrorDialog({
        title: '작업가능여부 변경 실패',
        errorMessage: '작업가능여부를 변경할 기기구분 ‘PDA’ 항목을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    const hasWorkAssigned = data?.content
      ?.filter(row => selectionModel?.includes(row.id))
      .some(row => row.isWorkAssigned);

    if (hasWorkAssigned) {
      showErrorDialog({
        title: '작업가능여부 변경 실패',
        errorMessage: '집품배정이 ‘배정완료‘ 상태입니다. \n집품배정을 취소한 후 변경해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    const url = `/devices/${isActive ? 'active' : 'inactive'}-work-available`;
    await axiosV2.put(url, { deviceIds: selectedDeviceIds });
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    if (!data?.content) {
      return;
    }
    const selectedDevice = getSelectedDevice(data.content, params.id as number);
    const deviceForm = generateDeviceForm(selectedDevice) as DeviceForm;
    setSelectedDeviceForm(deviceForm);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고작업기기 현황 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="기기구분"
          field="deviceType"
          options={[defaultOption, ...deviceTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Select
          label="작업파트구분"
          field="workType"
          options={[defaultOption, ...deviceWorkTypeOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.Select
          label="작업가능여부"
          field="isWorkAvailable"
          options={availableOptions}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.Select
          label="집품배정여부"
          field="isWorkAssigned"
          options={assignOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
        />
        <Filter.Select
          label="로그인여부"
          field="isLoggedIn"
          options={loginOptions}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Search label="작업자" field="worker" labelGridColumn="7/8" inputGridColumn="8/13" />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitSearch}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            작업기기 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              onClick={handleActiveWorkAvailable}
            >
              PDA ‘작업가능’ 저장
            </LoadingButton>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              onClick={handleInactiveWorkAvailable}
            >
              PDA ‘작업제외’ 저장
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? adjustRows(data) : []}
          columns={gridColDef(data?.content)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          getRowId={row => row.id}
          loading={isValidating}
          hasSearched={hasSearched}
          selectionModel={selectionModel}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={handleSelectionModelChange}
          isRowSelectable={params => params.row.deviceType === 'PDA'}
        />
      </Stack>
      <FormModal open={isModalOpen} title={'출고작업기기 수정'} onClose={handleCloseModal}>
        <DevicesForm
          selectedDevice={selectedDeviceForm}
          onClose={handleCloseModal}
          mutate={mutate}
        />
      </FormModal>
    </Page>
  );
};

export default DevicesWorks;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
