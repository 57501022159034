import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

type Props = {
  onSaveClick: () => void;
  onCloseClick: () => void;
  saveButtonName?: string;
  isLoading: boolean;
};

const SaveButtonGroup = (props: Props) => {
  const { onSaveClick, onCloseClick, isLoading, saveButtonName } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        marginTop: 2,
      }}
    >
      <Button variant="outlined" onClick={onCloseClick}>
        닫기
      </Button>
      <LoadingButton variant="contained" onClick={onSaveClick} loading={isLoading}>
        {saveButtonName ?? '저장'}
      </LoadingButton>
    </Box>
  );
};

export default SaveButtonGroup;
