import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../../consts/common/pageAndSize';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import FormModal from '../../../../../components/common/FormModal';
import convertUser from '../../../../../libs/common/convertUser';
import {
  warehouseAtom,
  warehouseOptionsAtom,
} from '../../../../../store/outbound/warehouse.recoil';
import useConditionalSWR from '../../../components/useConditionalSwr';
import AisleForm from './components/BayForm';
import gridColDef from './gridColDef';

const defaultOption = { displayName: '전체', value: 'ALL' };

export type Bay = {
  rowIndex: number;
  id: number;
  warehouseId: number;
  aisleId: number;
  baseCode: string;
  code: string;
  name: string;
  isActive: string;
  updatedUsername: string;
  updatedUserLoginId: string;
  updatedAt: string;
};

export type BayResponse = {
  content: Bay[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const gridTemplateColumns = [
  '80px',
  'minmax(45px, 1fr)',
  'minmax(45px, 1fr)',
  '80px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Bays = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const activeTypeOptions = refCodeOptions?.activeType || [];
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Bay>();
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<BayResponse>(
    [`/bays/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: BayResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Bay, index: number) => ({
      ...item,
      id: item.id,
      locationCode: item.baseCode + '-' + item.code,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedUserLoginId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    params.id && setIsModalOpen(true);
    const row = data?.content?.find(item => item.id === params.id);
    setSelectedRow(row);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(undefined);
  };

  const handleCreate = () => {
    setIsModalOpen(true);
  };
  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        구획 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={warehouseOption}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="사용여부"
          field="isActive"
          options={[defaultOption, ...activeTypeOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Divider
          sx={{
            gridRow: 2,
            gridColumn: '1/-1',
            pb: 1,
            mb: 1,
          }}
        />
        <Filter.DefaultButtonGroup
          sx={styles.filterDefaultButtonGroup}
          gridColumn="1/13"
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            구획 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCreate}
            >
              구획 등록
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={isModalOpen}
        title={selectedRow ? '구획 수정' : '구획 등록'}
        onClose={onCloseModal}
      >
        <AisleForm selectedRow={selectedRow} onClose={onCloseModal} onMutate={mutate} />
      </FormModal>
    </Page>
  );
};

export default Bays;

const styles = {
  filterDefaultButtonGroup: {
    justifySelf: 'center',
  },
  gridBorderTopTitle: {
    color: 'text.primary',
  },
  tightDataGrid: {
    bgColor: 'white',
    elevation: 6,
  },
};
