import React, { ReactNode } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import type { SxProps } from '@mui/material';

type Props = {
  onClick: () => void;
  children: ReactNode;
  sx?: SxProps;
  isLoading?: boolean;
  variant?: 'contained' | 'outlined';
};

const PackingButton = (props: Props) => {
  const { onClick, children, sx, isLoading = false, variant = 'contained' } = props;

  return (
    <LoadingButton
      onClick={onClick}
      variant={variant}
      sx={{
        height: '40px',
        fontSize: '17px',
        fontWeight: 700,
        ...sx,
      }}
      loading={isLoading}
    >
      {children}
    </LoadingButton>
  );
};

export default PackingButton;
