import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import useFilter from './hooks/useFilter';
import { buttonTheme } from 'styles/customedMuiTheme';
import { useRecoilValue } from 'recoil';
import { warehouseAtom } from 'store/outbound/warehouse.recoil';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

const InitButton = forwardRef((props: Props, ref) => {
  const { children, onClick } = props;
  const { setForm } = useFilter();
  const globalWarehouse = useRecoilValue(warehouseAtom);

  useImperativeHandle(ref, () => ({ handleClick }));

  const handleClick = () => {
    onClick && onClick();
    setForm({
      warehouseId: Number(globalWarehouse),
    });
  };

  return (
    <Button variant="outlined" onClick={handleClick} sx={buttonTheme.medium}>
      {children}
    </Button>
  );
});

export default InitButton;
