import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import type { ShippingInShippingOrder } from 'types/outbound';
import gridColDef from './gridColDef';
import { Options } from 'types/form';
import usePopup from 'hooks/usePopup';
import FormModal from 'components/common/FormModal';
import ShippingCompletedModal from './components/ShippingCompletedModal';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import useConditionalSWR from '../../components/useConditionalSwr';
import LoadingButton from '@mui/lab/LoadingButton';
import { RANGE } from '../../../../components/common/Filter/DateRangePickerWithSelect/RangeSelect';
import { GridRowId } from '@mui/x-data-grid-pro';
import ShippingDeletedModal from './components/ShippingDeletedModal';
const shippingOptions: Options = [
  {
    displayName: '배송번호',
    field: 'deliveryId',
  },
  {
    displayName: '배송ID',
    field: 'deliverySeq',
  },
  {
    displayName: '출고ID',
    field: 'shippingSerialNumber',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

type Shippings = ShippingInShippingOrder & {
  shippingSerialNumber: string;
  isStock: boolean;
  packingErrorCode: string;
  trackingNumber: string;
};

type ShippingResponse = {
  content: Shippings[];
  totalElements: number;
  size: number;
  number: number;
};

export type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};
const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const CsShippings = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const { showAlert } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const rangeSelectRef = useRef<{ selectRange: (value: string) => void }>();
  const [selectedShippingIds, setSelectedShippingIds] = useState<Shippings[]>([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenCompletedModal, setIsOpenCompletedModal] = useState(false);
  const { data, isValidating, mutate } = useConditionalSWR<ShippingResponse>(
    [`/shippings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );
  const makeRowIndex = (data: ShippingResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Shippings, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    rangeSelectRef.current?.selectRange(RANGE.lastMonth);

    setQueryParams(({ size }) => ({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    }));
  };

  const pageMutate = async () => {
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };
  const handleOpenDeleteModal = () => {
    if (selectedShippingIds.length === 0) {
      showAlert({ message: '삭제할 출고건을 선택해주세요.' });
      return;
    }
    // 1개만 선택 가능
    if (selectedShippingIds.length > 1) {
      showAlert({ message: '한 개의 출고건만 선택해주세요.' });
      return;
    }
    setIsOpenDeleteModal(true);
  };

  const handleOpenCompletedModal = async () => {
    if (selectedShippingIds.length === 0) {
      showAlert({ message: '출고(포장)완료할 출고건을 선택해주세요.' });
      return;
    }
    if (selectedShippingIds.length > 1) {
      showAlert({ message: '한 개의 출고건만 선택해주세요.' });
      return;
    }
    setIsOpenCompletedModal(true);
  };

  const handleCloseDeleteModal = async () => {
    setIsOpenDeleteModal(false);
    await pageMutate();
  };

  const handleCloseCompletedModal = async () => {
    setIsOpenCompletedModal(false);
    await pageMutate();
  };

  function getSelectedShippingIds(ids: GridRowId[]) {
    const selectedIds = data?.content.filter(item => ids.includes(item.id));
    setSelectedShippingIds(selectedIds ?? []);
  }

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고 관리(CS)
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.SearchWithSelect
          label="출고건"
          searchOptions={shippingOptions}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
          inputGridColumn="6/8"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            출고 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={styles.gridBorderTopButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleOpenCompletedModal}
            >
              {`출고(포장)완료`}
            </LoadingButton>
            <LoadingButton
              sx={styles.gridBorderTopButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleOpenDeleteModal}
            >
              {`출고삭제`}
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
          checkboxSelection
          selectionModel={selectedShippingIds.map(item => item.id)}
          onSelectionModelChange={ids => getSelectedShippingIds(ids)}
        />
      </Stack>
      <FormModal
        open={isOpenCompletedModal}
        title="출고(포장) 완료 CS"
        onClose={handleCloseCompletedModal}
      >
        <ShippingCompletedModal
          selectedRow={selectedShippingIds[0]}
          onClose={handleCloseCompletedModal}
        />
      </FormModal>
      <FormModal open={isOpenDeleteModal} title="출고 삭제 CS" onClose={handleCloseDeleteModal}>
        <ShippingDeletedModal
          selectedRow={selectedShippingIds[0]}
          onClose={handleCloseDeleteModal}
        />
      </FormModal>
    </Page>
  );
};

export default CsShippings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
