import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Page from '../../../../components/common/Layout/Page';
import Filter, { Form } from '../../../../components/common/Filter';
import TightDataGridPro from '../../../../components/common/TightDataGridPro';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import fetcher from '../../../../libs/common/fetcher';
import pageTitle from '../../../../styles/pageTitle';
import { listBoardTheme } from '../../../../styles/customedMuiTheme';
import { Options } from '../../../../types/form';
import usePopup from '../../../../hooks/usePopup';
import useConditionalSWR from '../../components/useConditionalSwr';
import { defaultOption } from '../../info/devices/index.page';
import makeGridColDef from './gridColDef';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Specification } from '../../components/SpecificationForPrint';
import { fetchReceivingItemsForSpecification } from './services';
import ReceivingItemsModal from './components/ReceivingItemsModal';
import { REACT_APP_BASE_PATH } from '../../../../consts/common/environment';
import useReceiveItemsFromSearch from '../../../../hooks/useReceiveItemsFromSearch';

const dateRangeTypeOptions: Options = [
  { displayName: '입고확정일', field: 'receivingCompletedAt' },
  { displayName: '입고일자', field: 'receivedDate' },
];

const searchKeywordOptions: Options = [
  { displayName: '공급업체', field: 'supplierName' },
  { displayName: '거래명세서명', field: 'receivingName' },
  { displayName: '발주서명', field: 'purchaseOrderName' },
];

const gridTemplateColumns = [
  '75px',
  'minmax(45px, 0.5fr)',
  'minmax(45px, 0.5fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '70px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
  '50px',
  'minmax(55px, 1.0fr)',
  'minmax(55px, 1.0fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

type SearchQuery = {
  page?: number;
  size?: number;
  status?: string;
  receivingType?: string;
  warehouseId?: number;
  step?: string;
  supplierName?: string;
  receivingCompletedAtFrom?: string;
  receivingCompletedAtTo?: string;
};

type InboundRegisteredReceivingContent = {
  warehouseId: number;
  name: string;
  receivedDate: string;
  purchaseOrderName: string;
  status: string;
  receivingId: string;
  supplierName: string;
  sku: number;
  quantity: number;
  totalCost: number;
  receivingType: string;
};

type InboundRegisteredReceiving = {
  content: InboundRegisteredReceivingContent[];
  totalElements: number;
  size: number;
  number: number;
};

const STEP = 'COMPLETED';

const SpecificationOfCompletedReceiving = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const state = Object.fromEntries(Array.from(searchParams.entries()));

  const globalWarehouse = useRecoilValue(warehouseAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingTypeOptions = refCodeOptions?.receivingType || [];
  const initButtonRef = useRef<{ handleClick: () => void }>();
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Specification | undefined>();
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    step: STEP,
    warehouseId: Number(globalWarehouse),
  });
  const { showErrorDialog } = usePopup();

  const warehouseOption = useRecoilValue(warehouseOptionsAtom);

  const dateRangePickerRef = useRef<{
    selectType: (value: string) => void;
    rangeDate: (value: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
  }>();

  const searchRef = useRef<{ inputText: (text: string) => void }>();

  useEffect(() => {
    if (state && state[`${state.dateType}From`]) {
      dateRangePickerRef.current?.selectType(state.dateType);
      dateRangePickerRef.current?.rangeDate([
        dayjs(state[`${state.dateType}From`]),
        dayjs(state[`${state.dateType}To`]),
      ]);

      searchRef.current?.inputText(state.supplierName);

      setQueryParams(({ size }) => ({
        warehouseId: Number(state.warehouseId),
        [`${state.dateType}From`]: state[`${state.dateType}From`],
        [`${state.dateType}To`]: state[`${state.dateType}To`],
        supplierName: state.supplierName,
        receivingType: state.receivingType,
        size,
        page: DEFAULT_PAGE,
        step: STEP,
      }));

      !hasSearched && setHasSearched(true);
    }
  }, []);

  const { data, isValidating, mutate } = useConditionalSWR<InboundRegisteredReceiving>(
    [`/receivings/specification/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: InboundRegisteredReceiving) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: InboundRegisteredReceivingContent, index: number) => ({
      ...item,
      id: item.receivingId,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      step: STEP,
      warehouseId: Number(globalWarehouse),
    });
    await pageMutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({
      ...updatedForm,
      size,
      page: DEFAULT_PAGE,
      step: STEP,
    }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };

  const pageMutate = async () => {
    await mutate();
    // await countMutate();
  };

  const handleClickTopBoardCount = async (field: string) => {
    initButtonRef.current && initButtonRef.current?.handleClick();
    !hasSearched && setHasSearched(true);

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      step: STEP,
    });
  };

  const handleCellClick = async (params: GridCellParams) => {
    if (params.field === 'specification') return;

    const response = await fetchReceivingItemsForSpecification(params.id);
    if (response?.status === 200) {
      setSelectedRow(response?.data);
    } else {
      showErrorDialog({
        title: '거래명세서 조회/입고목록 조회 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        거래명세서 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={state?.warehouseId ?? globalWarehouse}
        />
        {/*<Filter.Select*/}
        {/*  label="입고상태"*/}
        {/*  field="status"*/}
        {/*  options={[defaultOption, ...receivingStatusOptions]}*/}
        {/*  labelGridColumn="4/5"*/}
        {/*  selectGridColumn="5/6"*/}
        {/*  defaultValue={queryParams.status ?? 'ALL'}*/}
        {/*/>*/}
        <Filter.Select
          label="입고구분"
          field="receivingType"
          options={[defaultOption, ...receivingTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
          defaultValue={queryParams.receivingType ?? 'ALL'}
        />
        <Filter.DateRangePickerWithSelect
          ref={dateRangePickerRef}
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          ref={searchRef}
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          initRef={initButtonRef}
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            거래명세서 목록
          </Typography>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={makeGridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onCellClick={handleCellClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <ReceivingItemsModal
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        pageMutate={pageMutate}
      />
    </Page>
  );
};

export default SpecificationOfCompletedReceiving;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
