import { useEffect } from 'react';
import type { DataGridProProps } from '@mui/x-data-grid-pro';
import MuiPagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';
import {
  DataGridPro,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { COLORS } from 'consts/common/colors';
import { ROW_HEIGHT } from 'consts/common/dataGrid';

export type TightDataGridProps = DataGridProProps & {
  hasSearched?: boolean;
  selectedPickingGroupId?: number | null;
  customLocalText?: string;
};

const TightDataGridPro = (props: TightDataGridProps) => {
  const {
    onPageChange,
    rowsPerPageOptions,
    hasSearched,
    selectedPickingGroupId,
    checkboxSelection,
    customLocalText,
  } = props;

  return (
    <DataGridPro
      {...props}
      sx={{
        bgcolor: 'white',
        elevation: 6,
        '& .MuiDataGrid-row': { cursor: props.onRowClick && 'pointer' },
        '& .MuiDataGrid-row.Mui-selected': {
          backgroundColor: checkboxSelection ? 'white' : 'rgba(0, 98, 204, 0.08)',
        },
        [`& [data-id="${selectedPickingGroupId}"]`]: {
          backgroundColor: `rgba(0, 98, 204, 0.08) !important`,
        },
        '& .MuiDataGrid-row:hover': { bgcolor: COLORS.hoveredRow },
        '& .MuiDataGrid-columnHeaders': { bgcolor: COLORS.bgDeep },
        '& .MuiDataGrid-columnSeparator': { color: COLORS.bgDeep },
      }}
      pagination
      components={{
        Pagination: () => (
          <Pagination onPageChange={onPageChange} rowsPerPageOptions={rowsPerPageOptions} />
        ),
      }}
      rowHeight={ROW_HEIGHT}
      headerHeight={ROW_HEIGHT}
      componentsProps={{
        columnMenu: {
          sx: {
            '& .MuiMenuItem-root': { fontSize: '13px' },
          },
        },
        footer: {
          sx: {
            '& .MuiTablePagination-spacer': { flex: 0 },
          },
        },
      }}
      localeText={customLocalText ? { noRowsLabel: customLocalText } : makeNoRowsLabel(hasSearched)}
    />
  );
};

export default TightDataGridPro;

type Props = Pick<DataGridProProps, 'onPageChange' | 'rowsPerPageOptions'>;

const pageCountRef = { current: 0 };

const Pagination = (props: Props) => {
  const { onPageChange, rowsPerPageOptions } = props;
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  useEffect(() => {
    if (pageCount >= 0) {
      pageCountRef.current = pageCount;
    }
  }, [pageCount]);

  return (
    <TablePagination
      ActionsComponent={() => (
        <MuiPagination
          count={pageCount || pageCountRef.current}
          page={page + 1}
          color="primary"
          onChange={(event, page) => {
            apiRef.current.setPage(page - 1);
          }}
        />
      )}
      count={rowCount ?? 0}
      component="div"
      page={rowCount <= 0 ? 0 : page}
      onPageChange={(event, page) => {
        onPageChange && onPageChange(page, {});
      }}
      rowsPerPage={pageSize}
      onRowsPerPageChange={e => apiRef.current.setPageSize(Number(e.target.value))}
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage="페이지 당 행"
    />
  );
};

const makeNoRowsLabel = (hasSearched: boolean | undefined) => {
  return hasSearched
    ? { noRowsLabel: '조회 결과가 없습니다.' }
    : { noRowsLabel: '조회 조건 입력 후 ‘조회’ 버튼을 클릭해 주세요.' };
};
