import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';

export const getConveyor = async (id: number) => {
  try {
    return await axiosV2.get(`/conveyors/${id}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const updateConveyorPath = async (conveyorId: number, conveyorPath: string) => {
  try {
    return await axiosV2.patch(`/conveyors/${conveyorId}/path`, { conveyorPath });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type ConveyorCondition = {
  itemCount: number;
  comparisonType: string;
  minRealWeight: number;
  maxRealWeight: number;
  minLength: number;
  maxLength: number;
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
  minVolumeWeight: number;
  maxVolumeWeight: number;
};

export const updateConveyorCondition = async (conveyorId: number, condition: ConveyorCondition) => {
  try {
    return await axiosV2.put(`/conveyors/${conveyorId}/condition`, condition);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
