import { ChangeEvent, KeyboardEvent, useState } from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import type { SelectChangeEvent } from '@mui/material';
import useFilter from './hooks/useFilter';
import GridSection from '../GridSection';
import { selectTheme, listItemTextTheme } from 'styles/customedMuiTheme';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

type Props = {
  label: string;
  labelGridRow: `${number}/${number}`;
  labelGridColumn: `${number}/${number}`;
  selectOptions: { field: string; displayName: string }[];
  selectGridRow: `${number}/${number}`;
  selectGridColumn: `${number}/${number}`;
  defaultSelectValue?: string;
  textAreaGridRow: `${number}/${number}`;
  textAreaGridColumn: `${number}/${number}`;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
};

const VerticalSearchWithSelect = (props: Props) => {
  const {
    label,
    labelGridRow,
    labelGridColumn,
    selectOptions,
    selectGridRow,
    selectGridColumn,
    defaultSelectValue,
    textAreaGridRow,
    textAreaGridColumn,
    multiline = true,
    minRows = 1,
    maxRows = 1,
  } = props;
  const initialSelect = defaultSelectValue ?? selectOptions[0]?.field;
  const [selectedKeyword, setSelectedKeyword] = useState(initialSelect);
  const { form, setForm } = useFilter();

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const updatedForm = { ...form };

    updatedForm[e.target.value] = updatedForm[selectedKeyword];
    delete updatedForm[selectedKeyword];
    setForm(updatedForm);

    setSelectedKeyword(e.target.value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(form => ({ ...form, [selectedKeyword]: e.target.value.replaceAll('\n', ', ') }));
  };

  return (
    <>
      <GridSection sx={{ gridRow: labelGridRow, gridColumn: labelGridColumn }} isLabel>
        <Typography>{label}</Typography>
      </GridSection>
      <GridSection sx={{ gridRow: selectGridRow, gridColumn: selectGridColumn }}>
        <FormControl fullWidth>
          <Select
            id="search-type"
            value={selectedKeyword}
            onChange={handleSelectChange}
            sx={{
              '& .MuiSelect-select': {
                padding: 1,
              },
              ...selectTheme.medium,
            }}
          >
            {selectOptions.map(option => (
              <MenuItem key={option.displayName} value={option.field}>
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                    ...listItemTextTheme.medium,
                  }}
                  primary={option.displayName}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridSection>
      <GridSection sx={{ gridRow: textAreaGridRow, gridColumn: textAreaGridColumn }}>
        <TextField
          sx={{ '& .MuiInputBase-multiline': { p: 0.8 } }}
          minRows={minRows}
          maxRows={maxRows}
          multiline={multiline}
          fullWidth
          value={form[selectedKeyword] ?? ''}
          onChange={handleInputChange}
        ></TextField>
      </GridSection>
    </>
  );
};

export default VerticalSearchWithSelect;
