import { atom } from 'recoil';
import { Packing, Box } from '../../types/outbound';

export const stepAtom = atom({
  key: 'stepAtom',
  default: 0,
});

export const boxAtom = atom<Box | undefined>({
  key: 'boxAtom',
  default: undefined,
});

export const boxCodeAtom = atom({
  key: 'boxCodeAtom',
  default: '',
});

export const weightByScaleAtom = atom({
  key: 'weightByScaleAtom',
  default: '',
});

export const scaleDialogOpenAtom = atom({
  key: 'scaleDialogOpenAtom',
  default: true,
});

export const isFetchingPackingAtom = atom({
  key: 'isFetchingPackingAtom',
  default: false,
});

export const packingAtom = atom<Packing | undefined>({
  key: 'packingAtom',
  default: undefined,
});

export const stopModalOpenAtom = atom({
  key: 'stopModalOpenAtom',
  default: false,
});

const packingAtoms = {
  step: stepAtom,
  box: boxAtom,
  boxCode: boxCodeAtom,
  weightByScale: weightByScaleAtom,
  scaleDialogOpen: scaleDialogOpenAtom,
  isFetchingPacking: isFetchingPackingAtom,
  packing: packingAtom,
  stopModalOpen: stopModalOpenAtom,
};

export default packingAtoms;
