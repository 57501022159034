import Box from '@mui/system/Box';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AutoContainImage from 'components/common/Image/AutoContainImage';
import { getFinalUrl } from 'consts/outbound/imageUrl';

const gridColDef = [
  {
    field: 'rowNo',
    headerName: 'No',
    flex: 1,
    minWidth: 80,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'barcode',
    headerName: '바코드',
    flex: 2,
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'offlineBarcode',
    headerName: '오프라인 바코드',
    flex: 2,
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'itemCode',
    headerName: '아이템코드',
    flex: 2,
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'type',
    headerName: '종류',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'itemImageUrl',
    headerName: '이미지',
    flex: 1,
    minWidth: 60,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
        <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
      </Box>
    ),
  },
  {
    field: 'itemName',
    headerName: '아이템명',
    flex: 6,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'option',
    headerName: '옵션',
    flex: 2,
    minWidth: 90,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'availableQuantity',
    headerName: '창고가용수량',
    flex: 2,
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'releaseDate',
    headerName: '입고일',
    flex: 2,
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'weight',
    headerName: '실제중량무게(g)',
    flex: 2,
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'supplyPrice',
    headerName: '공급단가(원)',
    flex: 2,
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
] as GridColDef[];

export default gridColDef;
