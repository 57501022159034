import { useRecoilValue } from 'recoil';
import {
  warehouseAtom,
  warehouseOptionsAtom,
} from '../../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import { CarrierForm } from '../components/CarriersForm';

const generateCarrierRows = (carrier: CarrierForm) => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);

  return {
    basics: [
      {
        label: '창고',
        value: {
          type: 'typography',
          typography: convertWarehouseFullCode(Number(globalWarehouse), warehouseOption),
        },
      },
    ],
    details: [
      {
        label: '배송방식 이름',
        labelSx: styles.label,
        required: true,
        value: {
          type: 'input',
          input: {
            name: 'name',
            value: carrier.name,
          },
        },
      },
      {
        label: '정렬순서',
        labelSx: styles.label,
        required: true,
        value: {
          type: 'input',
          input: {
            name: 'displayOrder',
            value: carrier.displayOrder,
            type: 'number',
          },
        },
      },
      {
        label: '슈트번호',
        labelSx: styles.label,
        value: {
          type: 'input',
          input: {
            name: 'chuteNumber',
            value: carrier.chuteNumber,
            type: 'number',
          },
        },
      },
      {
        label: '최소무게(g)',
        labelSx: styles.label,
        required: true,
        value: {
          type: 'input',
          input: {
            name: 'minWeight',
            value: carrier.minWeight,
            type: 'number',
          },
        },
      },
      {
        label: '최대무게(g)',
        labelSx: styles.label,
        required: true,
        value: {
          type: 'input',
          input: {
            name: 'maxWeight',
            value: carrier.maxWeight,
            type: 'number',
          },
        },
      },
      {
        label: 'DIM',
        labelSx: styles.label,
        required: true,
        value: {
          type: 'input',
          input: {
            name: 'volumetricDivisor',
            value: carrier.volumetricDivisor,
            type: 'number',
          },
        },
      },
    ],
  };
};

export default generateCarrierRows;

const styles = {
  label: { width: '128px', fontSize: '13px' },
};
