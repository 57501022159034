import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { COLORS } from '../../../../../consts/common/colors';
import usePopup from '../../../../../hooks/usePopup';
import SaveButtonGroup from './SaveButtonGroup';
import { createBinFromExcel } from '../services';

type Props = {
  onCloseClick: () => void;
  onMutate: () => void;
};

const ExcelUpload = (props: Props) => {
  const { onCloseClick, onMutate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { showSnackbar, showAlert, showErrorDialog } = usePopup();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      showAlert({
        message: '파일을 선택해주세요.',
      });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    const response = await createBinFromExcel(formData);
    if (response?.status === 200) {
      setIsLoading(false);
      showSnackbar({ message: 'BIN이 생성되었습니다.', severity: 'success' });
      await onMutate();
      setIsLoading(false);
      onCloseClick();
    } else {
      showErrorDialog({
        title: 'BIN 엑셀 업로드 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      setIsLoading(false);
    }
  };
  const modalWidth = 600;
  return (
    <Box width={modalWidth}>
      <Box sx={styles.container}>
        <input
          accept=".xlsx,.xls"
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
      </Box>
      <Box>
        <SaveButtonGroup
          onSaveClick={handleUpload}
          onCloseClick={onCloseClick}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default ExcelUpload;

const styles = {
  container: {
    border: `1px solid ${COLORS.darkLine}`,
  },
  select: {
    height: '32px',
  },
};
