import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import axios, { AxiosError } from 'axios';
import { packingAtom, stepAtom } from 'store/outbound/packing.recoil';
import { isDiagnosisOnAtom } from 'store/outbound/diagnosis.recoil';
import borderedBox from 'styles/borderedBox';
import pageTitle from 'styles/pageTitle';
import getWaybillImage from 'libs/outbound/getWaybillImage';
import { isPrintingAtom, printerAtom } from 'store/common/printer.recoil';
import { PACKING } from 'consts/outbound/messages';
import { WAYBILL_FORMAT } from 'consts/outbound/waybillFormat';
import usePopup from 'hooks/usePopup';
import { isZpl } from '../../../../libs/common/isZpl';
import { barcodeAtom } from '../../../../store/common/barcode.recoil';
import ToteCodeLine from './components/ToteCodeLine';
import BoxLine from './components/BoxLine';
import WeightLine from './components/WeightLine';
import CompletionNotice from './components/CompletionNotice';
import Printer, { WAYBILL_INFO } from './components/Printer';
import Scale from './components/Scale';
import Page from 'components/common/Layout/Page';
import Timeline from '../components/Timeline';
import Dialog from 'components/common/Popup/Dialog';
import DiagnosisDialog from './components/DiagnosisDialog';
import { updatePacking } from './services/packing';
import FullScreenButton from '../components/FullScreenButton';

type ErrorResponse = { state: 'error'; errorMessage: string };
export const LAST_STEP = 2;

const Packing = () => {
  const step = useRecoilValue(stepAtom);
  const packing = useRecoilValue(packingAtom);
  const printer = useRecoilValue(printerAtom);
  const [reprintModalOpen, setReprintModalOpen] = useState(false);
  const setIsPrinting = useSetRecoilState(isPrintingAtom);
  const { showAlert, showErrorDialog } = usePopup();
  const setIsDiagnosisOn = useSetRecoilState(isDiagnosisOnAtom);
  const resetPacking = useResetRecoilState(packingAtom);
  const resetBarcode = useResetRecoilState(barcodeAtom);
  const resetStep = useResetRecoilState(stepAtom);

  useEffect(() => {
    if (!packing) {
      return;
    }
    resetPacking();
    resetBarcode();
    resetStep();
    const handleWindowChange = () => {
      packing.status === 'PROCESSING' && updatePacking({ id: packing.id, status: 'READY' });
    };

    window.addEventListener('beforeunload', handleWindowChange);

    return () => {
      window.removeEventListener('beforeunload', handleWindowChange);
    };
  }, [resetPacking, resetBarcode, resetStep]);

  const reprintWaybill = async () => {
    if (!printer) {
      showAlert({ message: PACKING.printNotPrepared });
      return;
    }

    const waybillInfo = localStorage.getItem(WAYBILL_INFO);
    if (!waybillInfo) {
      showAlert({ message: '이전 송장을 불러올 수 없습니다.' });
      return;
    }

    setIsPrinting(true);

    try {
      const { packingId } = JSON.parse(waybillInfo);
      const image = await getWaybillImage(packingId);
      if (isZpl(image)) {
        printer.send(
          `${image}`,
          () => setIsPrinting(false),
          (error: string) => {
            showErrorDialog({
              title: '송장 출력 실패',
              top: '송장 출력을 실패하였습니다',
              errorMessage: error,
              buttons: [{ text: '확인' }],
            });
            setIsPrinting(false);
          }
        );
      } else {
        printer.convertAndSendFile(
          `data:image/${WAYBILL_FORMAT};base64,${image}`,
          () => setIsPrinting(false),
          (error: string) => {
            showErrorDialog({
              title: '송장 출력 실패',
              top: '송장 출력을 실패하였습니다',
              errorMessage: error,
              buttons: [{ text: '확인' }],
            });
            setIsPrinting(false);
          }
        );
      }
    } catch (error) {
      const errorMessage = axios.isAxiosError(error)
        ? (error as AxiosError<ErrorResponse>).response?.data?.errorMessage ?? '알수 없는 에러'
        : (error as Error).message;
      showErrorDialog({
        title: 'S3 API 연동 실패',
        top: '송장을 불러오는 데 실패하였습니다.',
        errorMessage,
        buttons: [{ text: '확인' }],
      });
      setIsPrinting(false);
    }
  };

  const openDiagnosisDialog = () => {
    setIsDiagnosisOn(true);
  };

  return (
    <>
      <Printer />
      <Scale />
      <DiagnosisDialog />

      <Page>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={pageTitle}>
            개별포장 관리
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="outlined" onClick={() => setReprintModalOpen(true)}>
              이전 송장 재출력
            </Button>
            <Button variant="outlined" onClick={openDiagnosisDialog}>
              진단
            </Button>
            <FullScreenButton />
          </Box>
        </Box>
        <Box
          sx={{
            ...borderedBox,
            flex: 1,
            height: '90vh',
            padding: '32px',
          }}
        >
          <Timeline currentStep={step} sx={{ padding: 0, margin: 0 }}>
            <ToteCodeLine />
            <BoxLine />
            <WeightLine />
          </Timeline>
          {step === LAST_STEP + 1 && <CompletionNotice />}
        </Box>
      </Page>
      <Dialog
        open={reprintModalOpen}
        onClose={() => setReprintModalOpen(false)}
        buttons={[
          {
            text: '출력',
            onClick: () => reprintWaybill(),
            marked: true,
          },
          { text: '취소' },
        ]}
      >
        이전 포장의 송장을 재출력하시겠습니까?
      </Dialog>
    </>
  );
};

export default Packing;
