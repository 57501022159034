import type { GridColDef } from '@mui/x-data-grid-pro';
import { Warehouse } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';

const gridColumns = (warehouseOption: Warehouse[]) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const jobTypeOptions = refCodeOptions?.jobType || [];
  const columns: GridColDef[] = [
    {
      flex: 1,
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      flex: 1,
      field: 'warehouseId',
      headerName: '창고',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      flex: 2,
      field: 'deviceType',
      headerName: '기기구분',
      headerAlign: 'center',
      align: 'center',
    },
    {
      flex: 2,
      field: 'jobType',
      headerName: '업무파트',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => jobTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      flex: 2,
      field: 'deviceNumber',
      headerName: '작업기기번호',
      headerAlign: 'center',
      align: 'center',
    },
    {
      flex: 2,
      field: 'isForInventoryInvestigation',
      headerName: '전수재고조사',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => (value ? 'Y' : 'N'),
    },
    {
      flex: 4,
      field: 'registrationNumber',
      headerName: '기기고유번호',
      headerAlign: 'center',
      align: 'center',
    },
    {
      flex: 2,
      field: 'isDeviceAvailable',
      headerName: '사용여부',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => (value ? '사용' : '사용안함'),
    },
    {
      flex: 2,
      field: 'updatedUser',
      headerName: '수정자',
      headerAlign: 'center',
      align: 'center',
    },
    {
      flex: 2,
      field: 'updatedAt',
      headerName: '수정일시',
      headerAlign: 'center',
      align: 'center',
    },
  ];
  return columns;
};

export default gridColumns;
