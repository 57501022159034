export const REACT_APP_API_ENDPOINT_V2 =
  process.env.REACT_APP_API_ENDPOINT_V2 ?? 'https://dev-gms.ktown4u.io/api/v2';
export const REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH ?? '';
export const INVOICE_IMAGE_BUCKET_NAME = process.env.INVOICE_IMAGE_BUCKET_NAME ?? '';
export const REACT_APP_STAGE = process.env.REACT_APP_STAGE ?? '';
export const STAGE = process.env.STAGE ?? '';
export const GMS_TEMPLATES_URL = process.env.GMS_TEMPLATES_URL ?? '';
export const DHL_USERNAME = process.env.DHL_USERNAME ?? '';
export const DHL_PASSWORD = process.env.DHL_PASSWORD ?? '';
export const REACT_APP_DHL_SHIPMENT_REQUEST_URL =
  process.env.REACT_APP_DHL_SHIPMENT_REQUEST_URL ?? '';
export const REACT_APP_MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY ?? '';
