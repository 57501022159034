import { Carrier } from '../../store/outbound/carrier.recoil';

export const convertCarrierId = (value: number | undefined, carriers: Carrier[]) => {
  if (!value) {
    return '';
  }
  return carriers
    .filter(v => {
      if (v.id === value) {
        return v;
      }
    })
    .map(v => {
      return v.name;
    });
};
