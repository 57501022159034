import { useRecoilValue } from 'recoil';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';

type Option = {
  displayName: string;
  value: string;
};

const makeGridColDef = (receivingStatusOptions: Option[]) => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);

  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 70,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return warehouseOption.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'receivedDate',
      headerName: '입고일',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    // {
    //   field: 'inspectionAt', //TODO: field 확인요망
    //   headerName: '검수일',
    //   minWidth: 100,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    // },
    // {
    //   field: 'earlyOrderType', //TODO: field 확인요망
    //   headerName: '선주문 구분',
    //   minWidth: 80,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    // },
    {
      field: 'status',
      headerName: '입고확정상태',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return receivingStatusOptions.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'name',
      headerName: '입고명세서', //TODO: 입고ID 대신 임시로 입고명세서명으로 대체함. 차후 원복 예정
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'supplierName',
      headerName: '공급업체',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'sku',
      headerName: '품목수량',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return params.value?.toLocaleString();
      },
    },
    {
      field: 'quantity',
      headerName: '입고등록 수량',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return params.value?.toLocaleString();
      },
    },
    {
      field: 'inspectedCount', //TODO: field 확인요망
      headerName: '정상수량',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return params.value?.toLocaleString();
      },
    },
    {
      field: 'totalCost',
      headerName: '공급가 합계',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return params.value?.toLocaleString();
      },
    },
  ] as GridColDef[];
};

export default makeGridColDef;
