import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import React, { KeyboardEvent, useState } from 'react';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/common/colors';
import Barcode from '../../../../../components/common/Barcode';
import { BARCODE_SCAN_ACTION } from '../../../../../consts/outbound/barcodeScanAction';
import usePopup from '../../../../../hooks/usePopup';
import CompletionCard from '../../components/CompletionCard';
import InvisibleInput from '../../components/InvisibleInput';
import useInitialization from '../hooks/useInitialization';

const CompletionNotice = () => {
  const initializeStates = useInitialization();
  const [scannedBarcode, setScannedBarcode] = useState('');
  const { showAlert } = usePopup();
  const handleCombineTotes = () => {
    initializeStates();
  };

  const koreaFilter = () => {
    const koreanRegex =
      /^.*[가-힣ㄱ-ㅎㅏ-ㅣ\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F].*$/;

    if (koreanRegex.test(scannedBarcode)) {
      showAlert({ message: '한글 문자열이 포함되어 있습니다.' });
      return true;
    }
    return false;
  };

  const handleScannedBarcode = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    if (koreaFilter()) {
      setScannedBarcode('');
      return;
    }
    if (scannedBarcode.toLowerCase() === BARCODE_SCAN_ACTION.finishChangeTote) {
      handleCombineTotes();
    }
    setScannedBarcode('');
  };

  return (
    <CompletionCard
      content={
        <>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                color: COLORS.veryDarkGrey,
              }}
            >
              토트 이관이 완료되었습니다.
            </Typography>
            <Box style={{ display: 'flex' }}>
              <Barcode value={BARCODE_SCAN_ACTION.finishChangeTote} height={40} />
              <InvisibleInput
                value={scannedBarcode}
                onChange={e => setScannedBarcode(e.target.value)}
                onKeyDown={handleScannedBarcode}
              />
              <Button
                onClick={handleCombineTotes}
                size="large"
                variant="contained"
                sx={styles.button}
              >
                다음 토트이관 시작
              </Button>
            </Box>
          </Box>
        </>
      }
    />
  );
};

export default CompletionNotice;

const styles = {
  button: {
    marginLeft: '20px',
    height: '40px',
    fontSize: '17px',
  },
};
