import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import warehouseAtom, { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import gridColDef from './gridColDef';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import convertUser from '../../../../libs/common/convertUser';
import useConditionalSWR from '../../components/useConditionalSwr';
import ConveyorPathFormModal from './components/ConveyorPathFormModal';
import ConveyorConditionFormModal from './components/ConveyorConditionFormModal';

type conveyor = {
  id: number;
  basePath: string;
  boBoxes: string[];
  conveyorPath: string;
  deliveryType: string;
  isDefault: boolean;
  isTube: boolean;
  itemCountComparison: object;
  updatedAt: string;
  weightComparison: object;
  volumeWeightComparison: object;
  lengthComparison: object;
  widthComparison: object;
  heightComparison: object;
  updatedUsername: string;
  updatedLoginId: string;
};

export type ConveyorResponse = {
  content: conveyor[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Conveyors = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [isPathChangeModalOpen, setIsPathChangeModalOpen] = useState(false);
  const [isConditionChangeModalOpen, setIsConditionChangeModalOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const { data, isValidating, mutate } = useConditionalSWR<ConveyorResponse>(
    [`/conveyors/search`, { ...queryParams, sort: 'id' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: ConveyorResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: conveyor, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      conveyorName: item.conveyorPath,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleCellClick = (id: number, pathType: string) => {
    setSelectedId(id);

    if (pathType === 'conveyorPath') {
      setIsPathChangeModalOpen(true);
    } else if (pathType === 'basePath') {
      setIsConditionChangeModalOpen(true);
    }
  };

  const onCloseModal = () => {
    setSelectedId(-1);
    isPathChangeModalOpen && setIsPathChangeModalOpen(false);
    isConditionChangeModalOpen && setIsConditionChangeModalOpen(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        컨베이어 포장경로 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            컨베이어 포장경로 목록
          </Typography>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef(handleCellClick)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <ConveyorPathFormModal
        conveyorId={selectedId}
        isModalOpen={isPathChangeModalOpen}
        onClose={onCloseModal}
        onMutate={mutate}
      />
      <ConveyorConditionFormModal
        conveyorId={selectedId}
        isModalOpen={isConditionChangeModalOpen}
        onClose={onCloseModal}
        onMutate={mutate}
      />
    </Page>
  );
};

export default Conveyors;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
