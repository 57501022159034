import { GridRowId, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';

const TextFieldForDataGrid = ({
  field,
  id,
  value,
}: {
  field: string;
  id: GridRowId;
  value: string;
}) => {
  const apiRef = useGridApiContext();

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replaceAll(',', '');
    if (isNaN(Number(newValue))) return;

    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
    apiRef.current.commitCellChange({ id, field });
    apiRef.current.setCellMode(id, field, 'view');
  };

  return (
    <TextField
      variant="outlined"
      sx={{ '& .MuiOutlinedInput-root': { height: 30, fontSize: 13 } }}
      value={Number(value).toLocaleString()}
      onChange={handleChange}
    />
  );
};

export default TextFieldForDataGrid;
