import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import borderedBox from 'styles/borderedBox';
import { COLORS } from 'consts/common/colors';

type Props = {
  data: { title: string; value: number | undefined | any; field?: string }[];
  onClick?: (field: string) => void;
};

const TopBoard = ({ data, onClick }: Props) => {
  if (data.length > 4) {
    throw new Error('상단 보드에서는 최대 4개의 항목을 보여줄 수 있습니다');
  }

  return (
    <Box sx={styles.failedShippingQuantities}>
      {data.map((item, idx) => {
        return (
          <Box key={item.title} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6" sx={{ mr: 2 }}>
              {item.title}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: COLORS.ktown4u,
                fontWeight: 'bold',
                textDecoration: item.field ? 'underline' : undefined,
              }}
              onClick={() => {
                item.field && onClick ? onClick(item.field) : undefined;
              }}
            >
              {item.value?.toLocaleString()}
            </Typography>
            {data.length - 1 > idx && (
              <Typography variant="h6" sx={{ mx: 2 }}>
                |
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default TopBoard;

const styles = {
  failedShippingQuantities: {
    ...borderedBox,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    fontSize: 20,
  },
};
