import type { SelectChangeEvent } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FONT_SIZE } from '../../../../../../consts/common/typography';
import usePopup from '../../../../../../hooks/usePopup';
import SaveButtonGroup from '../../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../../components/Table2';
import { Warehouse, WarehouseResponse } from '../index.page';
import { create, CreateWarehouseRequest, getCenters, getDetail, update } from '../services';

const modalWidth = 480;
const modalFontSize = FONT_SIZE.medium;
export type WarehouseForm = Pick<Warehouse, 'isActive' | 'code' | 'name' | 'centerId'> & {
  baseCode: string;
  managerName?: string;
  departmentName?: string;
  managerContact?: string;
  city?: string;
  zipCode?: string;
  address1?: string;
  address2?: string;
};

type Props = {
  selectedRow?: Warehouse;
  onClose: () => void;
  onMutate: () => Promise<WarehouseResponse | undefined>;
};

const initialState: WarehouseForm = {
  isActive: 'ACTIVE',
  code: '',
  name: '',
  baseCode: '',
  centerId: -1,
};

type CenterCodeOption = { id: number; label: string; code: string };

const WarehouseForm = (props: Props) => {
  const { onClose, onMutate, selectedRow } = props;
  const [form, setForm] = useState<WarehouseForm>({ ...initialState });
  const [centerCodeOptions, setCenterCodeOptions] = useState<CenterCodeOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar, showAlert } = usePopup();

  useEffect(() => {
    setCenterOptions();
    if (!!selectedRow) {
      const warehouse = selectedRow?.warehouseId;
      setWarehouseDetail(warehouse);
      return;
    }
    setForm(() => ({ ...initialState }));
  }, [selectedRow]);

  const setWarehouseDetail = async (warehouseId: number | undefined) => {
    const response = await getDetail(warehouseId);

    if (response?.status === 200) {
      setForm({ ...response?.data });
    }
  };

  const setCenterOptions = async () => {
    const response = await getCenters();
    if (response?.status === 200) {
      const res = response?.data.map((item: { centerId: number; code: string; name: string }) => ({
        id: item.centerId,
        label: item.name,
        code: item.code, // baseCode
      }));
      setCenterCodeOptions(res);
    }
  };

  const handleChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = event.target;
    setForm({ ...form, [name]: makeValue(event.target) });
  };
  const handleSave = async () => {
    setIsLoading(true);

    if (selectedRow) {
      const requestBody: CreateWarehouseRequest = {
        centerId: form.centerId,
        name: form.name,
        code: form.code,
        isActive: form.isActive,
        managerName: form.managerName,
        departmentName: form.departmentName,
        managerContact: form.managerContact,
        city: form.city,
        zipCode: form.zipCode,
        address1: form.address1,
        address2: form.address2,
      };

      const res = await update(selectedRow.warehouseId, requestBody);
      if (res?.status !== 200) {
        showAlert({ message: res?.data?.errorMessage });
        setIsLoading(false);
        return;
      }

      showSnackbar({ message: '창고 정보가 수정 완료되었습니다.', severity: 'success' });
      await onMutate();
      onClose();
      setIsLoading(false);
      return;
    }

    const requestBody: CreateWarehouseRequest = { ...form };

    const res = await create(requestBody);
    if (res?.status !== 200) {
      showAlert({ message: res?.data?.errorMessage });
      setIsLoading(false);
      return;
    }

    showSnackbar({ message: '창고 정보가 생성 완료되었습니다.', severity: 'success' });
    await onMutate();
    onClose();
    setIsLoading(false);
  };

  const updateFromAutocomplete = (value: CenterCodeOption) => {
    setForm(form => ({
      ...form,
      centerId: value.id ? Number(value.id) : -1,
      baseCode: value.code ?? '',
    }));
  };
  console.log(form);

  const findCompanyOption = centerCodeOptions.find(option => option.id === form.centerId);
  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>기본정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'센터'} labelSx={styles.label} required={!selectedRow}>
            {selectedRow ? (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {findCompanyOption?.label ?? ''}
              </Typography>
            ) : (
              <Autocomplete
                disablePortal
                options={centerCodeOptions ?? []}
                onBlur={() => {
                  setForm({ ...form, centerId: findCompanyOption?.id ?? -1 });
                }}
                onChange={(event, value) => {
                  !!value && updateFromAutocomplete(value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={styles.autocomplete}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{ ...params.InputProps, sx: styles.inputProps }}
                    placeholder={'선택'}
                  />
                )}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'창고코드'} labelSx={styles.label} required={!selectedRow}>
            {selectedRow ? (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {form.code}
              </Typography>
            ) : (
              <FormControl fullWidth>
                <OutlinedInput
                  name={'code'}
                  value={form.code}
                  onChange={handleChange}
                  sx={styles.input}
                  inputProps={{ maxLength: 1 }}
                  type={'text'}
                  placeholder={'문자 1자리만 입력가능'}
                />
              </FormControl>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'로케이션코드'} labelSx={styles.label}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {form.baseCode}
              {form.code}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'창고명'} labelSx={styles.label} required={!selectedRow}>
            <FormControl fullWidth>
              <OutlinedInput
                name={'name'}
                value={form.name}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
                placeholder={'창고명을 입력해주세요.'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'사용여부'} labelSx={styles.label}>
            <Box sx={styles.rowCenter}>
              <Checkbox
                name={'isActive'}
                checked={form.isActive === 'ACTIVE'}
                size="small"
                sx={styles.checkbox}
                onChange={handleChange}
              />
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {'사용'}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </Table>
      <Box>
        <Typography sx={styles.modalSubTitle}>담당자정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'담당자명'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'managerName'}
                value={form.managerName}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'부서명'} labelSx={styles.label}>
            <FormControl fullWidth>
              <OutlinedInput
                name={'departmentName'}
                value={form.departmentName}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'전화번호'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'managerContact'}
                value={form.managerContact}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
      </Table>
      <Box>
        <Typography sx={styles.modalSubTitle}>입고주소정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'도시'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'city'}
                value={form.city}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'우편번호'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'zipCode'}
                value={form.zipCode}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'주소1'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'address1'}
                value={form.address1}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'주소2'} labelSx={styles.label} required>
            <FormControl fullWidth>
              <OutlinedInput
                name={'address2'}
                value={form.address2}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
      </Table>
      <SaveButtonGroup onSaveClick={handleSave} onCloseClick={onClose} isLoading={isLoading} />
    </Box>
  );
};

export default WarehouseForm;

type Target =
  | (EventTarget & (HTMLInputElement | HTMLTextAreaElement))
  | (EventTarget & { value: string; name: string });

const makeValue = (target: Target) => {
  if ('checked' in target) {
    if (target.name === 'isActive') {
      return target.checked ? 'ACTIVE' : 'INACTIVE';
    }
  }

  return target.value;
};

const styles = {
  select: { width: 344, ml: -1.5 },
  input: { height: 28, width: 344, ml: -1.5, fontSize: modalFontSize },
  checkbox: { pl: 0, pr: 0.6 },
  smallInput: { height: 28, width: 120, fontSize: modalFontSize },
  modalSubTitle: { fontSize: 20, mb: 1.5 },
  rowHeight: { height: 36 },
  label: { width: 128, fontSize: modalFontSize },
  coordinateBox: {
    width: 344,
    ml: -1.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  coordinateBlank: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  autocomplete: { width: 344, height: 26, ml: -1.5 },
  inputProps: {
    height: 28,
    fontSize: modalFontSize,
    '& .MuiAutocomplete-input': { mt: -1.5 },
  },
};
