import { axiosV2 } from '../../../../../libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

const getInspectionImage = async (inspectionId: number) => {
  try {
    return await axiosV2.get(`/inspections/${inspectionId}/images`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`검수 출고건 이미지 응답 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export default getInspectionImage;
