import useSWR, { KeyedMutator } from 'swr';
import { useEffect } from 'react';

function useConditionalSWR<Data>(
  url: any,
  fetcher: any,
  hasSearch: boolean
): {
  data: Data | undefined;
  isValidating: boolean;
  mutate: KeyedMutator<Data>;
  error: Error | undefined | any;
} {
  const finalUrl = hasSearch ? url : null;

  const { data, isValidating, mutate, error } = useSWR<Data>(finalUrl, fetcher);

  useEffect(() => {
    if (hasSearch) {
      mutate();
    }
  }, [hasSearch, mutate]);

  return { data, isValidating, mutate, error };
}

export default useConditionalSWR;
