import { axiosV2 } from 'libs/common/fetcher';
import axios, { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';
import { REACT_APP_API_ENDPOINT_V2 } from '../../../../../consts/common/environment';
import FileDownload from 'js-file-download';
export const getPackingIdsToCompleted = async (bulkPackingId: number) => {
  try {
    return await axiosV2.get(`/bulk-packings/${bulkPackingId}/completed/packing-ids`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`운송장 출력 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getUnmatchedBulkPackingItems = async (bulkPackingId: number) => {
  try {
    return await axiosV2.get(`/bulk-packings/${bulkPackingId}/completed/inventory-matching`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`대량포장 재고 불일치 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const completePackingOfBulkPacking = async (bulkPackingId: number, packingId: number) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/completed/${packingId}`);
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      datadogRum.addAction(`대량포장 개별 완료처리 실패 : ${(error as AxiosError).response?.data}`);
      return { status: 404 };
    }

    datadogRum.addAction(`대량포장 개별 완료처리 실패 : ${(error as AxiosError).response?.data}`);
    return { status: (error as AxiosError).response?.status };
  }
};

export const completeBulkPacking = async (bulkPackingId: number) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/completed`);
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      datadogRum.addAction(`대량포장 그룹 완료처리 실패 : ${(error as AxiosError).response?.data}`);
      return { status: 404 };
    }

    datadogRum.addAction(`대량포장 그룹 완료처리 실패 : ${(error as AxiosError).response?.data}`);
    return { status: (error as AxiosError).response?.status };
  }
};

export const exceptErrorPackings = async (bulkPackingId: number) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/except-packings`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`출고지시서 생성 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

// 파일이름의 중복을 없애기 위해 현재 시간을 문자열로 반환하는 함수
// ex. shippings_20230913175901.xls
const getCurrentDateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const getPackingIdsToPrintWaybill = async (bulkPackingId: number) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/printing-waybills`, {});
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`운송장 출력 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const printWaybill = async (bulkPackingId: number, packingIds: number[]) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/printing-waybills/new`, {
      packingIds: packingIds,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`운송장 출력 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const updatePackingToPrinted = async (bulkPackingId: number, packingIds: number[]) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/printing-waybills/printed`, {
      packingIds: packingIds,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(
      `대량포장 출력완료상태 업데이트 실패 : ${error1.response} packingId: ${packingIds}`
    );
    return error1.response;
  }
};

export const generateShippingExcelDownload = async (bulkPackingId: number) => {
  try {
    const url = REACT_APP_API_ENDPOINT_V2 + `/bulk-packings/${bulkPackingId}/excel-download`;
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    const filename = `shippings_${getCurrentDateTimeString()}.xlsx`;
    FileDownload(response.data, filename);
    return response;
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    const promise = await blobToJson(error1.response?.data);
    datadogRum.addAction(`엑셀 다운로드 실패 : ${promise}`);
    return promise;
  }
};

export const createWaybillImage = async (bulkPackingId: number) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/create-waybill-images`, {});
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`대량포장 송장 이미지 생성 실패 : ${error1.response}`);
    return error1.response;
  }
};

const blobToJson = (blob: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsText(blob);
  });
};
