import axios, { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { REACT_APP_API_ENDPOINT_V2 } from '../../../../../consts/common/environment';
import FileDownload from 'js-file-download';
import { SearchQuery } from '../../../outbound/packings/index.page';

// 파일이름의 중복을 없애기 위해 현재 시간을 문자열로 반환하는 함수
// ex. packings_20230913175901.xls
const getCurrentDateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const generateExcelDownload = async (queryParams: SearchQuery) => {
  try {
    const url = REACT_APP_API_ENDPOINT_V2 + `/inventories/excel-download`;
    const response = await axios.get(url, {
      responseType: 'blob',
      params: {
        ...queryParams,
      },
    });
    const filename = `아이템별 재고조회_${getCurrentDateTimeString()}.xlsx`;
    FileDownload(response.data, filename);
    return response;
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    const promise = await blobToJson(error1.response?.data);
    datadogRum.addAction(`엑셀 다운로드 실패 : ${promise}`);
    return promise;
  }
};

const blobToJson = (blob: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsText(blob);
  });
};
