import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ERROR_CODE } from 'consts/outbound/messages';
import { COLORS } from 'consts/common/colors';

export type ApiFailureProps = {
  title: string;
  top?: string;
  bottom?: string;
  errorCode?: string;
  errorMessage: string;
};

const ApiFailure = (props: ApiFailureProps) => {
  const { errorCode = '', errorMessage, title, top, bottom } = props;
  const mappedMessage = ERROR_CODE[errorCode];

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" sx={{ paddingBottom: '24px ', fontSize: '24px', fontWeight: 700 }}>
        {title}
      </Typography>
      <Typography
        sx={{
          paddingBottom: '16px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        }}
      >
        {top}
      </Typography>
      <Stack sx={boxStyle}>
        {errorCode && (
          <Box display="flex" gap="8px">
            <Typography>* 오류코드:</Typography>
            <Typography sx={{ fontWeight: 700, color: COLORS.red }}>{errorCode}</Typography>
          </Box>
        )}
        {mappedMessage && (
          <Typography sx={{ fontWeight: 700, color: COLORS.red }}>{mappedMessage}</Typography>
        )}
        <Typography>{errorMessage}</Typography>
      </Stack>
      <Typography sx={{ paddingTop: '8px' }}>{bottom}</Typography>
    </Stack>
  );
};

const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  width: '456px',
  marginBottom: '8px',
  padding: '8px',
  border: `1px solid ${COLORS.darkLine}`,
  borderRadius: '4px',
  wordBreak: 'break-all',
};

export default ApiFailure;
