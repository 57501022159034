import { useEffect } from 'react';
import { useRecoilSnapshot } from 'recoil';
import { STAGE } from 'consts/common/environment';

const DebugRecoil = (): null => {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    if (STAGE === 'production') {
      return;
    }

    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug('@:', node.key, snapshot.getLoadable(node).contents);
    }
  }, [snapshot]);

  return null;
};

export default DebugRecoil;
