import { useContext } from 'react';
import { TimelineStepContext } from '../components/Timeline';

const useTimelineCurrentStep = () => {
  const step = useContext(TimelineStepContext);

  if (step == null) {
    throw new Error('useTimelineCurrentStep must be used within <Timeline>');
  }

  return step;
};

export default useTimelineCurrentStep;
