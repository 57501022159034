import React, { useState } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import usePopup from 'hooks/usePopup';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import { updateLpn, UpdateLpnRequest } from '../services/lpn';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { inputTheme } from '../../../../../styles/customedMuiTheme';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const locale = 'ko';
const modalWidth = 550;
const modalFontSize = FONT_SIZE.medium;
type Props = {
  lpnId: number;
  warehouseId: number;
  itemCode: string;
  itemName: string;
  serialNumber: string;
  expirationDate: string;
  onClose: () => void;
  onMutate: () => void;
};

const LpnForm = (props: Props) => {
  const {
    onClose,
    onMutate,
    lpnId,
    warehouseId,
    itemCode,
    itemName,
    serialNumber,
    expirationDate,
  } = props;
  const { showErrorDialog, showSnackbar, showAlert } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [updatedExpirationDate, setUpdatedExpirationDate] = useState<Dayjs | null>(
    expirationDate ? dayjs(expirationDate) : dayjs(new Date())
  );
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);

  const isValidateForUpdate = () => {
    if (updatedExpirationDate === null) {
      showAlert({ message: '유효기한를 입력해 주세요.' });
      setIsLoading(false);
      return false;
    }
    return true;
  };

  const handleExpirationDateChange = (date: Dayjs | null) => {
    setUpdatedExpirationDate(date);
  };

  const handleUpdate = async () => {
    setIsLoading(true);

    if (!isValidateForUpdate()) return;

    const request: UpdateLpnRequest = {
      lpnId,
      expirationDate: updatedExpirationDate?.format('YYYY-MM-DD') || '',
    };

    const response = await updateLpn(request);
    if (response?.status === 200) {
      showSnackbar({ message: 'LPN 수정이 완료되었습니다.', severity: 'success' });
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: 'LPN 수정 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>LPN 기본정보</Typography>
      </Box>
      <Table>
        <TableRow>
          <TableCell
            label={'창고'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
            required={!lpnId}
          >
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {convertWarehouseFullCode(warehouseId, warehouseOption)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'아이템코드'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
            required={!lpnId}
          >
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {itemCode}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'아이템명'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
            required={!lpnId}
          >
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {itemName}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'LPN'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
            required={!lpnId}
          >
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {serialNumber}
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
      <Box>
        <Typography sx={styles.modalSubTitle}>LPN 관리정보</Typography>
      </Box>
      <Table>
        <TableRow>
          <TableCell
            label={'유효기간'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
            required={!lpnId}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
              <DemoContainer components={['DatePicker']} sx={styles.dateContainer}>
                <DemoItem>
                  <DatePicker
                    defaultValue={dayjs(expirationDate)}
                    onChange={handleExpirationDateChange}
                    format={'YYYY-MM-DD'}
                    slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
                    slotProps={{ actionBar: { actions: ['today'] } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </TableCell>
        </TableRow>
      </Table>
      <SaveButtonGroup onSaveClick={handleUpdate} onCloseClick={onClose} isLoading={isLoading} />
    </Box>
  );
};

export default LpnForm;
const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  label: { minHeight: 34, height: 'auto', minWidth: 120, maxWidth: 120, fontSize: modalFontSize },
  itemCell: { alignItems: 'stretch' },
  itemValue: { display: 'flex', alignItems: 'center', my: 1 },
  input: { height: 28, width: 420, ml: -1.5, fontSize: modalFontSize },
  dateContainer: {
    ...inputTheme.medium,
    overflow: 'hidden',
    p: '0px',
  },
};
