import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { axiosV2 } from '../../../../../libs/common/fetcher';

export const updateShippingPickingWorkflow = async (requestBody: {
  shippingIds: number[];
  conveyorPath: string;
}) => {
  try {
    return await axiosV2.patch(`conveyors/shippings`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`포장경로정보 수정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const updateConveyorPathFromExcel = async (formData: FormData) => {
  try {
    return await axiosV2.post('/conveyors/shippings/excel-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`excelUpload 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
