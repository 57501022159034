import { AxiosError } from 'axios';
import { axiosV2 } from '../../../libs/common/fetcher';
import { errorType } from '../../../types/common';

export type UpdateRequest = {
  password: string;
  newPassword: string;
};

export const update = async (requestBody: UpdateRequest) => {
  try {
    return await axiosV2.post(`/auth/change-password`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
