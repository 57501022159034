import { FormGroup, SelectChangeEvent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import usePopup from 'hooks/usePopup';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import { allWarehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import SelectBase from '../../devices/components/SelectBase';
import { createUser, createUserRequest, getUser, updateUser, updateUserRequest } from '../services';

const modalWidth = 600;
const modalFontSize = FONT_SIZE.medium;

export type User = {
  id: number;
  loginId: string;
  username: string;
  password?: string;
  boLoginId?: string;
  primaryWarehouseId: string;
  availableWarehouses: number[];
};

type Props = {
  userId: number;
  onClose: () => void;
  onMutate: () => void;
};
export const initialState: User = {
  id: -1,
  loginId: '',
  username: '',
  primaryWarehouseId: '',
  availableWarehouses: [],
};

const UserForm = (props: Props) => {
  const { onClose, onMutate, userId } = props;
  const { showErrorDialog, showSnackbar } = usePopup();
  const [user, setUser] = useState<User>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const warehouseOption = useRecoilValue(allWarehouseOptionsAtom);
  const [availableWarehouses, setAvailableWarehouses] = useState<number[]>([]);
  const [isCreate, setIsCreate] = useState(false);
  useEffect(() => {
    if (userId < 0) {
      setIsCreate(true);
      return;
    }
    const getUserDetail = async () => {
      const response = await getUser(userId);
      if (response?.status === 200) {
        setUser(response.data);
        setAvailableWarehouses(response.data.availableWarehouses);
      }
    };
    userId != user.id && getUserDetail();
  });

  const handleCreate = async () => {
    if (availableWarehouses.length === 0) {
      showErrorDialog({
        title: '사용자등록 실패',
        errorMessage: '사용가능 창고를 1개 이상 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }
    if (!availableWarehouses.includes(Number(user.primaryWarehouseId))) {
      showErrorDialog({
        title: '사용자등록 실패',
        errorMessage: '주창고를 사용가능 창고로 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    setIsLoading(true);

    const requestBody: createUserRequest = {
      loginId: user.loginId,
      username: user.username,
      primaryWarehouseId: user.primaryWarehouseId,
      availableWarehouseIds: availableWarehouses,
      password: user.password,
      boLoginId: user.boLoginId,
    };

    const response = await createUser(requestBody);
    if (response?.status === 200) {
      showSnackbar({ message: '사용자등록이 완료되었습니다.', severity: 'success' });
      setUser(initialState);
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: '사용자등록 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    if (availableWarehouses.length === 0) {
      showErrorDialog({
        title: '사용자정보 수정 실패',
        errorMessage: '사용가능 창고를 1개 이상 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }
    if (!availableWarehouses.includes(Number(user.primaryWarehouseId))) {
      showErrorDialog({
        title: '사용자정보 수정 실패',
        errorMessage: '주창고를 사용가능 창고로 선택해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    setIsLoading(true);

    const requestBody: updateUserRequest = {
      primaryWarehouseId: user.primaryWarehouseId,
      availableWarehouseIds: availableWarehouses,
      password: user.password,
      boLoginId: user.boLoginId,
    };

    const response = await updateUser(user.id, requestBody);
    if (response?.status === 200) {
      showSnackbar({ message: '사용자정보 수정이 완료되었습니다.', severity: 'success' });
      setUser(initialState);
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: '사용자정보 수정 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (event.target.checked) {
      setAvailableWarehouses([...availableWarehouses, id]);
    } else {
      setAvailableWarehouses(availableWarehouses.filter(item => item !== id));
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const key = event.target.name;
    const value = event.target.value;
    setUser({ ...user, [key]: value });
  };

  function handleChange1(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  }

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>사용자 기본정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'아이디'} labelSx={styles.label} required={isCreate}>
            {isCreate ? (
              <OutlinedInput
                name={'loginId'}
                value={user.loginId}
                onChange={event => handleChange1(event)}
                sx={styles.input}
                type={'text'}
                placeholder={'아이디를 입력해주세요.'}
              />
            ) : (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {user.loginId}
              </Typography>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'사용자명'} labelSx={styles.label} required={isCreate}>
            {isCreate ? (
              <OutlinedInput
                name={'username'}
                value={user.username}
                onChange={event => handleChange1(event)}
                sx={styles.input}
                type={'text'}
                placeholder={'사용자명를 입력해주세요.'}
              />
            ) : (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {user.username}
              </Typography>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'BO 연동아이디'} labelSx={styles.label}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              <OutlinedInput
                name={'boLoginId'}
                value={user.boLoginId}
                onChange={event => handleChange1(event)}
                sx={styles.input}
                type={'text'}
                placeholder={'BO 연동아이디를 입력해주세요.'}
              />
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'비밀번호 초기화'} labelSx={styles.label} required={isCreate}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              <OutlinedInput
                name={'password'}
                value={user.password}
                onChange={event => handleChange1(event)}
                sx={styles.input}
                type={'password'}
                placeholder={'임시 비밀번호를 입력해 주세요.'}
              />
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
      <Box>
        <Typography sx={styles.modalSubTitle}>사용자 창고정보</Typography>
      </Box>
      <Table>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'주창고'} labelSx={styles.label} required={isCreate}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              <SelectBase
                name={'primaryWarehouseId'}
                options={warehouseOption}
                selected={user.primaryWarehouseId}
                sx={styles.select}
                onChange={event => handleChange(event)}
              />
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={styles.rowHeight}>
          <TableCell label={'사용가능 창고'} labelSx={styles.label} required={true}>
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              <FormGroup sx={styles.checkbox}>
                {warehouseOption?.map(item => (
                  <FormControlLabel
                    value={item.id}
                    control={
                      <Checkbox
                        checked={availableWarehouses.includes(item.id)}
                        onChange={e => handleChangeCheckBox(e, item.id)}
                      />
                    }
                    label={item.displayName}
                  />
                ))}
              </FormGroup>
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
      <SaveButtonGroup
        onSaveClick={isCreate ? handleCreate : handleUpdate}
        onCloseClick={onClose}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default UserForm;

const styles = {
  select: { width: '344px', ml: -1.5 },
  input: { height: 28, width: 344, ml: -1.5, fontSize: modalFontSize },
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  rowHeight: { height: '36px' },
  label: { width: '128px', fontSize: modalFontSize },
  checkbox: { flexDirection: 'row' },
};
