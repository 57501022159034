import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/system/Box';
import { SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import usePopup from '../../../../../hooks/usePopup';
import FormModal from '../../../../../components/common/FormModal';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';
import CONVEYOR_PATH, { CONVEYOR_PATH_ARR } from '../../../../../consts/info/conveyorPath';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import SelectBase from '../../devices/components/SelectBase';
import { getConveyor, updateConveyorPath } from '../services';
import { KeyedMutator } from 'swr';
import { ConveyorResponse } from '../index.page';

const modalWidth = 480;
const modalFontSize = FONT_SIZE.medium;

export type Conveyor = {
  id: number;
  warehouseId: number;
  basePath: string;
  conveyorPath: string;
};

type Props = {
  conveyorId: number;
  isModalOpen: boolean;
  onClose: () => void;
  onMutate: KeyedMutator<ConveyorResponse>;
};

const initialState: Conveyor = {
  id: -1,
  warehouseId: -1,
  basePath: '',
  conveyorPath: '',
};

const ConveyorPathFormModal = ({ conveyorId, isModalOpen, onClose, onMutate }: Props) => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const [conveyor, setConveyor] = useState<Conveyor>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const conveyorPathOptions = refCodeOptions?.conveyorPath || [];

  useEffect(() => {
    if (conveyorId < 0) {
      return;
    }

    const getConveyorInfo = async () => {
      const response = await getConveyor(conveyorId);
      if (response?.status === 200) {
        setConveyor(response.data);
      } else {
        showErrorDialog({
          title: '포장경로 정보 조회 실패',
          errorMessage: response?.data.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    };

    getConveyorInfo();
  }, [conveyorId]);

  const handleChange = (event: SelectChangeEvent) => {
    setConveyor(conveyor => ({ ...conveyor, conveyorPath: event.target.value }));
  };

  const handleUpdate = async () => {
    setIsLoading(true);

    const response = await updateConveyorPath(conveyor.id, conveyor.conveyorPath);
    if (response?.status === 200) {
      showSnackbar({ message: '포장경로 정보가 수정 완료되었습니다.', severity: 'success' });
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: '포장경로 수정 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    setIsLoading(false);
  };

  return (
    <FormModal open={isModalOpen} title={'현재포장경로 수정'} onClose={onClose}>
      <Box width={modalWidth}>
        <Box>
          <Typography sx={styles.modalSubTitle}>포장경로 정보</Typography>
        </Box>
        <Table>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'창고'} labelSx={styles.label}>
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {convertWarehouseFullCode(Number(conveyor.warehouseId), warehouseOption)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'권장포장경로'} labelSx={styles.label}>
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {CONVEYOR_PATH[conveyor.basePath as keyof typeof CONVEYOR_PATH]}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'현재포장경로'} labelSx={styles.label}>
              <SelectBase
                name={'conveyorPath'}
                options={CONVEYOR_PATH_ARR}
                selected={conveyor.conveyorPath}
                sx={styles.select}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'현재작업공간'} labelSx={styles.label}>
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {conveyorPathOptions?.find(v => v.value === conveyor.conveyorPath)?.displayName}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
        <SaveButtonGroup onSaveClick={handleUpdate} onCloseClick={onClose} isLoading={isLoading} />
      </Box>
    </FormModal>
  );
};

export default ConveyorPathFormModal;

const styles = {
  select: { width: '344px', ml: -1.5 },
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  rowHeight: { height: '36px' },
  label: { width: '128px', fontSize: modalFontSize },
};
