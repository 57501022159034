import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import usePopup from '../../../../../hooks/usePopup';
import FormModal from '../../../../../components/common/FormModal';
import TightDataGridSum from '../../../../../components/common/TightDataGridSum';
import { inputTheme } from '../../../../../styles/customedMuiTheme';
import Table, { TableCell, TableRow } from '../../../components/Table';
import { updateReceivingItemsForSpecification } from '../services';
import {
  ReceivingItemsForSpecification,
  Specification,
} from '../../../components/SpecificationForPrint';
import gridColDefModal from './gridColDefModal';

type Props = {
  selectedRow: Specification | undefined;
  setSelectedRow: Dispatch<SetStateAction<Specification | undefined>>;
  pageMutate: () => void;
};

const ReceivingItemsModal = ({ selectedRow, setSelectedRow, pageMutate }: Props) => {
  const [receivingConfirmDate, setReceivingConfirmDate] = useState<dayjs.Dayjs>();
  const { showErrorDialog } = usePopup();

  useEffect(() => {
    setReceivingConfirmDate(dayjs(selectedRow?.receiveCompletedDate ?? new Date()));
  }, [selectedRow]);

  const handleReceivingConfirmDateChange = (date: dayjs.Dayjs | null) => {
    if (!date) return;
    setReceivingConfirmDate(date);
  };

  const handleReceivingConfirmModalCellEditCommit = async (params: GridCellEditCommitParams) => {
    if (!selectedRow) return;
    setSelectedRow(updateSelectedRow(selectedRow, params));
  };

  const handleUpdateButtonClick = async () => {
    if (!selectedRow?.receivingId) return;

    const formattedDate = dayjs(receivingConfirmDate).format('YYYY-MM-DD');
    const requestBody = {
      receiveCompletedDate: formattedDate,
      itemsToReceiving: selectedRow?.receivingItemsForSpecification.map(item => ({
        itemId: item.itemId,
        quantity: item.qty,
        cost: item.cost,
      })),
    };

    const response = await updateReceivingItemsForSpecification(
      selectedRow.receivingId,
      requestBody
    );

    if (response?.status === 200) {
    } else {
      showErrorDialog({
        title: '거래명세서 조회/입고목록 조회 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    setSelectedRow(undefined);
    pageMutate();
  };

  return (
    <FormModal
      open={!!selectedRow}
      title={selectedRow?.receivingName ? selectedRow.receivingName : ''}
      onClose={() => setSelectedRow(undefined)}
    >
      <Stack spacing={2}>
        <Box sx={{ width: '80vw' }}>
          <Table>
            <TableRow height="36px">
              <TableCell
                sx={styles.tableCell}
                label="입고확정일"
                value={
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                    <DemoContainer components={['DatePicker']} sx={styles.dateContainer}>
                      <DemoItem>
                        <DatePicker
                          value={receivingConfirmDate}
                          onChange={handleReceivingConfirmDateChange}
                          format={'YYYY-MM-DD'}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                }
                valueProps={styles.tableValueCell}
              />
            </TableRow>
          </Table>
        </Box>
        <Stack spacing={1.5} flexGrow={1}>
          <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 700 }}>
            입고 목록
          </Typography>
          <Box height={520}>
            <TightDataGridSum
              getRowId={row => row.itemId}
              rows={addSumRowToItems(selectedRow) ?? []}
              columns={gridColDefModal}
              onCellEditCommit={handleReceivingConfirmModalCellEditCommit}
              hideFooter
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button size="large" variant="outlined" onClick={() => setSelectedRow(undefined)}>
              취소
            </Button>
            <Button size="large" variant="contained" onClick={handleUpdateButtonClick}>
              수정
            </Button>
          </Box>
        </Stack>
      </Stack>
    </FormModal>
  );
};

const addSumRowToItems = (specification: Specification | undefined) => {
  if (specification === undefined) return [];

  const receivingItemsForSpecification = specification.receivingItemsForSpecification.map(
    (item: ReceivingItemsForSpecification) => ({
      ...item,
      rowIndex: item.itemCode,
      total: item.qty * item.cost,
    })
  );

  const summery = {
    rowIndex: 'total',
    itemId: '합계',
    barcode: '',
    itemName: '',
    qty: receivingItemsForSpecification.reduce((acc, cur) => acc + cur.qty, 0).toLocaleString(),
    cost: receivingItemsForSpecification.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString(),
    total: receivingItemsForSpecification.reduce((acc, cur) => acc + cur.total, 0).toLocaleString(),
  };

  return [...receivingItemsForSpecification, summery];
};

const updateSelectedRow = (selectedRow: Specification, params: GridCellEditCommitParams) => ({
  ...selectedRow,
  receivingItemsForSpecification: selectedRow.receivingItemsForSpecification.map(item => {
    if (item.itemId === params.id) {
      return {
        ...item,
        [params.field]: Number(params.value),
      };
    }
    return item;
  }),
});

const styles = {
  tableCell: { fontSize: '16px' },
  tableValueCell: { display: 'flex', alignItems: 'center', flex: 1, gap: 0.5, px: 1 },
  dateContainer: {
    ...inputTheme.medium,
    overflow: 'hidden',
    p: '0px',
  },
};

export default ReceivingItemsModal;
