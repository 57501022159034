import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { COLORS } from 'consts/common/colors';
import { SxProps } from '@mui/material/styles';

type TableProps = {
  children: ReactNode;
};

const Table = (props: TableProps) => {
  const { children } = props;

  return (
    <Stack
      sx={{
        margin: '10px 0',
        borderRadius: '4px',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
      }}
    >
      {children}
    </Stack>
  );
};

export default Table;

type TableRowProps = {
  children: ReactNode;
  height?: string;
};

export const TableRow = (props: TableRowProps) => {
  const { children, height = '56px' } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height,
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
      }}
    >
      {children}
    </Box>
  );
};

export const SmallTableRow = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  return (
    <TableRow {...sx} height="36px">
      {children}
    </TableRow>
  );
};

type TableCellProps = {
  sx?: SxProps;
  label: ReactNode;
  labelSx?: SxProps;
  value: ReactNode;
  valueSx?: SxProps;
  valueProps?: BoxProps;
  required?: boolean;
  isCreateMode?: boolean;
};

export const TableCell = (props: TableCellProps) => {
  const {
    sx,
    label,
    labelSx,
    value,
    valueSx,
    valueProps,
    required = false,
    isCreateMode = true,
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        color: 'common.black',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '172px',
          height: '100%',
          padding: '0 16px',
          fontWeight: 400,
          backgroundColor: COLORS.bgDeep,
          borderBottom: '1px solid #E0E0E0',
          ...labelSx,
        }}
      >
        {label}
        {required && isCreateMode && (
          <span style={{ color: COLORS.red, marginLeft: '2px' }}>*</span>
        )}
      </Box>
      <Box
        sx={{
          padding: '0 16px',
          fontWeight: 700,
          ...valueSx,
        }}
        {...valueProps}
      >
        {value}
      </Box>
    </Box>
  );
};

export const SmallTableCell = (props: TableCellProps) => {
  return <TableCell {...props} sx={{ fontSize: 13 }} labelSx={{ height: '100%' }} />;
};
