import { datadogRum } from '@datadog/browser-rum';

const APP_ID = process.env.REACT_APP_RUM_APP_ID ?? '';
const TOKEN = process.env.REACT_APP_RUM_CLIENT_TOKEN ?? '';
const STAGE = process.env.REACT_APP_STAGE ?? '';

let didInit = false;

export const initializeRum = () => {
  if (didInit) return;

  datadogRum.init({
    applicationId: APP_ID,
    clientToken: TOKEN,
    site: 'datadoghq.com',
    service: 'gms-fe',
    env: STAGE,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: ['https://v2-dev-gms.ktown4u.io/'],
  });

  datadogRum.startSessionReplayRecording();

  didInit = true;
};
