import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import { MAIN_WIDTH } from 'consts/common/layout';

type Props = {
  children: ReactNode;
  spacing?: number;
  withAppBar?: boolean;
};

const Page = (props: Props) => {
  const { children, spacing = 1, withAppBar = true } = props;

  return (
    <Stack
      component="main"
      spacing={spacing}
      sx={{
        minHeight: withAppBar ? '95vh' : '100vh',
        minWidth: MAIN_WIDTH,
        display: 'flex',
        marginTop: withAppBar ? '64px' : 0,
        padding: '32px',
      }}
    >
      {children}
    </Stack>
  );
};

export default Page;
