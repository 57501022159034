import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError, AxiosResponse } from 'axios';
import { errorType } from '../../../../../types/common';
import { Packing } from '../../../../../types/outbound';

export type changeToteRequest = {
  barcode?: string;
};

export const getPackingToChangeTote = async (
  requestBody: changeToteRequest
): Promise<Packing | undefined> => {
  const newVar = await axiosV2.get(`/packings/transfers/tote`, { params: requestBody });
  return newVar.data;
};

export type changeToteRequest1 = {
  fromToteBarcode: string | undefined;
  toToteBarcode: string;
};

export const changeTote = async (toteBarcode: changeToteRequest1) => {
  try {
    return await axiosV2.post(`/packings/transfers/tote`, toteBarcode);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`pda 배정 해제 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
