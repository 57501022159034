import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import { allWarehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';

const gridColumns = () => {
  const warehouseOption = useRecoilValue(allWarehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'loginId',
      headerName: '로그인아이디',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'username',
      headerName: '사용자명',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boLoginId',
      headerName: 'BO 연동아이디',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'primaryWarehouseId',
      headerName: '주창고',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'availableWarehouseIds',
      headerName: '사용가능창고',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value.map((v: number) => convertWarehouseFullCode(v, warehouseOption)).join(', ');
      },
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
