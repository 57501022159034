import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';
import { CarrierForm } from '../components/CarriersForm';

export type RequestBodyForUpdate = Pick<
  CarrierForm,
  'name' | 'displayOrder' | 'chuteNumber' | 'minWeight' | 'maxWeight' | 'volumetricDivisor'
>;

export const updateCarrier = async (id: number, carrier: RequestBodyForUpdate) => {
  try {
    return await axiosV2.put(`/carriers/${id}`, carrier);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`배송방식 수정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
