import type { GridColDef } from '@mui/x-data-grid-pro';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';

const gridColumns = () => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const statusOptions = refCodeOptions?.inspectionStatus || [];
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];

  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '대량검수상태',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => statusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'pickingGroupType',
      headerName: '집품구분',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'pickingGroupSerialNumber',
      headerName: '집품그룹ID',
      minWidth: 180,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingGroupCompletedAt',
      headerName: '집품완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'bulkInspectionCompletedAt',
      headerName: '검수완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'toteBarcodes',
      headerName: '토트',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingCount',
      headerName: '출고건수',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'estimatedWeight',
      headerName: '실제중량무게',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '작업일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
