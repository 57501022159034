import { axiosV2 } from 'libs/common/fetcher';
import { DeviceForm } from '../components/DevicesForm';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';

export type RequestBodyForUpdate = Pick<DeviceForm, 'workType' | 'isWorkAvailable'>;

export const updateDevice = async (id: number, device: RequestBodyForUpdate) => {
  try {
    return await axiosV2.put(`/devices/${id}/works`, device);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`출고지시서 생성 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
