import React, { useState } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import type { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import LoadingModal from '../components/LoadingModal';
import ShippingDataGrid from './components/ShippingDataGrid';
import ChevronDouble from '../components/ChevronDouble';
import usePopup from 'hooks/usePopup';
import gridColumns from './gridColDef';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import type { SummarizedShippingOrder } from 'types/outbound';
import type { Options } from 'types/form';
import { generateShippingOrder } from './services/shippingOrders';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import convertUser from '../../../../libs/common/convertUser';
import useConditionalSWR from '../../components/useConditionalSwr';

export type ShippingOrders = {
  content: SummarizedShippingOrder[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.8fr)',
  'minmax(45px, 0.8fr)',
  '90px',
  'minmax(55px, 1.6fr)',
  'minmax(55px, 1.4fr)',
  '80px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '0px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const defaultOption = { displayName: '전체', value: 'ALL' };
const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시생성일',
    field: 'shippingOrderCreatedAt',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '출고지시ID',
    field: 'serialNumber',
  },
];

const ShippingOrders = () => {
  const { showSnackbar, showDialog, showErrorDialog } = usePopup();
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedShippingOrder, setSelectedShippingOrder] = useState(null);
  const [selectedShippingOrderId, setSelectedShippingOrderId] = useState(null);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<ShippingOrders>(
    [`/shipping-orders`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const shippingOrderStatusOptions = refCodeOptions?.shippingOrderStatus || [];
  const shippingOrderTypeOptions = refCodeOptions?.shippingOrderType || [];

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    setSelectedShippingOrderId(null);
    setSelectedShippingOrder(null);
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    setSelectedShippingOrder(params.row);
    setSelectedShippingOrderId(orderId => {
      return params.row.id === orderId ? null : params.row.id;
    });
  };

  const handleGenerateShippingOrder = () => {
    const defaultWarehouseId = Number(globalWarehouse);
    showDialog({
      message:
        '[' +
        convertWarehouseFullCode(defaultWarehouseId, warehouseOption) +
        ']' +
        ' 창고의 출고지시서를 생성하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            setIsLoading(true);
            const response = await generateShippingOrder(defaultWarehouseId);
            setIsLoading(false);
            if (response?.status === 200) {
              await mutate();
              showSnackbar({
                message: '출고지시서 생성이 완료되었습니다.',
                severity: 'success',
              });
            } else {
              showErrorDialog({
                title: '출고지시서 생성 실패',
                errorMessage: response?.data?.errorMessage,
                buttons: [{ text: '확인' }],
              });
            }
          },
        },
      ],
    });
  };

  const generateRowData = (data: any) => {
    const { content, totalElements, size, number } = data;
    return content.map((item: any, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      createdUser: convertUser(item.createdUserName, item.createdUserLoginId),
    }));
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고지시서 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="출고지시서상태"
          field="status"
          options={[defaultOption, ...shippingOrderStatusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Select
          label="출고지시구분"
          field="type"
          options={[defaultOption, ...shippingOrderTypeOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={4}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            출고지시서 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleGenerateShippingOrder}
            >
              {`출고지시서 생성`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={() => console.log(1)}
              disabled
            >
              {`엑셀다운로드`}
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: '280px' }}>
          <TightDataGridPro
            rows={hasSearched && data?.content ? generateRowData(data) : []}
            columns={gridColumns(warehouseOption)}
            paginationMode="server"
            rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onRowClick={handleRowClick}
            onPageChange={page => setQueryParams(params => ({ ...params, page }))}
            pageSize={queryParams.size ?? DEFAULT_SIZE}
            onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
            loading={isValidating}
            hasSearched={hasSearched}
          />
        </Box>
        <LoadingModal isLoading={isLoading} message="출고지시서 생성중..." />
        {selectedShippingOrderId && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
              <ChevronDouble />
            </Box>
            <ShippingDataGrid
              selectedShippingOrderId={selectedShippingOrderId}
              shippingOrder={selectedShippingOrder}
            />
          </>
        )}
      </Stack>
    </Page>
  );
};

export default ShippingOrders;

const styles = {
  divider: {
    gridRow: 3,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
