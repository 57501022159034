import Box from '@mui/material/Box';
import { REACT_APP_BASE_PATH } from 'consts/common/environment';
import { useNavigate } from 'react-router-dom';
import devOnlyFlag from '../../libs/common/devOnlyFlag';

type Ktown4uLogoProps = {
  width?: number;
  marginBottom?: number;
};

const Ktown4uLogo = (props: Ktown4uLogoProps) => {
  const { width = 120, marginBottom = 0 } = props;
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  };

  const src = devOnlyFlag()
    ? `${REACT_APP_BASE_PATH}/images/ktown4u_logo-dev.png`
    : `${REACT_APP_BASE_PATH}/images/ktown4u_logo.png`;
  return (
    <Box style={{ lineHeight: 1 }} marginBottom={marginBottom} onClick={handleHome}>
      <img
        src={src}
        width={width}
        height={(width * 44) / 230}
        alt="Ktown4u Logo"
        style={styles.img}
      />
    </Box>
  );
};

export default Ktown4uLogo;

const styles = {
  img: {
    cursor: 'pointer',
  },
};
