import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/system/Box';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SortIcon from '@mui/icons-material/Sort';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { COLORS } from 'consts/common/colors';
import { GmsMenuItem, gmsMenus } from './menus';
import { CLOSED_SIDE_BAR_WIDTH, SCROLL_WIDTH, SIDE_BAR_WIDTH } from 'consts/common/layout';
import { inputAutoFocusAtom } from '../../../store';
import MenuPanel, { FavoritePanel, MenuTab } from './MenuPanel';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const Drawer = (props: DrawerProps) => {
  const { open, onClose, onOpen } = props;
  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);
  const loginUserRef = useRef('');
  const [selectedTab, setSelectedTab] = useState<MenuTab>('menu');
  const [searchText, setSearchText] = useState<string>('');
  const [highlightText, setHighlightText] = useState<string>('');
  const [menus, setMenus] = useState<GmsMenuItem[]>(gmsMenus);
  const [isShowAllMenu, setIsShowAllMenu] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const loginUser = localStorage.getItem('loginUser');
    if (loginUser) {
      loginUserRef.current = loginUser;
      const selectedMenuTab =
        (localStorage.getItem(`selectedMenuTab_${loginUser}`) as MenuTab) ?? 'menu';
      setSelectedTab(selectedMenuTab);
      return;
    }
    setSelectedTab('menu');
  }, []);

  const onSelectTab = (event: React.SyntheticEvent, newValue: MenuTab) => {
    localStorage.setItem(`selectedMenuTab_${loginUserRef.current}`, newValue);
    setSelectedTab(newValue);
  };

  const searchMenus = () => {
    setHighlightText(searchText);

    if (searchText === '') {
      setMenus(gmsMenus);
      return;
    }

    const filteredMenus = filterMenus(gmsMenus, searchText);

    if (filteredMenus.length === 0) {
      setMenus([]);
    } else {
      setMenus(filteredMenus);
    }

    setIsShowAllMenu(true);
  };

  const handleClearInput = () => {
    setSearchText('');
    setHighlightText('');
    setMenus(gmsMenus);
  };

  return (
    <Fragment>
      <MuiDrawer
        variant="persistent"
        open={open}
        sx={styles.drawer(open)}
        anchor="left"
        onClose={onClose}
      >
        <Box>
          <Toolbar />
          <TabContext value={selectedTab}>
            <TabList value={selectedTab} onChange={onSelectTab} sx={styles.tabIndicatorStyle}>
              <Tab label="전체메뉴" value="menu" sx={styles.tabStyle} />
              <Tab label="즐겨찾기" value="fav" sx={styles.tabStyle} />
            </TabList>
            {selectedTab === 'menu' && (
              <>
                <Box sx={styles.searchMenuContainer}>
                  <Box width={178}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      fullWidth
                      placeholder="메뉴 검색"
                      value={searchText}
                      onClick={() => setInputAutoFocus(false)}
                      onBlur={() => setInputAutoFocus(true)}
                      onChange={e => setSearchText(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          searchMenus();
                        }
                      }}
                      InputProps={{
                        endAdornment: searchText && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearInput}>
                              <CancelIcon sx={{ color: 'white', fontSize: 16 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={styles.searchInput}
                    />
                  </Box>
                  <Box sx={styles.searchIconBox} onClick={searchMenus}>
                    <SearchIcon sx={styles.searchIcon} />
                  </Box>
                </Box>
                <Box sx={styles.showAllMenuContainer}>
                  <Box sx={styles.showAllIcon}>
                    <SortIcon fontSize={'small'} />
                  </Box>
                  <Box
                    sx={styles.showAllButton}
                    onClick={() => {
                      setIsShowAllMenu(isShow => !isShow);
                    }}
                  >
                    {isShowAllMenu ? '- 전체닫기' : '+ 전체보기'}
                  </Box>
                </Box>
              </>
            )}
            <TabPanel value="menu" sx={{ padding: 0 }}>
              <MenuPanel
                menuList={menus}
                highlightText={highlightText}
                isShowAllMenu={isShowAllMenu}
              />
            </TabPanel>
            <TabPanel value="fav" sx={{ padding: 0 }}>
              <FavoritePanel />
            </TabPanel>
          </TabContext>
        </Box>
      </MuiDrawer>
      {open ? (
        <PullerOnOpen onClick={onClose} />
      ) : (
        <PullerOnClose
          onOpen={onOpen}
          onAllClick={() => setSelectedTab('menu')}
          onFavClick={() => setSelectedTab('fav')}
        />
      )}
    </Fragment>
  );
};

export default Drawer;

type PullerOnOpenProps = {
  onClick: () => void;
};

const PullerOnOpen = (props: PullerOnOpenProps) => {
  const { onClick } = props;

  return (
    <Box sx={styles.puller.open} onClick={onClick}>
      <ArrowBackIos viewBox="-5 0 20 20" sx={styles.puller.arrowBackIcon} />
    </Box>
  );
};

type PullerOnCloseProps = {
  onAllClick: () => void;
  onFavClick: () => void;
  onOpen: () => void;
};

const PullerOnClose = (props: PullerOnCloseProps) => {
  const { onAllClick, onFavClick, onOpen } = props;

  return (
    <Box sx={styles.puller.close} onClick={onOpen}>
      <Button
        onClick={() => {
          onOpen();
          onAllClick();
        }}
        sx={styles.puller.menuButton}
      >
        <Typography>전체</Typography>
        <Typography>메뉴</Typography>
      </Button>
      <Button
        onClick={() => {
          onOpen();
          onFavClick();
        }}
        sx={styles.puller.menuButton}
      >
        <Typography>즐겨</Typography>
        <Typography>찾기</Typography>
      </Button>
      <ArrowForwardIos sx={styles.puller.arrowForwardIcon} />
    </Box>
  );
};

const filterMenus = (gmsMenus: GmsMenuItem[], searchText: string): GmsMenuItem[] => {
  return gmsMenus
    .map(menu => {
      // 2번째 depth 메뉴 필터링
      const filteredSubMenus = menu.subMenus
        ?.map(subMenu1 => {
          const filteredSubSubMenus = subMenu1.subMenus?.filter(subMenu2 =>
            subMenu2.title?.includes(searchText)
          );

          return filteredSubSubMenus && filteredSubSubMenus.length > 0
            ? { ...subMenu1, subMenus: filteredSubSubMenus }
            : null;
        })
        .filter(Boolean);

      // 1번째 depth 메뉴에 매칭
      return filteredSubMenus && filteredSubMenus.length > 0
        ? { ...menu, subMenus: filteredSubMenus }
        : null;
    })
    .filter(Boolean) as GmsMenuItem[];
};

const styles = {
  drawer: (open: boolean) => ({
    transition: 'width 250ms',
    '& .MuiDrawer-paper': {
      width: open ? SIDE_BAR_WIDTH + SCROLL_WIDTH : 0,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      bgcolor: COLORS.darkGrey,
      color: 'white',
      overflowY: 'scroll',
      overflowX: 'hidden',
      borderRight: 'none',
    },
    '& .MuiTypography-root': {
      fontSize: '15px',
      whiteSpace: 'pre-line',
    },
  }),
  puller: {
    open: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 16,
      height: '100%',
      backgroundColor: COLORS.white,
      cursor: 'pointer',
      borderRight: `1px solid ${COLORS.darkLine}`,
    },
    close: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      visibility: 'visible',
      width: CLOSED_SIDE_BAR_WIDTH,
      height: '100%',
      pt: 9,
      backgroundColor: COLORS.white,
      cursor: 'pointer',
      borderRight: `1px solid ${COLORS.darkLine}`,
    },
    menuButton: {
      flexDirection: 'column',
      minWidth: '32px',
      width: '32px',
      height: '44px',
      p: 0,
      border: `1px solid ${COLORS.darkGrey}`,
      color: COLORS.darkGrey,
    },
    arrowBackIcon: {
      color: COLORS.darkGrey,
      fontSize: '12px',
    },
    arrowForwardIcon: {
      position: 'relative',
      top: 'calc(50% - 160px)',
      color: COLORS.darkGrey,
      fontSize: '12px',
    },
  },
  tabStyle: {
    flex: 1,
    color: COLORS.veryLightGrey,
    bgcolor: COLORS.veryDarkGrey,
    fontSize: '16px',
    py: 1,
    '&.Mui-selected': {
      color: 'white',
      bgcolor: COLORS.darkGrey,
    },
  },
  tabIndicatorStyle: {
    '& .MuiTabs-indicator': {
      backgroundColor: COLORS.darkLine,
    },
  },

  searchMenuContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    mt: 1,
  },
  searchInput: {
    caretColor: 'white',
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'whitesmoke',
    },
  },
  searchIconBox: {
    width: 38,
    height: 38,
    mr: 1,
    '&:hover': { cursor: 'pointer' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  searchIcon: {
    fontSize: 21,
    mb: 0.64,
  },
  showAllMenuContainer: {
    width: '100%',
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 1,
    backgroundColor: COLORS.grey,
  },
  showAllIcon: { m: 1, display: 'flex', alignItems: 'center' },
  showAllButton: {
    mr: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 80,
    fontSize: 13,
    height: '100%',
    '&:hover': { cursor: 'pointer' },
  },
};
