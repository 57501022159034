import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Page from '../../../../../components/common/Layout/Page';
import SelectItems, { type Delivery } from './components/SelectItems';
import TransferOutResult from './components/TransferOutResult';
import SelectDelivery from './components/SelectDelivery';
import { Warehouse, warehouseAtom } from '../../../../../store/outbound/warehouse.recoil';
import { MAIN_WIDTH } from '../../../../../consts/common/layout';
import pageTitle from '../../../../../styles/pageTitle';
import borderedBoxStyle from '../../../../../styles/borderedBox';
import { TransferOutGenerateResult } from './types';

const TransferGenerates = () => {
  const fromWarehouseId = useRecoilValue(warehouseAtom);

  const [delivery, setDelivery] = useState<Delivery>();
  const [result, setResult] = useState<TransferOutGenerateResult>();

  const handleSaveDelivery = (warehouse: Warehouse, date: Dayjs, carrier: string) => {
    setDelivery({
      toWarehouse: warehouse,
      desiredDate: date,
      carrierId: Number(carrier),
    });
  };

  const handleSaveResult = (result: TransferOutGenerateResult) => {
    setResult(result);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대체출고지시 생성
      </Typography>
      <Box sx={styles.container}>
        <SelectDelivery fromWarehouseId={fromWarehouseId} save={handleSaveDelivery} />
        {delivery && (
          <SelectItems
            fromWarehouseId={fromWarehouseId}
            delivery={delivery}
            save={handleSaveResult}
          />
        )}
        {result && <TransferOutResult result={result} />}
      </Box>
    </Page>
  );
};

export default TransferGenerates;

const styles = {
  container: {
    ...borderedBoxStyle,
    minWidth: MAIN_WIDTH,
    p: 1,
    pt: 0,
  },
};
