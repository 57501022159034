import { useEffect, useState } from 'react';

const useCountdown = (time: number) => {
  const [remaining, setRemaining] = useState(time);

  useEffect(() => {
    if (remaining <= 0) {
      return;
    }

    const timer = setTimeout(() => {
      setRemaining(remaining => remaining - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [remaining]);

  const complete = remaining === 0;

  return { remaining, complete };
};

export default useCountdown;
