import React from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { COLORS } from 'consts/common/colors';

const modalWidth = 420;

type Props = {
  total: number;
  success: number;
  error: number;
  onClose: () => void;
};

const CompletedPackingResultModal = (props: Props) => {
  const { total, success, error, onClose } = props;

  const reports = [
    { subject: '총건수', value: total },
    { subject: '성공건수', value: success },
    { subject: '실패건수', value: error },
  ];

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>대량포장이 완료되었습니다.</Typography>
        <Box sx={styles.body}>
          {reports.map((report, i) => (
            <Box key={i} sx={styles.report}>
              <Box sx={styles.reportSubject}>{report.subject}</Box>
              <Box sx={styles.reportResult}>{report.value.toLocaleString() ?? '-'}</Box>
            </Box>
          ))}
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button
            onClick={() => {
              onClose();
            }}
            variant="outlined"
          >
            닫기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CompletedPackingResultModal;

const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  body: {
    mb: 2,
  },
  report: {
    mt: 1,
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${COLORS.darkLine}`,
    borderRadius: '4px',
  },
  reportSubject: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    mr: 1,
    fontSize: 24,
  },
  reportResult: {
    flex: 1,
    ml: 1,
    fontSize: 24,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
};
