import React, { ChangeEvent, useState } from 'react';
import { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { GridCellEditCommitParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TightDataGridPro from 'components/common/TightDataGridPro';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import usePopup from 'hooks/usePopup';
import { Warehouse } from 'store/outbound/warehouse.recoil';
import { COLORS } from 'consts/common/colors';
import { getFinalUrl } from 'consts/outbound/imageUrl';
import { listBoardTheme } from 'styles/customedMuiTheme';
import useReceiveItemsFromSearch, { Item } from '../../../../../../hooks/useReceiveItemsFromSearch';
import { generateTransferOut, RequestBody } from '../services';
import { TransferOutGenerateResult } from '../types';
import makeColumns from './gridColDef';
import ImageZoomModal from '../../../../../../components/ImageZoomModal';
import usePopupWindow from '../../../../../../hooks/usePopupWindow';

type Props = {
  fromWarehouseId: string;
  delivery: Delivery;
  save: (result: TransferOutGenerateResult) => void;
};

export type Delivery = {
  toWarehouse: Warehouse;
  desiredDate: Dayjs;
  carrierId: number;
};

const SelectItems = (props: Props) => {
  const {
    fromWarehouseId,
    delivery: { toWarehouse, desiredDate, carrierId },
    save,
  } = props;

  const [note, setNote] = useState<string>('');
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const { showAlert, showSnackbar } = usePopup();

  const { items, updateItems } = useReceiveItemsFromSearch();
  const { openPopupWindow } = usePopupWindow();

  const handleAddItems = () => {
    openPopupWindow({
      url: `gms/transfer/orders/generates/search-item`,
      features: {
        width: 1300,
        height: 800,
      },
      body: {
        warehouseId: fromWarehouseId,
      },
    });
  };

  const handleOmitItems = async () => {
    if (selectedItemIds.length === 0) {
      showAlert({ message: '제외할 아이템을 선택해 주세요.' });
      return;
    }

    const newItems = gatherNotOmittedItemsByIds(items, selectedItemIds);
    updateItems(newItems);
    setSelectedItemIds([]);
    showSnackbar({ message: '선택한 아이템의 제외가 완료되었습니다.', severity: 'success' });
  };

  const handleNote = (event: ChangeEvent<HTMLInputElement>) => setNote(event.target.value);

  const handleGenerate = async () => {
    if (items.length === 0) {
      showAlert({ message: '대체출고 대상이 없습니다.' });
      return;
    }

    if (hasQuantity0(items)) {
      showAlert({ message: '대체출고수량을 입력하지 않은 항목이 있습니다.' });
      return;
    }

    if (hasQuantityOver(items)) {
      showAlert({ message: '창고가용수량을 초과한 요청수량 항목이 있습니다.' });
      return;
    }

    // item 의 무게가 19000 이 넘으면서 운송사가 CJ 대한통운인 경우 경고
    // todo: 1주문 다포장 도입시 제거 예정
    const totalWeight = items.reduce((acc, item) => acc + item.weight * item.requestedQuantity, 0);
    if (totalWeight > 19000 && carrierId === 10) {
      showAlert({
        message: `무게 초과입니다.\n배송방식을 퀵서비스로 설정하여 다시 등록해 주세요.\n(현재 무게: ${totalWeight.toLocaleString()}g)`,
      });
      return;
    }

    const requestBody: RequestBody = {
      fromWarehouseId: Number(fromWarehouseId),
      toWarehouseId: toWarehouse?.id,
      desiredDate: desiredDate.toISOString(),
      carrierId,
      note,
      itemRequests: items.map(item => ({
        itemId: item.id,
        qty: item.requestedQuantity,
        supplyPrice: item.supplyPrice,
      })),
    };

    const res = await generateTransferOut(requestBody);
    if (res?.status === 200) {
      showSnackbar({ message: '대체출고 생성이 완료되었습니다.', severity: 'success' });
    } else {
      showAlert({ message: '대체출고 생성에 실패했습니다.' });
      return;
    }

    save(makeResult(items, note, res?.data?.serialNumber));
    setHasGenerated(true);
    setSelectedItemIds([]);
  };

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    if (Number(params.value) === 0) {
      showAlert({
        message: '대체출고수량을 입력해 주세요.',
        onClick: () => {
          updateItems(updateQuantityAndTotalPrice(items, { ...params, value: 0 }));
        },
      });
      return;
    }

    if (validateQuantityOver(items, params)) {
      showAlert({
        message: '1~창고가용수량으로 입력해 주세요.',
        onClick: () => {
          updateItems(updateQuantityAndTotalPrice(items, { ...params, value: 0 }));
        },
      });
      return;
    }

    updateItems(updateQuantityAndTotalPrice(items, params));
  };

  const handleSelectionModelChange = (ids: GridSelectionModel) => {
    setSelectedItemIds(ids as number[]);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Typography
          variant="subtitle1"
          sx={styles.title}
          color={hasGenerated ? COLORS.grey : COLORS.ktown4u}
        >
          02. 대체출고 대상 목록을 추가해 주세요.
        </Typography>
        <Box>
          <Button
            sx={listBoardTheme.headerButton}
            variant="outlined"
            onClick={handleAddItems}
            disabled={hasGenerated}
          >
            {'대체출고 대상 추가'}
          </Button>
          <Button
            sx={listBoardTheme.headerButton}
            variant="outlined"
            onClick={handleOmitItems}
            disabled={hasGenerated}
          >
            {'대체출고 대상 제외'}
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: '50vh', mt: 1 }}>
        <TightDataGridPro
          rows={items?.slice().reverse() ?? []}
          columns={makeColumns(!hasGenerated)}
          hideFooter
          checkboxSelection={!hasGenerated}
          disableSelectionOnClick
          selectionModel={selectedItemIds}
          onSelectionModelChange={handleSelectionModelChange}
          onCellEditCommit={handleCellEditCommit}
          onCellClick={({ field, row }) => {
            if (field === 'itemImageUrl') setImageUrl(getFinalUrl(row.itemImageUrl));
          }}
          customLocalText={'대체출고 아이템을 추가해 주세요.'}
        />
      </Box>
      {hasGenerated ? (
        <></>
      ) : (
        <>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ color: 'text.primary', ml: 1 }}>
              대체출고 정보를 입력해 주세요.
            </Typography>
            <Table>
              <TableRow height="100px">
                <TableCell
                  label={'비고'}
                  labelSx={{ fontSize: 13 }}
                  valueSx={{ width: '100%' }}
                  value={
                    <FormControl fullWidth sx={{ '& .MuiInputBase-root': { height: '80px' } }}>
                      <TextField
                        multiline
                        maxRows={3}
                        onChange={handleNote}
                        InputProps={{ sx: { fontSize: 13 } }}
                      />
                    </FormControl>
                  }
                />
              </TableRow>
            </Table>
          </Box>
          <Box sx={styles.buttonGroup}>
            <Button variant="outlined" onClick={() => window.location.reload()} sx={{ width: 140 }}>
              취소
            </Button>
            <Button variant="contained" onClick={handleGenerate} sx={{ width: 140 }}>
              대체출고지시 생성
            </Button>
          </Box>
        </>
      )}
      <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
    </>
  );
};

export default SelectItems;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ml: 1,
  },
  title: { fontWeight: 'bold', fontSize: 20 },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    marginTop: 2,
  },
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '50vh',
    minWidth: '50vh',
    cursor: 'pointer',
  },
  closeIconWrapper: {
    position: 'absolute',
    top: -8,
    color: COLORS.white,
  },
};

const gatherNotOmittedItemsByIds = (items: Item[], ids: number[]) => {
  return items.filter(item => !ids.some(id => id === item.id));
};

const hasQuantity0 = (items: Item[]) => {
  return items.some(
    item =>
      item.requestedQuantity === 0 ||
      item.requestedQuantity === undefined ||
      item.requestedQuantity === null
  );
};

const hasQuantityOver = (items: Item[]) => {
  return items.some(item => item.requestedQuantity > item.availableQuantity);
};

export const makeResult = (
  items: Item[],
  memo: string,
  serialNumber: string
): TransferOutGenerateResult => {
  const totals = items.reduce(
    (res, item) => {
      res.totalSku += 1;
      res.totalItem += item.requestedQuantity;
      res.totalWeight += item.weight * item.requestedQuantity;
      res.totalPrice += item.totalPrice;
      return res;
    },
    { totalSku: 0, totalItem: 0, totalWeight: 0, totalPrice: 0 }
  );

  return {
    totalSku: totals.totalSku.toLocaleString(),
    totalItem: totals.totalItem.toLocaleString(),
    totalWeight: totals.totalWeight.toLocaleString(),
    totalPrice: totals.totalPrice.toLocaleString(),
    memo,
    serialNumber,
  };
};

const validateQuantityOver = (items: Item[], params: GridCellEditCommitParams) => {
  const selectedItem = items.find(item => item.id === params.id);
  return selectedItem && selectedItem.availableQuantity < Number(params.value);
};

const updateQuantityAndTotalPrice = (items: Item[], params: GridCellEditCommitParams) => {
  return items.map(item => {
    if (item.id === params.id) {
      return {
        ...item,
        totalWeight: item.weight * Number(params.value),
        requestedQuantity: Number(params.value),
        totalPrice: item.supplyPrice * Number(params.value),
      };
    }

    return item;
  });
};
