import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import gridColumns from './gridColDef';
import { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import FormModal from '../../../../components/common/FormModal';
import CarriersForm, { CarrierForm } from './components/CarriersForm';
import { generateCarrierForm, getSelectedCarrier } from './utils';
import Filter, { Form } from '../../../../components/common/Filter';
import Divider from '@mui/material/Divider';
import { useRecoilValue } from 'recoil';
import warehouseAtom, { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import _ from 'lodash';
import convertUser from '../../../../libs/common/convertUser';
import useConditionalSWR from '../../components/useConditionalSwr';

export type Carrier = {
  id: number;
  warehouseId: number;
  name: string;
  displayOrder: number;
  chuteNumber: string;
  minWeight: number;
  maxWeight: number;
  volumetricDivisor: number;
  updatedAt: string;
  updatedUsername: string;
  updatedLoginId: string;
};

export type CarrierResponse = {
  content: Carrier[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const defaultSize = 100;
const defaultPage = 0;

const defaultOption = { displayName: '전체', value: 'ALL' };

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Carriers = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedCarrierForm, setSelectedCarrierForm] = useState<CarrierForm>();
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: defaultPage,
    size: defaultSize,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<CarrierResponse>(
    [`/search/carriers`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: CarrierResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Carrier, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    if (!data?.content) {
      return;
    }

    const selectedCarrier = getSelectedCarrier(data.content, params.id as number);
    const carrierForm = generateCarrierForm(selectedCarrier) as CarrierForm;
    setSelectedCarrierForm(carrierForm);
    setIsModalOpen(true);
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        배송방식 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            배송 방식
          </Typography>
        </Box>
        <TightDataGridPro
          rows={data?.content ? makeRowIndex(data) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={data?.totalElements ?? 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? defaultSize}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal open={isModalOpen} title={'배송방식 수정'} onClose={onCloseModal}>
        <CarriersForm
          selectedCarrier={selectedCarrierForm}
          onClose={onCloseModal}
          mutate={mutate}
        />
      </FormModal>
    </Page>
  );
};

export default Carriers;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
