import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import type { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { DateRange } from '@mui/x-date-pickers-pro';

import { listItemTextTheme, selectTheme } from 'styles/customedMuiTheme';
import useDateRange from './hooks/useDateRange';
import type { Options } from 'types/form';

type RangeSelectProps = {
  id: string;
  defaultSelectedOption?: RangeType;
  options: Options;
};

export enum RANGE {
  today = 'today',
  yesterday = 'yesterday',
  lastThreeDays = 'lastThreeDays',
  lastSevenDays = 'lastSevenDays',
  lastMonth = 'lastMonth',
  all = 'all',
}

export type RangeType = (typeof RANGE)[keyof typeof RANGE];

const RangeSelect = forwardRef((props: RangeSelectProps, ref) => {
  const { id, defaultSelectedOption, options } = props;
  const { dateRange, setDateRange } = useDateRange();
  const [selectedOption, setSelectedOption] = useState(options[options.length - 1].field);

  const getDateRangeByOption = (option: string): DateRange<Dayjs> => {
    let endDate: Dayjs | null = dayjs();
    let startDate = null;

    if (option === RANGE.today) {
      startDate = endDate.clone();
    } else if (option === RANGE.yesterday) {
      startDate = endDate.subtract(1, 'day');
      endDate = startDate.clone();
    } else if (option === RANGE.lastThreeDays) {
      startDate = endDate.subtract(3, 'day');
    } else if (option === RANGE.lastSevenDays) {
      startDate = endDate.subtract(7, 'day');
    } else if (option === RANGE.lastMonth) {
      startDate = endDate.subtract(30, 'day');
    } else if (option === RANGE.all) {
      endDate = null;
    } else {
      throw new Error('Invalid date range option');
    }

    return [startDate, endDate];
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const selectedRangeOption = e.target.value;

    setSelectedOption(selectedRangeOption);
    setDateRange(getDateRangeByOption(selectedRangeOption));
  };

  useImperativeHandle(ref, () => ({ handleSelectChange }));

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    const isDateRangeInitialized = startDate === null && endDate === null;

    if (isDateRangeInitialized) {
      if (defaultSelectedOption) {
        setSelectedOption(defaultSelectedOption);
        setDateRange(getDateRangeByOption(defaultSelectedOption));
      } else {
        setSelectedOption(options[options.length - 1].field);
      }
    }
  }, [dateRange, options, defaultSelectedOption]);

  return (
    <Select
      id={id}
      value={selectedOption}
      sx={selectTheme.medium}
      onChange={handleSelectChange}
      fullWidth
    >
      {options.map(({ field, displayName }) => (
        <MenuItem key={field} value={field}>
          <ListItemText sx={styles.listItemText} primary={displayName} />
        </MenuItem>
      ))}
    </Select>
  );
});

export default RangeSelect;

const styles = {
  listItemText: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    ...listItemTextTheme.medium,
  },
};
