import { COLORS } from 'consts/common/colors';

export const beforeProgressTitle = {
  fontSize: '20px',
  lineHeight: '44px',
  fontWeight: 700,
  color: COLORS.darkLine,
};

export const inProgressTitle = {
  fontSize: '28px',
  lineHeight: '60px',
  fontWeight: 700,
  color: 'primary.main',
};

export const afterProgressTitle = {
  fontSize: '20px',
  lineHeight: '44px',
  fontWeight: 700,
  color: COLORS.darkGrey,
};
