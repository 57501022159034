import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import type { SelectChangeEvent } from '@mui/material';
import useFilter from './hooks/useFilter';
import GridSection from '../GridSection';
import { inputTheme, selectTheme, listItemTextTheme } from 'styles/customedMuiTheme';
import ListItemText from '@mui/material/ListItemText';
import type { Form } from './index';

type Props = {
  label: string;
  searchOptions: { field: string; displayName: string }[];
  labelGridColumn: `${number}/${number}`;
  selectGridColumn: `${number}/${number}`;
  inputGridColumn: `${number}/${number}`;
  gridRow?: number;
  placeholder?: string;
  onSubmit?: (form: Form) => void;
};

const SearchWithSelect = forwardRef((props: Props, ref) => {
  const {
    label,
    searchOptions,
    labelGridColumn,
    selectGridColumn,
    inputGridColumn,
    gridRow,
    placeholder,
    onSubmit,
  } = props;
  const [selectedKeyword, setSelectedKeyword] = useState(searchOptions[0].field);
  const { form, setForm } = useFilter();

  const hasInitiated = !Object.keys(form).includes(selectedKeyword);

  useEffect(() => {
    if (hasInitiated) {
      setSelectedKeyword(searchOptions[0].field);
    }
  }, [hasInitiated]);

  useImperativeHandle(ref, () => ({
    inputText: (text: string) => {
      handleInputChange({ target: { value: text } } as ChangeEvent<HTMLInputElement>);
    },
    selectOption: (option: string) => {
      handleSelectChange({ target: { value: option } } as SelectChangeEvent<string>);
    },
  }));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(form => ({ ...form, [selectedKeyword]: e.target.value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const updatedForm = { ...form };

    updatedForm[e.target.value] = updatedForm[selectedKeyword];
    delete updatedForm[selectedKeyword];
    setForm(updatedForm);

    setSelectedKeyword(e.target.value);
  };

  return (
    <>
      <GridSection sx={{ gridRow, gridColumn: labelGridColumn }} isLabel>
        <Typography>{label}</Typography>
      </GridSection>
      <GridSection sx={{ gridRow, gridColumn: selectGridColumn }}>
        <FormControl size="small" fullWidth>
          <Select
            id="search-type"
            value={selectedKeyword}
            onChange={handleSelectChange}
            sx={selectTheme.medium}
          >
            {searchOptions.map(({ field, displayName }) => (
              <MenuItem key={field} value={field}>
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                    ...listItemTextTheme.medium,
                  }}
                  primary={displayName}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridSection>
      <GridSection sx={{ gridRow, gridColumn: inputGridColumn }}>
        <TextField
          id="search-by-keyword"
          variant="outlined"
          size="small"
          value={form[selectedKeyword] ?? ''}
          onChange={handleInputChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSubmit && onSubmit(form);
            }
          }}
          fullWidth
          sx={inputTheme.medium}
          placeholder={placeholder}
        />
      </GridSection>
    </>
  );
});

export default SearchWithSelect;
