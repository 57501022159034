import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/common/colors';

type Info = {
  name: string;
  value?: string;
};

type Props = {
  infoList: Info[];
  hideValue: boolean;
};

const InfoListSection = ({ infoList, hideValue }: Props) => {
  return (
    <Box sx={styles.container} data-testid="info-list-section">
      {infoList.map(({ name, value }) => (
        <Box key={name} sx={styles.itemWrapper}>
          <Typography>{name}</Typography>
          {!hideValue && <Typography sx={styles.value}>{value}</Typography>}
        </Box>
      ))}
    </Box>
  );
};

export default InfoListSection;

const styles = {
  container: {
    display: 'flex',
    padding: '12px 24px',
    height: '46px',
    backgroundColor: COLORS.bgDeep,
  },
  itemWrapper: {
    display: 'flex',
    gap: 1,
    mr: 5,
  },
  value: {
    fontWeight: 700,
    color: COLORS.ktown4u,
  },
};
