import React from 'react';
import { useRecoilValue } from 'recoil';
import useSWR, { KeyedMutator } from 'swr';
import Box from '@mui/system/Box';
import usePopup from 'hooks/usePopup';
import { warehouseAtom } from '../../../../../store/outbound/warehouse.recoil';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import { PickingGroups } from '../index.page';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import fetcher from '../../../../../libs/common/fetcher';
import { fetchPickingGroups } from '../services';

const modalWidth = 600;

type PickingGroupFormProps = {
  mutate: KeyedMutator<PickingGroups>;
  onClose: () => void;
};

const PickingGroupForm = (props: PickingGroupFormProps) => {
  const { showSnackbar, showAlert, showErrorDialog } = usePopup();
  const { onClose, mutate } = props;
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [isLoading, setIsLoading] = React.useState(false);
  const requestBody = {
    warehouseId: Number(globalWarehouse),
  };

  const queryParams = {
    warehouseId: Number(globalWarehouse),
  };

  const { data } = useSWR([`/devices/count`, { ...queryParams }], fetcher);

  const handleCreate = async () => {
    setIsLoading(true);
    const response = await fetchPickingGroups(requestBody);
    if (response?.status === 200) {
      showSnackbar({
        message: 'PDA 자동배정 등록을 완료하였습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: 'PDA 자동배정 등록 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
    await mutate();
    onClose();
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f3f3fa',
      color: theme.palette.common.black,
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography variant="h6">배정가능 PDA 수량</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align={'center'}>대량집품 작업가능 PDA</StyledTableCell>
                <StyledTableCell align={'center'}>1주문다집품 작업가능 PDA</StyledTableCell>
                <StyledTableCell align={'center'}>개별집품 작업가능 PDA</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell align={'center'}>{data?.bulkPickingCount ?? 0}</StyledTableCell>
                <StyledTableCell align={'center'}>
                  {data?.singleBulkPickingCount ?? 0}
                </StyledTableCell>
                <StyledTableCell align={'center'}>{data?.pickingCount ?? 0}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography align={'center'}>
          작업가능 PDA 수량 조정이 필요한 경우 출고작업기기 현황 관리 메뉴에서 관리 가능합니다.
        </Typography>
      </Box>

      <SaveButtonGroup
        onSaveClick={handleCreate}
        onCloseClick={onClose}
        isLoading={isLoading}
        saveButtonName={'일괄배정 생성'}
      />
    </Box>
  );
};

export default PickingGroupForm;
