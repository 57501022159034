import { TableCell as MUITableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import InfoListSection from '../../../../../components/common/typography/InfoListSection';
import { numberFormatter } from '../../../../../libs/common/unitConverters';
import {
  bulkPackingDim,
  calVolumetricWeight,
} from '../../../../../libs/outbound/calWeightByVolume';
import ButtonGroup from './ButtonGroup';
import { SmallTableRow, SmallTableCell } from '../../../components/Table';
import { COLORS } from 'consts/common/colors';
import usePopup from '../../../../../hooks/usePopup';
import { useRecoilState, useRecoilValue } from 'recoil';
import bulkPackingAtom, {
  bulkPackingBoxAtom,
  bulkPackingStepAtom,
} from '../../../../../store/outbound/bulkPacking.recoil';
import { getEstimatedDeliveryPriceOfBulk } from '../services';

const modalWidth = 600;

type Props = {
  onClose: () => void;
  boxBarcode?: string;
};

type BoxDimension = {
  width: number | string;
  length: number | string;
  height: number | string;
  volumetricWeight?: number;
  estimatedDeliveryPrice?: number | string;
};

const initialBoxDimension: BoxDimension = {
  width: 0,
  length: 0,
  height: 0,
  volumetricWeight: 0,
  estimatedDeliveryPrice: '-',
};

const BoxSizeForm = (props: Props) => {
  const [selectedStep, setSelectedStep] = useRecoilState(bulkPackingStepAtom);
  const { showErrorDialog } = usePopup();
  const { onClose, boxBarcode } = props;
  const [isLoading, setIsLoading] = useState(false);
  const bulkPacking = useRecoilValue(bulkPackingAtom);
  const [bulkPackingBox, setBulkPackingBox] = useRecoilState(bulkPackingBoxAtom);
  const [baseBoxDimension, setBaseBoxDimension] = useState<BoxDimension>(initialBoxDimension);
  const [changingBoxDimension, setChangingBoxDimension] =
    useState<BoxDimension>(initialBoxDimension);
  const handleSave = async () => {
    if (
      [changingBoxDimension.width, changingBoxDimension.length, changingBoxDimension.height].some(
        input => !input || Number(input) <= 0
      )
    ) {
      showErrorDialog({
        title: '박스정보 저장 실패',
        errorMessage: '박스의 가로, 세로, 높이 정보를 입력해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    setSelectedStep(selectedStep + 1);
    updateBulkPackingBoxWidth();
    setIsLoading(false);
    onClose();
  };
  const emptyInputBoxes = ['Cartonwrap', 'BULK5080FAKE'];
  const isCustomBoxBarcode = emptyInputBoxes.includes(boxBarcode ?? '');

  useEffect(() => {
    const initBaseBoxDimension = async (width: string, length: string, height: string) => {
      const volumetricWeight = calVolumetricWeight(width, length, height);
      const estimatedDeliveryPrice = await getEstimatedDeliveryPrice(
        Number(width),
        Number(length),
        Number(height)
      );
      const boxDimensions: BoxDimension = {
        width,
        length,
        height,
        volumetricWeight,
        estimatedDeliveryPrice,
      };
      setChangingBoxDimension(boxDimensions);
      setBaseBoxDimension(boxDimensions);
    };
    if (bulkPackingBox && !emptyInputBoxes.includes(bulkPackingBox.barcode)) {
      const width = bulkPackingBox.width ? bulkPackingBox.width.toString() : '';
      const length = bulkPackingBox.length ? bulkPackingBox.length.toString() : '';
      const height = bulkPackingBox.height ? bulkPackingBox.height.toString() : '';
      initBaseBoxDimension(width, length, height);
    }
  }, [bulkPackingBox]);

  const updateBulkPackingBoxWidth = () => {
    const currentBulkPackingBox = { ...bulkPackingBox };
    const updatedPackingBox: any = {
      ...currentBulkPackingBox,
      width: changingBoxDimension.width,
      length: changingBoxDimension.length,
      height: changingBoxDimension.height,
    };
    setBulkPackingBox(updatedPackingBox);
  };

  const convertNumberOrDefault = (value: string | number) => Number(value) || 0;

  const handleWidthChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setChangingBoxDimension({ ...changingBoxDimension, width: value });
    await updateChangingBoxDimension(
      value,
      changingBoxDimension.length,
      changingBoxDimension.height
    );
  };

  const handleLengthInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setChangingBoxDimension({ ...changingBoxDimension, length: value });
    await updateChangingBoxDimension(
      changingBoxDimension.width,
      value,
      changingBoxDimension.height
    );
  };

  const handleHeightInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setChangingBoxDimension({ ...changingBoxDimension, height: value });
    await updateChangingBoxDimension(
      changingBoxDimension.width,
      changingBoxDimension.length,
      value
    );
  };

  const getEstimatedDeliveryPrice = async (width: number, length: number, height: number) => {
    const queryParams = {
      width: width,
      length: length,
      height: height,
      volumetricDivisor: bulkPackingDim,
    };
    const axiosResponse = await getEstimatedDeliveryPriceOfBulk(queryParams);
    const estimatedDeliveryPrice = axiosResponse?.data;
    if (axiosResponse?.status !== 200) {
      showErrorDialog({
        title: '예상 배송비 조회 실패',
        errorMessage: estimatedDeliveryPrice?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      return '-';
    }
    return estimatedDeliveryPrice;
  };

  // 예상 배송비 조회
  const updateChangingBoxDimension = async (
    width2: number | string,
    length2: number | string,
    height2: number | string
  ) => {
    const width = convertNumberOrDefault(width2);
    const length = convertNumberOrDefault(length2);
    const height = convertNumberOrDefault(height2);
    const volumetricWeight = calVolumetricWeight(width, length, height);
    if (height <= 0 || width <= 0 || length <= 0 || volumetricWeight <= 0) {
      setChangingBoxDimension({
        width: width2,
        length: length2,
        height: height2,
        volumetricWeight: 0,
        estimatedDeliveryPrice: '-',
      });
      return;
    }

    const estimatedDeliveryPrice = await getEstimatedDeliveryPrice(width, length, height);
    setChangingBoxDimension({
      width,
      length,
      height,
      volumetricWeight,
      estimatedDeliveryPrice,
    });
  };

  const infoList = [
    { name: '배송방식', value: 'DHL' },
    { name: 'DIM', value: String(bulkPackingDim) },
    { name: '실제중량무게(g)', value: String(bulkPacking?.totalWeightOfShipping) },
  ];

  return (
    <Box width={modalWidth}>
      <Box sx={styles.container}>
        <SmallTableRow>
          <SmallTableCell
            label={'박스명'}
            value={
              boxBarcode === 'Cartonwrap' ? (
                <Box>Cartonwrap</Box>
              ) : (
                <Box>{bulkPackingBox?.name}</Box>
              )
            }
          />
        </SmallTableRow>
        <SmallTableRow>
          <SmallTableCell
            label={'가로(mm)'}
            value={
              isCustomBoxBarcode ? (
                <input
                  value={changingBoxDimension.width}
                  onChange={handleWidthChange}
                  style={styles.select}
                />
              ) : (
                <Box>{changingBoxDimension.width}</Box>
              )
            }
          />
        </SmallTableRow>
        <SmallTableRow>
          <SmallTableCell
            label={'세로(mm)'}
            value={
              isCustomBoxBarcode ? (
                <input
                  value={changingBoxDimension.length}
                  onChange={handleLengthInput}
                  style={styles.select}
                />
              ) : (
                <Box>{changingBoxDimension.length}</Box>
              )
            }
          />
        </SmallTableRow>
        <SmallTableRow>
          <SmallTableCell
            label={'높이(mm)'}
            value={
              <input
                value={changingBoxDimension.height}
                onChange={handleHeightInput}
                style={styles.select}
              />
            }
          />
        </SmallTableRow>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={styles.informationText}>{'부피무게 예상배송비'}</Typography>
        <Box sx={{ marginY: 1 }}>
          <InfoListSection infoList={infoList} hideValue={false} />
        </Box>
        <Box sx={{ border: `1px solid ${COLORS.lightLine}`, borderRadius: '4px' }}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <SmallTableRow>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  구분
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  가로
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  세로
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  높이
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 3 }}>
                  포장실제부피무게(g)
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 2 }}>
                  예상배송비(원)
                </StyledTableCell>
              </SmallTableRow>
            </TableHead>
            <TableBody>
              <SmallTableRow>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  변경전
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {baseBoxDimension?.width}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {baseBoxDimension?.length}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {baseBoxDimension?.height}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 3 }}>
                  {numberFormatter(String(baseBoxDimension.volumetricWeight))}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 2 }}>
                  {numberFormatter(String(baseBoxDimension.estimatedDeliveryPrice))}
                </StyledTableCell>
              </SmallTableRow>
              <SmallTableRow>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  변경후
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {changingBoxDimension.width}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {changingBoxDimension.length}
                </StyledTableCell>
                <StyledTableCell align={'center'} style={{ flex: 1 }}>
                  {changingBoxDimension.height}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 3,
                    color:
                      Number(baseBoxDimension.volumetricWeight || 0) >
                      Number(changingBoxDimension.volumetricWeight || 0)
                        ? COLORS.red
                        : '',
                  }}
                >
                  {numberFormatter(String(changingBoxDimension.volumetricWeight))}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 2,
                    color:
                      Number(baseBoxDimension.estimatedDeliveryPrice || 0) >
                      Number(changingBoxDimension.estimatedDeliveryPrice || 0)
                        ? COLORS.red
                        : '',
                  }}
                >
                  {numberFormatter(String(changingBoxDimension.estimatedDeliveryPrice))}
                </StyledTableCell>
              </SmallTableRow>
              <SmallTableRow>
                <StyledTableCell align={'center'} style={{ flex: 6.1 }}>
                  예상배송비 차이
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 3,
                    color:
                      Number(baseBoxDimension.volumetricWeight || 0) >
                      Number(changingBoxDimension.volumetricWeight || 0)
                        ? COLORS.red
                        : undefined,
                  }}
                >
                  {numberFormatter(
                    String(
                      (changingBoxDimension.volumetricWeight || 0) -
                        (baseBoxDimension.volumetricWeight || 0)
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  align={'center'}
                  style={{
                    flex: 2,
                    color:
                      Number(baseBoxDimension.estimatedDeliveryPrice || 0) >
                      Number(changingBoxDimension.estimatedDeliveryPrice || 0)
                        ? COLORS.red
                        : undefined,
                  }}
                >
                  {numberFormatter(
                    String(
                      (Number(changingBoxDimension.estimatedDeliveryPrice) || 0) -
                        (Number(baseBoxDimension.estimatedDeliveryPrice) || 0)
                    )
                  )}
                </StyledTableCell>
              </SmallTableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <ButtonGroup onCloseClick={onClose} onSaveClick={handleSave} isLoading={isLoading} />
    </Box>
  );
};

const StyledTableCell = styled(MUITableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f3f3fa',
    color: theme.palette.common.black,
    fontSize: 13,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default BoxSizeForm;

const styles = {
  container: {
    border: `1px solid ${COLORS.lightLine}`,
    borderRadius: '4px',
  },
  select: {
    height: 30,
    fontSize: 13,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
};
