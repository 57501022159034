import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import warehouseAtom, { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { Options } from '../../../../types/form';
import gridColDef from './gridColDef';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import Button from '@mui/material/Button';
import FormModal from '../../../../components/common/FormModal';
import StocktakingForm from './components/StocktakingForm';
import usePopup from '../../../../hooks/usePopup';
import LoadingModal from '../../outbound/components/LoadingModal';
import convertUser from '../../../../libs/common/convertUser';
import {
  applyStocktakingToInventoryLpn,
  createStocktakingLocations,
  StopStocktaking,
} from './services/StopStocktaking';
import { generateExcelDownload } from './services/excelDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import ExcelUpload from './components/ExcelUpload';
import useConditionalSWR from '../../components/useConditionalSwr';
import ExcelLocationUpload from './components/ExcelLocationUpload';

type stocktaking = {
  id: number;
  stocktakingType: string;
  stocktakingStatus: string;
  scheduledAtFrom: string;
  scheduledAtTo: string;
  name: string;
  serialNumber: string;
  updatedUsername: string;
  updatedLoginId: string;
};

type StocktakingResponse = {
  content: stocktaking[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const dateRangeTypeOptions: Options = [
  {
    displayName: '조사시작일',
    field: 'scheduledAtFrom',
  },
  {
    displayName: '조사종료일',
    field: 'scheduledAtTo',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '재고조사ID',
    field: 'serialNumber',
  },
  {
    displayName: '재고조사명',
    field: 'name',
  },
];

const Stocktakings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [selectedStocktaking, setSelectedStocktaking] = useState<number[]>([]);
  const [hasSearched, setHasSearched] = useState(false);
  const { showErrorDialog, showSnackbar, showDialog, showAlert } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isExcelUploadOpen, setIsExcelUploadOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isApplyInventory, setIsApplyInventory] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const statusOptions = refCodeOptions?.stocktakingStatus || [];
  const typeOptions = refCodeOptions?.stocktakingType || [];

  const { data, isValidating, mutate } = useConditionalSWR<StocktakingResponse>(
    [`/inventories/stocktakings/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: StocktakingResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: stocktaking, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      stocktakingRange: `${item.scheduledAtFrom} ~ ${item.scheduledAtTo}`,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleStopStocktaking = () => {
    setSelectedStocktaking([]);
    const stocktakingLength = data?.content
      .filter(item => selectedStocktaking?.includes(item.id))
      .filter(s => s.stocktakingStatus === 'APPLIED' || s.stocktakingStatus === 'DISUSE')
      .length as number;
    // 재고조사 중지 가능 상태 확인
    if (selectedStocktaking.length === 0 || stocktakingLength > 0) {
      showErrorDialog({
        title: '재고조사 중지 실패',
        errorMessage: '실재고완료 상태가 아닌 재고조사를 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    // 선택된 재고조사 수 확인
    if (selectedStocktaking.length !== 1) {
      duplicateCheckBox();
      return;
    }
    showDialog({
      message:
        "선택하신 재고조사가 '중지' 상태로 변경됩니다.\n" +
        "'중지'상태로 변경되면 상태 복구가 되지 않으며, \n" +
        '해당 재고조사를 다시 진행할 수 없습니다.\n' +
        '재고조사를 중지하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            await stopStocktaking();
          },
        },
      ],
    });
  };

  const stopStocktaking = async () => {
    const stocktakingId = selectedStocktaking[0];
    const response = await StopStocktaking(stocktakingId);
    if (response?.status === 200) {
      await mutate();
      showSnackbar({
        message: '재고조사 중지가 완료되었습니다.',
        severity: 'success',
      });
      setSelectedStocktaking([]);
    } else {
      showErrorDialog({
        title: '재고조사 중지 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  const handleCreateStocktakingLocation = () => {
    setSelectedStocktaking([]);
    const stocktakingLength = data?.content
      .filter(item => selectedStocktaking?.includes(item.id))
      .filter(s => s.stocktakingStatus !== 'CREATED').length as number;
    if (selectedStocktaking.length === 0 || stocktakingLength > 0) {
      showErrorDialog({
        title: '재고조사 로케이션 등록 실패',
        errorMessage: 'DB생성대기 상태의 재고조사를 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    if (selectedStocktaking.length !== 1) {
      duplicateCheckBox();
      return;
    }
    showDialog({
      message: '재고조사용 DB 생성은 1번만 가능합니다. 진행하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            await createStocktakingLocation();
          },
        },
      ],
    });
  };

  const createStocktakingLocation = async () => {
    setIsLoading(true);
    const stocktakingId = selectedStocktaking[0];
    const response = await createStocktakingLocations(stocktakingId);
    if (response?.status === 200) {
      await mutate();
      showSnackbar({
        message: '재고조사 로케이션이 생성되었습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: '재고조사 로케이션생성 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  const downloadLocations = async () => {
    setIsDownload(true);
    if (selectedStocktaking.length !== 1) {
      duplicateCheckBox();
      setIsDownload(false);
      return;
    }
    const response = await generateExcelDownload(selectedStocktaking[0]);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setSelectedStocktaking([]);
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsDownload(false);
  };

  const duplicateCheckBox = () => {
    showErrorDialog({
      title: '재고조사 중복선택',
      errorMessage: '재고조사를 하나 선택해 주세요',
      buttons: [
        {
          text: '확인',
        },
      ],
    });
  };

  const uploadExceptLocations = () => {
    if (selectedStocktaking.length !== 1) {
      duplicateCheckBox();
      setIsDownload(false);
      return;
    }
    setIsExcelUploadOpen(true);
  };

  const uploadLocations = () => {
    if (selectedStocktaking.length !== 1) {
      duplicateCheckBox();
      setIsUpload(false);
      return;
    }
    const uploadStocktaking = data?.content?.filter(
      item => selectedStocktaking?.includes(item.id) && item.stocktakingStatus != 'CREATED'
    ).length as number;
    if (uploadStocktaking > 0) {
      showAlert({ message: 'DB생성대기 상태의 재고조사를 선택해 주세요. ' });
      return;
    }

    setIsUpload(true);
  };

  const applyStocktakingResultToInventoryLpn = async () => {
    if (selectedStocktaking.length !== 1) {
      duplicateCheckBox();
      setIsDownload(false);
      return;
    }
    setIsApplyInventory(true);
    const stocktakingId = selectedStocktaking[0];
    const response = await applyStocktakingToInventoryLpn(stocktakingId);
    if (response?.status === 200) {
      await mutate();
      showSnackbar({
        message: '실재고 반영이 완료되었습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: '실재고 반영 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsApplyInventory(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        재고조사 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="재고조사상태"
          field="status"
          options={[defaultOption, ...statusOptions]}
          labelGridColumn="4/6"
          selectGridColumn="6/8"
        />
        <Filter.Select
          label="재고조사구분"
          field="type"
          options={[defaultOption, ...typeOptions]}
          labelGridColumn="8/10"
          selectGridColumn="10/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            재고조사 관리 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              loading={isDownload}
              onClick={() => {
                downloadLocations();
              }}
            >
              로케이션 조사대상 양식 다운로드
            </LoadingButton>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              loading={isDownload}
              onClick={() => {
                uploadLocations();
              }}
            >
              로케이션 조사대상 업로드
            </LoadingButton>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleStopStocktaking}
            >
              재고조사 중지
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={applyStocktakingResultToInventoryLpn}
            >
              재고조사결과 실재고 적용
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              등록
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          selectionModel={selectedStocktaking}
          onSelectionModelChange={ids => setSelectedStocktaking(ids as number[])}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal open={isModalOpen} title={'재고조사 등록'} onClose={() => setIsModalOpen(false)}>
        <StocktakingForm
          warehouseId={Number(globalWarehouse)}
          onClose={() => setIsModalOpen(false)}
          onMutate={mutate}
        />
      </FormModal>
      <FormModal
        open={isExcelUploadOpen}
        title={'로케이션 제외 등록'}
        onClose={() => setIsExcelUploadOpen(false)}
      >
        <ExcelUpload
          stocktakingId={selectedStocktaking[0]}
          onCloseClick={() => setIsExcelUploadOpen(false)}
          onMutate={mutate}
        />
      </FormModal>
      <FormModal
        open={isUpload}
        title={'로케이션 조사대상 업로드'}
        onClose={() => setIsUpload(false)}
      >
        <ExcelLocationUpload
          stocktakingId={selectedStocktaking[0]}
          onCloseClick={() => setIsUpload(false)}
          onMutate={mutate}
        />
      </FormModal>
      <LoadingModal isLoading={isLoading} message={'재고조사 로케이션 생성중...'} />
      <LoadingModal isLoading={isApplyInventory} message={'실재고 반영중...'} />
    </Page>
  );
};

export default Stocktakings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
