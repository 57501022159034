import React from 'react';

const ChevronDouble = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_27140)">
        <path
          d="M21.6004 2.3999L12.0004 11.0399L2.40039 2.3999"
          stroke="#18AEEA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6004 12.96L12.0004 21.6L2.40039 12.96"
          stroke="#18AEEA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_121_27140">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronDouble;
