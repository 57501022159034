export enum FONT_SIZE {
  small = '12px',
  medium = '13px',
  large = '14px',
}

export enum LINE_HEIGHT {
  small = '20px',
  medium = '24px',
  large = '26px',
}

export enum LETTER_SPACING {
  small = '-0.3px',
  medium = '0.4px',
  large = '0.46px',
}
