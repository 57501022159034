import React, { ReactNode, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useSetRecoilState } from 'recoil';
import { shouldMountAppBarAtom, shouldMountDrawerAtom } from 'store';
import { COLORS } from 'consts/common/colors';

type Prop = {
  title: string;
  info: ReactNode;
  subTitle?: string;
  main: ReactNode;
  footer: ReactNode;
};

const PopupTemplate = (props: Prop) => {
  const { title, subTitle, main, footer, info } = props;
  const setShouldMountAppBar = useSetRecoilState(shouldMountAppBarAtom);
  const setShouldMountDrawer = useSetRecoilState(shouldMountDrawerAtom);

  useEffect(() => {
    setShouldMountAppBar(false);
    setShouldMountDrawer(false);
  }, []);

  return (
    <Stack spacing={3} sx={{ minHeight: '100vh', padding: '32px', backgroundColor: 'white' }}>
      <Stack spacing={1.5}>
        <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 700 }}>
          {title}
        </Typography>
        <Box
          sx={{ display: 'flex', gap: '8px', padding: '12px 24px', backgroundColor: COLORS.bgDeep }}
        >
          {info}
        </Box>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 700 }}>
          {subTitle}
        </Typography>
        {main}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>{footer}</Box>
    </Stack>
  );
};

export default PopupTemplate;
