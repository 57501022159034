const CONVEYOR_PATH = {
  AIR_MANUAL: '1',
  AIR_CARTON: '2',
  AIR_GENESIS: '3',
  CARTON: '4',
  GENESIS: '5',
  MANUAL: '6',
} as const;

export const CONVEYOR_PATH_ARR = Object.entries(CONVEYOR_PATH).map(([key, value]) => ({
  value: key,
  displayName: value,
}));

export default CONVEYOR_PATH;
