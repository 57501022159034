import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { axiosV2 } from '../../../../../libs/common/fetcher';

export const exceptStocktakingLocations = async (stocktakingId: number, formData: FormData) => {
  try {
    return await axiosV2.delete(`/inventories/stocktakings/${stocktakingId}/locations/remove`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`excelUpload 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
