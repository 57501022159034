import React, { ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import pageTitle from 'styles/pageTitle';

type FormModalProps = {
  open: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
  canCloseByBackground?: boolean;
};

const FormModal = (props: FormModalProps) => {
  const { open, onClose, title, children, canCloseByBackground = true } = props;

  return (
    <Modal
      open={open}
      onClose={canCloseByBackground ? onClose : undefined}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="form"
        sx={{
          padding: 2,
          backgroundColor: 'white',
          ':focus': {
            outline: 'none',
          },
        }}
        autoComplete="off"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              marginBottom: 2,
              ...pageTitle,
            }}
          >
            {title}
          </Typography>
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default FormModal;
