import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';

export type SearchBayRequest = {
  warehouseId: number;
};

export const getBins = async (params: SearchBayRequest) => {
  try {
    return await axiosV2.get('/bins/bays/search', { params });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateCompanyRequest = {
  name: string;
  code: string;
  isActive: string;
  registrationNumber: string;
};

export const createCompany = async (requestBody: CreateCompanyRequest) => {
  try {
    return await axiosV2.post('/companies', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type UpdateBinRequest = Omit<
  CreateCompanyRequest,
  'name' | 'isActive' | 'registrationNumber'
>;

export const updateCompany = async (companyId: number, requestBody: UpdateBinRequest) => {
  try {
    return await axiosV2.put(`/companies/${companyId}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
