import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from '../../../../../components/common/Layout/Page';
import TightDataGridSum from '../../../../../components/common/TightDataGridSum';
import TableHorizontal, { TableCell, TableRow } from '../../../components/TableHorizontal';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import usePopup from '../../../../../hooks/usePopup';
import pageTitle from '../../../../../styles/pageTitle';
import FormModal from '../../../../../components/common/FormModal';
import { listBoardTheme } from '../../../../../styles/customedMuiTheme';
import gridColDef from './gridColDef';
import { getReceiptsConfirmDetails, receivingCompleted, receivingReInspect } from './services';
import ImageZoomModal from '../../../../../components/ImageZoomModal';

type InboundReceiptConfirmDetailContent = {
  barcode: string;
  offlineBarcode: string;
  itemCode: string;
  imageUrl: string;
  itemName: string;
  expirationDate: string;
  receivedQty: number;
  inspectedQty: number;
  cost: number;
  lpnCode: string;
  lpnStatus: string;
  lpnType: string;
  totalReceivedCost: number;
};

type InboundReceiptConfirmDetail = {
  receivingId: number;
  receivingItem: InboundReceiptConfirmDetailContent[];
  receivedDate: string;
  inspectedAt: string;
  receivingName: string;
  supplierName: string;
};

const ReceiptsConfirmDetails = () => {
  const {
    state: { receivingId },
  } = useLocation();

  const [data, setData] = useState<InboundReceiptConfirmDetail>();
  const [imageUrl, setImageUrl] = useState('');
  const [isReInspectionModalOpen, setIsReInspectionModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isReInspectionLoading, setIsReInspectionLoading] = useState(false);
  const { showErrorDialog, showSnackbar } = usePopup();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getReceiptsConfirmDetails(receivingId);
      if (response?.status === 200) {
        setData(makeDataWithRowIndex(response.data));
      } else {
        showErrorDialog({
          title: '입고확정 상세 조회 실패',
          errorMessage: response?.data?.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    };

    fetchData();
  }, []);

  const topBoards = [
    { label: '입고ID', value: data?.receivingName },
    { label: '공급업체', value: data?.supplierName },
    { label: '입고일', value: data?.receivedDate },
    { label: '검수일', value: data?.inspectedAt }, //TODO: 검수일 추가해야 함.
  ];

  const handleGoConfirmListBack = () => {
    window.history.back();
  };

  const handleReceivingReInspect = async () => {
    setIsReInspectionLoading(true);
    const res = data && (await receivingReInspect(data.receivingId, rejectionReason));
    if (res?.status === 200) {
      showSnackbar({ message: '재검수 요청이 완료되었습니다.', severity: 'success' });
      window.history.back();
    } else {
      showErrorDialog({
        title: '재검수 요청 실패',
        errorMessage: res?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsReInspectionLoading(false);
  };

  const handleReceivingCompleted = async () => {
    setIsConfirmLoading(true);
    const res = data && (await receivingCompleted(data?.receivingId));
    if (res?.status === 200) {
      showSnackbar({ message: '입고확정 요청이 완료되었습니다.', severity: 'success' });
      window.history.back();
    } else {
      showErrorDialog({
        title: '입고확정 실패',
        errorMessage: res?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsConfirmLoading(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        입고확정 상세
      </Typography>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <TableHorizontal>
          <TableRow>
            {topBoards.map(board => (
              <TableCell key={board.label} label={board.label} value={board.value} />
            ))}
          </TableRow>
        </TableHorizontal>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            검수 아이템 목록
          </Typography>
        </Box>
        <TightDataGridSum
          getRowId={row => row.itemCode}
          rows={(data && addSumRowToItems(data.receivingItem)) ?? []}
          columns={gridColDef()}
          hideFooter
          onCellClick={({ field, row }) => {
            if (field === 'imageUrl') setImageUrl(getFinalUrl(row.imageUrl));
          }}
          hasSearched={true}
          autoHeight={true}
        />
        <Stack flexDirection="row" gap={1} justifyContent="flex-end">
          <Button variant="outlined" onClick={handleGoConfirmListBack}>
            목록
          </Button>
          <Button variant="outlined" onClick={() => setIsReInspectionModalOpen(true)}>
            재검수
          </Button>
          <Button variant="contained" onClick={() => setIsConfirmModalOpen(true)}>
            입고확정
          </Button>
        </Stack>

        <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />

        <FormModal
          open={isReInspectionModalOpen}
          title={'재검수 사유 등록'}
          onClose={() => setIsReInspectionModalOpen(false)}
        >
          <Stack width={600} gap={1}>
            <Typography>재검수 사유를 입력하세요.</Typography>
            <TextField
              fullWidth
              onChange={e => {
                setRejectionReason(e.target.value);
              }}
              onKeyDown={async e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  await handleReceivingReInspect();
                }
              }}
            />
            <Stack flexDirection={'row'} justifyContent={'center'} gap={1} m={1}>
              <Button variant={'outlined'} onClick={() => setIsReInspectionModalOpen(false)}>
                취소
              </Button>
              <LoadingButton
                variant={'contained'}
                disabled={!rejectionReason}
                onClick={handleReceivingReInspect}
                loading={isReInspectionLoading}
              >
                재검수 지시
              </LoadingButton>
            </Stack>
          </Stack>
        </FormModal>

        <FormModal
          open={isConfirmModalOpen}
          title={'입고확정'}
          onClose={() => setIsConfirmModalOpen(false)}
        >
          <Stack width={600} gap={1}>
            <Typography>입고 확정 시, 정상수량을 기준으로 거래명세서가 생성됩니다.</Typography>
            <Typography>입고를 확정하시겠습니까?</Typography>
            <Stack flexDirection={'row'} justifyContent={'center'} gap={1} m={1}>
              <Button variant={'outlined'} onClick={() => setIsConfirmModalOpen(false)}>
                취소
              </Button>
              <LoadingButton
                variant={'contained'}
                onClick={handleReceivingCompleted}
                loading={isConfirmLoading}
              >
                확인
              </LoadingButton>
            </Stack>
          </Stack>
        </FormModal>
      </Stack>
    </Page>
  );
};

export default ReceiptsConfirmDetails;

const makeDataWithRowIndex = (data: InboundReceiptConfirmDetail) => {
  return {
    ...data,
    receivingItem: data?.receivingItem.map(
      (item: InboundReceiptConfirmDetailContent, index: number) => ({
        ...item,
        rowIndex: index + 1,
        totalReceivedCost: item.receivedQty * item.cost,
      })
    ),
  };
};

const addSumRowToItems = (items: InboundReceiptConfirmDetailContent[] | undefined) => {
  if (items === undefined) return [];

  const summery = {
    rowIndex: 'total',
    barcode: '',
    offlineBarcode: '',
    itemCode: '',
    imageUrl: '',
    itemName: '',
    expirationDate: '',
    inspectedQty: 0,
    cost: items.reduce((acc, cur) => acc + cur.cost, 0) ?? 0,
    receivedQty: items.reduce((acc, cur) => acc + cur.receivedQty, 0) ?? 0,
    lpnCode: '',
    lpnStatus: '',
    lpnType: '',
    totalReceivedCost: items.reduce((acc, cur) => acc + cur.totalReceivedCost, 0) ?? 0,
  } as InboundReceiptConfirmDetailContent;

  return items.concat(summery);
};
