import { FallbackProps } from 'react-error-boundary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';

const ErrorFallback = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;

  return (
    <Box
      role="alert"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 3,
        alignItems: 'center',
        marginX: 'auto',
        marginTop: '30%',
      }}
    >
      <Typography variant="h2">에러가 발생했습니다 ☹️</Typography>
      <Typography>{error.message}</Typography>
      <ButtonGroup variant="text">
        <Link to="/">
          <Button>메인으로 돌아가기</Button>
        </Link>
        <Button onClick={resetErrorBoundary}>다시 해보기</Button>
      </ButtonGroup>
    </Box>
  );
};

export default ErrorFallback;
