import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React, { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { listItemTextTheme } from '../../../../../styles/customedMuiTheme';
import { Inventory } from '../index.page';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { TableCell, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { moveInventory, MovementRequestBody } from '../services/InventoryLocation';
import { datadogRum } from '@datadog/browser-rum';
import usePopup from '../../../../../hooks/usePopup';
import { COLORS } from '../../../../../consts/common/colors';

type Props = {
  inventoryLpn: Inventory | undefined;
  onClose: () => void;
  mutate: () => void;
};

const MoveQuantityBetweenItemForm = (props: Props) => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const { inventoryLpn, onClose, mutate } = props;
  const [toLocation, setToLocation] = useState<string>('');
  const [note, setNote] = useState<string | null>(null);
  const [movingQty, setMovingQty] = useState<string>('');
  const [isMoving, setIsMoving] = useState(false);
  const [movementType, setMovementType] = useState<string>('select');
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const inventoryMovementHistoryTypes = refCodeOptions?.inventoryMovementHistoryType || [];
  const handleInventoryMove = async () => {
    if (!inventoryLpn) {
      showErrorDialog({
        title: '재고 이동 실패',
        errorMessage: '이동 전 로케이션 정보가 없습니다.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    if (!toLocation) {
      showErrorDialog({
        title: '재고 이동 실패',
        errorMessage: '이동후 로케이션을 입력해 주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    if (!movingQty || movingQty === '0') {
      showErrorDialog({
        title: '재고 이동 실패',
        errorMessage: '재고이동 수량을 입력해주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    if (movementType === 'select') {
      showErrorDialog({
        title: '재고 이동 실패',
        errorMessage: '조정사유를 선택해주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    setIsMoving(true);

    const requestBody: MovementRequestBody = {
      warehouseId: inventoryLpn.warehouseId,
      movementType: movementType,
      note: note,
      movingItems: [
        {
          fromLocationCode: inventoryLpn.locationCode,
          toLocationCode: toLocation,
          inventoryLpnId: inventoryLpn.id,
          quantity: Number(movingQty),
        },
      ],
    };

    const response = await moveInventory(requestBody);

    if (response?.status === 200) {
      mutate();
      showSnackbar({ message: '재고 이동이 완료되었습니다.', severity: 'success' });
      onClose();
      datadogRum.addAction(`재고 이동 성공: ${inventoryLpn?.id}`);
    } else {
      showErrorDialog({
        title: '재고 이동 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      datadogRum.addError(`재고 이동 실패: ${response?.data?.errorMessage}`);
    }

    setIsMoving(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const carrierToChange = e.target.value;
    setMovementType(carrierToChange);
  };

  return (
    <Box width={1000}>
      <Typography sx={{ color: 'text.primary', fontWeight: '800' }}>
        이동전 로케이션에서 이동후 로케이션으로 아이템 재고이동하는 기능입니다. 로케이션 가용수량만
        재고이동 가능합니다.
      </Typography>
      <Box>
        <Box sx={styles.information}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            {'01. 이동전 로케이션'}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table
            size="small"
            sx={{ border: `1px solid ${COLORS.lightLine}` }}
            aria-label="customized table"
          >
            <TableHead sx={{ height: 36, backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell align={'center'} sx={{ width: 170 }}>
                  로케이션
                </TableCell>
                <TableCell align={'center'} sx={{ width: 120 }}>
                  LPN
                </TableCell>
                <TableCell align={'center'} sx={{ width: 120 }}>
                  아이템코드
                </TableCell>
                <TableCell align={'center'}>아이템명</TableCell>
                <TableCell align={'center'} sx={{ width: 132 }}>
                  로케이션 가용수량
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 36 }}>
              <TableRow>
                <TableCell align={'center'}>{inventoryLpn?.locationCode}</TableCell>
                <TableCell align={'center'}>{inventoryLpn?.lpnSerialNumber}</TableCell>
                <TableCell align={'center'}>{inventoryLpn?.itemCode}</TableCell>
                <TableCell align={'center'}>{inventoryLpn?.itemName}</TableCell>
                <TableCell align={'center'}>{inventoryLpn?.quantity}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.information}>
          <Typography variant="h6" sx={{ color: 'text.primary' }} mt={5}>
            {'02. 이동후 로케이션'}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table
            size="small"
            sx={{ border: `1px solid ${COLORS.lightLine}` }}
            aria-label="customized table"
          >
            <TableHead sx={{ height: 36, backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell align={'center'}>
                  로케이션<span style={{ color: COLORS.red }}> *</span>
                </TableCell>
                <TableCell align={'center'}>
                  재고이동 수량<span style={{ color: COLORS.red }}> *</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 36 }}>
              <TableRow>
                <TableCell align={'center'} sx={{ py: 0 }}>
                  <TextField
                    fullWidth
                    defaultValue={toLocation}
                    onBlur={e => setToLocation(e.target.value)}
                    variant="outlined"
                    size="small"
                    InputProps={{ sx: { fontSize: 13, height: 32 } }}
                  />
                </TableCell>
                <TableCell align={'center'} sx={{ py: 0 }}>
                  <TextField
                    fullWidth
                    value={movingQty}
                    onChange={e => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setMovingQty(value);
                    }}
                    variant="outlined"
                    size="small"
                    InputProps={{ sx: { fontSize: 13, height: 32 } }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.information}>
          <Typography variant="h6" sx={{ color: 'text.primary' }} mt={5}>
            {'조정사유'}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table
            size="small"
            sx={{ border: `1px solid ${COLORS.lightLine}` }}
            aria-label="customized table"
          >
            <TableHead sx={{ height: 36, backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell align={'center'} sx={{ width: 170 }}>
                  조정사유<span style={{ color: COLORS.red }}> *</span>
                </TableCell>
                <TableCell align={'center'}>조정 상세사유</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 36 }}>
              <TableRow>
                <TableCell align={'center'} sx={{ py: 0 }}>
                  <TextField
                    select
                    fullWidth
                    value={movementType}
                    onChange={handleChange}
                    size={'small'}
                    inputProps={{ sx: { paddingY: 0.4, fontSize: 13, height: 32 } }}
                  >
                    <MenuItem value={'select'}>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          margin: 0,
                          ...listItemTextTheme.medium,
                        }}
                        primary={'선택'}
                      />
                    </MenuItem>
                    {inventoryMovementHistoryTypes.map(option => (
                      <MenuItem key={option.displayName} value={option.value.toString()}>
                        <ListItemText
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            margin: 0,
                            ...listItemTextTheme.medium,
                          }}
                          primary={option.displayName}
                        />
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell align={'center'} sx={{ py: 0 }}>
                  <TextField
                    fullWidth
                    defaultValue={note}
                    onBlur={e => setNote(e.target.value)}
                    variant="outlined"
                    size="small"
                    InputProps={{ sx: { fontSize: 13, height: 32 } }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <LoadingButton
            sx={styles.button}
            onClick={handleInventoryMove}
            variant="contained"
            loading={isMoving}
          >
            재고이동
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
export default MoveQuantityBetweenItemForm;

const styles = {
  information: { m: 1 },
  informationText: { fontSize: '20px' },
  buttonContainer: { display: 'flex', justifyContent: 'center', mt: 1 },
  button: { m: 1 },
  select: { width: '100%', height: '100%', borderRadius: '0' },
};
