import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const statusOptions = refCodeOptions?.stocktakingStatus || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '조사상태',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => statusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'locationCode',
      headerName: '로케이션',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '온라인바코드',
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인바코드',
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'code',
      headerName: '아이템코드',
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'name',
      headerName: '아이템명',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'snapshotQty',
      headerName: '시스템수량',
      flex: 1,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'verifiedQty',
      headerName: '조사수량',
      flex: 1,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'differenceQty',
      headerName: '수량차이',
      flex: 1,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'expirationDate',
      headerName: '유통기한',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '조사자',
      flex: 1,
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '조사일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
