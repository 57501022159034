import React, { useState } from 'react';
import InvoiceCompletion from './InvoiceCompletion';
import useSWR from 'swr';
import fetcher from '../../../../../libs/common/fetcher';

type Result = {
  totalCount: number;
  successCount: number;
  errorCount: number;
};

type Props = {
  bulkPackingId: number;
  reset: () => void;
};

const InvoiceNotice = (props: Props) => {
  const { bulkPackingId, reset } = props;
  const { data: result } = useSWR<Result>(
    () => (bulkPackingId ? `/bulk-packings/${bulkPackingId}/result-counts` : null),
    fetcher
  );
  const [status, setStatus] = useState<'ready' | 'printing' | 'complete'>('ready');
  return (
    <>
      {status === 'ready' && (
        <InvoiceCompletion
          total={result?.totalCount ?? 0}
          success={result?.successCount ?? 0}
          failure={result?.errorCount ?? 0}
          onNextClick={() => reset()}
        />
      )}
    </>
  );
};

export default InvoiceNotice;
