import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { StockTaking } from '../components/StocktakingForm';

export const createStocktaking = async (stockTaking: StockTaking) => {
  try {
    return await axiosV2.post(`/inventories/stocktakings`, stockTaking);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고조사 생성 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const StopStocktaking = async (stocktakingId: number) => {
  try {
    return await axiosV2.patch(`/inventories/stocktakings/${stocktakingId}/disuse`, {
      stocktakingId,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고조사 중지 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const createStocktakingLocations = async (stocktakingId: number) => {
  try {
    return await axiosV2.post(`/inventories/stocktakings/${stocktakingId}/locations`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고조사 중지 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const applyStocktakingToInventoryLpn = async (stocktakingId: number) => {
  try {
    return await axiosV2.post(`/inventories/stocktakings/${stocktakingId}/apply`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`재고조사 실재고반영 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
