import { atom } from 'recoil';

export const printerAtom = atom<any>({
  key: 'printerAtom',
  default: undefined,
});

export const printerErrorAtom = atom<Error | undefined>({
  key: 'printerErrorAtom',
  default: undefined,
});

export const isPrintingAtom = atom({
  key: 'isPrintingAtom',
  default: false,
});

const printerAtoms = {
  printer: printerAtom,
  printerError: printerErrorAtom,
  isPrinting: isPrintingAtom,
};

export default printerAtoms;
