import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import { COLORS } from 'consts/common/colors';
import LoadingIcon from './LoadingIcon';

type Props = {
  isLoading: boolean;
  message: string;
};

const LoadingModal = (props: Props) => {
  const { isLoading, message } = props;

  return (
    <Backdrop
      open={isLoading}
      sx={{
        zIndex: 2000,
        backgroundColor: COLORS.faintBlack,
      }}
    >
      <Stack alignItems="center">
        <LoadingIcon />
        <Typography
          variant="h4"
          sx={{
            transform: 'translateY(24px)',
            whiteSpace: 'pre-wrap',
            fontWeight: 700,
            textAlign: 'center',
            color: 'white',
          }}
        >
          {message}
        </Typography>
      </Stack>
    </Backdrop>
  );
};

export default LoadingModal;
