import { createTheme } from '@mui/material/styles';
import { COLORS } from 'consts/common/colors';
import { koKR } from '@mui/x-data-grid';

const muiTheme = createTheme(
  {
    palette: {
      primary: {
        light: COLORS.ktown4uLight,
        main: COLORS.ktown4u,
        contrastText: '#FFFFFF',
      },
      error: {
        main: COLORS.red,
      },
      text: {
        primary: COLORS.black,
        secondary: COLORS.veryDarkGrey,
      },
      common: {
        black: COLORS.black,
      },
    },
    typography: {
      // fontSize 계산식 https://mui.com/material-ui/customization/typography/
      htmlFontSize: 16,
      h5: {
        fontWeight: 500,
      },
      fontFamily: [
        '"Noto Sans KR"',
        '"Segoe UI"',
        'Arial',
        'sans-serif',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 13,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: COLORS.lightLine,
              borderColor: COLORS.lightLine,
            },
            fontWeight: 700,
          },
          outlined: {
            backgroundColor: 'white',
          },
          text: {
            fontWeight: 500,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          autoComplete: 'off',
        },
      },
    },
  },
  koKR
);

export default muiTheme;
