import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { useRecoilValue } from 'recoil';
import CONVEYOR_PATH from '../../../../consts/info/conveyorPath';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';

const gridColumns = (cellClick: (id: number, type: string) => void) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const conveyorPathOptions = refCodeOptions?.conveyorPath || [];
  const comparisonTypeOptions = refCodeOptions?.comparisonType || [];
  const deliveryTypeOptions = refCodeOptions?.deliveryType || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'conveyorPath',
      headerName: '현재포장경로',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        const { id, conveyorPath } = row;

        return (
          <button style={styles.button} onClick={() => cellClick(id, 'conveyorPath')}>
            {CONVEYOR_PATH[conveyorPath as keyof typeof CONVEYOR_PATH]}
          </button>
        );
      },
    },
    {
      field: 'conveyorName',
      headerName: '작업공간',
      flex: 2,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return conveyorPathOptions?.find(v => v.value === value)?.displayName;
      },
    },
    {
      field: 'basePath',
      headerName: '권장포장경로',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        const { id, basePath } = row;

        return (
          <button style={styles.button} onClick={() => cellClick(id, 'basePath')}>
            {CONVEYOR_PATH[basePath as keyof typeof CONVEYOR_PATH]}
          </button>
        );
      },
    },
    {
      field: 'itemCountComparison',
      headerName: '아이템 개수',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value.threshold == 0
          ? '전체'
          : value.threshold +
              '개 ' +
              comparisonTypeOptions?.find(v => v.value === value.comparisonType)?.displayName;
      },
    },
    {
      field: 'weightComparison',
      headerName: '실제중량무게(g)',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        if (params.row.isDefault) return '전체';
        return `${params.value.minWeight} ~ ${params.value.maxWeight}`;
      },
    },
    {
      field: 'volumeWeightComparison',
      headerName: '실제부피무게(g)',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        if (params.row.isDefault) return '전체';
        return `${params.value.minVolumeWeight.toLocaleString()} ~ ${params.value.maxVolumeWeight.toLocaleString()}`;
      },
    },
    {
      field: 'widthComparison',
      headerName: '가로(mm)',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        if (params.row.isDefault) return '전체';
        return `${params.value.minWidth.toLocaleString()} ~ ${params.value.maxWidth.toLocaleString()}`;
      },
    },
    {
      field: 'lengthComparison',
      headerName: '세로(mm)',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        if (params.row.isDefault) return '전체';
        return `${params.value.minLength.toLocaleString()} ~ ${params.value.maxLength.toLocaleString()}`;
      },
    },
    {
      field: 'heightComparison',
      headerName: '높이(mm)',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        if (params.row.isDefault) return '전체';
        return `${params.value.minHeight.toLocaleString()} ~ ${params.value.maxHeight.toLocaleString()}`;
      },
    },
    {
      field: 'boBoxes',
      headerName: '처리제외 박스',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value.join(', ');
      },
    },
    {
      field: 'deliveryType',
      headerName: '배송방식',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        const isDefault = params.row.isDefault;
        return isDefault
          ? ''
          : deliveryTypeOptions?.find(v => v.value === params.value)?.displayName;
      },
    },
    {
      field: 'isTube',
      headerName: '지관통여부',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: params => {
        const isDefault = params.row.isDefault;
        return isDefault ? '' : params.value ? 'O' : 'X';
      },
    },
    {
      field: 'isDefault',
      headerName: '오류경로',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value ? 'O' : '';
      },
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;

const styles = {
  button: {
    cursor: 'pointer',
    color: '#0062CC',
    fontSize: 13,
    textDecoration: 'underline',
    flex: 1,
    height: '100%',
  },
};
