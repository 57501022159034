import React, { ReactNode } from 'react';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

type Message = {
  icon: string;
  primary: string;
  secondary: string[];
};

type Guides = {
  label: string;
  messages: Message[];
};

type Props = {
  guides: Guides;
  onClick: () => void;
  children?: ReactNode;
};

const DiagnosisDialogContent = (props: Props) => {
  const { guides, onClick, children } = props;

  return (
    <Box sx={styles.contentContainer}>
      <Box sx={styles.contentTextWrapper}>
        <List sx={styles.list}>
          {guides?.messages?.map((message: Message, idx: number) => (
            <ListItem key={idx} sx={styles.listItem}>
              <ListItemIcon sx={styles.listItemIcon}>
                <Typography sx={styles.guideTypography}>{message.icon}</Typography>
              </ListItemIcon>
              <ListItemText
                sx={styles.listItemText}
                primaryTypographyProps={{ component: 'div' }}
                primary={<Typography sx={styles.guideTypography}>{message.primary}</Typography>}
                secondaryTypographyProps={{ component: 'div' }}
                secondary={message.secondary?.map((description: string, i: number) => (
                  <Typography key={10 + i} sx={styles.guideTypography}>
                    {description}
                  </Typography>
                ))}
              />
            </ListItem>
          ))}
        </List>
        {children}
      </Box>
      <Box sx={styles.contentButtonWrapper}>
        <Button
          sx={styles.contentButton}
          variant={guides?.label === '새로고침' ? 'outlined' : 'contained'}
          onClick={onClick}
        >
          {guides?.label}
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    width: '100%',
  },
  contentTextWrapper: { flex: 1, width: '100%' },
  contentButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
  },
  secondButton: {
    mr: 1,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  listItem: {
    pt: 0,
    pb: 0,
  },
  listItemIcon: {
    alignSelf: 'flex-start',
    minWidth: '20px',
    mt: 0.8,
  },
  listItemText: {
    whiteSpace: 'pre-wrap',
  },
  guideTypography: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  contentButton: {
    fontSize: '16px',
    height: '48px',
  },
};

export default DiagnosisDialogContent;
