import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';
export const getAisles = async (warehouseId: number) => {
  try {
    return await axiosV2.get('/aisles/list', { params: { warehouseId } });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const getDetail = async (id: number | undefined) => {
  try {
    return await axiosV2.get('/bays/' + id);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export type CreateRequest = {
  warehouseId: number;
  aisleId: number;
  code: string;
  isActive: string;
};

export const create = async (requestBody: CreateRequest) => {
  try {
    return await axiosV2.post('/bays', requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const update = async (id: number, requestBody: CreateRequest) => {
  try {
    return await axiosV2.put(`/bays/${id}`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
