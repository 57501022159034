import React from 'react';
import Box from '@mui/system/Box';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const lpnTypeOption = refCodeOptions?.lpnType || [];
  const lpnStatusOption = refCodeOptions?.lpnStatus || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: params => {
        if (params.value === 'total') {
          return '합계';
        }

        return params.value;
      },
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '물류 바코드',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'imageUrl',
      headerName: '이미지',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.value && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          >
            <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
          </Box>
        ),
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return (
          <p style={{ textAlign: 'start' }}>
            <span>{row.itemName}</span>
            <span style={{ color: 'red' }}>{row.remark ? ` => ${row.remark}` : ''}</span>
          </p>
        );
      },
    },
    {
      field: 'lpnCode',
      headerName: 'LPN 코드',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'receivedQty',
      headerName: '입고등록수량',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.toLocaleString(),
    },
    {
      field: 'cost',
      headerName: '입고공급단가(원)',
      minWidth: 110,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.toLocaleString(),
    },
    {
      field: 'totalReceivedCost',
      headerName: '소계(원)',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.toLocaleString(),
    },
    {
      field: 'expirationDate',
      headerName: '유통기한',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'lpnType',
      headerName: '초도한정여부',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return lpnTypeOption?.find(v => v.value === value)?.displayName;
      },
    },
    {
      field: 'lpnStatus',
      headerName: '상품상태',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return lpnStatusOption?.find(v => v.value === value)?.displayName;
      },
    },
    // {
    //   field: 'inspectedQty',
    //   headerName: '검수수량',
    //   minWidth: 80,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    //   valueFormatter: (params: GridRenderCellParams) => {
    //     return params.value?.toLocaleString();
    //   },
    // },
    // {
    //   field: 'errorQty', //TODO: 필드명 확인요망
    //   headerName: '불량 수량',
    //   minWidth: 80,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    //   valueFormatter: (params: GridRenderCellParams) => {
    //     return params.value?.toLocaleString();
    //   },
    // },
    // {
    //   field: 'passedQty', //TODO: 필드명 확인요망
    //   headerName: '정상수량',
    //   minWidth: 80,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    //   valueFormatter: (params: GridRenderCellParams) => {
    //     return params.value?.toLocaleString();
    //   },
    // },
    // {
    //   field: 'cost',
    //   headerName: '공급단가(원)',
    //   minWidth: 90,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    //   valueFormatter: (params: GridRenderCellParams) => {
    //     return params.value?.toLocaleString();
    //   },
    // },
    // {
    //   field: 'totalPrice', //TODO: 필드명 확인요망
    //   headerName: '공급가합계(원)',
    //   minWidth: 100,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    //   valueFormatter: (params: GridRenderCellParams) => {
    //     return params.value?.toLocaleString();
    //   },
    // },
    // {
    //   field: 'tax', //TODO: 필드명 확인요망
    //   headerName: '부가세',
    //   minWidth: 80,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    //   valueFormatter: (params: GridRenderCellParams) => {
    //     return params.value?.toLocaleString();
    //   },
    // },
    // {
    //   field: 'mismatched', //TODO: 계산하고 색상 변경하는 로직은 다른 항목값 확인 후 작업예정
    //   headerName: '수량불일치',
    //   minWidth: 80,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    // },
  ];
  return columns;
};

export default gridColumns;
